import { Grid } from "@mui/material";
import { ReactNode, useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import ErrorPopup, {
  IErrorPopUp,
  defaultErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import {
  PICKUP_LOCATION_LOAD_SERVICE_ERROR,
  STATIC_TEXT_END_THERAPY_ERROR,
  STATIC_TEXT_MAKE_UNIT_AVAILABLE_ERROR,
  STORAGE_LOCATION_LOAD_SERVICE_ERROR,
  STORAGE_LOCATION_UPDATE_SERVICE_ERROR,
  SVC_FETCH_CUSTOMER_PLUS_INFO,
  SVC_UPDATE_INVENTORY_LOCATION_ERROR_MESSAGE,
} from "../../../util/errorMsg";
import {
  addOrUpdateCustomerPlusDetails,
  getFacilityStorageLocation,
  updateInventoryStorageLocation,
} from "../../../util/inventoryMgrService";
import { changeDateFormat, getSiteUseId } from "../../../util/utilityFunctions";
import { IInventoryProductDetails } from "../inventoryTabContainer.enum";
import "./inventoryPopUpContainer.css";
import {
  CustomerPlusFlow,
  InventoryPopUp,
  InventoryPopUpFlow,
} from "./inventoryPopUpContainer.enum";
import {
  MOVE_UNIT_TO_READY_CARE_SERVICE_ERROR,
  MOVE_UNIT_TO_STORED_PRODUCT_SERVICE_ERROR,
} from "../../../util/errorMsg";
import { moveInventoryProduct } from "../../../util/inventoryMgrService";
import {
  IInventoryPopUpContainerProps,
  IStorageLocation,
} from "./inventoryPopUpContainer.interface";
import { MoveUnitToStoredProductSuccess } from "./popUps/moveUnitToStoreProductSuccess/moveUnitToStoredProductSuccess.component";
import { MoveUnitToStoredProduct } from "./popUps/moveUnitToStoredProduct/moveUnitToStoredProduct.component";
import InventoryUpdateLocation from "./popUps/updateLocation/inventoryUpdateLocation.component";
import { ReturnStoredProductUnit } from "../storedProductInventory/returnStoredProductUnit/returnStoredProductUnit.component";
import { ReturnStoredProductUnitSuccess } from "../storedProductInventory/returnStoredProductUnit/returnStoredProductUnitSuccess/returnStoredProductUnitSuccess.component";
import EndTherapyPopUp, {
  IEndTherapyData,
} from "./popUps/endTherapyPopUp/endTherapyPopup.component";
import { EndTherapySuccess } from "./popUps/endTherapySuccessPopup/endTherapySuccessPopUp.component";
import MakeAvailableForPlacement from "./popUps/makeAvailableForPlacement/makeUnitAvailableForPlacement.component";
import {
  ICustomerListingData,
  ICustomerPlusListingDetails,
} from "../customerPlusInventory/customerPlusInventory.interface";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import moment from "moment";
import { MakeUnitAvailableValidator } from "./popUps/makeAvailableForPlacement/makeUnitAvailableValidator";
import { EndTherapyPopupValidator } from "./popUps/endTherapyPopUp/endTherapyPopupValidator";
import { SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE } from "../../../util/errorCode";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { IStoredProdReturnInformation } from "../storedProductInventory/returnStoredProductUnit/returnStoredProductUnit.interface";
import { defaultStoredProductReturnInformation } from "../storedProductInventory/returnStoredProductUnit/returnStoredProductUnit.model";
import { getCustomerPlusPatientInfo } from "../../../util/actueService";
import { IPatient } from "../../myPatients/patient.interface";

interface IMakeunitAvailable {
  dateAvailableForPlacement: IInputField;
  storageLocations: IInputField;
}

export const InventoryPopUpContainer = ({
  closePopUp,
  currentPopUp,
  flow,
  selectedProduct,
  selectedCustomerPlusListingObj,
  setCurrentPopUp,
  successAndClose,
  Validator = new MakeUnitAvailableValidator(),
  EndTherapyValidator = new EndTherapyPopupValidator(),
  setSelectedProductDetails,
  isMPDFlow = false,
  patientData = null,
}: IInventoryPopUpContainerProps) => {
  const [validator] = useState<MakeUnitAvailableValidator>(Validator);
  const [endTherapyValidator] =
    useState<EndTherapyPopupValidator>(EndTherapyValidator);
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [makeUnitAvailabledata, makeUnitAvailableSetData] =
    useState<IMakeunitAvailable>({
      dateAvailableForPlacement: {
        value: changeDateFormat(moment().toString()),
        valid: ValidationStatus.VALID,
      },
      storageLocations: {
        value: selectedCustomerPlusListingObj?.currentLocation!,
        valid:
          validator?.validate(
            selectedCustomerPlusListingObj?.currentLocation!,
            "storageLocations"
          )?.status! === ValidationStatus.INVALID
            ? ValidationStatus.UNTOUCHED
            : ValidationStatus.VALID,
      },
    });
  const [endTherapyData, setEndTherapyData] = useState<IEndTherapyData>({
    therapyStartDate: {
      value: selectedCustomerPlusListingObj?.therapyStartDate!,
      required: true,
      valid: ValidationStatus.VALID,
    },
    therapyEndDate: {
      value: changeDateFormat(moment().toString()),
      required: true,
      valid: ValidationStatus.VALID,
    },
    storageLocations: {
      value: "",
      required: true,
      valid: ValidationStatus.UNTOUCHED,
    },
  });
  const [storageLocations, setStorageLocations] = useState<string[]>([]);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [returnInformationData, setReturnInformationData] =
    useState<IStoredProdReturnInformation>(
      getDeepClone(defaultStoredProductReturnInformation)
    );
  const getStorageLocations = async () => {
    setCurrentPopUp(InventoryPopUp.LOADER);
    if (isMPDFlow) {
      await getCustomerPlusPlatientInfo(patientData);
    }
    const request = {
      siteUseId: getSiteUseId(authObj),
      userName: authObj && authObj.userProfile?.userName,
    };
    const response = await getFacilityStorageLocation(request);
    if (
      response &&
      response.succeeded &&
      (selectedProduct || selectedCustomerPlusListingObj || isMPDFlow)
    ) {
      let storageLocation =
        selectedProduct?.storageLocation ||
        selectedCustomerPlusListingObj?.currentLocation ||
        "";
      const allLocations: IStorageLocation[] = response.items;
      const filteredResult: IStorageLocation[] = allLocations.filter(
        (location: IStorageLocation) => location.isEnable
      );
      const locations = filteredResult.map((location) => location.locationName);
      setStorageLocations(locations);
      if (
        !locations.includes(storageLocation.toUpperCase()) &&
        flow !== InventoryPopUpFlow.RETURN_UNIT_TO_SOLVENTUM
      ) {
        storageLocation = "";
      }
      openMainPopUp();
    } else {
      const errorForStorageLocation = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorFlag: true,
        errorMessage:
          flow !== InventoryPopUpFlow.RETURN_UNIT_TO_SOLVENTUM
            ? STORAGE_LOCATION_LOAD_SERVICE_ERROR
            : PICKUP_LOCATION_LOAD_SERVICE_ERROR,
      };
      setError(errorForStorageLocation);
      setCurrentPopUp(InventoryPopUp.ERROR_POPUP);
    }
  };

  const makeUnitAvailableRequestBody = (action: CustomerPlusFlow) => {
    const patientFullName = `${selectedCustomerPlusListingObj?.patientFirstName} ${selectedCustomerPlusListingObj?.patientLastName}`;
    setSelectedProductDetails({
      ...selectedCustomerPlusListingObj!,
      availableDate: makeUnitAvailabledata.dateAvailableForPlacement.value!,
      storageLocation: makeUnitAvailabledata.storageLocations.value!,
    });
    const body = {
      ProductName: selectedCustomerPlusListingObj?.productName,
      UnitTagId: selectedCustomerPlusListingObj?.unitTagId,
      StorageLocation: makeUnitAvailabledata?.storageLocations?.value,
      SerialNumber: selectedCustomerPlusListingObj?.serialNumber,
      PatientName: patientFullName,
      AvaliablePlacementDate:
        makeUnitAvailabledata.dateAvailableForPlacement.value,
      Action: action,
      ID: selectedCustomerPlusListingObj?.id,
      siteUseId: getSiteUseId(authObj),
    };
    return body;
  };

  const endTherapyReqBody = (action: CustomerPlusFlow) => {
    const patientFullName = `${selectedCustomerPlusListingObj?.patientFirstName} ${selectedCustomerPlusListingObj?.patientLastName}`;
    setSelectedProductDetails({
      ...selectedCustomerPlusListingObj!,
      therapyEndDate: endTherapyData.therapyEndDate.value!,
      storageLocation: endTherapyData.storageLocations.value!,
    });
    const body = {
      ProductName: selectedCustomerPlusListingObj?.productName,
      UnitTagId: selectedCustomerPlusListingObj?.unitTagId,
      StorageLocation: endTherapyData.storageLocations.value!,
      SerialNumber: selectedCustomerPlusListingObj?.serialNumber,
      PatientName: patientFullName,
      CurrentLocation: selectedCustomerPlusListingObj?.currentLocation,
      TherapyEndDate: endTherapyData.therapyEndDate.value!,
      Action: action,
      SiteUseId: getSiteUseId(authObj),
      ID: selectedCustomerPlusListingObj?.id,
    };
    return body;
  };

  const getCurrentPopup = (action: CustomerPlusFlow) => {
    switch (action) {
      case CustomerPlusFlow.MAKE_UNIT_AVAILABLE_FOR_PLACEMENT:
        setCurrentPopUp(InventoryPopUp.MAKE_AVAILABLE_FOR_PLACEMENT_SUCCESS);
        break;
      case CustomerPlusFlow.END_THERAPY:
        setCurrentPopUp(InventoryPopUp.END_THERAPY_SUCCESS);
        break;
      case CustomerPlusFlow.CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION:
        successAndClose(true);
        break;
      default:
        break;
    }
  };

  const getCurrentErrorMessage = (action: CustomerPlusFlow) => {
    let errorMessage = "";
    switch (action) {
      case CustomerPlusFlow.MAKE_UNIT_AVAILABLE_FOR_PLACEMENT:
        errorMessage = STATIC_TEXT_MAKE_UNIT_AVAILABLE_ERROR;
        break;
      case CustomerPlusFlow.END_THERAPY:
        errorMessage = STATIC_TEXT_END_THERAPY_ERROR;
        break;
      case CustomerPlusFlow.CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION:
        errorMessage = SVC_UPDATE_INVENTORY_LOCATION_ERROR_MESSAGE;
        break;
      default:
        break;
    }
    return errorMessage;
  };

  const cutomerPlusMenuActionBtnClick = async (
    action: CustomerPlusFlow,
    reqBody: any
  ) => {
    setCurrentPopUp(InventoryPopUp.LOADER);
    const response = await addOrUpdateCustomerPlusDetails(reqBody);
    if (response && response.succeeded) {
      getCurrentPopup(action);
    } else {
      const errorForEndTherapy = {
        errorCode:
          response?.error?.errorCode ||
          response?.error?.code ||
          response?.status,
        errorFlag: true,
        errorMessage: getCurrentErrorMessage(action),
      };
      setError(errorForEndTherapy);
      setCurrentPopUp(InventoryPopUp.ERROR_POPUP);
    }
  };

  const changeStatusButtonClick = () => {
    const isValid = validator?.validateAll(
      makeUnitAvailabledata,
      makeUnitAvailableSetData
    );
    if (isValid) {
      const reqBody = makeUnitAvailableRequestBody(
        CustomerPlusFlow.MAKE_UNIT_AVAILABLE_FOR_PLACEMENT
      );
      cutomerPlusMenuActionBtnClick(
        CustomerPlusFlow.MAKE_UNIT_AVAILABLE_FOR_PLACEMENT,
        reqBody
      );
    }
  };

  const endTherapyBtn = () => {
    const isValid = endTherapyValidator?.validateAll(
      endTherapyData,
      setEndTherapyData
    );
    if (isValid) {
      const reqBody = endTherapyReqBody(CustomerPlusFlow.END_THERAPY);
      cutomerPlusMenuActionBtnClick(CustomerPlusFlow.END_THERAPY, reqBody);
    }
  };

  const confirmMove = async (
    programType: number,
    prodcut: IInventoryProductDetails,
    successPopUp: InventoryPopUp
  ) => {
    setCurrentPopUp(InventoryPopUp.LOADER);
    const userName = authObj && authObj.userProfile?.userName;
    const request = {
      programType: programType,
      productName: prodcut.product,
      serialNumber: prodcut.serialNumber,
      siteUseId: getSiteUseId(authObj),
      storageLocation: prodcut.storageLocation,
      userName: userName,
    };
    const response = await moveInventoryProduct(request);
    if (response && response.succeeded) {
      setCurrentPopUp(successPopUp);
    } else {
      const errorForStorageLocation = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorFlag: true,
        errorMessage:
          currentPopUp === InventoryPopUp.MOVE_TO_STORED_PRODUCT
            ? MOVE_UNIT_TO_STORED_PRODUCT_SERVICE_ERROR
            : MOVE_UNIT_TO_READY_CARE_SERVICE_ERROR,
      };
      setError(errorForStorageLocation);
      setCurrentPopUp(InventoryPopUp.ERROR_POPUP);
    }
  };

  const updateStoredProductLocation = async (
    data: IInventoryProductDetails
  ) => {
    const reqBody = {
      serialNumber: data.serialNumber,
      storageLocation: data.storageLocation,
    };
    setCurrentPopUp(InventoryPopUp.LOADER);
    const response = await updateInventoryStorageLocation(reqBody);
    if (response && response.succeeded) {
      successAndClose(true);
    } else {
      const errorObj: IErrorPopUp = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorFlag: true,
        errorMessage: SVC_UPDATE_INVENTORY_LOCATION_ERROR_MESSAGE,
      };
      setError(errorObj);
      setCurrentPopUp(InventoryPopUp.ERROR_POPUP);
    }
  };

  const updateCustomerPlusStorageLocation = async (
    data: IInventoryProductDetails
  ) => {
    let siteUseIdValue = getSiteUseId(authObj);
    if (siteUseIdValue) {
      const reqBody = {
        serialNumber: data.serialNumber,
        storageLocation: data.storageLocation,
        productName: data.productName,
        unitTagId: data.unitTagId,
        siteUseId: siteUseIdValue,
        action: CustomerPlusFlow.CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION,
        id: data.id,
      };
      cutomerPlusMenuActionBtnClick(
        CustomerPlusFlow.CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION,
        reqBody
      );
    } else {
      const errorForStorageLocation = {
        errorCode: SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
        errorFlag: true,
        errorMessage: STORAGE_LOCATION_UPDATE_SERVICE_ERROR,
      };
      setError(errorForStorageLocation);
      setCurrentPopUp(InventoryPopUp.ERROR_POPUP);
    }
  };

  const openMainPopUp = () => {
    switch (flow) {
      case InventoryPopUpFlow.MOVE_TO_READY_CARE:
        setCurrentPopUp(InventoryPopUp.MOVE_TO_READY_CARE_PRODUCT);
        break;
      case InventoryPopUpFlow.MOVE_TO_STORED_PRODUCT:
        setCurrentPopUp(InventoryPopUp.MOVE_TO_STORED_PRODUCT);
        break;
      case InventoryPopUpFlow.UPDATE_LOCATION:
        setCurrentPopUp(InventoryPopUp.UPDATE_LOCATION);
        break;
      case InventoryPopUpFlow.RETURN_UNIT_TO_SOLVENTUM:
        setCurrentPopUp(InventoryPopUp.RETURN_UNIT_TO_SOLVENTUM);
        break;
      case InventoryPopUpFlow.END_THERAPY:
        setCurrentPopUp(InventoryPopUp.END_THERAPY);
        break;
      case InventoryPopUpFlow.MAKE_AVAILABLE_FOR_PLACEMENT:
        setCurrentPopUp(InventoryPopUp.MAKE_AVAILABLE_FOR_PLACEMENT);
        break;
      case InventoryPopUpFlow.MAKE_AVAILABLE_FOR_PLACEMENT_SUCCESS:
        setCurrentPopUp(InventoryPopUp.MAKE_AVAILABLE_FOR_PLACEMENT_SUCCESS);
        break;
      case InventoryPopUpFlow.CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION:
        setCurrentPopUp(InventoryPopUp.CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION);
        break;
      default:
        break;
    }
  };

  const redirectHandler = (
    changeSectionTo: InventoryPopUp,
    product: IInventoryProductDetails
  ): void => {
    if (selectedProduct) {
      selectedProduct.storageLocation = product.storageLocation;
    }
    setCurrentPopUp(changeSectionTo);
  };

  const generateCustomerPlus = () => {
    if (selectedCustomerPlusListingObj) {
      let data: IInventoryProductDetails = {
        storageLocation: selectedCustomerPlusListingObj.currentLocation!,
        product: selectedCustomerPlusListingObj.productNameDescription!,
        productName: selectedCustomerPlusListingObj.productName!,
        serialNumber: selectedCustomerPlusListingObj.serialNumber!,
        storageDays: parseInt(selectedCustomerPlusListingObj.storageDays),
        status: selectedCustomerPlusListingObj.status,
        unitTagId: selectedCustomerPlusListingObj.unitTagId,
        workOrderNumber: 0,
        storeAndClean: "",
        id: selectedCustomerPlusListingObj.id,
        isMissingAsset: selectedCustomerPlusListingObj.isMissingAsset!,
      };
      return data;
    }
    return null;
  };

  useEffect(() => {
    getStorageLocations();
  }, []);

  const getCustomerPlusPlatientInfo = async (
    patientSelected: IPatient | null
  ) => {
    setCurrentPopUp(InventoryPopUp.LOADER);
    const reqBody = {
      roNumber: patientSelected!.roNumber,
      siteUseId: getSiteUseId(authObj),
      userName: authObj?.userProfile?.userName,
      serialNumber: patientSelected!.productSerialNumber,
    };
    const response = await getCustomerPlusPatientInfo(reqBody);
    if (response && response.succeeded) {
      setSelectedProductDetails(response.item);
    } else {
      const errorForselectedCustomerPlusData = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorFlag: true,
        errorMessage: SVC_FETCH_CUSTOMER_PLUS_INFO,
      };
      setError(errorForselectedCustomerPlusData);
      setCurrentPopUp(InventoryPopUp.ERROR_POPUP);
    }
  };

  const popUpToDisplay = () => {
    let page: ReactNode;
    switch (currentPopUp) {
      case InventoryPopUp.ERROR_POPUP:
        page = (
          <ErrorPopup
            errorPopupFlag={error?.errorFlag}
            handleBackButton={closePopUp}
            popUpStyles="errorPopup"
            errorMessage={error?.errorMessage}
            buttontitle="Done"
            showTitle={false}
            isShortSentence={
              flow !== InventoryPopUpFlow.RETURN_UNIT_TO_SOLVENTUM
            }
            errorCode={error?.errorCode}
            isAlreadyPopUpOpen={true}
          />
        );
        break;

      case InventoryPopUp.LOADER:
        page = (
          <div className="inventory-pop-up-spinner">
            <LoadingSpinner />
          </div>
        );
        break;

      case InventoryPopUp.MOVE_TO_READY_CARE_PRODUCT:
        page = (
          <MoveUnitToStoredProduct
            closePopUp={closePopUp}
            confirmMove={confirmMove}
            isMoveStoredProduct={false}
            selectedProduct={selectedProduct!}
            storageLocations={storageLocations}
          />
        );
        break;

      case InventoryPopUp.MOVE_TO_READY_CARE_PRODUCT_SUCCESS:
        page = (
          <MoveUnitToStoredProductSuccess
            closePopUp={successAndClose}
            isMoveStoredProduct={false}
            selectedProdcut={selectedProduct!}
          />
        );
        break;

      case InventoryPopUp.MOVE_TO_STORED_PRODUCT:
        page = (
          <MoveUnitToStoredProduct
            closePopUp={closePopUp}
            confirmMove={confirmMove}
            isMoveStoredProduct={true}
            selectedProduct={selectedProduct!}
            storageLocations={storageLocations}
          />
        );
        break;

      case InventoryPopUp.MOVE_TO_STORE_PRODUCT_SUCCESS:
        page = (
          <MoveUnitToStoredProductSuccess
            closePopUp={successAndClose}
            isMoveStoredProduct={true}
            selectedProdcut={selectedProduct!}
          />
        );
        break;

      case InventoryPopUp.UPDATE_LOCATION:
        page = (
          <InventoryUpdateLocation
            redirectHandler={redirectHandler}
            updateStoredProductLocation={updateStoredProductLocation}
            selectedProduct={selectedProduct!}
            storageLocations={storageLocations}
            closePopUp={closePopUp}
          />
        );
        break;

      case InventoryPopUp.RETURN_UNIT_TO_SOLVENTUM:
        page = (
          <ReturnStoredProductUnit
            redirectHandler={redirectHandler}
            returnStoredProductData={selectedProduct!!}
            locationData={storageLocations}
            closePopUp={closePopUp}
            setError={setError}
            returnInformationData={returnInformationData}
            setReturnInformationData={setReturnInformationData}
          />
        );
        break;

      case InventoryPopUp.RETURN_UNIT_TO_SOLVENTUM_SUCCESS:
        page = (
          <ReturnStoredProductUnitSuccess
            returnStoredProductData={selectedProduct!!}
            closePopup={successAndClose}
            returnInformationData={returnInformationData}
          />
        );
        break;

      case InventoryPopUp.END_THERAPY:
        page = (
          <EndTherapyPopUp
            selectedProduct={selectedCustomerPlusListingObj!}
            closePopUp={closePopUp}
            storageLocations={storageLocations}
            endTherapyData={endTherapyData}
            setEndTherapyData={setEndTherapyData}
            endButtonAction={endTherapyBtn}
          />
        );
        break;

      case InventoryPopUp.END_THERAPY_SUCCESS:
        page = (
          <EndTherapySuccess
            closePopUp={successAndClose}
            selectedProduct={selectedCustomerPlusListingObj!}
          />
        );
        break;

      case InventoryPopUp.MAKE_AVAILABLE_FOR_PLACEMENT:
        page = (
          <MakeAvailableForPlacement
            selectedProduct={selectedCustomerPlusListingObj!}
            closePopUp={closePopUp}
            storageLocations={storageLocations}
            ChangeStatusBtn={changeStatusButtonClick}
            makeUnitAvailabledata={makeUnitAvailabledata}
            makeUnitAvailableSetData={makeUnitAvailableSetData}
          />
        );
        break;

      case InventoryPopUp.MAKE_AVAILABLE_FOR_PLACEMENT_SUCCESS:
        page = (
          <EndTherapySuccess
            closePopUp={successAndClose}
            selectedProduct={selectedCustomerPlusListingObj!}
            isComingFromMakeUnitAvailable={true}
          />
        );
        break;
      case InventoryPopUp.CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION:
        page = (
          <InventoryUpdateLocation
            redirectHandler={redirectHandler}
            updateStoredProductLocation={updateCustomerPlusStorageLocation}
            selectedProduct={generateCustomerPlus()}
            storageLocations={storageLocations}
            closePopUp={closePopUp}
          />
        );
        break;
    }
    return page;
  };

  return (
    <div>
      <Grid>{popUpToDisplay()}</Grid>
    </div>
  );
};
