import "./billingInformation.css";
import { Grid, InputBase, useMediaQuery } from "@mui/material";
import { InpatientOrderValidator } from "../../inpatientOrder.validator";
import {
  IBillingInformation,
  IBillingInformationProps,
} from "./billingInformation.interface";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";

import {
  BILLING_INFO_POREQUIRED_FALSE,
  BILLING_INFO_POREQUIRED_TRUE,
} from "../../../../util/staticText";

export const BillingInformation = ({
  data,
  setData,
  billingInformation,
  isSelectionSuppliesFlow,
}: IBillingInformationProps) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const billingInfoDescription = () => {
    if (billingInformation.isPORequired === true) {
      return BILLING_INFO_POREQUIRED_TRUE;
    } else if (billingInformation.isPORequired === false) {
      return BILLING_INFO_POREQUIRED_FALSE;
    } else return "";
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const validator = new InpatientOrderValidator();
    const updatedValue = value.toUpperCase();
    const isValid = validator.validate(updatedValue, name);

    const getValidationStatus = () => {
      if (
        (name.toLowerCase() === "salesponumber" &&
          data.billingInformation.value.salesPONumber.required === false &&
          updatedValue === "") ||
        (name.toLowerCase() === "rentalponumber" &&
          data.billingInformation.value.rentalPONumber.required === false &&
          updatedValue === "")
      ) {
        return ValidationStatus.VALID;
      }
      return isValid!.status;
    };

    const tempData = {
      ...data,
      billingInformation: {
        ...data.billingInformation,
        value: {
          ...data.billingInformation.value,
          [name]: {
            ...Object(data.billingInformation.value)[name],
            errorMessage: null,
            valid: getValidationStatus(),
            value: updatedValue,
          },
        },
      },
    };
    setData(tempData);
  };

  const getRentalPONumber = () => {
    if (
      billingInformation &&
      billingInformation.rentalPONumber &&
      billingInformation.rentalPONumber.value !== null &&
      billingInformation.rentalPONumber.value.trim() !== ""
    ) {
      return billingInformation.rentalPONumber.value;
    }
    return "";
  };
  const getSalesPONumber = () => {
    if (
      billingInformation &&
      billingInformation.salesPONumber &&
      billingInformation.salesPONumber.value !== null &&
      billingInformation.salesPONumber.value.trim() !== ""
    ) {
      return billingInformation.salesPONumber.value;
    }
    return "";
  };

  return (
    <div
      className="in-patient-order-billing-information-component"
      data-testid="in-patient-order-billing-information-component"
    >
      <p
        className="in-patient-order-billing-information-title"
        data-testid="in-patient-order-billing-information-title"
        id="in-patient-order-billing-information-title"
      >
        Billing Information
      </p>
      <div className="billingInfoDiv">
        <p className="billingInfoDesc" data-testid="billing-informantion-desc">
          {billingInfoDescription()}
        </p>
      </div>
      <Grid
        className="in-patient-order-billing-information-grid-container"
        container
        spacing={2}
      >
        {isSelectionSuppliesFlow && (
          <Grid
            className="in-patient-order-billing-information-grid-item"
            item
            xs={isMobileScreen ? 12 : 6}
          >
            <InputWithLabel
              isRequired={billingInformation.salesPONumber?.required}
              error={
                billingInformation.salesPONumber?.valid ===
                ValidationStatus.INVALID
              }
              id={billingInformation.salesPONumber?.componentId!}
              isShowWarning={true}
              label={billingInformation.salesPONumber?.title!}
              labelClassName="billing-information-title"
              testId={billingInformation.salesPONumber?.componentId!}
              warningMessage={billingInformation.salesPONumber?.errorMessage}
            >
              <InputBase
                className="billing-information-input"
                data-testid={billingInformation.salesPONumber?.id!}
                id={billingInformation.salesPONumber?.id!}
                name="salesPONumber"
                onChange={validateAndSetData}
                value={getSalesPONumber()}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </InputWithLabel>
          </Grid>
        )}
        <Grid
          className="in-patient-order-billing-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              billingInformation.rentalPONumber.valid ===
              ValidationStatus.INVALID
            }
            isRequired={billingInformation.rentalPONumber.required}
            id={billingInformation.rentalPONumber.componentId!}
            isShowWarning={true}
            label={billingInformation.rentalPONumber.title!}
            labelClassName="billing-information-title"
            testId={billingInformation.rentalPONumber.componentId!}
            warningMessage={billingInformation.rentalPONumber.errorMessage}
          >
            <InputBase
              className="billing-information-input"
              data-testid={billingInformation.rentalPONumber.id!}
              id={billingInformation.rentalPONumber.id!}
              name="rentalPONumber"
              onChange={validateAndSetData}
              value={getRentalPONumber()}
              inputProps={{
                maxLength: 30,
              }}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
