import { Grid, Typography } from "@mui/material";
import { useContext } from "react";
import "./salesAssistanceRequestor.css";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../../context/MobileDisplayContext";
import { formatPhoneNumber } from "../../../../../util/utilityFunctions";

const SalesAssistanceRequestor = () => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const userProfile = authObj!.userProfile;
  const phoneNumber = userProfile && userProfile.phoneNumber;
  const mobileNumber = userProfile && userProfile.mobilePhoneNumber!;
  const requestorPhnNumber = phoneNumber !== "" ? phoneNumber : mobileNumber;
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);
  return (
    <>
      {userProfile && (
        <div
          className="sales-assistance-and-requestor-main-div"
          data-testid="sales-assistance-and-requestor-main-div"
          id="sales-assistance-and-requestor-main-div"
        >
          <div
            className="sales-assisstance-div"
            data-testid="sales-assisstance-div"
          >
            <Typography
              className="sales-assisstance-header"
              data-testid="sales-assisstance-header"
              id="sales-assisstance-header"
            >
              Assistance
            </Typography>
            <Typography
              className="sales-assisstance-description"
              data-testid="sales-assisstance-description"
              id="sales-assisstance-description"
            >
              Please select your contact reason, enter additional information
              and upload helpful screen images or documents if applicable. You
              will receive an email confirmation that your comments have been
              sent.
            </Typography>
          </div>
          <div
            className="sales-assisstance-div"
            data-testid="requestor-section-div"
          >
            <Typography
              className="sales-assisstance-header requestor"
              data-testid="sales-assisstance-header requestor"
              id="sales-assisstance-header requestor"
            >
              Requester
            </Typography>
            <Grid container spacing={2} xs={12}>
              <Grid item xs={isMobileScreen ? 12 : 2.5}>
                <Typography
                  className="requestor-data-title"
                  data-testid="requestor-salesPerson-title"
                  id="requestor-salesPerson-title"
                >
                  Requester Name
                </Typography>
                <Typography
                  className="requestor-data-title-value"
                  data-testid="requestor-salesPerson-title-value"
                  id="requestor-salesPerson-title-value"
                >
                  {userProfile.firstName + " " + userProfile.lastName}
                </Typography>
              </Grid>
              <Grid item xs={isMobileScreen ? 12 : 4.5}>
                <Typography
                  className="requestor-data-title"
                  data-testid="requestor-email-title"
                  id="requestor-email-title"
                >
                  Email
                </Typography>
                <Typography
                  className="requestor-data-title-value email-phNumber"
                  data-testid="requestor-email-title-value"
                  id="requestor-email-title-value"
                >
                  <a href={`mailto:${userProfile.emailAddress!}`}>
                    {userProfile.emailAddress}
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={isMobileScreen ? 12 : 2}>
                <Typography
                  className="requestor-data-title"
                  data-testid="requestor-phNo-title"
                  id="requestor-phNo-title"
                >
                  Phone Number
                </Typography>
                <Typography
                  className="requestor-data-title-value email-phNumber"
                  data-testid="requestor-phNo-title-value"
                  id="requestor-phNo-title-value"
                >
                  <a
                    href={`tel:${formatPhoneNumber(requestorPhnNumber ?? "")}`}
                  >
                    {formatPhoneNumber(requestorPhnNumber ?? "")}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesAssistanceRequestor;
