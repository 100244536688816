import moment from "moment";
import { IDropZoneDocumentSelect } from "../../core/customDropZone/dropZoneDocumentSelect.interface";
import {
  IInputField,
  Validation,
  ValidationStatus,
} from "../../core/interfaces/input.interface";
import { getDeepClone } from "../../util/ObjectFunctions";
import { ERROR_INVALID_INCOMPLETE_DATA } from "../../util/errorMsg";
import { Validator } from "../../util/order.validations";
import {
  getValidObj,
  makeCapitalEachWordInString,
  updateErrorMessage,
} from "../../util/utilityFunctions";
import {
  Question,
  QuestionTypes,
  WoundQuestionaries,
} from "./clinicalInformation/clinicalInfo.interface";
import { ShowAdditionalFields } from "./insuranceInformation/insuranceInformation/insuranceInformation.model";
import { InsuranceInformationValidator } from "./insuranceInformation/insuranceInformation/insuranceInformation.validator";

export class NewOrderValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(txt: string): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      // Patient information
      ["firstName", this._validator.patientInfonameValidation],
      ["middleName", this._validator.middleNameValidation],
      ["lastName", this._validator.patientInfonameValidation],
      ["patientinfofirstName", this._validator.patientInfonameValidation],
      ["patientinfolastName", this._validator.patientInfonameValidation],
      ["userName", this._validator.userNameValidation],
      ["dob", this._validator.dobValidation],
      ["language", this._validator.emptyCheck],
      ["phone", this._validator.phoneValidation],
      ["phoneType", this._validator.emptyCheck],
      ["email", this._validator.patientemailValidation],
      ["address1", this._validator.addressLine1],
      ["address2", this._validator.addressValidationWithEmpty],
      ["city", this._validator.cityValidation],
      ["state", this._validator.emptyCheck],
      ["zip", this._validator.facilityZipcodeValidation],

      // Emergency Contact Info
      ["emergencyContactFirstName", this._validator.nameValidationWithEmpty],
      [
        "emergencyContactLastName",
        this._validator.lastNameValidationWithEmptyNotMandatory,
      ],
      [
        "emergencyContactPhoneNumber",
        this._validator.phoneValidationForOptional,
      ],

      // Contributing Cause
      ["contributingCause", this._validator.emptyCheck],
      ["dateOfAccident", this._validator.dateValidation],
      ["accidentType", this._validator.emptyCheck],

      // Submit a valid prescription
      ["submitPrescription", this._validator.emptyCheck],

      //prod info
      ["productInformation", this._validator.emptyCheck],

      ["deliveryContactFirstName", this._validator.patientInfonameValidation],
      ["deliveryContactLastName", this._validator.patientInfonameValidation],
      ["deliveryContactPhone", this._validator.phoneValidation],
      ["deliveryInstructions", this._validator.additionalInfoValidation],

      // Therapy Information
      ["lengthOfTherapy", this._validator.emptyCheck],
      ["goalOfTherapy", this._validator.emptyCheck],

      // VAC Therapy Location
      ["vacTherapyLocation", this._validator.emptyCheck],
      ["otherVacUseLocation", this._validator.vacTherapyLocationOther],

      // Prescriber Search
      ["nationalRegistryNumber", this._validator.physicianNumberValidation],
      ["nationalRegistryState", this._validator.emptyCheck],
      ["filterNPIList", this._validator.emptyCheck],
      ["NPIFirstName", this._validator.nameValidationWithEmpty],
      ["NPILastName", this._validator.prescriberLastNameValidation],

      // Inpatient Transition
      ["wasNPWTInitiated", this._validator.emptyCheck],
      ["dateInitiated", this._validator.dateValidation],

      // Print Common Docs
      ["commonDocs", this._validator.emptyCheck],

      // Patient Current Address
      ["IsSamePermanentAddress", this._validator.noValidation],
      ["patientCurrentAddressPhone", this._validator.phoneValidation],
      ["patientCurrentAddress1", this._validator.addressLine1],
      ["patientCurrentAddress2", this._validator.addressValidationWithEmpty],
      ["patientCurrentAddressCity", this._validator.cityValidation],
      ["patientCurrentAddressState", this._validator.emptyCheck],
      ["patientCurrentAddressZip", this._validator.facilityZipcodeValidation],

      // Verify Requester Info
      ["IsRequesterSameasSubmitter", this._validator.noValidation],
      ["requesterFirstName", this._validator.lastNameValidation],
      ["requesterLastName", this._validator.lastNameValidation],
      ["requesterEmail", this._validator.mandatoryEmailValidation],
      ["requesterPhoneNumber", this._validator.phoneValidation],
      ["requesterTitle", this._validator.titleValidation],

      // Home Care Provider
      ["homeCareName", this._validator.addressValidation],
      ["addressLine1", this._validator.addressLineValidation],
      ["addressLine2", this._validator.addressValidationWithEmpty],
      ["city", this._validator.cityValidation],
      ["state", this._validator.emptyCheck],
      ["zipCode", this._validator.facilityZipcodeValidation],
      ["extension", this._validator.extensionValidation],
      ["providerType", this._validator.emptyCheck],
      ["contactNumber", this._validator.phoneValidation],

      // Delivery Information
      ["deliveryProductNeedByDate", this._validator.deliveryDateValidation],
      ["deliveryProductNeedByTime", this._validator.emptyCheck],
      ["deliverySiteType", this._validator.emptyCheck],
      ["deliveryFacilityName", this._validator.facilityNameValidation],
      ["deliveryAddressLine1", this._validator.addressLine1],
      ["deliveryAddressLine2", this._validator.addressValidationWithEmpty],
      ["deliveryCity", this._validator.cityValidation],
      ["deliveryState", this._validator.emptyCheck],
      ["deliveryZipCode", this._validator.facilityZipcodeValidation],

      // Previous Therapies details
      ["previousTherapies", this._validator.previousTherapies],
      ["previousTherapyOther", this._validator.previousTherapyOther],
      ["previousTherapiesCausesOther", this._validator.previousTherapyOther],

      // Comorbodities
      ["wndInfocomorbodities", this._validator.previousTherapies],
      ["payerType", this._validator.emptyCheck],
      ["payerName", this._validator.previousTherapyOther],
      ["payerContactNumber", this._validator.phoneValidation],

      // New Order Nutrion section
      ["nutriStatusCompromized", this._validator.emptyCheck],
      ["nutritionActions", this._validator.noCheckboxSelection],

      // Osteomyelitis
      ["isOsteomyelitisPresent", this._validator.emptyCheck],
      ["osteomyelitisies", this._validator.osteomyelitisies],
      ["osteomyelitisItemText", this._validator.osteomyelitis],
      ["isTreatemenForResolveBoneInfection", this._validator.emptyCheck],
      ["antibioticName", this._validator.nameValidation],

      // Debridement
      ["debridementAttempted", this._validator.emptyCheck],
      ["debridementType", this._validator.emptyCheck],
      ["debridementDate", this._validator.dateValidation],
      ["serialDebridementRequired", this._validator.emptyCheck],

      // woundTunneling
      ["woundTunneling", this._validator.emptyCheck],
      ["location1Position", this._validator.emptyCheck],
      ["location2Position", this._validator.emptyCheck],
      ["location1Depth", this._validator.woundDimension],
      ["location2Depth", this._validator.woundDimension],

      // woundDimension
      ["woundMeasurementDate", this._validator.measurementDateValidation],
      ["woundThickness", this._validator.emptyCheck],
      ["woundLength", this._validator.woundDimension],
      ["woundWidth", this._validator.woundDimension],
      ["woundDepth", this._validator.woundDimension],

      // wound exudate
      ["exudateAmount", this._validator.emptyCheck],
      ["exudateAppearance", this._validator.emptyCheck],

      // Clinical Information
      [
        "shortNarrativeOfPossibleConsequences",
        this._validator.additionalInfoValidation,
      ],
      ["woundAge", this._validator.woundAgeValidation],
      ["woundAgeType", this._validator.emptyCheck],
      ["woundLocation", this._validator.emptyCheck],
      ["woundDirection", this._validator.emptyCheck],
      ["woundOrientation", this._validator.emptyCheck],
      ["isTissuePresent", this._validator.emptyCheck],

      // woundUndermining
      ["woundUndermining", this._validator.emptyCheck],
      ["underminingLocation1PositionFrom", this._validator.emptyCheck],
      ["underminingLocation2PositionFrom", this._validator.emptyCheck],
      ["underminingLocation1PositionTo", this._validator.emptyCheck],
      ["underminingLocation2PositionTo", this._validator.emptyCheck],
      ["underminingLocation1Depth", this._validator.woundDimension],
      ["underminingLocation2Depth", this._validator.woundDimension],
      ["woundType", this._validator.emptyCheck],
      ["productName", this._validator.emptyCheck],
      ["secProductName", this._validator.emptyCheck],
      ["productSizeName", this._validator.emptyCheck],
      ["secProductSizeName", this._validator.emptyCheck],

      // Accessory
      ["accessories", this._validator.emptyArrayCheck],
      [
        "updatedPrescriberEmail",
        this._validator.mandatoryPrescriberEmailValidation,
      ],

      //Homecare Provider Search
      ["homeCareProvider", this._validator.emptyCheck],
      ["homeCareProviderSearch", this._validator.payerNameValidation],

      //share order invite validation
      ["shareOrderInviteFName", this._validator.nameValidation],
      ["shareOrderInviteLName", this._validator.nameValidation],
      ["shareOrderInviteEmail", this._validator.emailValidation],
      ["shareOrderInviteNote", this._validator.additionalInfoValidation],

      ["length", this._validator.woundDimension],
      ["width", this._validator.woundDimension],
      ["depth", this._validator.woundDimension],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateArray(input: any, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  private dbValidateFieldToMethodMapping(
    field: string
  ): ((txt: string) => Promise<Validation>) | undefined {
    const mapping = new Map<string, (txt: string) => Promise<Validation>>([
      // account information
      ["userName", this._validator.userNameDbValidation],
      // contact information
      ["email", this._validator.emailDbValidation],
    ]);
    const validator = mapping.get(field);
    return validator;
  }

  public validateWithDb(input: string, field: string) {
    try {
      const validator = this.dbValidateFieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    newOrderData: any,
    requesterData: any,
    dressingKit: any,
    deliveryInformation: any,
    newOrderDocuments: any,
    prescriptionDocuments: any,
    updateNewOrderDataIfUntouchedAndValidated: Function,
    updateRequesterDataIfUntouchedAndValidated: Function,
    updateDressingDataIfUntouchedAndValidated: Function,
    updateDeliveryInformationDataIfUntouchedAndValidated: Function,
    updateUploadDocumentsDataIfUntouchedAndValidated: Function,
    setScrollableComponentId: React.Dispatch<
      React.SetStateAction<string | null>
    >,
    setAutoCursorComponentId: React.Dispatch<
      React.SetStateAction<string | null>
    >
  ) {
    let temp = getDeepClone(newOrderData);
    let requesterTemp = getDeepClone(requesterData);
    let dressingTemp = getDeepClone(dressingKit);
    let deliveryInformationTemp = getDeepClone(deliveryInformation);
    const primaryInsurance = temp["primaryInsurance"];
    const secondaryInsurance = temp["secondaryInsurance"];
    const uploadDocumentsTemp = getDeepClone(newOrderDocuments);
    const prescriptionDocumentsTemp = getDeepClone(prescriptionDocuments);
    if (temp["IsSamePermanentAddress"].value === "true") {
      temp["patientCurrentAddress1"].valid = ValidationStatus.VALID;
      temp["patientCurrentAddress2"].valid = ValidationStatus.VALID;
      temp["patientCurrentAddressCity"].valid = ValidationStatus.VALID;
      temp["patientCurrentAddressState"].valid = ValidationStatus.VALID;
      temp["patientCurrentAddressZip"].valid = ValidationStatus.VALID;
    }
    if (temp["contributingCause"].value === "no") {
      temp["dateOfAccident"].valid = ValidationStatus.VALID;
      temp["accidentType"].valid = ValidationStatus.VALID;
    }
    if (temp["wasNPWTInitiated"].value === "no") {
      temp["dateInitiated"].valid = ValidationStatus.VALID;
    }
    if (
      deliveryInformationTemp["deliverySiteType"].value === "101" ||
      deliveryInformationTemp["deliverySiteType"].value === "102" ||
      deliveryInformationTemp["deliverySiteType"].value === "103"
    ) {
      deliveryInformationTemp["deliveryFacilityName"].valid =
        ValidationStatus.VALID;
      deliveryInformationTemp["deliveryAddressLine1"].valid =
        ValidationStatus.VALID;
      deliveryInformationTemp["deliveryAddressLine2"].valid =
        ValidationStatus.VALID;
      deliveryInformationTemp["deliveryCity"].valid = ValidationStatus.VALID;
      deliveryInformationTemp["deliveryState"].valid = ValidationStatus.VALID;
      deliveryInformationTemp["deliveryZipCode"].valid = ValidationStatus.VALID;
    }
    if (temp["homeCareProvider"].value === "no") {
      temp["addedCaregiverName"].valid = ValidationStatus.VALID;
      temp["addedCaregiverAddress1"].valid = ValidationStatus.VALID;
      temp["addedCaregiverAddress2"].valid = ValidationStatus.VALID;
      temp["addedCaregiverCity"].valid = ValidationStatus.VALID;
      temp["addedCaregiverState"].valid = ValidationStatus.VALID;
      temp["addedCaregiverPhone"].valid = ValidationStatus.VALID;
      temp["addedCaregiverZip"].valid = ValidationStatus.VALID;
      temp["addedCaregiverFacilityType"].valid = ValidationStatus.VALID;
      temp["addedCaregiverID"].valid = ValidationStatus.VALID;
      temp["addedCaregiverSiteUseID"].valid = ValidationStatus.VALID;
      temp["addedCaregiverFacilityTypeCode"].valid = ValidationStatus.VALID;
      temp["addedCaregiverAccountNumber"].valid = ValidationStatus.VALID;
    }
    if (
      temp["submitPrescription"].value === "EPrescription" &&
      !temp["updatedPrescriberEmail"].value
    ) {
      temp["updatedPrescriberEmail"].valid = ValidationStatus.INVALID;
    } else {
      temp["updatedPrescriberEmail"].valid = ValidationStatus.VALID;
    }
    let smallestOrderWithInvalidStatus: any = null;
    if (prescriptionDocumentsTemp && prescriptionDocuments.length > 0) {
    }
    if (temp["submitPrescription"].value === "RxImage") {
      if (
        (prescriptionDocumentsTemp.length === 0 &&
          !uploadDocumentsTemp.some(
            (file: IDropZoneDocumentSelect) =>
              file.documentType &&
              file.documentType.value === "Signed Prescription"
          )) ||
        (prescriptionDocumentsTemp.length > 0 &&
          prescriptionDocumentsTemp[0].succeeded === false)
      ) {
        // Make Rx Upload section highlight
        temp["submitPrescription"].valid = ValidationStatus.INVALID;
      } else {
        temp["submitPrescription"].valid = ValidationStatus.VALID;
      }
    }
    Object.keys(temp).forEach((x: string) => {
      if (
        temp[x].isOptional === true &&
        temp[x].valid !== ValidationStatus.VALID
      ) {
        temp[x].valid = ValidationStatus.VALID;
      } else if (temp[x].valid === ValidationStatus.UNTOUCHED) {
        temp[x].valid = ValidationStatus.INVALID;
      }
      // Find smallest order number which has invalid status
      if (temp[x].valid === ValidationStatus.INVALID && temp[x].order) {
        if (
          !smallestOrderWithInvalidStatus ||
          (smallestOrderWithInvalidStatus.order &&
            temp[x].order &&
            smallestOrderWithInvalidStatus.order > temp[x].order)
        ) {
          smallestOrderWithInvalidStatus = temp[x];
        }
      }
      updateErrorMessage(temp[x]);
    });
    Object.keys(dressingTemp).forEach((x: string) => {
      if (
        dressingTemp[x].isOptional === true &&
        dressingTemp[x].valid !== ValidationStatus.VALID
      ) {
        dressingTemp[x].valid = ValidationStatus.VALID;
      } else if (dressingTemp[x].valid === ValidationStatus.UNTOUCHED) {
        dressingTemp[x].valid = ValidationStatus.INVALID;
      }
      // Find smallest order number which has invalid status
      if (
        dressingTemp[x].valid === ValidationStatus.INVALID &&
        dressingTemp[x].order &&
        ((smallestOrderWithInvalidStatus &&
          smallestOrderWithInvalidStatus.order > 40) ||
          !smallestOrderWithInvalidStatus)
      ) {
        if (
          !smallestOrderWithInvalidStatus ||
          (smallestOrderWithInvalidStatus.order &&
            dressingTemp[x].order &&
            smallestOrderWithInvalidStatus.order > dressingTemp[x].order)
        ) {
          smallestOrderWithInvalidStatus = dressingTemp[x];
        }
      }
      updateErrorMessage(dressingTemp[x], true);
    });
    Object.keys(requesterTemp).forEach((x: string) => {
      if (
        requesterTemp[x].isOptional === true &&
        requesterTemp[x].valid !== ValidationStatus.VALID
      ) {
        requesterTemp[x].valid = ValidationStatus.VALID;
      } else if (
        requesterTemp[x].required &&
        requesterTemp[x].valid === ValidationStatus.UNTOUCHED
      ) {
        requesterTemp[x].valid = ValidationStatus.INVALID;
      }
      // Find smallest order number which has invalid status
      if (
        requesterTemp[x].valid === ValidationStatus.INVALID &&
        requesterTemp[x].order &&
        ((smallestOrderWithInvalidStatus &&
          smallestOrderWithInvalidStatus.order > 45) ||
          !smallestOrderWithInvalidStatus)
      ) {
        if (
          !smallestOrderWithInvalidStatus ||
          (smallestOrderWithInvalidStatus.order &&
            requesterTemp[x].order &&
            smallestOrderWithInvalidStatus.order > requesterTemp[x].order)
        ) {
          smallestOrderWithInvalidStatus = requesterTemp[x];
        }
      }
      updateErrorMessage(requesterTemp[x]);
    });
    Object.keys(deliveryInformationTemp).forEach((x: string) => {
      if (
        deliveryInformationTemp[x].isOptional === true &&
        deliveryInformationTemp[x].valid !== ValidationStatus.VALID
      ) {
        deliveryInformationTemp[x].valid = ValidationStatus.VALID;
      } else if (
        deliveryInformationTemp[x].valid === ValidationStatus.UNTOUCHED
      ) {
        deliveryInformationTemp[x].valid = ValidationStatus.INVALID;
      }
      // Find smallest order number which has invalid status
      if (
        deliveryInformationTemp[x].valid === ValidationStatus.INVALID &&
        deliveryInformationTemp[x].order &&
        ((smallestOrderWithInvalidStatus &&
          smallestOrderWithInvalidStatus.order > 51) ||
          !smallestOrderWithInvalidStatus)
      ) {
        if (
          !smallestOrderWithInvalidStatus ||
          (smallestOrderWithInvalidStatus.order &&
            deliveryInformationTemp[x].order &&
            smallestOrderWithInvalidStatus.order >
              deliveryInformationTemp[x].order)
        ) {
          smallestOrderWithInvalidStatus = deliveryInformationTemp[x];
        }
      }
      updateErrorMessage(deliveryInformationTemp[x]);
    });
    uploadDocumentsTemp.forEach(
      (document: IDropZoneDocumentSelect, index: number) => {
        if (document["documentType"] && document["documentType"].value === "") {
          document["documentType"].order = 57 + index;
          document["documentType"].valid = ValidationStatus.INVALID;
          updateErrorMessage(document["documentType"]);
          // Find smallest order number which has invalid status
          if (
            document["documentType"].valid === ValidationStatus.INVALID &&
            document["documentType"].order &&
            ((smallestOrderWithInvalidStatus &&
              smallestOrderWithInvalidStatus.order > 56) ||
              !smallestOrderWithInvalidStatus)
          ) {
            if (
              !smallestOrderWithInvalidStatus ||
              (smallestOrderWithInvalidStatus.order &&
                document["documentType"].order &&
                smallestOrderWithInvalidStatus.order >
                  document["documentType"].order)
            ) {
              document["documentType"].componentId = `upload-documents-${
                index + 1
              }`;
              smallestOrderWithInvalidStatus = document["documentType"];
            }
          }
        }
      }
    );
    updateNewOrderDataIfUntouchedAndValidated(temp);
    updateRequesterDataIfUntouchedAndValidated(requesterTemp);
    updateDressingDataIfUntouchedAndValidated(dressingTemp);
    updateUploadDocumentsDataIfUntouchedAndValidated(uploadDocumentsTemp);
    updateDeliveryInformationDataIfUntouchedAndValidated(
      deliveryInformationTemp
    );
    const ifAllValid = Object.keys(temp)
      .filter((x) => temp[x].valid)
      .every((x: string) => temp[x].valid === ValidationStatus.VALID);
    const ifRequesterValid = Object.keys(requesterTemp)
      .filter((x) => requesterTemp[x].valid)
      .every((x: string) => requesterTemp[x].valid === ValidationStatus.VALID);
    const ifDressingValid = Object.keys(dressingTemp)
      .filter((x) => dressingTemp[x].valid)
      .every((x: string) => dressingTemp[x].valid === ValidationStatus.VALID);
    const ifDeliveryInformationValid = Object.keys(deliveryInformationTemp)
      .filter((x) => deliveryInformationTemp[x].valid)
      .every(
        (x: string) =>
          deliveryInformationTemp[x].valid === ValidationStatus.VALID
      );
    let ifUploadDocumentValid = false;
    if (uploadDocumentsTemp.length > 0) {
      let arr: any = [];
      uploadDocumentsTemp.forEach((document: IDropZoneDocumentSelect) => {
        arr.push(document.documentType?.valid === ValidationStatus.VALID);
      });
      ifUploadDocumentValid = arr.every((x: boolean) => x === true);
    } else {
      ifUploadDocumentValid = true;
    }
    let isPrimaryInsuranceValid = false;
    let secondaryInsuranceValid = false;
    const isSecondaryOpen = temp["isSecondaryInsurancePresent"].value === true;
    const insuranceValidator = new InsuranceInformationValidator();
    [isPrimaryInsuranceValid, smallestOrderWithInvalidStatus] =
      insuranceValidator.validateAll(
        primaryInsurance,
        true,
        smallestOrderWithInvalidStatus,
        updateNewOrderDataIfUntouchedAndValidated
      );
    if (isSecondaryOpen) {
      [secondaryInsuranceValid, smallestOrderWithInvalidStatus] =
        insuranceValidator.validateAll(
          secondaryInsurance,
          false,
          smallestOrderWithInvalidStatus,
          updateNewOrderDataIfUntouchedAndValidated
        );
    }
    const isFinalValid =
      ifAllValid &&
      ifRequesterValid &&
      ifDressingValid &&
      ifDeliveryInformationValid &&
      isPrimaryInsuranceValid &&
      ifUploadDocumentValid &&
      ((isSecondaryOpen && secondaryInsuranceValid) || !isSecondaryOpen);
    if (smallestOrderWithInvalidStatus) {
      setScrollableComponentId(
        smallestOrderWithInvalidStatus.componentId ?? ""
      );
      setAutoCursorComponentId(smallestOrderWithInvalidStatus.id ?? "");
    }
    return isFinalValid ? ValidationStatus.VALID : ValidationStatus.INVALID;
  }

  public validateMinimumFields(
    newOrderData: any,
    updateNewOrderDataIfUntouchedAndValidated: Function
  ) {
    let temp = getDeepClone(newOrderData);
    Object.keys(temp).forEach((x: string) => {
      if (temp[x].minimumRequired) {
        if (temp[x].valid === ValidationStatus.UNTOUCHED) {
          temp[x].valid = ValidationStatus.INVALID;
        }
      }
    });
    updateNewOrderDataIfUntouchedAndValidated(temp);
    const ifAllValid = Object.keys(temp)
      .filter((x) => temp[x].minimumRequired)
      .every((x: string) => temp[x].valid === ValidationStatus.VALID);
    return ifAllValid ? ValidationStatus.VALID : ValidationStatus.INVALID;
  }

  public validateUserEnteredAnyDataOrNot(
    newOrderData: any,
    newOrderDocuments: any,
    requesterData: any,
    dressingKit: any,
    accessory: any,
    insuranceShowingDetails: ShowAdditionalFields
  ) {
    let temp = getDeepClone(newOrderData);
    const keys = Object.keys(temp);
    const insuranceValidator = new InsuranceInformationValidator();
    for (let x of keys) {
      if (
        x === "inpatientFacility" ||
        x === "inpatientFacilityAsDefault" ||
        x === "requesterFacility" ||
        x === "requesterFacilityAsDefault"
      ) {
        if (temp[x] === false) {
          return true;
        }
      } else if (
        x === "accessories" ||
        x === "prescriptionDoc" ||
        x === "uploadDocument"
      ) {
        if (temp[x].length > 0) {
          return true;
        }
      } else if (x === "primaryInsurance" || x === "secondaryInsurance") {
        if (
          insuranceValidator.validateUserEnteredAnyDataOrNot(
            temp[x],
            x === "primaryInsurance",
            insuranceShowingDetails
          )
        ) {
          return true;
        }
      } else if (
        temp[x].isDefaultValid !== true &&
        temp[x].isOptional !== true &&
        temp[x].valid !== ValidationStatus.UNTOUCHED
      ) {
        return true;
      }
    }
    let requesterTemp = getDeepClone(requesterData);
    const requesterKeys = Object.keys(requesterTemp);
    for (let x of requesterKeys) {
      if (x === "IsRequesterSameasSubmitter") {
        if (temp["IsRequesterSameasSubmitter"].value !== "yes") {
          return true;
        }
      } else if (
        x === "requesterFirstName" ||
        x === "requesterLastName" ||
        x === "requesterEmail"
      ) {
        if (
          temp["IsRequesterSameasSubmitter"].value !== "yes" &&
          temp[x].valid !== ValidationStatus.UNTOUCHED
        ) {
          return true;
        }
      } else if (
        temp[x].isDefaultValid !== true &&
        temp[x].isOptional !== true &&
        temp[x].valid !== ValidationStatus.UNTOUCHED
      ) {
        return true;
      }
    }
    let dressingTemp = getDeepClone(dressingKit);
    const dressingKeys = Object.keys(dressingTemp);
    for (let x of dressingKeys) {
      if (
        temp[x].isDefaultValid !== true &&
        temp[x].isOptional !== true &&
        temp[x].valid !== ValidationStatus.UNTOUCHED
      ) {
        return true;
      }
    }
    let accessoryTemp = getDeepClone(accessory);
    const accessoryKeys = Object.keys(accessoryTemp);
    for (let x of accessoryKeys) {
      if (x === "accessories") {
        if (temp[x].length > 0) {
          return true;
        }
      } else if (
        temp[x].isDefaultValid !== true &&
        temp[x].isOptional !== true &&
        temp[x].valid !== ValidationStatus.UNTOUCHED
      ) {
        return true;
      }
    }
    if (newOrderDocuments.length > 0) {
      return true;
    }
    return false;
  }

  public validateAllWoundInfo(
    woundInfo: any,
    updateWoundInfoIfUntouchedAndValidated: Function,
    secondaryWoundInfo: any,
    updateSecondaryWoundInfoIfUntouchedAndValidated: Function,
    primaryQuestData: any,
    updatePriamryQuestData: Function,
    secondaryQuestData: any,
    updateSecondaryQuestData: Function,
    newOrderDocuments: any,
    prescriptionDocuments: any,
    submitPrescription: any,
    updateUploadDocumentsDataIfUntouchedAndValidated: Function,
    setScrollableComponentId: React.Dispatch<
      React.SetStateAction<string | null>
    >,
    setAutoCursorComponentId: React.Dispatch<
      React.SetStateAction<string | null>
    >
  ) {
    let temp = getDeepClone(woundInfo);
    let ifAnyInValid = true;
    const uploadDocumentsTemp = getDeepClone(newOrderDocuments);
    const prescriptionDocumentsTemp = getDeepClone(prescriptionDocuments);
    let smallestOrderWithInvalidStatus: any = null;
    let isSubmitPrescriptionInvalid = false;
    if (submitPrescription.value === "RxImage") {
      if (
        (prescriptionDocumentsTemp.length === 0 &&
          !uploadDocumentsTemp.some(
            (file: IDropZoneDocumentSelect) =>
              file.documentType &&
              file.documentType.value === "Signed Prescription"
          )) ||
        (prescriptionDocumentsTemp.length > 0 &&
          prescriptionDocumentsTemp[0].succeeded === false)
      ) {
        // Make Rx Upload section highlight
        isSubmitPrescriptionInvalid = true;
        submitPrescription.valid = ValidationStatus.INVALID;
      } else {
        submitPrescription.valid = ValidationStatus.VALID;
      }
    }
    Object.keys(temp).forEach((x: string) => {
      if (
        x === "osteomyelitisies" &&
        temp.isOsteomyelitisPresent.value === "Yes"
      ) {
        let isNotValid = false;
        let unTouched = true;
        temp[x].value.forEach((item: any) => {
          if (item.selected) {
            unTouched = false;
          }
          if (
            item.selected &&
            item.isRequiredTextBox &&
            item.isTextBoxValueValid !== ValidationStatus.VALID
          ) {
            item.isTextBoxValueValid = ValidationStatus.INVALID;
            isNotValid = true;
          }
          // Find smallest order number which has invalid status
          if (
            item.isTextBoxValueValid === ValidationStatus.INVALID &&
            item.order
          ) {
            if (!smallestOrderWithInvalidStatus) {
              smallestOrderWithInvalidStatus = item;
            } else if (
              smallestOrderWithInvalidStatus.order &&
              item.order &&
              smallestOrderWithInvalidStatus.order > item.order
            ) {
              smallestOrderWithInvalidStatus = item;
            }
          }
        });
        if (!isNotValid) {
          isNotValid = temp[x].value.every((item: any) => !item.selected);
        }
        if (isNotValid) {
          temp[x].valid = ValidationStatus.INVALID;
          if (unTouched)
            temp[x].errorMessage =
              makeCapitalEachWordInString(temp[x]?.title) + " is required";
          else
            temp[x].errorMessage =
              makeCapitalEachWordInString(temp[x]?.title) +
              " - Remove invalid / incomplete data";
        }
      } else if (temp[x].required) {
        if (
          temp[x].valid === ValidationStatus.UNTOUCHED &&
          temp[x].value.toString().trim().length !== 0
        ) {
          temp[x].valid = ValidationStatus.INVALID;
        }
        if (temp[x].valid === ValidationStatus.UNTOUCHED) {
          temp[x].valid = ValidationStatus.INVALID;
          temp[x].errorMessage =
            makeCapitalEachWordInString(temp[x]?.title) + " is required";
        } else if (temp[x].valid === ValidationStatus.INVALID) {
          if (
            temp[x].value &&
            temp[x].value.toString().trim().length !== 0 &&
            ([
              "debridementDate",
              "woundMeasurementDate",
              "underminingLocation1Depth",
              "underminingLocation2Depth",
              "location1Depth",
              "location2Depth",
              "woundLength",
              "woundWidth",
              "woundDepth",
            ].includes(x) ||
              [
                "Other",
                "Starting Position",
                "Ending Position",
                "Clock Position",
              ].includes(temp[x].title))
          ) {
            temp[x].errorMessage = ERROR_INVALID_INCOMPLETE_DATA;
          } else {
            temp[x].errorMessage =
              makeCapitalEachWordInString(temp[x]?.title) + " is required";
          }
        }
      }
      if (!Array.isArray(temp[x].value) && x !== "osteomyelitisies") {
        temp[x].value = temp[x].value.trim();
        if (temp[x].value && temp[x].value !== "") {
          if (
            temp[x].valid === ValidationStatus.INVALID &&
            (temp[x].errorMessage === null ||
              temp[x].errorMessage === "" ||
              temp[x].errorMessage === undefined)
          ) {
            temp[x].errorMessage = ERROR_INVALID_INCOMPLETE_DATA;
          } else if (
            temp[x].valid !== ValidationStatus.VALID &&
            x === "shortNarrativeOfPossibleConsequences"
          ) {
            if (
              this._validator.additionalInfoValidation(temp[x].value).status ===
              ValidationStatus.INVALID
            ) {
              temp[x].valid = ValidationStatus.INVALID;
              temp[x].errorMessage = ERROR_INVALID_INCOMPLETE_DATA;
            } else {
              temp[x].valid = ValidationStatus.VALID;
              temp[x].errorMessage = null;
            }
          }
        }
      }
      // Find smallest order number which has invalid status
      if (temp[x].valid === ValidationStatus.INVALID && temp[x].order) {
        ifAnyInValid = false;
        if (!smallestOrderWithInvalidStatus) {
          smallestOrderWithInvalidStatus = temp[x];
        } else if (
          smallestOrderWithInvalidStatus.order &&
          temp[x].order &&
          smallestOrderWithInvalidStatus.order > temp[x].order
        ) {
          smallestOrderWithInvalidStatus = temp[x];
        }
      }
    });
    if (
      smallestOrderWithInvalidStatus &&
      smallestOrderWithInvalidStatus.order &&
      smallestOrderWithInvalidStatus.order === 11
    ) {
      ifAnyInValid = false;
      const osteomyelitisies = temp["osteomyelitisies"].value;
      for (let item of osteomyelitisies) {
        if (
          item.selected &&
          item.isRequiredTextBox &&
          item.isTextBoxValueValid === ValidationStatus.INVALID &&
          item.order
        ) {
          smallestOrderWithInvalidStatus = item;
          break;
        }
      }
    }
    uploadDocumentsTemp.forEach(
      (document: IDropZoneDocumentSelect, index: number) => {
        if (document["documentType"] && document["documentType"].value === "") {
          document["documentType"].order = 134 + index;
          document["documentType"].valid = ValidationStatus.INVALID;
          updateErrorMessage(document["documentType"]);
          // Find smallest order number which has invalid status
          if (
            document["documentType"].valid === ValidationStatus.INVALID &&
            document["documentType"].order &&
            ((smallestOrderWithInvalidStatus &&
              smallestOrderWithInvalidStatus.order > 133) ||
              !smallestOrderWithInvalidStatus)
          ) {
            if (
              !smallestOrderWithInvalidStatus ||
              (smallestOrderWithInvalidStatus.order &&
                document["documentType"].order &&
                smallestOrderWithInvalidStatus.order >
                  document["documentType"].order)
            ) {
              document["documentType"].componentId = `upload-documents-${
                index + 1
              }`;
              smallestOrderWithInvalidStatus = document["documentType"];
            }
          }
        }
        Object.keys(document).forEach((x: string) => {
          if (document["documentType"]?.value === "") {
            document["documentType"].valid = ValidationStatus.INVALID;
            return x;
          }
        });
      }
    );
    updateWoundInfoIfUntouchedAndValidated(temp);
    updateUploadDocumentsDataIfUntouchedAndValidated(uploadDocumentsTemp);
    const ifAllValid = Object.keys(temp)
      .filter((x) => temp[x].required)
      .every((x: string) => temp[x].valid === ValidationStatus.VALID);
    const isShowSecondaryWoundInfo =
      temp["isShowSecondaryWoundInfo"].value === "Yes" ? true : false;
    let secondaryWoundDataValid = false;
    let secondaryAdditionalsValid = false;
    let primaryAdditionalsValid: boolean = false;
    // update error in additional questions if any
    if (primaryQuestData !== undefined) {
      [primaryAdditionalsValid, smallestOrderWithInvalidStatus] =
        this.validateAllAdditionalQuestions(
          primaryQuestData,
          smallestOrderWithInvalidStatus,
          updatePriamryQuestData,
          false
        );
    }
    let ifUploadDocumentValid = false;
    if (uploadDocumentsTemp.length > 0) {
      let arr: any = [];
      uploadDocumentsTemp.forEach((document: IDropZoneDocumentSelect) => {
        arr.push(document.documentType?.valid === ValidationStatus.VALID);
      });
      ifUploadDocumentValid = arr.every((x: boolean) => x === true);
    } else {
      ifUploadDocumentValid = true;
    }
    if (isShowSecondaryWoundInfo) {
      if (secondaryQuestData !== undefined) {
        [secondaryAdditionalsValid, smallestOrderWithInvalidStatus] =
          this.validateAllAdditionalQuestions(
            secondaryQuestData,
            smallestOrderWithInvalidStatus,
            updateSecondaryQuestData,
            true
          ) ?? false;
      }
      [secondaryWoundDataValid, smallestOrderWithInvalidStatus] =
        this.validateSecondaryWoundData(
          secondaryWoundInfo,
          smallestOrderWithInvalidStatus,
          updateSecondaryWoundInfoIfUntouchedAndValidated
        );
    }
    if (smallestOrderWithInvalidStatus) {
      setScrollableComponentId(
        smallestOrderWithInvalidStatus.componentId ?? ""
      );
      setAutoCursorComponentId(smallestOrderWithInvalidStatus.id ?? "");
    } else if (isSubmitPrescriptionInvalid) {
      setScrollableComponentId(submitPrescription.componentId ?? "");
      setAutoCursorComponentId(submitPrescription.id ?? "");
    }
    const checkForSecondary =
      (isShowSecondaryWoundInfo &&
        secondaryWoundDataValid &&
        secondaryAdditionalsValid) ||
      !isShowSecondaryWoundInfo;
    return ifAllValid &&
      primaryAdditionalsValid &&
      checkForSecondary &&
      ifUploadDocumentValid &&
      ifAnyInValid
      ? ValidationStatus.VALID
      : ValidationStatus.INVALID;
  }

  public validateAdditionalQuestions(
    index: number,
    question: Question,
    type: QuestionTypes,
    isSecondaryWoundInfo: boolean
  ) {
    try {
      const orderId = isSecondaryWoundInfo ? index + 61 : index + 17;
      if (type === QuestionTypes.RADIO) {
        if (
          question.required === "True" &&
          question.value.toString().trim().length === 0
        ) {
          question.order = orderId;
          question.componentId = `questions-${orderId}-id`;

          return ValidationStatus.INVALID;
        } else {
          return ValidationStatus.VALID;
        }
      } else if (type === QuestionTypes.TEXT) {
        if (question.required === "True") {
          if (question.value.toString().trim().length > 0) {
            const valStatus = this._validator.descSurgicalProc(question.value);
            question.order = orderId;
            question.componentId = `questions-${orderId}-id`;
            question.id = `questions-${orderId}-id-input`;
            return valStatus.status;
          } else return ValidationStatus.UNTOUCHED;
        } else {
          return ValidationStatus.VALID;
        }
      } else if (type === QuestionTypes.DATE) {
        if (question.required === "True") {
          if (question.value !== null) {
            let valStatus: Validation;
            if (question.text === "Date of surgical procedure") {
              valStatus = this._validator.dateOfSurgicalProcedureValidation(
                question.value
              );
            } else {
              valStatus = this._validator.dateValidation(question.value);
            }

            question.order = orderId;
            question.componentId = `questions-${orderId}-id`;
            question.id = `questions-${orderId}-id-input`;
            return question.required === "True" &&
              question.value.toString().trim().length > 0
              ? valStatus.status
              : ValidationStatus.UNTOUCHED;
          } else return ValidationStatus.UNTOUCHED;
        } else {
          return ValidationStatus.VALID;
        }
      }
    } catch (error) {
      console.log(`validator method for field is not configured`);
    }
  }

  public validateAllAdditionalQuestions(
    questionaries: WoundQuestionaries,
    smallestOrderWithInvalidStatus: any,
    updateData: Function,
    isSecondaryWoundInfo: boolean
  ) {
    const quests = questionaries.additionalQuestion.map((quest, index) => {
      const valStatus: any = this.validateAdditionalQuestions(
        index,
        quest,
        quest.type,
        isSecondaryWoundInfo
      );
      let errorMessage = null;
      if (valStatus === ValidationStatus.INVALID) {
        if (quest.type === QuestionTypes.DATE) {
          let enteredDate = moment(quest.value, "MM/DD/YYYY", true);
          if (!enteredDate.isValid()) {
            errorMessage = "Remove invalid / incomplete data";
          } else if (moment().add(14, "days")) {
            errorMessage =
              "Future surgery date can be no more than 14 days from today";
          }
        } else errorMessage = "Remove invalid / incomplete data";
      } else if (valStatus === ValidationStatus.UNTOUCHED) {
        errorMessage = "Above Field is required";
      }
      //Find the smallest order number which has invalid status

      if (valStatus !== ValidationStatus.VALID && quest.order) {
        if (!smallestOrderWithInvalidStatus) {
          smallestOrderWithInvalidStatus = quest;
        } else if (
          smallestOrderWithInvalidStatus.order &&
          quest.order &&
          smallestOrderWithInvalidStatus.order > quest.order
        ) {
          quest.componentId = `questions-${quest.order}-id`;
          if (quest.type !== QuestionTypes.RADIO) {
            quest.id = `questions-${quest.order}-id-input`;
          }
          smallestOrderWithInvalidStatus = quest;
        }
      }

      return {
        ...quest,
        valid:
          valStatus === ValidationStatus.UNTOUCHED
            ? ValidationStatus.INVALID
            : valStatus,
        errorMessage: errorMessage,
      } as Question;
    });
    updateData((data: any) => ({
      ...data,
      additionalQuestion: quests!,
    }));
    const isAllValid = quests
      .filter((x) => Boolean(x.required))
      .every((x) => x.valid === ValidationStatus.VALID);

    return [isAllValid, smallestOrderWithInvalidStatus];
  }

  public validateSecondaryWoundData(
    data: any,
    smallestOrderWithInvalidStatus: any,
    updateSecondaryWoundInfoIfUntouchedAndValidated: Function
  ) {
    let temp = getDeepClone(data);
    Object.keys(temp).forEach((x: string) => {
      if (temp[x].required) {
        if (
          temp[x].valid === ValidationStatus.UNTOUCHED &&
          temp[x].value.toString().trim().length !== 0
        ) {
          temp[x].valid = ValidationStatus.INVALID;
        }

        if (temp[x].valid === ValidationStatus.UNTOUCHED) {
          temp[x].valid = ValidationStatus.INVALID;
          temp[x].errorMessage =
            makeCapitalEachWordInString(temp[x]?.title) + " is required";
        } else if (temp[x].valid === ValidationStatus.INVALID) {
          if (
            temp[x].value &&
            temp[x].value.toString().trim().length !== 0 &&
            ([
              "debridementDate",
              "woundMeasurementDate",
              "underminingLocation1Depth",
              "underminingLocation2Depth",
              "location1Depth",
              "location2Depth",
              "woundLength",
              "woundWidth",
              "woundDepth",
            ].includes(x) ||
              [
                "Other",
                "Starting Position",
                "Ending Position",
                "Clock Position",
              ].includes(temp[x].title))
          ) {
            temp[x].errorMessage = ERROR_INVALID_INCOMPLETE_DATA;
          } else {
            temp[x].errorMessage =
              makeCapitalEachWordInString(temp[x]?.title) + " is required";
          }
        }
      }
      if (!Array.isArray(temp[x].value) && x !== "osteomyelitisies") {
        temp[x].value = temp[x].value.trim();
        if (temp[x].value && temp[x].value !== "") {
          if (
            temp[x].valid === ValidationStatus.INVALID &&
            (temp[x].errorMessage === null ||
              temp[x].errorMessage === "" ||
              temp[x].errorMessage === undefined)
          ) {
            temp[x].errorMessage = ERROR_INVALID_INCOMPLETE_DATA;
          } else if (
            temp[x].valid !== ValidationStatus.VALID &&
            x === "shortNarrativeOfPossibleConsequences"
          ) {
            if (
              this._validator.nameValidation(temp[x].value).status ===
              ValidationStatus.INVALID
            )
              temp[x].valid = ValidationStatus.INVALID;
            temp[x].errorMessage = ERROR_INVALID_INCOMPLETE_DATA;
          }
        }
      }
      // Find smallest order number which has invalid status
      if (temp[x].valid === ValidationStatus.INVALID && temp[x].order) {
        if (!smallestOrderWithInvalidStatus) {
          smallestOrderWithInvalidStatus = temp[x];
        } else if (
          smallestOrderWithInvalidStatus.order &&
          temp[x].order &&
          smallestOrderWithInvalidStatus.order > temp[x].order
        ) {
          smallestOrderWithInvalidStatus = temp[x];
        }
      }
    });
    updateSecondaryWoundInfoIfUntouchedAndValidated(temp);
    const ifAllValid = Object.keys(temp)
      .filter((x) => temp[x].required)
      .every((x: string) => temp[x].valid === ValidationStatus.VALID);
    return [ifAllValid, smallestOrderWithInvalidStatus];
  }

  public validateSecondaryWoundForUserEnteredAnyDataOrNot(data: any) {
    let temp = getDeepClone(data);
    let result = false;
    Object.keys(temp).forEach((x: string) => {
      if (
        x !== "woundBedTotal" &&
        temp[x].isDefaultValid !== true &&
        temp[x].valid !== ValidationStatus.UNTOUCHED
      ) {
        result = true;
        return;
      }
    });
    return result;
  }
}
