import React, { useContext, useState } from "react";
import {
  contentCardOptions,
  IContentOptions,
  LifeOfAnOrderGuidePageSection,
} from "../lifeOfAnOrderGuide.interface";
import { Grid, Typography } from "@mui/material";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import "./lifeOfAnOrderGuideContainer.css";
import PostReleaseContent from "../Components/postReleaseContent/postReleaseContent.component";
import PreReleaseContent from "../Components/preReleaseContent/preReleaseContent.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ReactComponent as BackIcon } from "../../../assets/LPBackButton.svg";
import { useHistory } from "react-router-dom";

const LifeOfAnOrderGuide: React.FC = () => {
  const [pageSection, setPageSection] = useState<LifeOfAnOrderGuidePageSection>(
    LifeOfAnOrderGuidePageSection.contentSelection
  );
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const history = useHistory();

  const optionSelection = () => {
    return (
      <div className="life-order-guide-component">
        <Grid
          className="life-guide-grid-option-container"
          container
          spacing={2}
        >
          {contentCardOptions.map((option: IContentOptions, index: number) => (
            <Grid
              className="life-guide-card-option-grid-item"
              item
              key={index}
              xs={isMobileScreen ? 12 : 6}
            >
              <div
                className="life-guide-card-section"
                onClick={() => setPageSection(option.contentSection)}
              >
                <Typography
                  className="life-guide-card-section-title"
                  data-testid="life-guide-card-section-title"
                  id="life-guide-card-section-title"
                >
                  {option.title}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const renderSections = () => {
    switch (pageSection) {
      case LifeOfAnOrderGuidePageSection.contentSelection:
        return optionSelection();
      case LifeOfAnOrderGuidePageSection.preReleaseContent:
        return <PreReleaseContent />;
      case LifeOfAnOrderGuidePageSection.postReleaseContent:
        return <PostReleaseContent />;
    }
  };

  const handleBacButtonClick = () => {
    const isContentSelection =
      pageSection === LifeOfAnOrderGuidePageSection.contentSelection;
    if (isContentSelection) {
      history.goBack();
    } else {
      setPageSection(LifeOfAnOrderGuidePageSection.contentSelection);
    }
  };

  return (
    <div className="life-order-guide-container">
      <ExpressButton
        variant="text"
        parentClass="life-order-guide-container-back-button"
        startIcon={<BackIcon className="backIcon-SelectaFacility" />}
        clickHandler={() => handleBacButtonClick()}
        testId="life-order-guide-container-back-button"
      >
        Return to Dashboard
      </ExpressButton>
      <div className="life-order-guide-display-sections">
        {renderSections()}
      </div>
    </div>
  );
};

export default LifeOfAnOrderGuide;
