import { Grid, Typography } from "@mui/material";
import { ISalesRoundingPatient } from "../salesRoundingDashboard.interface";
import "./salesRoundingPatientSummary.css";
import moment from "moment";
import { makeCapitalEachWordInString } from "../../../../util/utilityFunctions";

interface SalesRoundingPatientSummaryProps {
  patientData: ISalesRoundingPatient;
}
export const SalesRoundingPatientSummary = ({
  patientData,
}: SalesRoundingPatientSummaryProps) => {
  return (
    <div>
      <div className="srt-patient-summary-main">
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-patientId-test"
          id="srt-summary-patientId-id"
        >
          <div className="srt-patient-summary-title">Patient ID</div>
          <div className="srt-patient-summary-value">
            {patientData.patientId ? patientData?.patientId : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-ro-test"
          id="srt-summary-ro-id"
        >
          <div className="srt-patient-summary-title">RO#</div>
          <div className="srt-patient-summary-value">
            {patientData?.roNumber}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-placementType-test"
          id="srt-summary-placementType-id"
        >
          <div className="srt-patient-summary-title">Placement Type</div>
          <div className="srt-patient-summary-value">
            {patientData?.placementType ? patientData?.placementType : "--"}
          </div>
        </div>

        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-dischargeStatus-test"
          id="srt-summary-dischargeStatus-id"
        >
          <div className="srt-patient-summary-title">Discharge Status</div>
          <div className="srt-patient-summary-value">
            {patientData?.dischargeStatus
              ? makeCapitalEachWordInString(patientData?.dischargeStatus)
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-reason-test"
          id="srt-summary-reason-id"
        >
          <div className="srt-patient-summary-title">
            Reason not transitioning home
          </div>
          <div className="srt-patient-summary-value">
            {patientData?.reasonNotTransitioningHome
              ? makeCapitalEachWordInString(
                  patientData?.reasonNotTransitioningHome
                )
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-transition-test"
          id="srt-summary-transition-id"
        >
          <div className="srt-patient-summary-title">
            Transition Therapy Start Date
          </div>
          <div className="srt-patient-summary-value">
            {patientData?.transitionTherapyStartDate
              ? moment(patientData?.transitionTherapyStartDate).format("L")
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-lead-test"
          id="srt-summary-lead-id"
        >
          <div className="srt-patient-summary-title">Lead Generation</div>
          <div className="srt-patient-summary-value">
            {patientData?.leadGeneration
              ? makeCapitalEachWordInString(patientData?.leadGeneration)
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-attending-test"
          id="srt-summary-attending-id"
        >
          <div className="srt-patient-summary-title">Attending Physician</div>
          <div className="srt-patient-summary-value">
            {patientData?.attendingPhysicianFirstName &&
            patientData?.attendingPhysicianLastName
              ? `${makeCapitalEachWordInString(
                  patientData?.attendingPhysicianFirstName
                )} ${makeCapitalEachWordInString(
                  patientData?.attendingPhysicianLastName
                )}, NPI ${patientData?.attendingPhysicianNPI}`
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-prescribing-test"
          id="srt-summary-prescribing-id"
        >
          <div className="srt-patient-summary-title">Prescribing Physician</div>
          <div className="srt-patient-summary-value">
            {patientData?.prescribingPhysicianFirstName &&
            patientData?.prescribingPhysicianLastName
              ? `${makeCapitalEachWordInString(
                  patientData?.prescribingPhysicianFirstName
                )} ${makeCapitalEachWordInString(
                  patientData?.prescribingPhysicianLastName
                )}, NPI ${patientData?.prescribingPhysicianNPI}`
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-service-test"
          id="srt-summary-service-id"
        >
          <div className="srt-patient-summary-title">Service Covering</div>
          <div className="srt-patient-summary-value">
            {patientData?.serviceCovering
              ? makeCapitalEachWordInString(patientData?.serviceCovering)
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-primaryWound-test"
          id="srt-summary-primaryWound-id"
        >
          <div className="srt-patient-summary-title">Primary Wound Type</div>
          <div className="srt-patient-summary-value">
            {patientData?.primaryWoundType
              ? makeCapitalEachWordInString(patientData?.primaryWoundType)
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-primaryWoundLocation-test"
          id="srt-summary-primaryWoundLocation-id"
        >
          <div className="srt-patient-summary-title">
            Primary Wound Location
          </div>
          <div className="srt-patient-summary-value">
            {patientData?.primaryWoundLocation
              ? makeCapitalEachWordInString(patientData?.primaryWoundLocation)
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-secondaryWound-test"
          id="srt-summary-secondaryWound-id"
        >
          <div className="srt-patient-summary-title">Secondary Wound Type</div>
          <div className="srt-patient-summary-value">
            {patientData?.secondaryWoundType
              ? makeCapitalEachWordInString(patientData?.secondaryWoundType)
              : "--"}
          </div>
        </div>
        <div
          className="srt-patient-summary-data"
          data-testid="srt-summary-secondaryWoundLocation-test"
          id="srt-summary-secondaryWoundLocation-id"
        >
          <div className="srt-patient-summary-title">
            Secondary Wound Location
          </div>
          <div className="srt-patient-summary-value">
            {patientData?.secondaryWoundLocation
              ? makeCapitalEachWordInString(patientData?.secondaryWoundLocation)
              : "--"}
          </div>
        </div>
      </div>
      <Grid className="srt-patient-details-header-grid-item" item xs={12}>
        <div
          className="srt-patient-details-div"
          data-testid="srt-patients-card-updated-test"
        >
          <Typography className={`updatedtitle`}>Updated</Typography>
          <Typography className={`value updateValue`}>
            {patientData.lastUpdatedDate !== null &&
            patientData.lastUpdatedDate !== ""
              ? moment.utc(patientData.lastUpdatedDate).local().format("L") +
                " " +
                moment.utc(patientData.lastUpdatedDate).local().format("LT")+" "+patientData.lastUpdatedBy
              : "--"}
          </Typography>
        </div>
      </Grid>
    </div>
  );
};
