import "./payerAllDetails.css";
import { useContext, useState } from "react";
import InputMask from "react-input-mask";
import { Grid, InputBase } from "@mui/material";
import { INewOrder } from "../../../newOrder.interface";
import { ValidationStatus } from "../../../../../core/interfaces/input.interface";
import { IVacTherapyInformation } from "../../../woundBed/vacTherapyInformation.interface";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { InsuranceInformationValidator } from "../../insuranceInformation/insuranceInformation.validator";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../../../context/NewOrderContext";

type Props = {
  data: INewOrder;
  setData: Function;
  isPrimaryComponent: boolean;
  insuranceTypeSelected: string;
  insuredRelation: any;
  vacTherapyInformationData?: IVacTherapyInformation | undefined;
};

const PayerAllDetails = ({
  data,
  setData,
  isPrimaryComponent,
  insuranceTypeSelected,
  insuredRelation,
  vacTherapyInformationData,
}: Props) => {
  const [validator] = useState<InsuranceInformationValidator>(
    new InsuranceInformationValidator()
  );
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const componentType =
    insuranceTypeSelected === "medicareAdvantage"
      ? "medicareAdvantage"
      : insuranceTypeSelected === "managedMedicaid"
      ? "managedMedicaid"
      : "commercialInsurance";
  const insuranceTypeText = isPrimaryComponent
    ? "primaryInsurance"
    : "secondaryInsurance";

  const [focusClasses, setFocusClasses] = useState({
    payerContactNumber: "",
  });

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetInsuranceData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { value, name } = e.target;
    if (
      name === "payerContactNumber" &&
      data[insuranceTypeText][componentType].payerContactNumber.valid ===
        ValidationStatus.UNTOUCHED &&
      (value === "(___) ___-____" || value === "")
    ) {
      return;
    }
    const isValid = validator.validate(value, name);
    const tempData = {
      ...data,
      [insuranceTypeText]: {
        ...data[insuranceTypeText],
        insuranceType: data[insuranceTypeText].insuranceType,
        insuranceTypeCode: data[insuranceTypeText].insuranceTypeCode,
        [insuranceTypeSelected]: {
          ...data[insuranceTypeText][componentType],
          [name]: {
            ...Object(data[insuranceTypeText][componentType])[name],
            errorMessage: null,
            valid: isValid!.status,
            value: value,
          },
        },
      },
    };
    setData(tempData);
  };

  return (
    <div className="payerAllDetail-container">
      <InputWithLabel
        error={
          data[insuranceTypeText][componentType].payerName.valid ===
          ValidationStatus.INVALID
            ? true
            : false
        }
        id={data[insuranceTypeText][componentType].payerName.componentId!}
        isRequired={data[insuranceTypeText][componentType].payerName.required}
        isShowTooltip={true}
        isShowWarning={true}
        label={data[insuranceTypeText][componentType].payerName.title!}
        labelClassName="payer-name"
        testId="PayerNameID"
        tooltipHeader={
          vacTherapyInformationData &&
          vacTherapyInformationData?.bubbleInfo[1]?.sectionHeader
        }
        tooltipContent={
          vacTherapyInformationData &&
          vacTherapyInformationData?.bubbleInfo[1]?.sectionContent
        }
        warningMessage={
          data[insuranceTypeText][componentType].payerName.errorMessage
        }
      >
        <InputBase
          className="payer-information-input"
          data-testid="medicare-information-memberid"
          id={data[insuranceTypeText][componentType].payerName.id!}
          name="payerName"
          onChange={(e: any) => validateAndSetInsuranceData(e)}
          required={data[insuranceTypeText][componentType].payerName.required}
          value={data[insuranceTypeText][componentType].payerName.value}
        />
      </InputWithLabel>
      <div className="member-group-id">
        <InputWithLabel
          error={
            data[insuranceTypeText][componentType].memberID.valid ===
            ValidationStatus.INVALID
              ? true
              : false
          }
          id={data[insuranceTypeText][componentType].memberID.componentId!}
          isRequired={data[insuranceTypeText][componentType].memberID.required}
          isShowWarning={true}
          label={data[insuranceTypeText][componentType].memberID.title!}
          testId="memberTestID"
          warningMessage={
            data[insuranceTypeText][componentType].memberID.errorMessage
          }
        >
          <InputBase
            className="payer-information-input"
            data-testid="medicare-information-memberid"
            id={data[insuranceTypeText][componentType].memberID.id!}
            name="memberID"
            onChange={(e: any) => validateAndSetInsuranceData(e)}
            required={data[insuranceTypeText][componentType].memberID.required}
            value={data[insuranceTypeText][componentType].memberID.value}
            inputProps={{
              maxLength: 20,
            }}
          />
        </InputWithLabel>
        <InputWithLabel
          error={
            data[insuranceTypeText][componentType].groupID.valid ===
            ValidationStatus.INVALID
              ? true
              : false
          }
          id={data[insuranceTypeText][componentType].groupID.componentId!}
          isRequired={false}
          isShowWarning={true}
          label={data[insuranceTypeText][componentType].groupID.title!}
          testId="groupTestID"
          warningMessage={
            data[insuranceTypeText][componentType].groupID.errorMessage
          }
        >
          <InputBase
            className="payer-information-input"
            data-testid="medicare-information-memberid"
            id={data[insuranceTypeText][componentType].groupID.id!}
            name="groupID"
            onChange={(e: any) => validateAndSetInsuranceData(e)}
            value={data[insuranceTypeText][componentType].groupID.value}
          />
        </InputWithLabel>
      </div>
      <div className="member-group-id">
        <div className="payer-number-div">
          <div className="payerContactNumberDiv">
            <InputWithLabel
              error={
                data[insuranceTypeText][componentType].payerContactNumber
                  .valid === ValidationStatus.INVALID
              }
              id={
                data[insuranceTypeText][componentType].payerContactNumber
                  .componentId!
              }
              isRequired={false}
              isShowWarning={true}
              label={
                data[insuranceTypeText][componentType].payerContactNumber.title!
              }
              labelClassName={focusClasses.payerContactNumber}
              testId="payerContactNumberID"
              warningMessage={
                data[insuranceTypeText][componentType].payerContactNumber
                  .errorMessage
              }
            >
              <InputMask
                className="payerContactNumber"
                data-testid="payer-number-value"
                id={
                  data[insuranceTypeText][componentType].payerContactNumber.id!
                }
                mask="(999) 999-9999"
                name="payerContactNumber"
                onBlur={(e) => setClasses(e, "")}
                onChange={validateAndSetInsuranceData}
                onFocus={(e) => setClasses(e, "Mui-focused")}
                placeholder="(___) ___-____"
                value={
                  data[insuranceTypeText][componentType].payerContactNumber
                    .value
                }
                type="tel"
                pattern="[0–9]*"
              />
            </InputWithLabel>
          </div>
          <Grid className="payerContactExtensionDiv" item xs={4}>
            <InputWithLabel
              error={
                data[insuranceTypeText][componentType].extension.valid ===
                ValidationStatus.INVALID
              }
              id={data[insuranceTypeText][componentType].extension.componentId!}
              isRequired={false}
              isShowWarning={true}
              label={data[insuranceTypeText][componentType].extension.title!}
              testId="extensionID"
              warningMessage={
                data[insuranceTypeText][componentType].extension.errorMessage
              }
            >
              <InputBase
                className="payer-information-input"
                id={data[insuranceTypeText][componentType].extension.id!}
                inputProps={{ inputMode: "numeric", pattern: "[0-9*]" }}
                name="extension"
                onChange={validateAndSetInsuranceData}
                type="text"
                value={data[insuranceTypeText][componentType].extension.value}
              />
            </InputWithLabel>
          </Grid>
        </div>
        <InputWithLabel
          error={
            data[insuranceTypeText][componentType].relationShipInsured.valid ===
            ValidationStatus.INVALID
              ? true
              : false
          }
          id={
            data[insuranceTypeText][componentType].relationShipInsured
              .componentId!
          }
          isRequired={false}
          isShowWarning={true}
          label={
            data[insuranceTypeText][componentType].relationShipInsured.title!
          }
          labelClassName="insurance-type-title"
          testId="relationshiptypeLabel"
          warningMessage={
            data[insuranceTypeText][componentType].relationShipInsured
              .errorMessage
          }
          isDropdown={true}
        >
          <CustomDropDown
            handleChange={(e: any) => validateAndSetInsuranceData(e)}
            menuItem={insuredRelation}
            name={"relationShipInsured"}
            placeHolder="Select"
            selectpropsClassName={
              data[insuranceTypeText][componentType].relationShipInsured?.value
                ? "insurance-informantion-select"
                : "placeHolder"
            }
            selectClassName={
              data[insuranceTypeText][componentType].relationShipInsured.value
                ? "insurance-informantion-input"
                : "placeHolder"
            }
            testId={`relationship-type`}
            value={
              data[insuranceTypeText][componentType].relationShipInsured.value
            }
          />
        </InputWithLabel>
      </div>
    </div>
  );
};

export default PayerAllDetails;
