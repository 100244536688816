import "./newOrder.css";
import {
  ERROR_MSG_ACUTE_ORDER_DETAIL,
  ERROR_MSG_ACUTE_ORDER_VIEW,
  ERROR_MSG_DUPLICATE_CHECK_FAILED,
  ERROR_MSG_GET_PRESCRIBER_DETAILS,
  ERROR_MSG_STOP_SHARING_ORDER,
  ERROR_MSG_VAC_ORDER_UPLOAD_DOCUMENT,
  ERROR_VAC_ORDER_DEL,
  ERROR_VAC_ORDER_SAVE_SUBMIT_UPDATE,
  FILE_UPLOAD_INVALID_FILETYPE_ERROR_MESSAGE,
  FILE_UPLOAD_UNABLETOSCAN_ERROR_MESSAGE,
} from "../../util/errorMsg";
import {
  ICanister,
  IDressingKit,
  INewOrder,
  INewOrderProps,
  IProductAccessory,
} from "./newOrder.interface";
import {
  CMS_VAC_THERAPY_INFORMATION_CONTENT,
  DD_INSURANCE_TYPE_CONTENT,
  DD_INSURANCE_TYPE_TRANSITION_CONTENT,
  DD_PROVIDER_TYPE,
  DD_UPLOAD_DOCUMENT_TYPE,
  DD_US_STATES_CONTENT,
  FILE_UPLOAD_LOCAL_ERROR_MESSAGE,
  FILE_UPLOAD_SERVER_ERROR_MESSAGE,
} from "../../util/staticText";
import {
  getVacDressingKitProducts,
  getVacOrderLockStatus,
  saveVacOrder,
  updateVacOrder,
} from "../../util/3meService";
import {
  NewOrderPageSection,
  UploadDocumentAction,
} from "./NewOrderContainer.enum";
import {
  callShareVacOrder,
  cancelOrSubmitVacOrder,
  checkIfDuplicateOrder,
  getPdfContent,
  UnlockVacOrder,
} from "../../util/vacOrderService";
import {
  IAnalyticsData,
  getCodeFromText,
  getPdfUrl,
  getSiteUseId,
  getTextFromCode,
  sendAnalyticsData,
} from "../../util/utilityFunctions";
import { Grid } from "@mui/material";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../context/NewOrderContext";
import { format } from "react-string-format";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../context/RolesPermissionContext";
import {
  vacCannisterMapper,
  vacPrimaryDressingKitMapper,
  vacReadyCareDefaultDressingMapper,
  vacSecondaryDressingKitMapper,
} from "./mapper/VacOrderSummary/vacProductsMapper";
import {
  Question,
  WoundQuestionaries,
} from "./clinicalInformation/clinicalInfo.interface";
import { getCMSContent } from "../../util/cmsService";
import { uploadDocument } from "../../util/3meService";
import {
  mapAccessory,
  mapCanister,
  mapClinicalInformation,
  mapDeliveryInformation,
  mapDressingKit,
  mapProductInfo,
  mapRequesterInfo,
  mapSecondaryWoundIformation,
  mapVacOrderResponse,
  mapWoundQuestionaries,
} from "./mapper/VacOrderSummary/newOrderResponseMapper";
import { NewOrderValidator } from "./newOrder.validator";
import { getNPIPrescriber } from "../../util/npiService";
import { Popup } from "../../core/popup/popup.component";
import { useHistory, useLocation } from "react-router-dom";
import { cancelSharedOrder } from "../../util/alertService";
import SnackBar from "../../core/snackBar/snackBar.component";
import ProgressBar from "../progressBar/progressBar.component";
import { getdropDownContent } from "../../util/dropDownService";
import { getVacOrderSummary } from "../../util/vacOrderService";
import { LoadingSpinner } from "../../core/loader/LoadingSpinner";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { NextOrder } from "./newOrderFooterGroup/nextOrder.component";
import { mapSaveVacOrderRequest } from "./mapper/newOrderRequestMapper";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { ValidationStatus } from "../../core/interfaces/input.interface";
import { SaveExitOrder } from "./newOrderFooterGroup/saveExit.component";
import { DeletePopup } from "../../core/deleteOrder/deleteOrder.component";
import { ISaveVacOrderRequest } from "./mapper/newOrderRequestMapper.interface";
import { ExpressButton } from "../../core/expressButton/expressButton.component";
import UserConfirmation from "./saveOrderConfirmation/userConfirmation.component";
import { IFacility } from "../manageProfile/facilityInformation/facility.interface";
import { IVacTherapyInformation } from "./woundBed/vacTherapyInformation.interface";
import { SharedOrderModal } from "./newOrderFooterGroup/shareOrder/shareOrder.enum";
import { getAdditionalWoundQuestionaries } from "../../util/primaryWoundTypeService";
import { ShareOrderInfo } from "./newOrderFooterGroup/shareOrder/shareOrderMainComponent";
import ReviewOrderStepper from "./newOrderReviewOrderStepper/reviewOrderStepper.component";
import PatientInfoStepper from "./newOrderPatientInfoStepper/patientInfoStepper.component";
import { VacOrderSummaryData } from "./mapper/VacOrderSummary/newOrderResponseMapper.interface";
import { WoundInformation } from "./newOrderWoundInfoStepper/newOrderWoundInfoStepper.Component";
import { VacDressingKitModel } from "./dressingSupplies/vacDressingKit/vacDressingKit.interface";
import { IShareOrderRequest } from "./newOrderFooterGroup/shareOrder/shareOrderRequest.interface";
import NewOrderErrorPopupMessage from "./newOrderFooterGroup/newOrderErrorPopupMessage.component";
import { IPrescriberModal } from "./prescriberInformation/prescriberSearch/prescriberSearch.model";
import { NewOrderFooterButtonGroup } from "./newOrderFooterGroup/newOrderFooterBtnGroup.component";
import { IDropZoneDocumentSelect } from "../../core/customDropZone/dropZoneDocumentSelect.interface";
import { IPrintOrderSummaryRequest } from "./newOrderReviewOrderStepper/PrintOrderSummaryPdf.interface";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../context/InternalSignOnContext";
import { ReactComponent as BackIcon } from "../../assets/LPBackButton.svg";
import {
  mapCreateTransitionOrderStoredWoundResponse,
  mapCreateTransitionSecondaryStoredWoundIformation,
  mappedPrescriberInformation,
  mapTransitionOrderResponse,
} from "./mapper/transitionOrder/transitionOrderResponseMapper";
import { IAcuteOrderDetail } from "./newOrderAcuteTransition/newOrderAcuteTransition.interface";
import {
  getAcuteOrderRoDetails,
  getCustomerPlusAcuteOrderRoDetails,
} from "../../util/actueService";
import { useQuery } from "react-query";
import { autoSaveOrderTimeInterval } from "./newOrder.model";
import ErrorPopup from "../../core/errorPopup/errorPopup.component";
import {
  mapCreateTransitionOrderResponse,
  mapCreateTransitionOrderWoundResponse,
  mapCreateTransitionSecondaryWoundIformation,
} from "./mapper/createTransitionOrderResponseMapper/createTransitionOrderResponseMapper";
import { ICreateTransitionOrder } from "./mapper/createTransitionOrderResponseMapper/createTransitionOrderResponseMapper.interface";
import { INewOrderWoundInfo } from "./newOrderWoundInfoStepper/newOrderWoundInfo.interface";
import { VAC_ORDER_ID_MISSING } from "../../util/errorCode";
import DuplicateOrderCheckDialogue from "./duplicateOrderCheck/duplicateOrderCheckDialogue.component";
import { IDuplicateRO } from "./patientInformation/patientInformation.interface";
import moment from "moment";

export const NewOrder = ({
  Validator = new NewOrderValidator(),
}: INewOrderProps) => {
  const history = useHistory();
  const location: any = useLocation();
  const [validator] = useState<NewOrderValidator>(Validator);
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const showDialog = newOrderObj?.showDialog;
  const newOrderData = newOrderObj?.newOrderData;
  const setNewOrderData = newOrderObj!.setNewOrderData;
  const requesterInfo = newOrderObj?.requesterInfo;
  const setRequesterInfo = newOrderObj?.setRequesterInfo;
  const productInfo = newOrderObj?.productInfo;
  const setProductInfo = newOrderObj?.setProductInfo;
  const dressingKit = newOrderObj?.dressingKit;
  const setDressingKit = newOrderObj?.setDressingKit;
  const canister = newOrderObj?.canister;
  const setCanister = newOrderObj?.setCanister;
  const accessory = newOrderObj?.accessory;
  const setAccessory = newOrderObj?.setAccessory;
  const newOrderDocuments = newOrderObj?.newOrderDocuments;
  const prescriptionDocuments = newOrderObj?.prescriptionDocuments;
  const woundInfoData = newOrderObj?.woundInfoData;
  const setWoundInfoData = newOrderObj?.setWoundInfoData;
  const secondaryWoundInfoData = newOrderObj?.secondaryWoundInfoData;
  const deliveryInformation = newOrderObj?.deliveryInformation;
  const setDeliveryInformation = newOrderObj?.setDeliveryInformation;
  const updatedDeliveryInformation = newOrderObj?.updatedDeliveryInformation;
  const setUpdatedDeliveryInformation =
    newOrderObj?.setUpdatedDeliveryInformation;
  const isEditingDeliveryInformation =
    newOrderObj?.isEditingDeliveryInformation;
  const setIsEditingDeliveryInformation =
    newOrderObj?.setIsEditingDeliveryInformation;
  const isNextButtonClicked = newOrderObj?.isNextButtonClicked ?? false;
  const setIsNextButtonClicked = newOrderObj!.setIsNextButtonClicked;
  const setSecondaryWoundInfoData = newOrderObj?.setSecondaryWoundInfoData;
  const setNewOrderDocuments = newOrderObj?.setNewOrderDocuments;
  const [getApiLoader, setGetAPILoader] = useState(false);
  const [nextOrderOpen, setNextOrderOpen] = useState(false);
  const [saveAPILoader, setSaveAPILoader] = useState(false);
  const [isSharedOrder, setIsSharedOrder] = useState(false);
  const [isSaveExitFlow, setIsSaveExitFlow] = useState(false);
  const [emptyPopup, setEmptyPopup] = useState<boolean>(false);
  const [saveAndExitOpen, setSaveAndExitOpen] = useState(false);
  const [deleteOrderOpen, setDeleteOrderOpen] = useState(false);
  const [shareOrderLoader, setShareOrderLoader] = useState(false);
  const [cancelShareLoader, setCancelShareLoader] = useState(false);
  const [saveOrderToastFlag, setSaveOrderToastFlag] = useState(false);
  const [showSupportPhoneNum, setShowSupportPhoneNum] = useState(true);
  const [cancelBtnVisibility, setCancelBtnVisibility] = useState(true);
  const [freshNewOrderLoader, setFreshNewOrderLoader] = useState(false);
  const [newOrderErrorPopPpFlag, setNewOrderErrorPopUpFlag] = useState(false);
  const [isStopShareOrderSuccess, setIsStopShareOrderSuccess] = useState(false);
  const [showMissingRequiredFieldPrompt, setShowMissingRequiredFieldPrompt] =
    useState(false);
  const [isSharedOrderNeedToBeDisable, setIsSharedOrderNeedToBeDisable] =
    useState(false);

  const [pdfUrl, setPDFURL] = useState("");
  const [vacOrderID, setVacOrderID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [sharedOrderUserA, setSharedOrderUserA] = useState("");
  const [shareOrderButtonText, setShareOrderButtonText] = useState("");
  const [scrollableComponentId, setScrollableComponentId] = useState<
    string | null
  >(null);
  const [autoCursorComponentId, setAutoCursorComponentId] = useState<
    string | null
  >(null);

  const [states, setStates] = useState([]);
  const [statesText, setStatesText] = useState([]);
  const [providerTypes, setProviderTypes] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [documentTypeText, setdocumentTypeText] = useState([]);
  const [insuranceTypesText, setInsuranceTypesText] = useState([]);
  const [documentTypeCodeList, setdocumentTypeCodeList] = useState([]);
  const [deepLinkVacOrderLocked, setDeepLinkVacOrderLocked] = useState(null);
  const [insuranceTypesTransition, setInsuranceTypesTransition] = useState([]);
  const [insuranceTypesTransitionText, setInsuranceTypesTransitionText] =
    useState([]);
  const [showInsuranceTypeTransition, setShowInsuranceTypeTransition] =
    useState(false);
  const [shareOrderText, setshareOrderText] = useState<any>();
  const [vacTherapyInformationData, setvacTherapyInformationData] =
    useState<IVacTherapyInformation>();
  const [vacAllProducts, setAllVacProducts] =
    useState<VacDressingKitModel | null>(null);
  const [isCreateTransitionOrder] = useState(location?.state);
  const [createTransitionErrorHandle, setCreateTransitionErrorHandle] =
    useState(false);

  //->Auto Save Order
  //To detect wheather order is auto saved flag enabled/disabled
  const [isAutoSaveEnabled, setIsAutoSaveEnabled] = useState<boolean>(false);
  //Is to check order object has been changed or not with newOrderDataRef Ref in UseEffect
  //newOrderDataRef is to Finding Order Object has updated or not
  const newOrderDataRef = useRef(newOrderData);
  //woundOrderDataRef is to Finding Order Object has updated or not
  const woundOrderDataRef = useRef(woundInfoData);
  //To specify order is saved in autoSave Function
  const [isAutoSavedOrder, setIsAutoSavedOrder] = useState<boolean>(false);
  //To check wheather the documents Array updated or not both PrescriberDocs/New Order Docs
  const [isUploadDocumentsChanged, setIsUploadDocumentsChanged] =
    useState<boolean>(false);
  const [isTransition, setIsTransition] = useState(
    isCreateTransitionOrder?.createTransition
  );
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);
  const [isDuplicateOrder, setIsDuplicateOrder] = useState<boolean>(false);
  const [duplicateRODetails, setDuplicateRODetails] = useState<any>();
  
  const getVacOrderUploadErrorMessage = (errorCode: number) => {
    switch (errorCode) {
      case 1043:
        return FILE_UPLOAD_SERVER_ERROR_MESSAGE;
      case 1017:
        return FILE_UPLOAD_INVALID_FILETYPE_ERROR_MESSAGE;
      case 1044:
        return FILE_UPLOAD_UNABLETOSCAN_ERROR_MESSAGE;
    }
  };

  const getUploadImagesAPIParams = (): any[] => {
    let allDocs: { [key: string]: any } = {};
    const allDeletedImage = [
      ...newOrderObj!.deletedOrderDocuments,
      ...newOrderObj!.deletedPrescriptionDocuments,
    ];
    const newOrderDeletedDocsCount = newOrderObj!.deletedOrderDocuments.length;
    allDeletedImage.forEach((document, index) => {
      if (document.documentId) {
        let deletedDoc = {
          actions: UploadDocumentAction.DELETION,
          content: !document.documentId ? document.documentBase64 : null,
          documentId: document.documentId ? document.documentId : null,
          fileName: document.documentName,
          fileNetDocTypeCode:
            newOrderDeletedDocsCount > index
              ? getCodeFromText(
                  documentTypeCodeList,
                  document.documentType?.value!
                )
              : "RXSection",
        };
        // Check if any other file exist with same name
        // If not then add to dictioanry.
        if (!allDocs[document.documentName]) {
          allDocs[document.documentName] = deletedDoc;
        }
      }
    });
    const allSelectedImage = [
      ...newOrderObj!.newOrderDocuments,
      ...newOrderObj!.prescriptionDocuments,
    ];
    const newOrderDocsCount = newOrderObj!.newOrderDocuments.length;
    allSelectedImage.forEach((document, index) => {
      if (
        document.succeeded &&
        ((newOrderDocsCount > index &&
          document.documentType &&
          document.documentType.valid === ValidationStatus.VALID) ||
          (newOrderDocsCount <= index && !document.isRemoved))
      ) {
        let uploadedDoc = {
          actions:
            newOrderDocsCount > index && !document.documentId
              ? UploadDocumentAction.ADDITION
              : document.documentId
              ? UploadDocumentAction.UPDATION
              : UploadDocumentAction.ADDITION,
          content: !document.documentId ? document.documentBase64 : null,
          documentId: document.documentId ? document.documentId : null,
          fileName: document.documentName,
          fileNetDocTypeCode:
            newOrderDocsCount > index
              ? getCodeFromText(
                  documentTypeCodeList,
                  document.documentType?.value!
                )
              : "RXSection",
        };
        let existingDoc = allDocs[document.documentName];
        if (!existingDoc) {
          allDocs[document.documentName] = uploadedDoc;
        } else {
          if (existingDoc.actions === UploadDocumentAction.DELETION) {
            uploadedDoc.actions = UploadDocumentAction.UPDATION;
            uploadedDoc.documentId = existingDoc.documentId;
            allDocs[document.documentName] = uploadedDoc;
          } else {
            allDocs[document.documentName] = uploadedDoc;
          }
        }
      }
    });
    const uploadDocus = Object.values(allDocs);
    return uploadDocus;
  };

  const initiateSaveVacOrder = async (
    isSaveAndExit: boolean,
    shareOrderPopupData?: IShareOrderRequest,
    isNavigationSaveExitClicked?: boolean,
    isSaveOnNextClick?: boolean
  ) => {
    let isOrderSavedSucceeded: boolean = false;
    setSaveAPILoader(true);
    let errorInUploadFile = false;
    const result = await callSaveVacOrder(isSaveAndExit);
    if (!result || !result.succeeded) {
      setSaveAPILoader(false);
      setNewOrderErrorPopUpFlag(true);
      setErrorMessage(ERROR_VAC_ORDER_SAVE_SUBMIT_UPDATE);
      setErrorCode(result?.error?.code || result?.status);
      return isOrderSavedSucceeded;
    } else {
      isOrderSavedSucceeded = true;
      // Add documents to DB here
      newOrderObj?.setIsHandleChangeTriggered(false);
      newOrderObj?.setVacOrderID(result.data);
      let uploadedDocs: any[] = getUploadImagesAPIParams();
      let response: any;
      if (uploadedDocs.length > 0) {
        let docRequest = {
          entityID: result.data,
          entityType: 1,
          Files: uploadedDocs,
        };
        const docResult = await uploadDocument(docRequest);
        if (!docResult.succeeded) {
          isOrderSavedSucceeded = false;
          response = docResult.succeeded;
          setSaveAndExitOpen(false);
          setNewOrderErrorPopUpFlag(true);
          setErrorMessage(
            getVacOrderUploadErrorMessage(
              docResult?.error?.code || docResult?.status
            )!
          );
          setErrorCode(docResult?.error?.code || docResult?.status);
          setShowSupportPhoneNum(false);
        } else {
          isOrderSavedSucceeded = true;
          response = docResult.data;
          let updatedFiles: IDropZoneDocumentSelect[] =
            newOrderObj?.newOrderDocuments.map((item) => {
              var successFile = response.filter(
                (file: { fileName: any }) =>
                  file.fileName === item.documentName && item.succeeded
              );
              if (
                item.documentName === successFile[0]?.fileName &&
                (successFile[0]?.code === 1054 ||
                  successFile[0]?.code === 1042 ||
                  successFile[0]?.code === 1075)
              ) {
                const updatedItem: IDropZoneDocumentSelect = {
                  ...item,
                  succeeded: true,
                  documentId: successFile[0]?.message,
                };
                return updatedItem;
              } else if (
                item.documentName !== successFile[0]?.fileName &&
                item.documentId
              ) {
                return item;
              } else {
                const errorItem: IDropZoneDocumentSelect = {
                  ...item,
                  succeeded: false,
                  errorMessage: successFile[0]?.message ?? item.errorMessage,
                };
                return errorItem;
              }
            })!;
          newOrderObj?.setNewOrderDocuments(updatedFiles);
          newOrderObj?.setDeletedOrderDocuments([]);
          // Missing Rx Documents
          newOrderObj?.setDeletedPrescriptionDocuments([]);
          const latestDocuments =
            newOrderObj?.prescriptionDocuments.filter(
              (item) => !item.isRemoved
            ) ?? [];
          let updatedMissingRxFiles: IDropZoneDocumentSelect[] =
            latestDocuments.map((item) => {
              var successFile = response.filter(
                (file: { fileName: any }) =>
                  file.fileName === item.documentName && item.succeeded
              );
              if (
                item.documentName === successFile[0]?.fileName &&
                (successFile[0]?.code === 1054 ||
                  successFile[0]?.code === 1042 ||
                  successFile[0]?.code === 1075)
              ) {
                const updatedItem: IDropZoneDocumentSelect = {
                  ...item,
                  succeeded: true,
                  documentId: successFile[0]?.message,
                };
                return updatedItem;
              } else if (
                item.documentName !== successFile[0]?.fileName &&
                item.documentId
              ) {
                return item;
              } else {
                const errorItem: IDropZoneDocumentSelect = {
                  ...item,
                  succeeded: false,
                  errorMessage: successFile[0]?.message ?? item.errorMessage,
                };
                return errorItem;
              }
            })!;
          newOrderObj?.setPrescriptionDocuments(updatedMissingRxFiles);
          newOrderObj?.setDeletedPrescriptionDocuments([]);
        }
        if (!response) {
          errorInUploadFile = true;
          setSaveAndExitOpen(false);
          setNewOrderErrorPopUpFlag(true);
          setErrorMessage(FILE_UPLOAD_SERVER_ERROR_MESSAGE);
          setShowSupportPhoneNum(false);
        } else if (
          response?.filter(
            (file: { code: number }) =>
              file.code !== 1042 && file.code !== 1054 && file.code !== 1075
          ).length > 0
        ) {
          errorInUploadFile = true;
          setSaveAndExitOpen(false);
          setNewOrderErrorPopUpFlag(true);
          setErrorMessage(FILE_UPLOAD_SERVER_ERROR_MESSAGE);
          setShowSupportPhoneNum(false);
        }
      }
      setSaveAndExitOpen(false);
      if (isSaveAndExit && !errorInUploadFile && !isNavigationSaveExitClicked) {
        setSaveAndExitOpen(false);
        history.goBack();
      } else {
        setSaveAndExitOpen(false);
        setVacOrderID(result.data);
        newOrderObj?.setVacOrderID(result.data);
        setSaveOrderToastFlag(true);
        setTimeout(() => {
          setSaveOrderToastFlag(false);
        }, 5000);
        if (shareOrderPopupData?.isSharedOrder) {
          setSaveAPILoader(false);
          setShareOrderLoader(true);
          let shareOrderRequestObj = {
            OrderId: result.data,
            SharedTo: shareOrderPopupData?.sharedTo,
            SharedOrderNotes: shareOrderPopupData?.sharedOrderNotes,
            facilityName: authObj?.registeredFaciltyAddress?.accountName,
            SiteUseId: getSiteUseId(authObj),
          };
          apicallShareVacOrder(shareOrderRequestObj);
        }
        setSaveAPILoader(false);
        if (!errorInUploadFile && isNavigationSaveExitClicked) {
          return true;
        } else if (isSaveOnNextClick) {
          return isOrderSavedSucceeded;
        } else return false;
      }
    }
    setSaveAPILoader(false);
  };

  const setQuestionnaireContent = async () => {
    const questionsData = await getAdditionalWoundQuestionaries();
    const mapData = new Map<string, WoundQuestionaries>();
    questionsData.forEach((val: any) => {
      if (!mapData.has(val.woundType)) {
        mapData.set(val.woundType, {
          additionalQuestion: val.additionalQuestion.map((quest: Question) => ({
            ...quest,
            value: "",
            valid: ValidationStatus.UNTOUCHED,
          })),
          category: val.category,
          woundType: val.woundType,
        });
      }
    });
    newOrderObj?.setWoundQuestionnaries(mapData);
    return mapData;
  };

  const getIsSavedOrderFlag = async (): Promise<boolean> => {
    let result = internalObj?.isVACOrderSSO
      ? true
      : await handleSaveAndValidate();
    return result || !newOrderObj?.isHandleChangeTriggered;
  };

  const validateAll = async () => {
    let isValid = "";
    if (
      newOrderObj?.newOrderPage === NewOrderPageSection.NEWORDER_PATIENT_INFO
    ) {
      setIsNextButtonClicked(true);
      isValid = validator.validateAll(
        newOrderData,
        requesterInfo,
        dressingKit,
        updatedDeliveryInformation,
        newOrderDocuments,
        prescriptionDocuments,
        setNewOrderData!,
        setRequesterInfo!,
        setDressingKit!,
        setUpdatedDeliveryInformation!,
        setNewOrderDocuments!,
        setScrollableComponentId,
        setAutoCursorComponentId
      );
      if (
        newOrderData?.submitPrescription?.value === "EPrescription" &&
        newOrderObj?.prescriberList?.eScript === "No"
      ) {
        isValid = ValidationStatus.INVALID;
      }
      if (isValid === ValidationStatus.VALID) {
        const isOrderSavedSucceeded = await getIsSavedOrderFlag();
        if (isOrderSavedSucceeded) {
          vacOrderAnalytics("Next");
          if (newOrderObj?.showConfirmOrderPopup) {
            if (!newOrderObj?.isPartialOrder) {
              newOrderObj?.setnewOrderProgress(40);
              newOrderObj?.setNewOrderPage(
                NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO
              );
              setNextOrderOpen(false);
              window.scrollTo(0, 0);
            } else {
              newOrderObj?.setNewOrderPage(
                NewOrderPageSection.NEWORDER_REVIEW_ORDER
              );
              setNextOrderOpen(false);
              newOrderObj?.setisComingFromPrev(true);
              window.scrollTo(0, 0);
            }
          } else {
            setNextOrderOpen(true);
            newOrderObj?.setShowConfirmOrderPopup(true);
          }
        }
        if(newOrderObj?.isDuplicateCheckKeyFieldChanged)
        await validateIsDuplicateOrder(newOrderData!);
      } else {
        setShowMissingRequiredFieldPrompt(true);
      }
    } else if (
      newOrderObj?.newOrderPage ===
      NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO
    ) {
      const isValid = validator.validateAllWoundInfo(
        newOrderObj?.woundInfoData,
        newOrderObj?.setWoundInfoData,
        newOrderObj.secondaryWoundInfoData,
        newOrderObj.setSecondaryWoundInfoData,
        newOrderObj?.primaryAdditionalQuestions,
        newOrderObj?.setPrimaryAdditionalQuestions,
        newOrderObj?.secondaryAdditionalQuestions,
        newOrderObj?.setSecondaryAdditionalQuestions,
        newOrderObj?.newOrderDocuments,
        newOrderObj?.prescriptionDocuments,
        newOrderData?.submitPrescription,
        newOrderObj?.setNewOrderDocuments!,
        setScrollableComponentId,
        setAutoCursorComponentId
      );
      if (isValid === ValidationStatus.VALID) {
        const isOrderSavedSucceeded = await getIsSavedOrderFlag();
        if (isOrderSavedSucceeded) {
          if (
            newOrderData?.submitPrescription.valid === ValidationStatus.INVALID
          ) {
            window.scrollTo(0, 0);
            newOrderObj?.setisComingFromPrev(true);
            newOrderObj?.setnewOrderProgress(20);
            newOrderObj?.setNewOrderPage(
              NewOrderPageSection.NEWORDER_PATIENT_INFO
            );
            setShowMissingRequiredFieldPrompt(true);
          } else {
            vacOrderAnalytics("Next");
            window.scrollTo(0, 0);
            newOrderObj?.setnewOrderProgress(80);
            newOrderObj?.setNewOrderPage(
              NewOrderPageSection.NEWORDER_REVIEW_ORDER
            );
          }
        }
      } else {
        setShowMissingRequiredFieldPrompt(true);
      }
    } else if (
      newOrderObj?.newOrderPage === NewOrderPageSection.NEWORDER_REVIEW_ORDER
    ) {
      vacOrderAnalytics("Submit_Vac_Order");
      callSubmitVacOrder();
    }
  };

  const vacOrderAnalytics = (eventName: string) => {
    let analyticsData: IAnalyticsData = {
      page_type: "react",
      view_name: "vacOrderComponent",
      event_type: "click",
      event_name: eventName,
      tealium_event: "Vac_Order_Button",
      mmmex_userrecordid: authObj?.userProfile?.userID!,
      mmmex_facilityid: authObj?.registeredFaciltyAddress?.siteUseId!,
      mmmex_roleid: permissionObj?.mappedRolesPermissionData?.roleName!,
      mmmex_pagename: "vacOrder",
    };
    sendAnalyticsData(analyticsData);
  };

  const checkVacOrderDeeplink = () => {
    return authObj &&
      authObj.deepLinkPath &&
      authObj.deepLinkPath.includes("/orders/newOrder/deepLink")
      ? true
      : false;
  };

  const checkFirstErroredFieldAndFocusOnInputField = async () => {
    await setTimeout(() => {
      if (scrollableComponentId) {
        const scrollableComponent = document.getElementById(
          scrollableComponentId
        );
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
          setScrollableComponentId(null);
        }
      }
    }, 500);
    await setTimeout(() => {
      if (autoCursorComponentId) {
        const focusInput = document.getElementById(autoCursorComponentId);
        if (focusInput) {
          focusInput.focus();
        }
        setAutoCursorComponentId(null);
      }
    }, 300);
  };

  const updateOrderID = () => {
    let parseOrderID = "";
    if (location.state !== undefined && location.state.orderID !== undefined) {
      parseOrderID = location.state.orderID;
    } else if (location.state !== undefined && checkVacOrderDeeplink()) {
      parseOrderID = location.state.stateData.orderID;
    } else if (location.state === undefined && checkVacOrderDeeplink()) {
      const urlData = authObj?.deepLinkPath?.split("/");
      const decodeData = JSON.parse(atob(urlData![4]));
      parseOrderID = decodeData.orderId;
    }
    if (
      newOrderObj?.vacOrderID === "" ||
      newOrderObj?.vacOrderID === null ||
      newOrderObj?.vacOrderID === undefined
    )
      newOrderObj?.setVacOrderID(parseOrderID);
    return parseOrderID;
  };
  let orderID = updateOrderID();

  const checkValidFacilityOnDeepLink = (
    allFacilities: IFacility[],
    siteUseID: string
  ) => {
    let facilityFound: IFacility[] = allFacilities?.filter(
      (item: IFacility) => item.siteUseId === siteUseID
    );
    if (facilityFound.length === 0) {
      history.replace("/home");
    } else {
      authObj?.setregisteredFaciltyAddress(facilityFound[0]);
    }
  };

  const handleNextOrderPopup = () => {
    setNextOrderOpen(false);
  };

  const handleSaveAndExit = () => {
    setSaveAPILoader(true);
    setIsSaveExitFlow(true);
    try {
      newOrderObj?.setShowDialog(false);
      initiateSaveVacOrder(true);
    } catch (error) {
      setNewOrderErrorPopUpFlag(true);
      setErrorMessage(ERROR_VAC_ORDER_SAVE_SUBMIT_UPDATE);
    }
  };

  const handleSaveAndExitNavigation = () => {
    setIsSaveExitFlow(true);
    const isValid = validator.validateMinimumFields(
      newOrderData,
      setNewOrderData!
    );
    if (isValid === ValidationStatus.VALID) {
      let errorFiles = newOrderObj?.newOrderDocuments.filter(
        (file) => file.succeeded !== true
      );
      if (errorFiles?.length === 0) {
        setSaveAPILoader(true);
        try {
          let obj = initiateSaveVacOrder(true, undefined, true);
          return obj;
        } catch (error) {
          setNewOrderErrorPopUpFlag(true);
          setErrorMessage(ERROR_VAC_ORDER_SAVE_SUBMIT_UPDATE);
          return false;
        }
      } else {
        setNewOrderErrorPopUpFlag(true);
        setErrorMessage(FILE_UPLOAD_LOCAL_ERROR_MESSAGE);
        setShowSupportPhoneNum(false);
        return false;
      }
    } else {
      setNewOrderErrorPopUpFlag(true);
      setErrorMessage(
        "First Name, Last Name, and Date of Birth are Required fields in order to save. Please complete these fields to save your order."
      );
      setShowSupportPhoneNum(false);
      return false;
    }
  };

  const saveAndExitPopupHandler = () => {
    setIsSaveExitFlow(true);
    const isValid = validator.validateMinimumFields(
      newOrderData,
      setNewOrderData!
    );
    if (isValid === ValidationStatus.VALID) {
      vacOrderAnalytics("Save_Exit_Vac_Order");
      let errorFiles = newOrderObj?.newOrderDocuments.filter(
        (file) => file.succeeded !== true
      );
      if (errorFiles?.length === 0) {
        setSaveAndExitOpen(true);
      } else {
        setNewOrderErrorPopUpFlag(true);
        setErrorMessage(FILE_UPLOAD_LOCAL_ERROR_MESSAGE);
        setShowSupportPhoneNum(false);
      }
    } else {
      setNewOrderErrorPopUpFlag(true);
      setErrorMessage(
        "First Name, Last Name, and Date of Birth are Required fields in order to save. Please complete these fields to save your order."
      );
      setShowSupportPhoneNum(false);
    }
  };

  const saveOrderHandler = async (): Promise<boolean> => {
    let isOrderSaved: boolean = false;
    const isValid = validator.validateMinimumFields(
      newOrderData,
      setNewOrderData!
    );
    if (isValid === ValidationStatus.VALID) {
      vacOrderAnalytics("Save_Vac_Order");
      //check for file uplaod error
      let errorFiles = newOrderObj?.newOrderDocuments.filter(
        (file) => file.succeeded !== true
      );
      if (errorFiles?.length === 0) {
        setSaveAPILoader(true);
        try {
          isOrderSaved =
            (await initiateSaveVacOrder(false, undefined, false, true)) ??
            false;
        } catch (error) {
          setNewOrderErrorPopUpFlag(true);
          setErrorMessage(ERROR_VAC_ORDER_SAVE_SUBMIT_UPDATE);
        }
      } else {
        setNewOrderErrorPopUpFlag(true);
        setErrorMessage(FILE_UPLOAD_LOCAL_ERROR_MESSAGE);
        setShowSupportPhoneNum(false);
      }
    } else {
      setNewOrderErrorPopUpFlag(true);
      setErrorMessage(
        "First Name, Last Name, and Date of Birth are Required fields in order to save. Please complete these fields to save your order."
      );
      setShowSupportPhoneNum(false);
    }
    return isOrderSaved;
  };

  const shareOrderHandler = () => {
    if (shareOrderButtonText === "Share Order") {
      const isValid = validator.validateMinimumFields(
        newOrderData,
        setNewOrderData!
      );
      if (isValid === ValidationStatus.VALID) {
        newOrderObj?.setshareOrderAddPopUpSection(SharedOrderModal.SHARE_ORDER);
        newOrderObj?.setshareOrderPopup(true);
        vacOrderAnalytics("Share_Vac_Order");
      } else {
        setNewOrderErrorPopUpFlag(true);
        setErrorMessage(
          "First Name, Last Name, and Date of Birth are Required fields in order to save. Please complete these fields to Share your order."
        );
        setShowSupportPhoneNum(false);
      }
    } else {
      callStopShareOrder(vacOrderID);
    }
  };

  const previousButtonHandler = () => {
    vacOrderAnalytics("Previous");
    if (
      newOrderObj?.isPartialOrder &&
      newOrderObj?.newOrderPage === NewOrderPageSection.NEWORDER_REVIEW_ORDER
    ) {
      window.scrollTo(0, 0);
      newOrderObj?.setisComingFromPrev(true);
      newOrderObj?.setnewOrderProgress(20);
      newOrderObj?.setNewOrderPage(NewOrderPageSection.NEWORDER_PATIENT_INFO);
    } else {
      if (
        newOrderObj?.newOrderPage ===
        NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO
      ) {
        window.scrollTo(0, 0);
        newOrderObj?.setisComingFromPrev(true);
        newOrderObj?.setnewOrderProgress(20);
        newOrderObj?.setNewOrderPage(NewOrderPageSection.NEWORDER_PATIENT_INFO);
      } else if (
        newOrderObj?.newOrderPage === NewOrderPageSection.NEWORDER_REVIEW_ORDER
      ) {
        window.scrollTo(0, 0);
        newOrderObj?.setisComingFromPrev(true);
        newOrderObj?.setnewOrderProgress(40);
        newOrderObj?.setNewOrderPage(
          NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO
        );
      } else {
        history.goBack();
      }
    }
  };

  const showCancelPopupHandler = () => {
    if (
      newOrderObj?.isPartialOrder &&
      newOrderObj?.newOrderPage === NewOrderPageSection.NEWORDER_REVIEW_ORDER
    ) {
      newOrderObj?.setNewOrderPage(NewOrderPageSection.NEWORDER_PATIENT_INFO);
    } else {
      if (
        newOrderObj?.newOrderPage ===
        NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO
      ) {
        newOrderObj?.setisComingFromPrev(true);
        newOrderObj?.setnewOrderProgress(20);
        newOrderObj?.setNewOrderPage(NewOrderPageSection.NEWORDER_PATIENT_INFO);
      } else if (
        newOrderObj?.newOrderPage === NewOrderPageSection.NEWORDER_REVIEW_ORDER
      ) {
        newOrderObj?.setisComingFromPrev(true);
        newOrderObj?.setnewOrderProgress(40);
        newOrderObj?.setNewOrderPage(
          NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO
        );
      } else {
        const isUserUpdatedForm = validator.validateUserEnteredAnyDataOrNot(
          newOrderData,
          newOrderDocuments,
          requesterInfo,
          dressingKit,
          accessory,
          newOrderObj!.showAddtionalObject
        );
        if (isUserUpdatedForm) {
          history.goBack();
        } else {
          history.goBack();
        }
      }
    }
  };

  const showCancelPopupHandlerDirect = () => {
    const isUserUpdatedForm = validator.validateUserEnteredAnyDataOrNot(
      newOrderData,
      newOrderDocuments,
      requesterInfo,
      dressingKit,
      accessory,
      newOrderObj!.showAddtionalObject
    );
    if (isUserUpdatedForm) {
      setDeleteOrderOpen(true);
    } else {
      history.goBack();
    }
  };

  const handleErrorPopupBackButton = () => {
    setNewOrderErrorPopUpFlag(false);
    if (
      newOrderObj?.isHandleChangeTriggered &&
      (newOrderObj?.newOrderPage ===
        NewOrderPageSection.NEWORDER_PATIENT_INFO ||
        newOrderObj?.newOrderPage ===
          NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO)
    ) {
      newOrderObj?.setShowDialog(true);
    }
    setShowSupportPhoneNum(true);
    setErrorCode("");
    setIsSaveExitFlow(false);
  };
  const handleErrorPopupBackForTransition = () => {
    setNewOrderErrorPopUpFlag(false);
    newOrderObj?.resetNewOrderForm();
    history.goBack();
  };

  const handleExitButton = () => {
    if (vacOrderID) {
      UnlockVacOrder(vacOrderID);
    }

    newOrderObj?.setShowDialog(false);
    setIsSaveExitFlow(false);
    setShowSupportPhoneNum(true);
    setErrorCode("");
    history.goBack();
  };

  const handleSaveAndShare = (
    sharedToEmail?: string,
    sharedToNote?: string
  ) => {
    let shareOrderPopupData: IShareOrderRequest = {
      sharedTo: sharedToEmail!,
      sharedOrderNotes: sharedToNote!,
      isSharedOrder: true,
    };
    try {
      initiateSaveVacOrder(false, shareOrderPopupData);
    } catch (error) {
      setNewOrderErrorPopUpFlag(true);
      setErrorMessage(ERROR_VAC_ORDER_SAVE_SUBMIT_UPDATE);
    }
  };

  const doNotDeleteHandler = () => {
    setDeleteOrderOpen(false);
  };

  const deleteHandler = async () => {
    if (vacOrderID !== null && vacOrderID !== "") {
      callCancelVacOrder(0);
      vacOrderAnalytics("Delete_Vac_Order");
    } else {
      newOrderObj?.setShowDialog(false);
      newOrderObj?.resetNewOrderForm();
      history.goBack();
    }
  };

  const fetchVacDressingProducts = async () => {
    try {
      const response = await getVacDressingKitProducts();
      if (response.items.length > 0) {
        setAllVacProducts(response.items);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchDropdownContents = async () => {
    try {
      const ddContent = format(
        "{0},{1},{2},{3},{4}",
        DD_US_STATES_CONTENT,
        DD_INSURANCE_TYPE_CONTENT,
        DD_INSURANCE_TYPE_TRANSITION_CONTENT,
        DD_PROVIDER_TYPE,
        DD_UPLOAD_DOCUMENT_TYPE
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const statesObject = data.items.filter(
          (item: { name: string }) => item.name === DD_US_STATES_CONTENT
        );
        const statesData = statesObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setStates(statesData);
        setStatesText(statesData.map((x: { text: string }) => x.text));
        const relationshipObject = data.items.filter(
          (item: { name: string }) => item.name === DD_INSURANCE_TYPE_CONTENT
        );
        const relationshipDataArray = relationshipObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setInsuranceTypes(relationshipDataArray);
        setInsuranceTypesText(
          relationshipDataArray.map((x: { text: string }) => x.text)
        );
        const insuranceTypeTransition = data.items.filter(
          (item: { name: string }) =>
            item.name === DD_INSURANCE_TYPE_TRANSITION_CONTENT
        );
        const insuranceTypeTransitionArray =
          insuranceTypeTransition[0].data.sort(
            (a: { order: number }, b: { order: number }) =>
              a.order > b.order ? 1 : -1
          );
        setInsuranceTypesTransition(insuranceTypeTransitionArray);
        setInsuranceTypesTransitionText(
          insuranceTypeTransitionArray.map((x: { text: string }) => x.text)
        );
        const providerDataTypeObj = data.items.filter(
          (item: { name: string }) => item.name === DD_PROVIDER_TYPE
        );
        const providerDataTypeArr = providerDataTypeObj[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setProviderTypes(providerDataTypeArr);
        const uploadDocumentObject = data.items.filter(
          (item: { name: string }) => item.name === DD_UPLOAD_DOCUMENT_TYPE
        );
        const uploadDocumentData = uploadDocumentObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setdocumentTypeCodeList(uploadDocumentData);
        setdocumentTypeText(
          uploadDocumentData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const callSaveVacOrder = async (
    isSaveAndExit: boolean,
    isFromAutoSave: boolean = false
  ) => {
    const newOrderWoundDetails = newOrderObj?.woundInfoData;
    const requesterDetails = newOrderObj?.requesterInfo;
    const productDetails = newOrderObj?.productInfo;
    const dressingDetails = newOrderObj?.dressingKit;
    const canisterDetails = newOrderObj?.canister;
    const accessoryDetails = newOrderObj?.accessory;
    const updatedDeliveryInformation = newOrderObj?.updatedDeliveryInformation;
    const orderIDExists = vacOrderID !== "";
    const loggedInUserSiteUseID = authObj?.registeredFaciltyAddress?.siteUseId!;
    const previousSavedDeliveryContactData =
      newOrderObj!.previousSavedDeliveryContactData;
    const isDeliveryContactEditing = newOrderObj!.editMode;
    let result;
    const userFacilitySiteUseId =
      authObj?.userProfile?.facilities?.length! > 0
        ? authObj?.userProfile?.facilities[0].siteUseId
        : "";
    const userFacilityNumber =
      authObj?.userProfile?.facilities?.length! > 0
        ? authObj?.userProfile?.facilities[0].number
        : "";
    const reqParam: ISaveVacOrderRequest = await mapSaveVacOrderRequest(
      isSaveAndExit,
      newOrderData!,
      requesterDetails!,
      productDetails!,
      newOrderWoundDetails!,
      dressingDetails!,
      canisterDetails!,
      accessoryDetails!,
      newOrderObj,
      orderIDExists ? vacOrderID! : null,
      updatedDeliveryInformation!,
      loggedInUserSiteUseID,
      isDeliveryContactEditing,
      previousSavedDeliveryContactData,
      userFacilitySiteUseId,
      userFacilityNumber
    );
    if (orderIDExists) {
      result = await updateVacOrder(reqParam);
    } else {
      result = await saveVacOrder(reqParam);
      //To indicate that the order is saved automatically
      setIsAutoSavedOrder(isFromAutoSave);
    }
    //here we are indicating that order is manually saved and updated data is send to DB and
    //there is no data change happends after that if any then auto save will save it.
    setIsAutoSaveEnabled(false);
    //change are updated to db and setting flag to be flase
    newOrderObj?.setIsHandleChangeTriggered(false);
    return result;
  };

  const saveAndGetVacOrderId = async (): Promise<string> => {
    let vacOrderId: string = "";
    if (internalObj?.isVACOrderSSO) {
      const result = await callSaveVacOrder(true);
      if (!result || !result.succeeded) {
        setErrorCode(
          result?.error?.code || result?.error?.errorCode || result?.status
        );
        setFreshNewOrderLoader(false);
      } else {
        vacOrderId = result.data;
      }
    } else {
      vacOrderId = newOrderObj!.vacOrderID;
      if (!vacOrderId || vacOrderId === "") {
        //Vac Order ID Missing
        setErrorCode(VAC_ORDER_ID_MISSING);
      }
    }
    return vacOrderId;
  };

  const callSubmitVacOrder = async () => {
    try {
      const vacOrderIdResult = await saveAndGetVacOrderId();
      if (vacOrderIdResult !== "") {
        setFreshNewOrderLoader(true);
        setVacOrderID(vacOrderIdResult);
        newOrderObj?.setVacOrderID(vacOrderIdResult);
        const uploadDocReponseObj = await callUploadDocApi(vacOrderIdResult);
        const response = await cancelOrSubmitVacOrder(
          vacOrderIdResult,
          1,
          authObj?.registeredFaciltyAddress?.siteUseId
        );
        const getPdfResponse = await apiCallGetPdfContent(vacOrderIdResult);
        if (response.succeeded) {
          window.scrollTo(0, 0);
          newOrderObj?.setNewOrderPage(NewOrderPageSection.NEWORDER_SUMMARY);
          if (!uploadDocReponseObj.success) {
            if (uploadDocReponseObj.docResult.data.length > 0) {
              type failedDocument = {
                fileName: string;
                message: string;
                code: number;
              };
              const filteredFailedDocuments =
                uploadDocReponseObj.docResult.data.filter(
                  (doc: failedDocument) => {
                    const errorCodes = [1017, 1083, 1018, 1043, 1055, 1076];
                    return errorCodes.includes(doc.code);
                  }
                );
              const failedDocumentsList = filteredFailedDocuments
                .map(
                  (doc: failedDocument) =>
                    `<li>${doc.fileName} - <span className="uploadDocument-error">ErrorCode: ${doc.code}</span></li>`
                )
                .join("");
              setNewOrderErrorPopUpFlag(true);
              setErrorMessage(
                `${ERROR_MSG_VAC_ORDER_UPLOAD_DOCUMENT}<br><br> <ul>${failedDocumentsList}</ul>`
              );
              setShowSupportPhoneNum(false);
            }
          }
          if (getPdfResponse) {
            setFreshNewOrderLoader(false);
          }
        } else {
          setFreshNewOrderLoader(false);
          setNewOrderErrorPopUpFlag(true);
          setErrorMessage(ERROR_VAC_ORDER_SAVE_SUBMIT_UPDATE);
          setErrorCode(response?.error?.code || response?.status);
        }
      } else {
        setErrorMessage(ERROR_VAC_ORDER_SAVE_SUBMIT_UPDATE);
        setNewOrderErrorPopUpFlag(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const apiCallGetPdfContent = async (vacOrderID: any) => {
    if (vacOrderID) {
      let reqParams: IPrintOrderSummaryRequest = {
        EntityID: vacOrderID,
        EntityType: 0,
        SiteUseId: getSiteUseIdForCallPdfContent(),
        Email: authObj?.userProfile?.userName,
      };
      const responseObj = await getPdfContent(reqParams);
      if (responseObj && responseObj.succeeded) {
        const url = getPdfUrl(responseObj.data.file);
        setPDFURL(url);
      } else {
        setPDFURL("");
      }
      return responseObj;
    }
  };

  const getSiteUseIdForCallPdfContent = () => {
    if (internalObj && internalObj.isVACOrderSSO) {
      return newOrderData?.requestingFacility?.value?.siteUseId ?? "";
    } else {
      return getSiteUseId(authObj);
    }
  };

  const callUnloackVacOrder = async () => {
    try {
      if (vacOrderID) {
        const result = await UnlockVacOrder(vacOrderID);
        if (!result || !result.succeeded) {
          setNewOrderErrorPopUpFlag(true);
          setErrorMessage(
            "Your request to open the saved  V.A.C.<sup>®</sup> Therapy Order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at"
          );
          setErrorCode(result?.error?.code || result?.status);
          return false;
        } else {
          return true;
        }
      } else return true;
    } catch (error) {
      console.log("error", error);
    }
  };

  const callUploadDocApi = async (vacOrderId: any) => {
    // Add documents to DB here
    let uploadedDocs: any[] = getUploadImagesAPIParams();
    let response: any;
    let returnObj: any = {
      success: true,
      docResult: null,
    };
    if (uploadedDocs.length > 0) {
      let docRequest = {
        entityID: vacOrderId,
        entityType: 1,
        Files: uploadedDocs,
      };
      const docResult = await uploadDocument(docRequest);
      returnObj.docResult = docResult;
      if (!docResult.succeeded) {
        setSaveAndExitOpen(false);
        returnObj.success = false;
      } else {
        response = docResult.data;
        let updatedFiles: any[] = newOrderObj?.newOrderDocuments.map((item) => {
          var successFile = response.filter(
            (file: { fileName: any }) => file.fileName === item.documentName
          );
          if (
            item.documentName === successFile[0]?.fileName &&
            (successFile[0]?.code === 1054 ||
              successFile[0]?.code === 1042 ||
              successFile[0]?.code === 1075)
          ) {
            const updatedItem: IDropZoneDocumentSelect = {
              ...item,
              succeeded: true,
              documentId: successFile[0]?.message,
            };
            return updatedItem;
          } else if (
            item.documentName !== successFile[0]?.fileName &&
            item.documentId
          ) {
            return item;
          } else {
            const errorItem: IDropZoneDocumentSelect = {
              ...item,
              succeeded: false,
              errorMessage: successFile[0]?.message,
            };
            return errorItem;
          }
        })!;
        newOrderObj?.setNewOrderDocuments(updatedFiles);
        newOrderObj?.setDeletedOrderDocuments([]);
        // Missing Rx Documents
        const latestDocuments =
          newOrderObj?.prescriptionDocuments.filter(
            (item) => !item.isRemoved
          ) ?? [];
        let updatedMissingRxFiles: IDropZoneDocumentSelect[] =
          latestDocuments.map((item) => {
            var successFile = response.filter(
              (file: { fileName: any }) => file.fileName === item.documentName
            );
            if (
              item.documentName === successFile[0]?.fileName &&
              (successFile[0]?.code === 1054 ||
                successFile[0]?.code === 1042 ||
                successFile[0]?.code == 1075)
            ) {
              const updatedItem: IDropZoneDocumentSelect = {
                ...item,
                succeeded: true,
                documentId: successFile[0]?.message,
              };
              return updatedItem;
            } else if (
              item.documentName !== successFile[0]?.fileName &&
              item.documentId
            ) {
              return item;
            } else {
              const errorItem: IDropZoneDocumentSelect = {
                ...item,
                succeeded: false,
                errorMessage: successFile[0]?.message,
              };
              return errorItem;
            }
          })!;
        newOrderObj?.setPrescriptionDocuments(updatedMissingRxFiles);
        newOrderObj?.setDeletedPrescriptionDocuments([]);
      }
      if (
        response?.filter(
          (file: { code: number }) =>
            file.code !== 1042 && file.code !== 1054 && file.code !== 1075
        ).length > 0
      ) {
        returnObj.success = false;
      }
    }
    return returnObj;
  };

  const apicallShareVacOrder = async (shareOrderRequestObj: any) => {
    const responseObj = await callShareVacOrder(shareOrderRequestObj);
    if (responseObj && responseObj.succeeded) {
      newOrderObj?.setshareOrderAddPopUpSection(
        SharedOrderModal.SHARE_ORDER_INVITE_SUCESS
      );
      setIsSharedOrder(true);
      const subText = "Shared Order";
      setShareOrderButtonText("Stop Share");
      const subText2 = "To " + shareOrderRequestObj.SharedTo;
      setshareOrderText(
        sharedOrderAlert(
          subText,
          subText2,
          shareOrderRequestObj.SharedOrderNotes,
          shareOrderRequestObj.OrderId
        )
      );
      setSharedOrderUserA(
        authObj?.userProfile?.firstName + " " + authObj?.userProfile?.lastName
      );
      setShareOrderLoader(false);
    } else {
      setShareOrderLoader(false);
      newOrderObj?.setshareOrderPopup(false);
      setNewOrderErrorPopUpFlag(true);
      setErrorMessage(responseObj.error.message);
      setErrorCode(responseObj?.error?.code || responseObj.status);
      setShowSupportPhoneNum(false);
    }
  };

  const fetchVACTherapyInformationData = async () => {
    try {
      const data = await getCMSContent(CMS_VAC_THERAPY_INFORMATION_CONTENT);
      if (data.item !== undefined) {
        setvacTherapyInformationData(data.item);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const callCancelSharedOrder = async (reqBody: any) => {
    try {
      const result = await cancelSharedOrder(reqBody);
      if (!result || !result.succeeded) {
        setErrorMessage(ERROR_MSG_STOP_SHARING_ORDER);
        setErrorCode(result?.error?.code || result?.status);
        setNewOrderErrorPopUpFlag(true);
        setIsStopShareOrderSuccess(false);
      } else {
        setCancelShareLoader(false);
        setIsStopShareOrderSuccess(true);
      }
      setCancelShareLoader(false);
    } catch (error) {
      setCancelShareLoader(false);
    }
  };

  const callStopShareOrder = (OrderIdValue: string) => {
    setCancelShareLoader(true);
    const reqParam = {
      OrderId: OrderIdValue,
      FacilityName: authObj?.registeredFaciltyAddress?.accountName,
      SiteUseId: authObj?.registeredFaciltyAddress?.siteUseId,
    };
    callCancelSharedOrder(reqParam);
  };

  const getOrderDetails = async (orderID: string) => {
    const result: any = await Promise.all([
      getVacOrderSummary(orderID, getSiteUseId(authObj), true),
      setQuestionnaireContent(),
    ]);
    const orderResponse = result[0];
    const questionaries = result[1];
    if (!orderResponse.succeeded) {
      if (orderResponse?.error?.code! === 1035) {
        setGetAPILoader(false);
        setDeepLinkVacOrderLocked(orderResponse.error.message);
      } else {
        setGetAPILoader(false);
        history.push("/home");
        newOrderObj?.resetNewOrderForm();
      }
    } else {
      newOrderObj?.setIsDuplicateCheckKeyFieldChanged(true); //intial load
      if (orderResponse && questionaries && orderResponse.succeeded) {
        const vacOrderSummary: VacOrderSummaryData = result[0]
          .data as VacOrderSummaryData;
        let mapperRes: INewOrder = await mapVacOrderResponse(
          vacOrderSummary,
          insuranceTypesTransition,
          newOrderObj?.setShowAdditionalObject,
          providerTypes,
          validator,
          authObj
        );
        mapperRes.previousRo.value !== ""
          ? setIsTransition(true)
          : setIsTransition(false);
        if (
          vacOrderSummary?.primaryInsurance?.type === 9 ||
          vacOrderSummary?.secondaryInsurance?.type === 9
        ) {
          setShowInsuranceTypeTransition(true);
        }
        var productInfo = mapProductInfo(vacOrderSummary);
        await newOrderObj?.setProductInfo(productInfo);
        const userName =
          authObj && authObj.userProfile
            ? `${authObj?.userProfile?.firstName} ${authObj?.userProfile?.lastName}`
            : "";
        if (
          userName !== "" &&
          vacOrderSummary.sharedDetails.to &&
          vacOrderSummary.sharedDetails.to === userName
        ) {
          setCancelBtnVisibility(false);
        }
        if (newOrderObj && vacOrderSummary.administeringDressingChanges) {
          newOrderObj.setActive(true);
          newOrderObj.setCaregiverSelected(true);
        }
        if (
          vacOrderSummary.sharedDetails.from &&
          vacOrderSummary.sharedDetails.to &&
          authObj &&
          authObj.userProfile &&
          authObj.userProfile.firstName &&
          authObj.userProfile.lastName
        ) {
          setIsSharedOrder(true);
          const userName = `${authObj.userProfile.firstName} ${authObj.userProfile.lastName}`;
          if (
            vacOrderSummary.sharedDetails.from !== userName &&
            vacOrderSummary.sharedDetails.to !== userName
          ) {
            setshareOrderText("");
            setIsSharedOrderNeedToBeDisable(true);
          } else {
            const subText = "Shared Order";
            setShareOrderButtonText("Stop Share");
            if (vacOrderSummary.sharedDetails.from === userName) {
              const subText2 = "To " + vacOrderSummary.sharedDetails.to;
              setshareOrderText(
                sharedOrderAlert(
                  subText,
                  subText2,
                  vacOrderSummary.sharedDetails.notes,
                  vacOrderID
                )
              );
            } else {
              const subText2 = "From " + vacOrderSummary.sharedDetails.from;
              setshareOrderText(
                sharedOrderAlert(
                  subText,
                  subText2,
                  vacOrderSummary.sharedDetails.notes,
                  vacOrderID
                )
              );
            }
            setSharedOrderUserA(vacOrderSummary.sharedDetails.from);
          }
        }
        if (vacOrderSummary.prescriberNPI) {
          const reqParams = {
            NPI: vacOrderSummary.prescriberNPI,
          };
          const data = await getNPIPrescriber(reqParams);
          if (data != null && data.succeeded && data.items.length > 0) {
            newOrderObj?.setPrescriberAddedData({
              ...data.items[0],
              email: vacOrderSummary.prescriberEmailAddress,
            });
          } else {
            const prescriberData: IPrescriberModal = {
              firstName: vacOrderSummary.prescriberFirstName,
              lastName: vacOrderSummary.prescriberLastName,
              npi: vacOrderSummary.prescriberNPI,
              email: vacOrderSummary.prescriberEmailAddress,
              city: "",
              state: "",
              telephoneNumber: "",
              zipCode: "",
              address1: "",
              address2: "",
            };
            newOrderObj?.setPrescriberAddedData(prescriberData);
          }
        }
        newOrderObj?.setNewOrderData(mapperRes);
        let tempData: any = [];
        let missingRxTempData: any = [];
        if (vacOrderSummary.document?.length !== 0) {
          vacOrderSummary.document.forEach((element) => {
            if (element.fileNetDocType === "RXSection") {
              missingRxTempData.push({
                documentName: element.docName,
                documentBase64: "",
                succeeded: true,
                errorMessage: null,
                documentId: element.documentId,
              });
            } else {
              tempData.push({
                documentName: element.docName,
                documentBase64: "",
                succeeded: true,
                errorMessage: null,
                documentId: element.documentId,
                documentType: {
                  valid: ValidationStatus.VALID,
                  value: getTextFromCode(
                    documentTypeCodeList,
                    element.fileNetDocType
                  ),
                  required: true,
                },
              });
            }
          });
        }
        newOrderObj?.setNewOrderDocuments(tempData);
        newOrderObj?.setPrescriptionDocuments(missingRxTempData);
        const existingPhoneNumber = authObj?.userProfile?.phoneNumber
          ? authObj?.userProfile?.phoneNumber
          : authObj?.userProfile?.mobilePhoneNumber;
        var requesterInfo = mapRequesterInfo(
          vacOrderSummary,
          authObj?.userProfile?.firstName,
          authObj?.userProfile?.lastName,
          authObj?.userProfile?.emailAddress,
          existingPhoneNumber,
          validator
        );
        newOrderObj?.setRequesterInfo(requesterInfo);
        let clinicalInfo = mapClinicalInformation(vacOrderSummary, validator);
        newOrderObj?.setWoundInfoData(clinicalInfo);
        const deliveryInformation = mapDeliveryInformation(
          vacOrderSummary,
          validator
        );
        newOrderObj?.setUpdatedDeliveryInformation(deliveryInformation);
        var primaryQuestions = questionaries.get(clinicalInfo.woundType?.value);
        if (primaryQuestions) {
          const primaryWoundQuestionares = mapWoundQuestionaries(
            vacOrderSummary.primaryWound,
            primaryQuestions!
          );
          newOrderObj?.setPrimaryAdditionalQuestions(primaryWoundQuestionares);
        }
        if (clinicalInfo.isShowSecondaryWoundInfo.value === "Yes") {
          const secondaryWoundInfo = mapSecondaryWoundIformation(
            vacOrderSummary,
            validator
          );
          newOrderObj?.setSecondaryWoundInfoData(secondaryWoundInfo);
          var secondaryQuestions = questionaries.get(
            secondaryWoundInfo.woundType?.value
          );
          if (secondaryQuestions) {
            var secondaryWoundQuestionares = mapWoundQuestionaries(
              vacOrderSummary.secondaryWound,
              secondaryQuestions!
            );
            newOrderObj?.setSecondaryAdditionalQuestions(
              secondaryWoundQuestionares
            );
          }
        }
        let dressingMapperRes: IDressingKit = mapDressingKit();
        const canisterMapperRes: ICanister = mapCanister(vacOrderSummary);
        const accessoryMapperRes: IProductAccessory =
          mapAccessory(vacOrderSummary);
        // if its ready care and has additional dressing
        if (
          vacOrderSummary.additionalDressing.sku &&
          vacOrderSummary.isFromReadyCare === true
        ) {
          dressingMapperRes = vacPrimaryDressingKitMapper(
            vacOrderSummary,
            dressingMapperRes!,
            vacAllProducts,
            newOrderObj
          );
        } else {
          if (vacOrderSummary.isFromReadyCare === true) {
            dressingMapperRes = vacReadyCareDefaultDressingMapper(
              dressingMapperRes!
            );
            newOrderObj?.setShowPrimaryDressingKit(false);
          }
        }
        // non-readycare nad has dressing
        if (
          vacOrderSummary.mainDressing.sku &&
          vacOrderSummary.isFromReadyCare === false
        ) {
          dressingMapperRes = vacPrimaryDressingKitMapper(
            vacOrderSummary,
            dressingMapperRes!,
            vacAllProducts,
            newOrderObj
          );
        } else {
          if (vacOrderSummary.isFromReadyCare === false) {
            newOrderObj?.setShowPrimaryDressingKit(false);
          }
        }
        if (
          vacOrderSummary.additionalDressing.sku &&
          vacOrderSummary.isFromReadyCare === false
        ) {
          dressingMapperRes = vacSecondaryDressingKitMapper(
            vacOrderSummary,
            dressingMapperRes!,
            vacAllProducts,
            newOrderObj
          );
        }
        vacCannisterMapper(vacOrderSummary, newOrderObj);
        newOrderObj?.setDressingKit(dressingMapperRes!);
        newOrderObj?.setCanister(canisterMapperRes!);
        newOrderObj?.setAccessory(accessoryMapperRes!);
        setGetAPILoader(false);
      } else {
        setGetAPILoader(false);
        history.push("/orders/newOrder");
        setVacOrderID("");
        newOrderObj?.setVacOrderID("");
      }
    }
  };

  const callCancelVacOrder = async (flag: any) => {
    newOrderObj?.setShowDialog(false);
    setDeleteOrderOpen(false);
    try {
      setFreshNewOrderLoader(true);
      const response = await cancelOrSubmitVacOrder(
        vacOrderID,
        flag,
        authObj?.registeredFaciltyAddress?.siteUseId
      );
      if (response?.succeeded) {
        setTimeout(() => {
          setFreshNewOrderLoader(false);
        }, 1000);
        history.goBack();
      } else {
        setFreshNewOrderLoader(false);
        setNewOrderErrorPopUpFlag(true);
        setErrorMessage(ERROR_VAC_ORDER_DEL);
        setErrorCode(response?.error?.code || response?.status);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleReturnToSelectAFacilityClick = () => {
    history.push("/ssoRedirect");
  };

  useEffect(() => {
    setShareOrderButtonText("Share Order");
    fetchVACTherapyInformationData();
    fetchDropdownContents();
    if (orderID) {
      setVacOrderID(orderID);
      fetchVacDressingProducts();
      newOrderObj?.setVacOrderID(orderID);
      setGetAPILoader(true);
    }
    newOrderObj?.setshareOrderAddPopUpSection(SharedOrderModal.SHARE_ORDER);
  }, []);

  useEffect(() => {
    // Handle Deeplink
    if (authObj && authObj.allFacilities && authObj.registeredFaciltyAddress) {
      if (location.state === undefined && checkVacOrderDeeplink()) {
        const urlData = authObj.deepLinkPath!.split("/");
        const decodeData = JSON.parse(atob(urlData![4]));
        checkValidFacilityOnDeepLink(
          authObj.allFacilities,
          decodeData.siteUseId
        );
      } else if (
        location.state &&
        location.state.orderID === undefined &&
        checkVacOrderDeeplink()
      ) {
        checkValidFacilityOnDeepLink(
          authObj.allFacilities,
          location.state.stateData.siteUseId
        );
      }
    }
    // handle multi facility on reload render for transition order
    if (isCreateTransitionOrder?.createTransition) {
      if (
        authObj?.registeredFaciltyAddress?.siteUseId !==
        isCreateTransitionOrder?.siteUseID
      ) {
        history.replace("/home");
      } else {
        newOrderObj?.setIsHandleChangeTriggered(true);
      }
    }
  }, [authObj?.allFacilities, authObj?.registeredFaciltyAddress]);

  useEffect(() => {
    if (newOrderObj) {
      if (
        newOrderObj.isHandleChangeTriggered &&
        (newOrderObj.newOrderPage ===
          NewOrderPageSection.NEWORDER_PATIENT_INFO ||
          newOrderObj.newOrderPage ===
            NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO ||
          newOrderObj.newOrderPage ===
            NewOrderPageSection.NEWORDER_REVIEW_ORDER)
      ) {
        newOrderObj.setShowDialog(true);
      } else {
        newOrderObj.setShowDialog(false);
      }
    }
  }, [newOrderObj?.newOrderPage, newOrderObj?.isHandleChangeTriggered]);

  useEffect(() => {
    if (
      newOrderObj &&
      newOrderObj.vacOrderID &&
      insuranceTypes.length > 0 &&
      providerTypes.length > 0 &&
      vacAllProducts !== null &&
      authObj &&
      authObj.userProfile
    ) {
      getOrderDetails(orderID);
      setVacOrderID(orderID);
      newOrderObj.setVacOrderID(orderID);
    }
  }, [
    insuranceTypes,
    providerTypes,
    newOrderObj?.setPrescriberAddedData,
    vacAllProducts,
    newOrderObj?.vacOrderID,
  ]);

  const getAcuteOrderDetails = async () => {
    setGetAPILoader(true);
    const reqBody = {
      RentalOrderNumber: isCreateTransitionOrder?.roNumber,
      UserName: isCreateTransitionOrder?.userName,
      SiteUseId: isCreateTransitionOrder?.siteUseID,
    };
    const response = await getAcuteOrderRoDetails(reqBody);
    const questionaries = await setQuestionnaireContent();
    if (response && response.succeeded && questionaries) {
      const acuteTransitionOrder: IAcuteOrderDetail = response?.item;
      let mapperRes: INewOrder = await mapTransitionOrderResponse(
        acuteTransitionOrder,
        validator
      );
      if (
        acuteTransitionOrder.customerType.toLowerCase() ===
        "Nursing Home".toLowerCase()
      ) {
        setShowInsuranceTypeTransition(true);
      }
      newOrderObj?.setNewOrderData(mapperRes);
      if (acuteTransitionOrder.prescriberNPI) {
        const prescriberAPIData = await getNPIPrescriber({
          NPI: acuteTransitionOrder.prescriberNPI,
        });
        const prescriberResponseData = await mappedPrescriberInformation(
          prescriberAPIData
        );
        newOrderObj?.setPrescriberAddedData(prescriberResponseData);
      }
      if (acuteTransitionOrder.wounds.length > 0) {
        let mappedPrimaryWound: INewOrderWoundInfo =
          await mapCreateTransitionOrderStoredWoundResponse(
            acuteTransitionOrder,
            validator
          );
        let primaryQuestions = questionaries.get(
          mappedPrimaryWound?.woundType?.value
        );
        newOrderObj?.setWoundInfoData(mappedPrimaryWound);
        if (primaryQuestions) {
          const primaryWoundQuestionares = mapWoundQuestionaries(
            mappedPrimaryWound!,
            primaryQuestions!
          );
          newOrderObj?.setPrimaryAdditionalQuestions(primaryWoundQuestionares);
        }
        if (
          mappedPrimaryWound.isShowSecondaryWoundInfo.value.toLowerCase() ===
          "yes"
        ) {
          let mappedSecondaryWound: INewOrderWoundInfo =
            await mapCreateTransitionSecondaryStoredWoundIformation(
              acuteTransitionOrder,
              validator
            );
          let secondaryWoundQuestions = questionaries.get(
            mappedSecondaryWound?.woundType?.value
          );
          newOrderObj?.setSecondaryWoundInfoData(mappedSecondaryWound);
          if (secondaryWoundQuestions) {
            let secondaryWoundQuestionares = mapWoundQuestionaries(
              mappedSecondaryWound!,
              secondaryWoundQuestions!
            );
            newOrderObj?.setSecondaryAdditionalQuestions(
              secondaryWoundQuestionares
            );
          }
        }
      }
    } else {
      setCreateTransitionErrorHandle(true);
      setErrorCode(
        response?.error?.code || response?.status || response?.error?.errorCode
      );
      setErrorMessage(ERROR_MSG_ACUTE_ORDER_DETAIL);
      setNewOrderErrorPopUpFlag(true);
    }
    setGetAPILoader(false);
  };

  useEffect(() => {
    if (newOrderObj && newOrderObj.vacOrderID) {
      const obj = {
        OrderId: newOrderObj.vacOrderID,
        SiteUseId: getSiteUseId(authObj),
      };
      getVacOrderLockStatus(obj);
    }
    if (isCreateTransitionOrder && isCreateTransitionOrder?.createTransition) {
      getAcuteOrderDetails();
    }
  }, []);

  useEffect(() => {
    //if any of the data change and if updated data is not matched with current value
    //then enable the flag to be true of AutoSaving --> IsAutoSaveEnabled
    if (
      newOrderDataRef.current !== newOrderData ||
      woundOrderDataRef.current !== woundInfoData
    ) {
      setIsAutoSaveEnabled(true);
      newOrderDataRef.current = newOrderData;
      woundOrderDataRef.current = woundInfoData;
    } else {
      setIsAutoSaveEnabled(false);
    }
  }, [newOrderData, woundInfoData]);

  useEffect(() => {
    const prescriberDocumentsChanged =
      prescriptionDocuments && prescriptionDocuments.length > 0;
    const newOrdeDocumentsChanged =
      newOrderDocuments && newOrderDocuments.length > 0;
    if (prescriberDocumentsChanged || newOrdeDocumentsChanged) {
      setIsUploadDocumentsChanged(true);
    } else {
      setIsUploadDocumentsChanged(false);
    }
  }, [newOrderDocuments, prescriptionDocuments]);

  const getCustomerPlusPatientDetails = async () => {
    if (
      authObj &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId
    ) {
      setCancelShareLoader(true);
      let reqParams = {
        siteUseId: getSiteUseId(authObj),
        serialNumber: isCreateTransitionOrder?.serialNumber,
        roNumber: isCreateTransitionOrder.roNumber.toString(),
      };

      const result: any = await Promise.all([
        getCustomerPlusAcuteOrderRoDetails(reqParams),
        setQuestionnaireContent(),
      ]);
      const orderResponse = result[0];
      const questionaries = result[1];

      if (orderResponse && !orderResponse.succeeded) {
        setCancelShareLoader(false);
        setErrorPopUpFlag(true);
        setErrorCode(
          orderResponse.error?.errorCode ||
            orderResponse.error?.code ||
            orderResponse?.status
        );
        setErrorMessage(ERROR_MSG_ACUTE_ORDER_VIEW);
      } else if (orderResponse && questionaries && orderResponse.succeeded) {
        const data: ICreateTransitionOrder = result[0]
          .data as ICreateTransitionOrder;
        let prescriberReqParams = {
          NPI: result[0].data.prescriberNPI ? result[0].data.prescriberNPI : "",
        };
        let prescriberNPI;
        if (result[0].data.prescriberNPI !== null) {
          const prescriberAPIData = await getNPIPrescriber(prescriberReqParams);
          if (prescriberAPIData.succeeded) {
            prescriberNPI = prescriberAPIData;
          } else {
            setErrorPopUpFlag(true);
            setErrorMessage(ERROR_MSG_GET_PRESCRIBER_DETAILS);
            setErrorCode(
              prescriberAPIData?.error?.errorCode || prescriberAPIData?.status
            );
          }
        }
        let mapperRes: INewOrder = await mapCreateTransitionOrderResponse(
          data,
          validator
        );
        newOrderObj?.setNewOrderData(mapperRes);
        if (result[0].data.prescriberNPI !== null) {
          let prescriberData: IPrescriberModal = {
            firstName: prescriberNPI?.items[0].firstName,
            lastName: prescriberNPI?.items[0].lastName,
            npi: prescriberNPI?.items[0].npi,
            city: prescriberNPI?.items[0].city,
            state: prescriberNPI?.items[0].state,
            telephoneNumber: prescriberNPI?.items[0].telephoneNumber,
            zipCode: prescriberNPI?.items[0].zipCode,
            address1: prescriberNPI?.items[0].address1,
            address2: prescriberNPI?.items[0].address2,
            faxNumber: prescriberNPI?.items[0].faxNumber,
          };
          newOrderObj?.setPrescriberAddedData(prescriberData);
        } else {
          newOrderObj?.setPrescriberAddedData(undefined);
        }
        let woundData: INewOrderWoundInfo =
          await mapCreateTransitionOrderWoundResponse(data, validator);
        newOrderObj?.setWoundInfoData(woundData);
        let clinicalInfo = mapCreateTransitionOrderWoundResponse(
          data,
          validator
        );
        var primaryQuestions = questionaries.get(clinicalInfo.woundType?.value);
        if (primaryQuestions) {
          const primaryWoundQuestionares = mapWoundQuestionaries(
            data.primaryWound,
            primaryQuestions!
          );
          newOrderObj?.setPrimaryAdditionalQuestions(primaryWoundQuestionares);
        }
        if (clinicalInfo.isShowSecondaryWoundInfo.value === "Yes") {
          const secondaryWoundInfo =
            mapCreateTransitionSecondaryWoundIformation(data, validator);
          newOrderObj?.setSecondaryWoundInfoData(secondaryWoundInfo);
          var secondaryQuestions = questionaries.get(
            secondaryWoundInfo.woundType?.value
          );
          if (secondaryQuestions) {
            var secondaryWoundQuestionares = mapWoundQuestionaries(
              data.secondaryWound,
              secondaryQuestions!
            );
            newOrderObj?.setSecondaryAdditionalQuestions(
              secondaryWoundQuestionares
            );
          }
        }
        setCancelShareLoader(false);
      }
    }
  };

  useEffect(() => {
    if (location?.state?.isTransitionFromCustomerPlus) {
      getCustomerPlusPatientDetails();
    }
  }, []);

  const validationCheckForAutoSave = (): boolean => {
    let isEnabled: boolean = false;
    if (
      newOrderData?.firstName.valid === ValidationStatus.VALID && //required field
      newOrderData?.lastName.valid === ValidationStatus.VALID && //required field
      newOrderData?.dob.valid === ValidationStatus.VALID && //required field
      newOrderObj?.isHandleChangeTriggered && //enabled only on change event trigger
      isAutoSaveEnabled && //Flag indication for auto save enabling
      !internalObj?.isVACOrderSSO
    ) {
      isEnabled = true;
    } else isEnabled = false;
    return isEnabled;
  };
  const autoSaveOrderHandler = async () => {
    if (validationCheckForAutoSave()) {
      vacOrderAnalytics("Save_Vac_Order");
      const result = await callSaveVacOrder(false, true);
      if (result.succeeded) {
        setVacOrderID(result.data);
        newOrderObj?.setVacOrderID(result.data);
        newOrderObj?.setShowDialog(false);
      }
    }
  };

  useQuery({
    queryKey: ["VAC-Order-Auto-Save-Query"],
    queryFn: autoSaveOrderHandler,
    //Enabling background on browser minimized and browser focused out.
    refetchIntervalInBackground: true,
    //trigger the auto save queryControl method for every 5 minutes
    refetchInterval: autoSaveOrderTimeInterval,
    //disabling auto save queryControl method on component mounting phase
    refetchOnMount: false,
    //when on comming from diffrent window not to trigger the query call method
    //this is because if we click on pdf window will out of focus when we come back it is refetching
    refetchOnWindowFocus: false,
    //Disabled Auto Save in Landing Page and auto save will only work only if enabled -> true
    enabled: !internalObj?.isVACOrderSSO,
  });

  const handleSaveAndValidate = async (): Promise<boolean> => {
    let isOrderSaved: boolean = false;
    let isAllDocumentsValid: boolean = true;
    newOrderDocuments?.forEach((document: IDropZoneDocumentSelect) => {
      if (
        document.documentType?.valid !== ValidationStatus.VALID ||
        !document.succeeded
      ) {
        isAllDocumentsValid = false;
      }
    });
    if (
      (newOrderObj?.newOrderPage ===
        NewOrderPageSection.NEWORDER_PATIENT_INFO ||
        newOrderObj?.newOrderPage ===
          NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO) &&
      (validationCheckForAutoSave() || isUploadDocumentsChanged) &&
      isAllDocumentsValid &&
      !internalObj?.isVACOrderSSO
    ) {
      newOrderObj?.setIsHandleChangeTriggered(false);
      isOrderSaved = await saveOrderHandler();
    }
    //seetting below states to false becasue on next button click if any change happend then it will
    //go inside save handler and call DB and reset the flag but in this flow isValid key will get
    //changed on validateAll method call then it will trigger useEffect and sets IsAutoSavedFlag -> true
    //For Invalid case we should not auto save the i.e just validated the Object
    setIsAutoSaveEnabled(false);
    setIsUploadDocumentsChanged(false);
    return isOrderSaved;
  };

  const validateIsDuplicateOrder = async(obj: INewOrder) =>
    { 
      setFreshNewOrderLoader(true); 
      newOrderObj?.setIsDuplicateCheckKeyFieldChanged(false); 
      const reqParams = {
         PatientLastName: obj.lastName.value.trim(),
         ZipCode: obj.zip.value,
         Dob: moment( obj.dob.value).format("yyyy-MM-DD"),
        };
        const response = await checkIfDuplicateOrder(reqParams);
        if(response && response?.item && response?.item?.roNumber)
        {
          let itemValue =
            {
              roNumber: response?.item.roNumber,
              createdDate: response?.item.createdDate
            }
        setIsDuplicateOrder(true);
        setDuplicateRODetails(itemValue);
        setFreshNewOrderLoader(false); 
        }
        else if(response && response.error)
        {
          setErrorMessage!(ERROR_MSG_DUPLICATE_CHECK_FAILED);
          setErrorPopUpFlag!(true);
          setErrorCode(
            response?.error?.code || response?.status || response?.error?.errorCode
          );
          setFreshNewOrderLoader(false);
        }
        setFreshNewOrderLoader(false);
    }

  const handleErrorDone =()=>
  {
    if(errorMessage == ERROR_MSG_DUPLICATE_CHECK_FAILED)
    {
      setErrorPopUpFlag(false);
    }
    else
    history.goBack();
  }
  const sharedOrderAlert = (
    subText1: string,
    subText2: string,
    notes: string,
    OrderIdValue: string
  ) => {
    return (
      <div className="sharedOrderStyle">
        <div>
          <p className="shareOrderDesc">
            {" "}
            <span className="shareOrderAlertTitle"> {subText1}</span> {subText2}
            <span
              className="stopSharingbtnLink"
              onClick={() => callStopShareOrder(OrderIdValue)}
            >
              {" "}
              Stop Sharing{" "}
            </span>
          </p>
        </div>
        <p className="shareOrderNote">
          {" "}
          <b>Note:</b> {notes}{" "}
        </p>
      </div>
    );
  };

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (newOrderObj?.newOrderPage) {
      case NewOrderPageSection.NEWORDER_PATIENT_INFO:
        page = (
          <PatientInfoStepper
            orderId={orderID}
            newOrderData={newOrderData}
            setNewOrderData={setNewOrderData}
            nextOrderOpen={nextOrderOpen}
            vacTherapyInformationData={vacTherapyInformationData!}
            states={states}
            statesText={statesText}
            insuranceTypes={
              showInsuranceTypeTransition
                ? insuranceTypesTransition
                : insuranceTypes
            }
            insuranceTypesText={
              showInsuranceTypeTransition
                ? insuranceTypesTransitionText
                : insuranceTypesText
            }
            sharedOrderText={shareOrderText}
            requesterInfo={requesterInfo}
            setRequesterInfo={setRequesterInfo}
            productInfo={productInfo}
            setProductInfo={setProductInfo}
            dressingKit={dressingKit}
            setDressingKit={setDressingKit}
            canister={canister}
            setCanister={setCanister}
            accessory={accessory}
            setAccessory={setAccessory}
            deliveryInformation={deliveryInformation}
            setDeliveryInformation={setDeliveryInformation}
            updatedDeliveryInformation={updatedDeliveryInformation}
            setUpdatedDeliveryInformation={setUpdatedDeliveryInformation}
            isEditingDeliveryInformation={isEditingDeliveryInformation}
            setIsEditingDeliveryInformation={setIsEditingDeliveryInformation}
            isNextButtonClicked={isNextButtonClicked}
            documentTypeText={documentTypeText}
            documentTypeCodeList={documentTypeCodeList}
            isSharedOrder={isSharedOrder}
            isSharedOrderNeedToBeDisable={isSharedOrderNeedToBeDisable}
            isVacOrderFromSSO={internalObj?.isVACOrderSSO}
            isCreateTransition={isTransition}
            setIsDuplicateOrder={setIsDuplicateOrder}
            setDuplicateRODetails={setDuplicateRODetails}
            setErrorPopUpFlag={setErrorPopUpFlag}
            setErrorMessage={setErrorMessage}
            setErrorCode={setErrorCode}
          />
        );
        break;
      case NewOrderPageSection.NEWORDER_PATIENT_WOUND_INFO:
        page = (
          <WoundInformation
            isSavedOrder={vacOrderID !== ""}
            secondaryWoundInfoData={secondaryWoundInfoData!}
            setSecondaryWoundInfoData={setSecondaryWoundInfoData}
            setWoundInfoData={setWoundInfoData}
            woundInfoData={woundInfoData!}
            vacTherapyInformationData={vacTherapyInformationData!}
            documentTypeText={documentTypeText}
            documentTypeCodeList={documentTypeCodeList}
            isVacOrderFromSSO={internalObj?.isVACOrderSSO}
          />
        );
        break;
      case NewOrderPageSection.NEWORDER_REVIEW_ORDER:
        page = (
          <ReviewOrderStepper
            isOrderSummary={false}
            isPartialOrder={newOrderObj?.isPartialOrder ?? false}
            isReviewOrder={true}
            newOrderData={newOrderData}
            requesterInfo={requesterInfo}
            productInfo={productInfo}
            dressingKit={dressingKit}
            canister={canister}
            accessory={accessory}
            nextOrderOpen={nextOrderOpen}
            secondaryWoundInfoData={secondaryWoundInfoData!}
            setNewOrderData={setNewOrderData}
            setRequesterInfo={setRequesterInfo}
            setProductInfo={setProductInfo}
            setDressingKit={setDressingKit}
            setCanister={setCanister}
            setAcessory={setAccessory}
            setSecondaryWoundInfoData={setSecondaryWoundInfoData}
            setWoundInfoData={setWoundInfoData}
            woundInfoData={woundInfoData!}
            vacTherapyInformationData={vacTherapyInformationData!}
            newOrderDocuments={newOrderDocuments!}
            deliveryInformation={updatedDeliveryInformation}
            setDeliveryInformation={setUpdatedDeliveryInformation}
            documentTypeText={documentTypeText}
            documentTypeCodeList={documentTypeCodeList}
            isVacOrderFromSSO={internalObj?.isVACOrderSSO}
            isCreateTransition={isTransition}
          />
        );
        break;
      case NewOrderPageSection.NEWORDER_SUMMARY:
        page = (
          <ReviewOrderStepper
            isOrderSummary={true}
            isPartialOrder={newOrderObj?.isPartialOrder ?? false}
            isReviewOrder={true}
            newOrderData={newOrderData}
            requesterInfo={requesterInfo}
            productInfo={productInfo}
            dressingKit={dressingKit}
            canister={canister}
            accessory={accessory}
            nextOrderOpen={nextOrderOpen}
            secondaryWoundInfoData={secondaryWoundInfoData!}
            setSecondaryWoundInfoData={setSecondaryWoundInfoData}
            setNewOrderData={setNewOrderData}
            setRequesterInfo={setRequesterInfo}
            setProductInfo={setProductInfo}
            setDressingKit={setDressingKit}
            setCanister={setCanister}
            setAcessory={setAccessory}
            setWoundInfoData={setWoundInfoData}
            woundInfoData={woundInfoData!}
            vacTherapyInformationData={vacTherapyInformationData!}
            newOrderDocuments={newOrderDocuments!}
            deliveryInformation={updatedDeliveryInformation}
            setDeliveryInformation={setUpdatedDeliveryInformation}
            vacOrderID={vacOrderID!}
            pdfUrl={pdfUrl}
            documentTypeText={documentTypeText}
            documentTypeCodeList={documentTypeCodeList}
            isVacOrderFromSSO={internalObj?.isVACOrderSSO}
            isCreateTransition={isTransition}
          />
        );
        break;
    }
    return page;
  };

  const spinner = () => {
    return (
      <div>
        <div className="saveapi-header">Saving Order</div>
        <div className="saveapi-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  const getSpinner = () => {
    return (
      <div>
        <div className="saveapi-header">
          {isCreateTransitionOrder?.createTransition
            ? "Retrieving Order Details"
            : "Retrieving Saved Order"}
        </div>
        <div className="saveapi-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  const stopShareOrderSpinner = () => {
    return (
      <div>
        <div className="saveapi-header"></div>
        <div className="saveapi-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  const shareOrderSpinner = () => {
    return (
      <div>
        <div className="saveapi-header">Sharing Order</div>
        <div className="saveapi-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  const freshNewOrderSpinner = () => {
    return (
      <div>
        <div className="saveapi-header"></div>
        <div className="saveapi-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };

  const closeSuccessSharedPopup = () => {
    if (
      sharedOrderUserA ===
      authObj?.userProfile?.firstName + " " + authObj?.userProfile?.lastName
    ) {
      setshareOrderText("");
      setShareOrderButtonText("Share Order");
    } else {
      newOrderObj?.setIsHandleChangeTriggered(false);
      history.push("/home");
    }
    setIsStopShareOrderSuccess(false);
  };

  const stopShareOrderSuccessPopUp = () => {
    return (
      <div className="successStopShare">
        <div className="successStopShareTitle">
          {sharedOrderUserA ===
          authObj?.userProfile?.firstName + " " + authObj?.userProfile?.lastName
            ? `Success! The order is no longer shared`
            : `Success! The order is no longer shared with you`}
        </div>
        <div className="successStopShareDesp">
          {sharedOrderUserA ===
          authObj?.userProfile?.firstName + " " + authObj?.userProfile?.lastName
            ? `Any edits others have made and saved will remain in the order. If you wish to share the order again, you can now do so.`
            : `Any edits you have made and saved will remain in the order. You can
            also re-add the order to your list using the Add Patient option from
            the My Patients dashboard.`}
        </div>
        <div className="successStopShareClose">
          <ExpressButton
            parentClass="successStopShareButton"
            variant="contained"
            clickHandler={closeSuccessSharedPopup}
          >
            Close
          </ExpressButton>
        </div>
      </div>
    );
  };

  const patientLocked = (deepLinkVacOrderLocked: any) => {
    return (
      <div className="patient-load-spinner" data-testid="patienLocked">
        <div className="patient-locked" data-testid="patient-locked-popup">
          This order is currently being edited by {deepLinkVacOrderLocked} {}.
        </div>
        <div
          className="patient-locked-desp"
          data-testid="patient-locked-popup-bodytext"
        >
          You can edit the order when they are finished.
        </div>
        <ExpressButton
          variant="contained"
          parentClass="cancelBtn"
          clickHandler={() => {
            authObj?.setDeepLinkPath(null);
            sessionStorage.removeItem("DeepLinkPath");
            setDeepLinkVacOrderLocked(null);
            history.push("/home");
          }}
        >
          Close
        </ExpressButton>
      </div>
    );
  };

  return (
    <>
      <Grid
        className={
          internalObj?.isVACOrderSSO
            ? "newOrder-flow newOrderLandingPage-flow"
            : "newOrder-flow"
        }
        container
        data-testid="newOrder-flow"
      >
        {internalObj?.isVACOrderSSO && (
          <div className="select-facility-return-btn">
            <ExpressButton
              testId=""
              variant="text"
              parentClass="select-a-facility-back"
              clickHandler={handleReturnToSelectAFacilityClick}
              children={"Return to Select a Facility"}
              startIcon={<BackIcon className="backIcon-SelectaFacility" />}
            ></ExpressButton>
          </div>
        )}
        {newOrderObj?.newOrderPage !== NewOrderPageSection.NEWORDER_SUMMARY && (
          <Grid
            className={
              internalObj?.isVACOrderSSO
                ? "homeorder-mobileresponse-sso-vacOrder"
                : "homeorder-mobileresponse"
            }
            item
            xs={12}
          >
            <ProgressBar
              pageTitle="Home Order"
              progressValue={newOrderObj?.newOrderProgress!}
              backButtonAction={() => {
                showCancelPopupHandler();
              }}
              isShowBackButton={internalObj?.isVACOrderSSO ? false : true}
            />
          </Grid>
        )}
        <Grid className="container-mobileresponsive" item xs={12}>
          {sectionToDisplay()}
        </Grid>
        <SaveExitOrder
          handleSaveAndExit={handleSaveAndExit}
          setSaveAndExitOpen={setSaveAndExitOpen}
          saveAndExitOpen={saveAndExitOpen}
        />
        <ShareOrderInfo
          handleSaveAndShare={handleSaveAndShare}
          vacOrderID={vacOrderID}
        />
        <NextOrder
          nextOrderOpen={nextOrderOpen}
          handlenextPopUp={handleNextOrderPopup}
          setNextOrderOpen={setNextOrderOpen}
        />
        {newOrderObj?.newOrderPage !== NewOrderPageSection.NEWORDER_SUMMARY && (
          <NewOrderFooterButtonGroup
            validateAll={validateAll}
            handleSave={saveOrderHandler}
            handlePrevious={previousButtonHandler}
            handleShareOrder={shareOrderHandler}
            handleCancelOrder={showCancelPopupHandlerDirect}
            handleSaveExit={saveAndExitPopupHandler}
            cancelBtnVisibility={cancelBtnVisibility}
            shareOrderButtonText={shareOrderButtonText}
            isSharedOrderNeedToBeDisable={isSharedOrderNeedToBeDisable}
          />
        )}
      </Grid>
      <SnackBar
        toastStyle="newOrderSaveToast"
        handleCloseAlert={() => {
          setSaveOrderToastFlag(false);
        }}
        msg="Order has been Saved"
        openFlag={saveOrderToastFlag}
      />
      <NewOrderErrorPopupMessage
        errorPopupFlag={newOrderErrorPopPpFlag}
        handleBackButton={() =>
          createTransitionErrorHandle
            ? handleErrorPopupBackForTransition()
            : handleErrorPopupBackButton()
        }
        errorMessage={errorMessage}
        isSaveExitFlow={isSaveExitFlow}
        handleExitButton={() => handleExitButton()}
        isSupportPhoneShow={showSupportPhoneNum}
        isShortSentence={true}
        errorCode={errorCode}
      />

      {saveAPILoader ? (
        <Popup
          hideCloseButton={true}
          openFlag={saveAPILoader}
          closeHandler={() => {}}
        >
          {spinner()}
        </Popup>
      ) : (
        ""
      )}
      {getApiLoader ? (
        <Popup
          hideCloseButton={true}
          openFlag={getApiLoader}
          closeHandler={() => {}}
        >
          {getSpinner()}
        </Popup>
      ) : (
        ""
      )}
      {cancelShareLoader ? (
        <Popup
          hideCloseButton={true}
          openFlag={cancelShareLoader}
          closeHandler={() => {}}
        >
          {stopShareOrderSpinner()}
        </Popup>
      ) : (
        ""
      )}
      {shareOrderLoader ? (
        <Popup
          hideCloseButton={true}
          openFlag={shareOrderLoader}
          closeHandler={() => {}}
        >
          {shareOrderSpinner()}
        </Popup>
      ) : (
        ""
      )}
      {freshNewOrderLoader ? (
        <Popup
          hideCloseButton={true}
          openFlag={freshNewOrderLoader}
          closeHandler={() => {}}
        >
          {freshNewOrderSpinner()}
        </Popup>
      ) : (
        ""
      )}
      {isStopShareOrderSuccess ? (
        <Popup
          openFlag={isStopShareOrderSuccess}
          closeHandler={closeSuccessSharedPopup}
        >
          {stopShareOrderSuccessPopUp()}
        </Popup>
      ) : (
        ""
      )}
      <Popup
        closeHandler={() => {
          setEmptyPopup((x) => !x);
        }}
        openFlag={emptyPopup}
      >
        <div className="emptyPopup"></div>
      </Popup>
      {deleteOrderOpen ? (
        <Popup
          openFlag={deleteOrderOpen}
          closeHandler={() => setDeleteOrderOpen(false)}
        >
          <DeletePopup
            doNotDeleteHandler={doNotDeleteHandler}
            deleteHandler={deleteHandler}
          />
        </Popup>
      ) : null}
      <UserConfirmation
        when={newOrderObj?.showDialog!}
        navigate={(path, state) =>
          history.push({ pathname: path, state: state })
        }
        shouldBlockNavigation={(location) => {
          // This case it blocks the navigation when:
          // 1. the login form is dirty, and
          // 2. the user is going to 'other' scene.
          //    (Just an example, in real case you might
          //     need to block all location regarding this case)
          if (showDialog) {
            if (location.pathname !== "/orders/newOrder") {
              return true;
            }
          }
          return false;
        }}
        handleSaveExit={handleSaveAndExitNavigation}
        handleUnloacVacOrder={callUnloackVacOrder}
      />
      <Popup
        hideCloseButton={true}
        openFlag={deepLinkVacOrderLocked !== null}
        closeHandler={() => {
          setDeepLinkVacOrderLocked(null);
        }}
      >
        {patientLocked(deepLinkVacOrderLocked)}
      </Popup>
      <Popup
        dialogParentClass="missing-field-pop-up"
        openFlag={showMissingRequiredFieldPrompt}
        closeHandler={() => {
          setShowMissingRequiredFieldPrompt(false);
          checkFirstErroredFieldAndFocusOnInputField();
        }}
      >
        <div
          className="showMissingRequiredFieldPrompt"
          data-testid="showMissingRequiredFieldPrompt"
        >
          <div
            className="showMissingRequiredFieldTitle"
            data-testid="showMissingRequiredFieldTitle"
          >
            Missing required fields
          </div>
          <div
            className="showMissingRequiredField-desp"
            data-testid="showMissingRequiredField-desp"
          >
            Please see the above red highlighted field(s) for additional
            required information.
          </div>
          <ExpressButton
            variant="contained"
            parentClass="cancelBtn"
            clickHandler={() => {
              setShowMissingRequiredFieldPrompt(false);
              checkFirstErroredFieldAndFocusOnInputField();
            }}
          >
            Done
          </ExpressButton>
        </div>
      </Popup>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={handleErrorDone}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
       <Popup
        hideCloseButton={false}
        openFlag={isDuplicateOrder}
        closeHandler={() => {
          setIsDuplicateOrder(false);
        }}
      >
        <DuplicateOrderCheckDialogue
          closeDialog={() => {
            setIsDuplicateOrder(false);
          }}
          handleSaveAndExit={handleSaveAndExit}
          handleDeleteOrder={deleteHandler}
          duplicateRODetails={duplicateRODetails!}
          isVacOrderFromSSO={internalObj?.isVACOrderSSO}
        />
      </Popup>
    </>
  );
};
