import parse from "html-react-parser";
import "./storedProductInventory.css";
import {
  IInventoryProductDetails,
  InventoryTabListingSection,
  SPMenuActionTypes,
} from "../inventoryTabContainer.enum";
import {
  agGridGetRowStyle,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../context/InpatientOrderContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { useContext, useRef, useState } from "react";
import {
  InventoryPopUp,
  InventoryPopUpFlow,
} from "../popUpContainer/inventoryPopUpContainer.enum";
import { Popup } from "../../../core/popup/popup.component";
import { PICKUP_PENDING_ERROR } from "../../../util/errorMsg";
import { CustomHeader } from "./customHeader/customHeaderComponent";
import ErrorPopup from "../../../core/errorPopup/errorPopup.component";
import { GET_INVENTORY_COMING_SOON_TEXT } from "../../../util/staticText";
import { ReactComponent as Download } from "../../../assets/download.svg";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { ReactComponent as CheckIcon } from "../../../assets/checkMark.svg";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import InventoryProductMenu from "../InventoryProductMenu/InventoryProductMenu.component";
import { InventoryPopUpContainer } from "../popUpContainer/inventoryPopUpContainer.component";
import { ReactComponent as DownloadDisabledIcon } from "../../../assets/downloadDisabled.svg";

interface props {
  downloadBtnAction: any;
  refreshStoredProductData: Function;
  setReadyCareProductData: Function;
  storedProductData: IInventoryProductDetails[];
  manageStorageLocationsButtonAction: Function;
}

const csvColumns: string[] = [
  "Product",
  "Serial Number",
  "Location",
  "Status",
  "Days in Storage",
  "Pickup Pending",
  "MIA",
];

export const StoredProductInventory = ({
  downloadBtnAction,
  refreshStoredProductData,
  setReadyCareProductData,
  storedProductData,
  manageStorageLocationsButtonAction,
}: props) => {
  const history = useHistory();

  const [openContainerPopupFlag, setOpenContainerPopupFlag] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width:1024px)");
  const isMobileScreenPickUpPending = useMediaQuery("(max-width:1272px)");

  const gridRef: any = useRef(null);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const disableDownloadBtn =
    storedProductData && storedProductData.length === 0;
  const [currentPopUp, setCurrentPopUp] = useState<InventoryPopUp>(
    InventoryPopUp.LOADER
  );
  const [popUpFlow, setPopUpFlow] = useState<InventoryPopUpFlow>();
  const [selectedProduct, setSelectedProduct] =
    useState<IInventoryProductDetails | null>(null);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const inpatientOrderObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );

  const defaultColDef = {
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    autoHeight: true,
    filter: false,
    resizable: false,
  };

  const columnDefs: ColDef[] = [
    {
      headerName: "Product",
      field: "product",
      sortable: true,
      suppressMovable: true,
      width: isMobileScreen ? 100 : 250,
      pinned: isMobileScreen ? "left" : null,
      lockPinned: isMobileScreen ? true : false,
      wrapText: true,
      flex: isMobileScreen ? 0 : 1,
    },
    {
      headerName: "Serial Number",
      field: "serialNumber",
      sortable: true,
      wrapHeaderText: true,
      filter: false,
      suppressMovable: true,
      width: isMobileScreen ? 140 : 200,
      flex: isMobileScreen ? 0 : 1,
    },
    {
      headerName: "Location",
      field: "storageLocation",
      sortable: true,
      width: isMobileScreen ? 200 : 350,
      filter: false,
      wrapText: true,
      suppressMovable: true,
      flex: isMobileScreen ? 0 : 1,
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={"inventory-stored-product-location"}
              onClick={() => handleUpdateLocationPopupOpen(params.data)}
            >
              {params.data.storageLocation}
            </span>
          </>
        );
      },
    },

    {
      field: "status",
      sortable: true,
      filter: false,
      suppressMovable: true,
      width: isMobileScreen ? 120 : 120,
      cellRenderer: function (params: any) {
        return <div>{makeCapitalEachWordInString(params.data.status)}</div>;
      },
      headerComponent: CustomHeader,
      headerComponentParams: {
        enableSorting: true,
      },
    },
    {
      headerName: "Pickup Pending",
      wrapHeaderText: isMobileScreen ? true : false,
      field: "isPickupPending",
      sortable: true,
      filter: false,
      suppressMovable: true,
      width: isMobileScreen ? 110 : 150,
      flex: isMobileScreen ? 0 : 1,
      cellRenderer: function (params: any) {
        return (
          <div
            className={
              isMobileScreenPickUpPending
                ? "inventory-stored-product-location-name"
                : "inventory-stored-product-days"
            }
          >
            {params.data.isPickupPending === true ? (
              <CheckIcon />
            ) : params.data.isPickupPending === null ? (
              <span
                className="inventory-stored-product-location"
                onClick={() => handlePickupPendingPopupOpen(params.data)}
              >
                Unknown
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      headerName: "Days in Storage",
      field: "storageDays",
      sortable: true,
      filter: false,
      wrapHeaderText: isMobileScreen ? true : false,
      suppressMovable: true,
      width: isMobileScreen ? 110 : 150,
      flex: isMobileScreen ? 0 : 1,
      cellRenderer: function (params: any) {
        return (
          <div
            className={
              isMobileScreenPickUpPending
                ? "inventory-stored-product-location-name"
                : "inventory-stored-product-days"
            }
          >
            {params.data.storageDays}
          </div>
        );
      },
    },
    {
      headerName: "MIA",
      field: "isMissingAsset",
      sortable: true,
      filter: false,
      wrapHeaderText: false,
      width: 70,
      flex: isMobileScreen ? 0 : 1,
      cellRenderer: function (params: any) {
        return (
          <div className="inventory-stored-missing-asset">
            {params.data.isMissingAsset ? <CheckIcon /> : ""}
          </div>
        );
      },
    },
    {
      headerName: "",
      field: "",
      width: isMobileScreen ? 45 : 65,
      pinned: isMobileScreen ? "right" : null,
      suppressMovable: true,
      lockPinned: isMobileScreen ? true : false,
      sortable: false,
      type: "rightAligned",
      headerClass: "menu-column-empty-text",
      cellClass: "menu-column-menu-btn",
      cellRendererSelector: (params) => {
        return {
          component: InventoryProductMenu,
          params: {
            productData: params.data,
            handleClickFunctionlity: handleLocationMenuOptionClick,
            tabListingSection:
              InventoryTabListingSection.STORED_PRODUCT_LISTING_SECTION,
          },
        };
      },
    },
  ];

  const closePopUp = () => {
    setOpenContainerPopupFlag(false);
    setCurrentPopUp(InventoryPopUp.LOADER);
  };

  const handleLocationMenuOptionClick = (
    typeOfEvent: string,
    product: IInventoryProductDetails
  ) => {
    if (
      typeOfEvent.toLowerCase() ===
      SPMenuActionTypes.UPDATE_STORAGE_LOCATION.toLowerCase()
    ) {
      handleUpdateLocationPopupOpen(product);
    } else if (
      typeOfEvent.toLowerCase() ===
      SPMenuActionTypes.RETURN_UNIT_TO_SOLVENTUM.toLowerCase()
    ) {
      handleReturnToSolventumPopup(product);
    } else if (
      typeOfEvent.toLowerCase() ===
      SPMenuActionTypes.MOVE_UNIT_VAC_READY_CARE.toLowerCase()
    ) {
      handleMoveToReadyCarePopUp(product);
    } else if (
      typeOfEvent.toLowerCase() ===
      SPMenuActionTypes.PLACE_ON_PATIENT.toLowerCase()
    ) {
      handlePlaceOnPatient(product);
    }
  };

  const handleUpdateLocationPopupOpen = (product: IInventoryProductDetails) => {
    const updatedProduct: IInventoryProductDetails = {
      ...product,
      storageLocation: product.storageLocation
        ? product.storageLocation.toUpperCase()
        : "",
    };
    setSelectedProduct(updatedProduct);
    setCurrentPopUp(InventoryPopUp.LOADER);
    setPopUpFlow(InventoryPopUpFlow.UPDATE_LOCATION);
    setOpenContainerPopupFlag(true);
  };

  const [pickupPendingErrorPopup, setpickupPendingErrorPopup] = useState({
    code: 0,
    flag: false,
  });

  const handlePickupPendingPopupOpen = (
    pickupPendingData: IInventoryProductDetails
  ) => {
    const errorobj = {
      code: pickupPendingData.errorCode!,
      flag: true,
    };
    setpickupPendingErrorPopup(errorobj);
  };

  const handleMoveToReadyCarePopUp = (product: IInventoryProductDetails) => {
    const updatedProduct: IInventoryProductDetails = {
      ...product,
      storageLocation: product.storageLocation.toUpperCase(),
    };
    setSelectedProduct(updatedProduct);
    setCurrentPopUp(InventoryPopUp.LOADER);
    setPopUpFlow(InventoryPopUpFlow.MOVE_TO_READY_CARE);
    setOpenContainerPopupFlag(true);
  };

  const handlePlaceOnPatient = (product: IInventoryProductDetails) => {
    const availableStoredProducts = storedProductData.filter(
      (product) => product.status.toLowerCase() === "available"
    );
    window.scrollTo(0, 0);
    inpatientOrderObj?.resetContext();
    history.push({
      pathname: "/orders/inpatientOrder",
      state: {
        allProducts: availableStoredProducts,
        product: product,
      },
    });
  };

  const handleClosePopUpAction = () => {
    if (
      currentPopUp === InventoryPopUp.RETURN_UNIT_TO_SOLVENTUM_SUCCESS ||
      currentPopUp === InventoryPopUp.MOVE_TO_READY_CARE_PRODUCT_SUCCESS ||
      currentPopUp === InventoryPopUp.MOVE_TO_STORE_PRODUCT_SUCCESS
    ) {
      handleSuccessAndCloseAction(false);
    } else {
      closePopUp();
    }
  };

  const handleSuccessAndCloseAction = (isUpdateOnlyCurrentProduct: boolean) => {
    // Get updated stored products
    refreshStoredProductData();
    if (!isUpdateOnlyCurrentProduct) {
      // Clear all Ready Care Product
      setReadyCareProductData(null);
    }
    // Clear selected product
    setSelectedProduct(null);
    // Close pop up
    closePopUp();
  };

  const handleDownloadBtnCSV = async () => {
    let rowData: any[] = [];
    gridRef?.current?.api?.forEachNode((node: any) => rowData.push(node.data));
    const csvData = rowData?.map((x: any) => {
      return {
        product: x.product,
        serialNumber: x.serialNumber,
        storageLocation: x.storageLocation,
        status: makeCapitalEachWordInString(x.status),
        storageDays: x.storageDays,
        pickupPending: x.isPickupPending ? "Done" : "Open",
        MIA: x.isMissingAsset ? "Yes" : "No",
      };
    });
    const registeredFacilty = authObj?.registeredFaciltyAddress;
    const csvFileName: string = `${registeredFacilty?.accountName
      .split(" ")
      .join("_")}_${registeredFacilty?.accountNumber}_SP_Inventory_`;
    const reqsBody = {
      Columns: csvColumns,
      Data: csvData,
    };
    await downloadBtnAction(reqsBody, csvFileName);
  };

  const handleReturnToSolventumPopup = (product: IInventoryProductDetails) => {
    const updatedProduct: IInventoryProductDetails = {
      ...product,
      storageLocation: product.storageLocation.toUpperCase(),
    };
    setOpenContainerPopupFlag(true);
    setSelectedProduct(updatedProduct);
    setPopUpFlow(InventoryPopUpFlow.RETURN_UNIT_TO_SOLVENTUM);
  };

  return (
    <div className="inventory-stored-product-main-div">
      <div className="inventory-stored-product-sub-div">
        <div
          data-testid="inventory-stored-product-ag-table"
          className="ag-theme-quartz"
          style={{ height: 400, width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            getRowStyle={agGridGetRowStyle}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={storedProductData}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
            suppressContextMenu={true}
          />
        </div>
        <div className="inventory-storedProduct-btn-div">
          <ExpressButton
            clickHandler={handleDownloadBtnCSV}
            variant="outlined"
            parentClass="stored-product-download-btn"
            testId="stored-product-download-button-test"
            startIcon={
              disableDownloadBtn ? <DownloadDisabledIcon /> : <Download />
            }
            disabled={disableDownloadBtn}
          >
            Download
          </ExpressButton>
          {permissionObj &&
            permissionObj.mappedRolesPermissionData.IsAdminMyListsButton && (
              <ExpressButton
                clickHandler={() => manageStorageLocationsButtonAction()}
                variant="outlined"
                parentClass="manage-storage-locations-btn"
                testId="stored-product-manage-storage-button-test"
              >
                Manage Storage Locations
              </ExpressButton>
            )}
        </div>
      </div>
      <div className="InventorycomingSoonContainer">
        <div
          className="heading"
          data-testid="InventorycomingSoonContainer_heading"
        >
          Want to place a unit from storage or request a return?
        </div>
        <div
          className="contentBody"
          data-testid="InventorycomingSoonContainer_contentBody"
        >
          {parse(GET_INVENTORY_COMING_SOON_TEXT)}
        </div>
      </div>
      <Popup
        openFlag={openContainerPopupFlag}
        dialogParentClass="inventory-pop-up-container"
        closeHandler={handleClosePopUpAction}
        hideCloseButton={currentPopUp === InventoryPopUp.LOADER}
      >
        {selectedProduct && popUpFlow && (
          <InventoryPopUpContainer
            closePopUp={handleClosePopUpAction}
            currentPopUp={currentPopUp}
            flow={popUpFlow}
            setCurrentPopUp={setCurrentPopUp}
            selectedProduct={selectedProduct}
            successAndClose={handleSuccessAndCloseAction}
          />
        )}
      </Popup>
      <ErrorPopup
        popUpStyles="errorPopup"
        handleBackButton={() =>
          setpickupPendingErrorPopup((p) => ({ ...p, flag: false }))
        }
        isShortSentence={true}
        buttontitle="Done"
        errorPopupFlag={pickupPendingErrorPopup.flag}
        errorMessage={PICKUP_PENDING_ERROR}
        errorCode={pickupPendingErrorPopup.code}
        showTitle={false}
      />
    </div>
  );
};
