import { Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import "./postReleaseContent.css";
import { ScrollableTabHeader } from "../../../../core/scrollableTabs/scrollableTabHeader.component";

const postReleaseTabs = [
  { label: "Subsequent Cycles", testId: "tab-subsequent-cycles" },
  { label: "Discharge Process", testId: "tab-discharge-process" },
  { label: "Rebill/Restart Processes", testId: "tab-rebill-restart-process" },
];

const tabClasses = {
  textColorPrimary: "post-release-scroll-tabs-textlabel",
  selected: "post-release-scroll-tabs-textlabel-select",
  root: "scroll-tabs-height",
};

const PostReleaseContent: React.FC = () => {
  const [tabValue, setTabValue] = useState<number>(0);

  return (
    <div className="post-release-content-container">
      <Typography
        className="post-release-content-title"
        data-testid="post-release-content-title"
      >
        Post-release Content
      </Typography>
      <div className="post-release-scroll-table-header">
        <ScrollableTabHeader
          scrollTabValue={tabValue}
          handleChange={(e: any, value: number) => setTabValue(value)}
          tabsIndicator="scroll-tabs-indicator"
          tabsRoot="scroll-tabs"
          variant="scrollable"
        >
          {postReleaseTabs.map(({ label, testId }) => (
            <Tab
              key={testId}
              classes={tabClasses}
              disableRipple
              iconPosition="end"
              label={label}
              data-testid={testId}
            />
          ))}
        </ScrollableTabHeader>
      </div>
    </div>
  );
};

export default PostReleaseContent;
