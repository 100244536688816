import "./devicePlacement.css";
import {
  agGridGetRowStyle,
  makeCapitalEachWordInString,
} from "../../../../util/utilityFunctions";
import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  useMediaQuery,
} from "@mui/material";
import { ColDef, DomLayoutType } from "ag-grid-enterprise";
import { IInpatientOrder } from "../../inpatientOrder.interface";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ReactComponent as RadioButtonIcon } from "../../../../assets/radioButton.svg";
import { IInventoryProductDetails } from "../../../inventory/inventoryTabContainer.enum";
import { DevicePlacementReview } from "./reviewDevicePlacement/devicePlacementReview.component";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../assets/selectedRadioButton.svg";
import { ICustomerPlusListingDetails } from "../../../inventory/customerPlusInventory/customerPlusInventory.interface";
import { IDevicePlacementTableData } from "../../container/inpatientOrderContainer.component";
import {
  IAgGridTableData,
  IRadioButtonGroup,
  IScrollableTabs,
} from "./devicePlacement.interface";
import {
  DevicePlacementTabs,
  PlacementTypeSelection,
} from "./devicePlacemet.enums";
import { ScrollableTabHeader } from "../../../../core/scrollableTabs/scrollableTabHeader.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../../context/InpatientOrderContext";
import { IBillingAddress } from "../billingAddress/billingAddress.interface";
import { InpatientOrderValidator } from "../../inpatientOrder.validator";

interface Props {
  addresses: IBillingAddress[];
  allProducts: ICustomerPlusListingDetails[] | IInventoryProductDetails[];
  data: IInpatientOrder;
  editButtonAction?: any;
  isCustomerPlusProduct: boolean | null;
  setIsCustomerPlusProduct: Function;
  isReviewOrder?: boolean;
  isSummary?: boolean;
  setData: Function;
  devicePlacementTableData: IDevicePlacementTableData;
  isFromStartNewOrder?: boolean;
  devicePlacementScrollableTab?: number;
  setDevicePlacementScrollableTab?: Function;
  devicePlacementSelectedTab?: DevicePlacementTabs;
  setDevicePlacementSelectedTab?: Function;
  updateSolventumProduct: Function;
}

export const DevicePlacement = ({
  addresses,
  allProducts,
  data,
  editButtonAction,
  isCustomerPlusProduct,
  setIsCustomerPlusProduct,
  isReviewOrder = false,
  isSummary = false,
  setData,
  devicePlacementTableData,
  isFromStartNewOrder,
  devicePlacementScrollableTab,
  setDevicePlacementScrollableTab,
  setDevicePlacementSelectedTab,
  updateSolventumProduct,
}: Props) => {
  const rowHeight = 52;
  const gridRef: any = useRef(null);
  const [gridApi, setGridApi] = useState<any>(null);
  const isSmallerMobileScreen = useMediaQuery("(max-width:600px)");
  const selectedTab = data.devicePlacement.value.productType.value;
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const inpatientObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );
  const Validator = new InpatientOrderValidator();
  let primaryDateValue =
    data.patientWoundInformation.value.primaryWoundInformation
      .woundMeasurementDate.value;
  let secondaryDateValue =
    data.patientWoundInformation.value.secondaryWoundInformation
      .woundMeasurementDate.value;

  const defaultColDef = {
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    autoHeight: true,
    resizable: false,
    flex:
      isMobileScreen || selectedTab === DevicePlacementTabs.CUSTOMER_PLUS_TAB
        ? 0
        : 1,
  };

  const availableUnitsColDefs: ColDef[] = [
    {
      headerName: "",
      field: "",
      pinned: isMobileScreen ? "left" : null,
      suppressMovable: true,
      lockPinned: isMobileScreen ? true : false,
      sortable: false,
      width: 60,
      flex: 0,
      type: "rightAligned",
      cellRenderer: (params: any) => {
        return (
          <Radio
            icon={<RadioButtonIcon />}
            checkedIcon={<SelectedRadioButtonIcon />}
            checked={
              data.devicePlacement.value.selectedProduct.value?.serialNumber ===
              params.data.serialNumber
            }
            onChange={() => handleRowSelection(params.data)}
          />
        );
      },
    },
    {
      headerName: "Product",
      maxWidth: isMobileScreen ? 110 : 190,
      field: "productNameDescription",
      sortable: true,
      suppressMovable: true,
      wrapText: true,
    },
    {
      headerName: "Serial Number",
      maxWidth: isMobileScreen ? 130 : 150,
      field: "serialNumber",
      sortable: true,
      suppressMovable: true,
    },
    {
      headerName: "Location",
      field: "currentLocation",
      sortable: true,
      wrapText: true,
      suppressMovable: true,
      maxWidth: isMobileScreen ? 200 : 200,
      cellRenderer: function (params: any) {
        return <div>{params.data.currentLocation}</div>;
      },
    },
    {
      headerName: "Unit Tag ID",
      field: "unitTagId",
      sortable: true,
      pinned: isMobileScreen ? "left" : null,
      lockPinned: isMobileScreen ? true : false,
      suppressMovable: true,
      maxWidth: isMobileScreen ? 120 : 190,
      wrapText: true,
      cellRenderer: (params: any) => {
        return <>{makeCapitalEachWordInString(params.data.unitTagId)}</>;
      },
    },
    {
      headerName: "Days in Storage",
      field: "storageDays",
      sortable: true,
      wrapHeaderText: true,
      suppressMovable: true,
      width: isMobileScreen ? 120 : 100,
      minWidth: 120,
      flex: isMobileScreen ? 0 : 1,
      cellRenderer: function (params: any) {
        return (
          <div className="inpatient-device-placement-days">
            {params.data.storageDays}
          </div>
        );
      },
    },
  ];

  const storedProductColDefs: ColDef[] = [
    {
      headerName: "",
      field: "",
      pinned: isMobileScreen ? "left" : null,
      suppressMovable: true,
      lockPinned: isMobileScreen ? true : false,
      sortable: false,
      width: 60,
      flex: 0,
      type: "rightAligned",
      cellRenderer: (params: any) => {
        return (
          <Radio
            icon={<RadioButtonIcon />}
            checkedIcon={<SelectedRadioButtonIcon />}
            checked={
              data.devicePlacement.value.selectedProduct.value?.serialNumber ===
              params.data.serialNumber
            }
            onChange={() => handleRowSelection(params.data)}
          />
        );
      },
    },
    {
      headerName: "Product",
      pinned: isMobileScreen ? "left" : null,
      width: isMobileScreen ? 110 : 190,
      field: "product",
      sortable: true,
      suppressMovable: true,
      wrapText: true,
    },
    {
      headerName: "Serial Number",
      width: isMobileScreen ? 130 : 150,
      field: "serialNumber",
      sortable: true,
      suppressMovable: true,
    },
    {
      headerName: "Location",
      field: "storageLocation",
      sortable: true,
      wrapText: true,
      suppressMovable: true,
      width: 250,
      cellRenderer: function (params: any) {
        return <div>{params.data.storageLocation}</div>;
      },
    },
    {
      headerName: "Days in Storage",
      field: "storageDays",
      sortable: true,
      wrapHeaderText: true,
      suppressMovable: true,
      width: isMobileScreen ? 120 : 100,
      flex: isSmallerMobileScreen ? 0 : 1,
      cellRenderer: function (params: any) {
        return (
          <div className="inpatient-device-placement-days">
            {params.data.storageDays}
          </div>
        );
      },
    },
  ];

  //UI Logic
  const getDevicePlacementHeader = (): string => {
    let headerText: string = "Device Placement";
    if (!isFromStartNewOrder) {
      headerText += isCustomerPlusProduct
        ? ": Customer Plus"
        : ": Stored Product";
    }
    return headerText;
  };

  const getTablabel = (title: string, productsCount: number) => {
    return (
      <>
        {title}
        <span className="sideNavmadminBtnStyle margin-left">
          {productsCount}
        </span>
      </>
    );
  };

  const scrollableTabs: IScrollableTabs[] = [
    {
      tabLabel: "Stored Product Listing",
      tabCount: devicePlacementTableData.storedProductListingData.filter(
        (row) => !row.isMissingAsset
      ).length,
      tabType: DevicePlacementTabs.STORED_PRODUCT_TAB,
    },
    {
      tabLabel: "Customer Plus Product Listing",
      tabCount: devicePlacementTableData.customerPlusListingData.filter(
        (row) => !row.isMissingAsset
      ).length,
      tabType: DevicePlacementTabs.CUSTOMER_PLUS_TAB,
    },
  ];

  const radioOptions: IRadioButtonGroup[] = [
    {
      raidoButtonLabel: `Place from Storage (${
        devicePlacementTableData.customerPlusListingData.length +
        devicePlacementTableData.storedProductListingData.length
      } available)`,
      radioButtonValue: PlacementTypeSelection.PLACE_FROM_STORAGE,
      radioButtonTestId: "placement-type-place-from-storage",
      radioButtonDisabled:
        devicePlacementTableData.customerPlusListingData.length +
          devicePlacementTableData.storedProductListingData.length ===
        0,
    },
    {
      raidoButtonLabel: "Place Order with Solventum",
      radioButtonValue: PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM,
      radioButtonTestId: "placement-type-place-order-from-solventum",
      radioButtonDisabled: false,
    },
  ];

  const getRadioButtonRootClassName = (
    placementType: PlacementTypeSelection
  ): string => {
    return data.devicePlacement.value.placementType.value === placementType
      ? "placementTypeRootActive"
      : "placementTypeOptionRoot";
  };

  const handleRowSelection = (
    selectedRow: ICustomerPlusListingDetails | IInventoryProductDetails
  ) => {
    let tempData = {
      ...data,
      devicePlacement: {
        ...data.devicePlacement,
        value: {
          ...data.devicePlacement.value,
          selectedProduct: {
            ...data.devicePlacement.value.selectedProduct,
            valid: ValidationStatus.VALID,
            value: selectedRow,
          },
        },
      },
    };
    setData(tempData);
  };

  const getDomLayout = (
    tableData: ICustomerPlusListingDetails[] | IInventoryProductDetails[]
  ): DomLayoutType => {
    let domLayout: DomLayoutType = "normal";
    if (tableData?.length > 1 || tableData?.length === 0) {
      domLayout = "normal";
    } else domLayout = "autoHeight";
    return domLayout;
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (
      gridApi &&
      allProducts.length > 0 &&
      data.devicePlacement.value.selectedProduct.value &&
      data.devicePlacement.value.selectedProduct.value.serialNumber !== ""
    ) {
      const rowIndex = allProducts.findIndex(
        (unit) =>
          unit.serialNumber ===
          data.devicePlacement.value.selectedProduct.value.serialNumber
      );
      if (rowIndex !== -1) {
        gridRef.current.api.ensureIndexVisible(rowIndex);
      }
    }
  }, [data.devicePlacement.value.selectedProduct.value, allProducts, gridApi]);

  const getRowsAndColumnsForTable = (): IAgGridTableData => {
    const selectedTab = data.devicePlacement.value.productType.value;
    let tableInformation: IAgGridTableData = {
      columnDef:
        (isFromStartNewOrder &&
          selectedTab === DevicePlacementTabs.CUSTOMER_PLUS_TAB) ||
        isCustomerPlusProduct
          ? availableUnitsColDefs
          : storedProductColDefs,
      rowData: isFromStartNewOrder
        ? selectedTab === DevicePlacementTabs.CUSTOMER_PLUS_TAB
          ? devicePlacementTableData.customerPlusListingData.filter(
              (row) => !row.isMissingAsset
            )
          : devicePlacementTableData.storedProductListingData.filter(
              (row) => !row.isMissingAsset
            )
        : allProducts,
    };
    return tableInformation;
  };

  const handleDevicePlacementTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setDevicePlacementScrollableTab!(newValue);
  };

  const validateWoundMeasurementDate = (
    dateValue: string,
    woundMeasurementDate: any,
    type: DevicePlacementTabs | PlacementTypeSelection
  ): ValidationStatus => {
    if (type === DevicePlacementTabs.CUSTOMER_PLUS_TAB && dateValue === "") {
      return ValidationStatus.VALID;
    }
    if (woundMeasurementDate.value !== "") {
      return Validator.validate(
        woundMeasurementDate.value,
        "woundMeasurementDate"
      )!.status;
    }
    return ValidationStatus.UNTOUCHED;
  };

  const handleDevicePlacementTabClick = (tabType: DevicePlacementTabs) => {
    let tempData: IInpatientOrder = data;
    let isMeasurementDateRequired: boolean = false;
    setDevicePlacementSelectedTab!(tabType);
    tempData = {
      ...tempData,
      devicePlacement: {
        ...tempData.devicePlacement,
        value: {
          ...tempData.devicePlacement.value,
          productType: {
            ...data.devicePlacement.value.productType,
            value: tabType,
            valid: ValidationStatus.VALID,
          },
        },
      },
    };
    setIsCustomerPlusProduct(tabType === DevicePlacementTabs.CUSTOMER_PLUS_TAB);
    if (tabType === DevicePlacementTabs.CUSTOMER_PLUS_TAB) {
      isMeasurementDateRequired = false;
      tempData = {
        ...tempData,
        therapyStartDate: {
          ...tempData.therapyStartDate,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        billingAddress: {
          ...tempData.billingAddress,
          required: false,
          valid: ValidationStatus.UNTOUCHED,
          value: null,
        },
        devicePlacement: {
          ...tempData.devicePlacement,
          value: {
            ...tempData.devicePlacement.value,
            selectedProduct: {
              ...tempData.devicePlacement.value.selectedProduct,
              valid: ValidationStatus.UNTOUCHED,
              value: null,
            },
          },
        },
      };
    } else {
      isMeasurementDateRequired = true;
      tempData = {
        ...tempData,
        billingAddress: {
          ...tempData.billingAddress,
          required: addresses && addresses.length > 0 ? true : false,
          valid:
            addresses.length === 1
              ? ValidationStatus.VALID
              : ValidationStatus.UNTOUCHED,
          value:
            addresses.length === 1
              ? addresses[0].billToSiteId.toString()
              : null,
        },
        therapyStartDate: {
          ...tempData.therapyStartDate,
          required: false,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        devicePlacement: {
          ...tempData.devicePlacement,
          value: {
            ...tempData.devicePlacement.value,
            selectedProduct: {
              ...tempData.devicePlacement.value.selectedProduct,
              valid: ValidationStatus.UNTOUCHED,
              value: null,
            },
          },
        },
      };
    }

    tempData.patientWoundInformation.value = {
      ...tempData.patientWoundInformation.value,
      primaryWoundInformation: {
        ...tempData.patientWoundInformation.value.primaryWoundInformation,
        woundMeasurementDate: {
          ...tempData.patientWoundInformation.value.primaryWoundInformation
            .woundMeasurementDate,
          required: isMeasurementDateRequired,
          valid: validateWoundMeasurementDate(
            primaryDateValue,
            tempData.patientWoundInformation.value.primaryWoundInformation
              .woundMeasurementDate,
            tabType
          ),
        },
      },
      secondaryWoundInformation: {
        ...tempData.patientWoundInformation.value.secondaryWoundInformation,
        woundMeasurementDate: {
          ...tempData.patientWoundInformation.value.secondaryWoundInformation
            .woundMeasurementDate,
          required: isMeasurementDateRequired,
          valid: validateWoundMeasurementDate(
            secondaryDateValue,
            tempData.patientWoundInformation.value.secondaryWoundInformation
              .woundMeasurementDate,
            tabType
          ),
        },
      },
    };

    setData(tempData);
  };

  const validateAndSetData = (event: any) => {
    let tempData: IInpatientOrder = data;
    const { name, value } = event.target;
    tempData = {
      ...tempData,
      devicePlacement: {
        ...tempData.devicePlacement,
        value: {
          ...tempData.devicePlacement.value,
          [name]: {
            ...Object(data.devicePlacement.value)[name],
            value: value,
            valid: ValidationStatus.VALID,
          },
        },
      },
      orderWithSolventum: {
        ...tempData.orderWithSolventum,
        required: value === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM,
        valid: ValidationStatus.UNTOUCHED,
        value: null,
      },
      billingAddress: {
        ...tempData.billingAddress,
        required:
          value === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM &&
          addresses &&
          addresses.length > 0
            ? true
            : false,
      },
      therapyStartDate: {
        ...tempData.therapyStartDate,
        required: value !== PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM,
      },
    };
    if (value === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM) {
      setIsCustomerPlusProduct(false);
      setDevicePlacementScrollableTab!(0);
      setDevicePlacementSelectedTab!(DevicePlacementTabs.STORED_PRODUCT_TAB);
      tempData = {
        ...tempData,
        selectionSupplies: {
          ...data.selectionSupplies,
          value: "",
          required: true,
          valid: ValidationStatus.UNTOUCHED,
        },
        devicePlacement: {
          ...tempData.devicePlacement,
          value: {
            ...tempData.devicePlacement.value,
            productType: {
              ...tempData.devicePlacement.value.productType,
              value: DevicePlacementTabs.STORED_PRODUCT_TAB,
            },
            selectedProduct: {
              ...tempData.devicePlacement.value.selectedProduct,
              valid: ValidationStatus.UNTOUCHED,
              required: false,
              value: null,
            },
            therapyStartDate: {
              ...data.therapyStartDate,
              value: "",
              required: false,
              valid: ValidationStatus.UNTOUCHED,
            },
          },
        },
      };
    } else if (value === PlacementTypeSelection.PLACE_FROM_STORAGE) {
      tempData.therapyStartDate = {
        ...tempData.therapyStartDate,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
        required: false,
      };
      tempData.devicePlacement.value.selectedProduct = {
        ...tempData.devicePlacement.value.selectedProduct,
        valid: ValidationStatus.UNTOUCHED,
        required: true,
      };
      tempData.selectionSupplies = {
        ...tempData.selectionSupplies,
        value: null,
        required: false,
        valid: ValidationStatus.UNTOUCHED,
      };
    }

    tempData.patientWoundInformation.value = {
      ...tempData.patientWoundInformation.value,
      primaryWoundInformation: {
        ...tempData.patientWoundInformation.value.primaryWoundInformation,
        woundMeasurementDate: {
          ...tempData.patientWoundInformation.value.primaryWoundInformation
            .woundMeasurementDate,
          required: true,
          valid: validateWoundMeasurementDate(
            primaryDateValue,
            tempData.patientWoundInformation.value.primaryWoundInformation
              .woundMeasurementDate,
            value
          ),
        },
      },
      secondaryWoundInformation: {
        ...tempData.patientWoundInformation.value.secondaryWoundInformation,
        woundMeasurementDate: {
          ...tempData.patientWoundInformation.value.secondaryWoundInformation
            .woundMeasurementDate,
          required: true,
          valid: validateWoundMeasurementDate(
            secondaryDateValue,
            tempData.patientWoundInformation.value.secondaryWoundInformation
              .woundMeasurementDate,
            value
          ),
        },
      },
    };
    updateSolventumProduct(
      tempData,
      addresses.length === 1 ? addresses[0].billToSiteId.toString() : null
    );
  };

  return (
    <div
      className="inpatient-device-placement-main-div"
      data-testId="inpatient-device-placement-main-div"
    >
      {isReviewOrder ? (
        <DevicePlacementReview
          data={
            data.devicePlacement.value.placementType.value !==
            PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
              ? data.devicePlacement.value.selectedProduct.value
              : data.orderWithSolventum.value
          }
          editButtonAction={editButtonAction}
          isSummary={isSummary}
          title={
            data.devicePlacement.value.placementType.value ===
            PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
              ? "3M-delivered"
              : data.devicePlacement.value.productType.value ===
                DevicePlacementTabs.CUSTOMER_PLUS_TAB
              ? `Customer Plus`
              : `Stored Product`
          }
          isSolventumProduct={
            data.devicePlacement.value.placementType.value ===
            PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
          }
        />
      ) : (
        <InputWithLabel
          id={data.devicePlacement.value.selectedProduct.componentId}
          isShowWarning={true}
          error={
            data.devicePlacement.value.selectedProduct.valid ===
            ValidationStatus.INVALID
          }
          warningMessage={
            data.devicePlacement.value.selectedProduct.errorMessage
          }
        >
          <div
            className="inpatient-device-placement-sub-div"
            data-testId="inpatient-device-placement-sub-div"
          >
            <h3
              className="device-placement-header"
              data-testId="device-placement-header"
            >
              {getDevicePlacementHeader()}
            </h3>
            {isFromStartNewOrder && (
              <div
                className="device-placement-radio-group"
                data-testid="device-placement-type-radio-btn-group"
              >
                <InputWithLabel
                  label={data.devicePlacement.value.placementType.title}
                  isRequired={data.devicePlacement.value.placementType.required}
                  warningMessage={
                    data.devicePlacement.value.placementType.errorMessage
                  }
                  isShowWarning={
                    data.devicePlacement.value.placementType.valid ===
                    ValidationStatus.INVALID
                  }
                  id={data.devicePlacement.value.placementType.componentId}
                  error={
                    data.devicePlacement.value.placementType.valid ===
                    ValidationStatus.INVALID
                  }
                >
                  <RadioGroup
                    value={data.devicePlacement.value.placementType.value}
                    classes={{ root: "placementTypeRadioRoot" }}
                    onChange={validateAndSetData}
                    name="placementType"
                  >
                    {radioOptions.map(
                      ({
                        raidoButtonLabel,
                        radioButtonValue,
                        radioButtonTestId,
                        radioButtonDisabled,
                      }: IRadioButtonGroup) => (
                        <FormControlLabel
                          key={radioButtonValue}
                          classes={{
                            root: getRadioButtonRootClassName(radioButtonValue),
                          }}
                          componentsProps={{
                            typography: {
                              classes: { root: "placementTypeOptiontxt" },
                            },
                          }}
                          control={
                            <Radio
                              icon={<RadioButtonIcon />}
                              checkedIcon={<SelectedRadioButtonIcon />}
                            />
                          }
                          data-testid={radioButtonTestId}
                          label={raidoButtonLabel}
                          value={radioButtonValue}
                          disabled={radioButtonDisabled}
                          className={radioButtonDisabled ? "disabled" : ""}
                        />
                      )
                    )}
                  </RadioGroup>
                </InputWithLabel>
              </div>
            )}
            {data.devicePlacement.value.placementType.value !==
              PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM && (
              <p
                className="device-placement-header-desc"
                data-testId="device-placement-header-desc"
              >
                Choose the device you want to use from the list of available
                units. Be sure to confirm the serial number before placement.
              </p>
            )}
            {data.devicePlacement.value.placementType.value ===
              PlacementTypeSelection.PLACE_FROM_STORAGE && (
              <>
                {isFromStartNewOrder && (
                  <div
                    className="scroll-tab-component"
                    data-testid="device-type-scroll-tab-component"
                  >
                    <ScrollableTabHeader
                      tabsIndicator="scroll-tabs-indicator"
                      tabsRoot="scroll-tabs"
                      variant="scrollable"
                      scrollTabValue={devicePlacementScrollableTab!}
                      handleChange={handleDevicePlacementTabChange}
                    >
                      {scrollableTabs.map(
                        ({ tabLabel, tabCount, tabType }: IScrollableTabs) => (
                          <Tab
                            key={tabType}
                            classes={{
                              textColorPrimary:
                                "inpatient-scroll-tabs-textlabel",
                              selected:
                                "inpatient-scroll-tabs-textlabel-select",
                              root: "scroll-tabs-height-ip",
                            }}
                            disableRipple={true}
                            iconPosition="end"
                            label={getTablabel(tabLabel, tabCount)}
                            data-testid={`inventory-${tabType.toLowerCase()}-tab`}
                            onClick={() =>
                              handleDevicePlacementTabClick(tabType)
                            }
                          />
                        )
                      )}
                    </ScrollableTabHeader>
                  </div>
                )}
              </>
            )}
            {data.devicePlacement.value.placementType.value !==
              PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM && (
              <div
                data-testId="device-placement-ag-grid"
                className="ag-theme-quartz"
                style={{
                  height: isFromStartNewOrder ? 258 : 200,
                  width: "100%",
                }}
              >
                <AgGridReact
                  ref={gridRef}
                  onGridReady={onGridReady}
                  getRowStyle={agGridGetRowStyle}
                  columnDefs={getRowsAndColumnsForTable().columnDef}
                  defaultColDef={defaultColDef}
                  rowData={getRowsAndColumnsForTable().rowData}
                  suppressRowClickSelection={true}
                  suppressDragLeaveHidesColumns={true}
                  rowHeight={rowHeight}
                  domLayout={getDomLayout(allProducts)}
                />
              </div>
            )}
          </div>
        </InputWithLabel>
      )}
    </div>
  );
};
