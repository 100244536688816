import Grid from "@mui/material/Grid";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { SalesRoundingValidator } from "../../salesRoundingTool.validator";
import "./additionalNotes.css";
import { InputBase } from "@mui/material";
import { ISRTAdditionalNotesProps } from "./additionalNotes.model";
import { ISalesRoundingPatientDetails } from "../../salesRoundingTool.interface";
export const AdditionalNotes = ({
  data,
  setData,
  additionalNotes,
}: ISRTAdditionalNotesProps) => {
  const validator = new SalesRoundingValidator();

  const validateAndSetDataFreeFormText = (e: any) => {
    let { value, name } = e.target;
    let isValid = validator.validate(value, name);
    const tempData: ISalesRoundingPatientDetails = {
      ...data,
      additionalNotes: {
        ...data.additionalNotes,
        notes: {
          ...data.additionalNotes.notes,
          value: value,
          valid: isValid!.status,
          errorMessage: null,
        },
      },
    };
    setData(tempData);
  };

  return (
    <div className="srtAdditionalNotes-info">
      <h2
        className="srtAdditionalNotes-info-title"
        data-testid="srtAdditionalNotes-info-title"
        id="srtAdditionalNotes-info-titleid"
      >
        Notes
      </h2>
      <Grid className="srtAdditionalNotes-info-grid-container" container>
        <Grid className="srtAdditionalNotes-info-item" item xs={12}>
          <InputWithLabel
            id={additionalNotes.notes.componentId!}
            error={additionalNotes.notes.valid === ValidationStatus.INVALID}
            isRequired={additionalNotes.notes.required}
            label="Additional Notes"
            labelClassName="srtStatus-type-title"
            testId="discribe-input-label"
            warningMessage={additionalNotes.notes.errorMessage}
            isShowWarning={true}
          >
            <InputBase
              className="srt-additional-notes-info-input"
              data-testid="describe-input-value"
              error={additionalNotes.notes.valid === ValidationStatus.INVALID}
              inputProps={{
                className:
                  additionalNotes.notes.valid === ValidationStatus.INVALID
                    ? "showCommentError"
                    : "noCommentError",
                maxLength: 500,
              }}
              multiline={true}
              name="notes"
              onChange={validateAndSetDataFreeFormText}
              required={additionalNotes.notes.required}
              rows={3}
              value={additionalNotes.notes.value}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
