import { Circle } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "react-string-format";
import { useDebounce } from "use-debounce";
import { ReactComponent as AcuteOrderIcon } from "../../assets/acuteOrder.svg";
import { ReactComponent as DisabledFilterIcon } from "../../assets/disabledFilterIcon.svg";
import { ReactComponent as Download } from "../../assets/download.svg";
import { ReactComponent as DownloadDisabled } from "../../assets/downloadDisabled.svg";
import { ReactComponent as FilterIcon } from "../../assets/filterIcon.svg";
import { ReactComponent as GridView } from "../../assets/gridView.svg";
import { ReactComponent as GridViewSlected } from "../../assets/gridView_selected.svg";
import { ReactComponent as ListView } from "../../assets/listView.svg";
import { ReactComponent as ListViewSelected } from "../../assets/listView_selected.svg";
import { ReactComponent as PatientAction } from "../../assets/patientAction.svg";
import { ReactComponent as PostAcuteOrderIcon } from "../../assets/postAcuteOrder.svg";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import {
  ConfirmPlacementContext,
  ConfirmPlacementContextType,
} from "../../context/ConfirmPlacementContext";
import {
  DischargeRequestContext,
  DischargeRequestContextType,
} from "../../context/DischargeRequestContext";
import {
  ExchangeVacUnitContext,
  ExchangeVacUnitContextType,
} from "../../context/ExchangeVacUnitContext";
import {
  MyPatientContext,
  MyPatientContextType,
} from "../../context/MyPatientContext";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../context/NewOrderContext";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../context/OrderDetailsContext";
import {
  PickUpRequestContext,
  PickUpRequestContextType,
} from "../../context/PickUpRequestContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../context/RolesPermissionContext";
import {
  SendNoteContext,
  SendNoteContextType,
} from "../../context/SendNoteContext";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../context/SupplyOrderContext";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../context/submitProofOfDeliveryContext";
import { DeletePopup } from "../../core/deleteOrder/deleteOrder.component";
import { DropDownMultiCheckboxValue } from "../../core/dropdownWithMutliCheckbox/dropdownWithMutliCheckbox.component";
import ErrorPopup from "../../core/errorPopup/errorPopup.component";
import FeaturesInfoPopup from "../../core/featuresInfoPopup/featuresInfoPopup.component";
import { LoadingSpinner } from "../../core/loader/LoadingSpinner";
import { Popup } from "../../core/popup/popup.component";
import SnackBar from "../../core/snackBar/snackBar.component";
import {
  getCSVDownload,
  getFaciityPatients,
  getFacilityAdminDetails,
  updateGuidencePopupFlag,
} from "../../util/3meService";
import { getDeepClone } from "../../util/ObjectFunctions";
import {
  createAlert,
  createMeasurementDueAlert,
  getMissingDocsAlert,
  getSupplyOrderDeliveredAlert,
  getSupplyOrderPendingAlert,
} from "../../util/alertFunctions";
import { getAlerts, negotiate } from "../../util/alertService";
import { getCMSContent } from "../../util/cmsService";
import {
  getdropDownContent,
  getMultiCheckDropdownContent,
} from "../../util/dropDownService";
import {
  ERROR_CONFIRM_PLACEMENT_SITEID_MATCHED,
  ERROR_MSG_DOWNLOAD_MY_PATIENTS,
  ERROR_MSG_FACILITY_ADMIN_DETAILS,
  ERROR_MSG_NO_PATIENTS_MPD,
  SVC_FETCH_CUSTOMER_PLUS_INFO,
} from "../../util/errorMsg";
import { Validator } from "../../util/order.validations";
import {
  CMS_USER_GUIDENCE,
  DD_DISPLAY_ORDER_STATUS,
  DD_DISPLAY_ORDER_TYPES,
  HOLD_THERAPY_ERROR_MESSAGE,
  NO_PATIENT_FOUND,
  RESUME_THERAPY_ERROR_MESSAGE,
  GET_WOUND_LIST_ERROR_MESSAGE,
  HOLD_OR_RESUME_THERAPY_ERROR_MESSAGE,
  NO_WOUND_ERROR_MESSAGE_FOR_HOLD_THERAPY,
  CMS_ORDER_SOURCE_CONTENT,
} from "../../util/staticText";
import {
  IAnalyticsData,
  getCSV,
  getSiteUseId,
  getcaregiverSiteUseId,
  makeCapitalEachWordInString,
  sendAnalyticsData,
  statusColor,
  getAcuteMenuActionPermission,
  getCareGiverId,
  getTextFromCode,
  getCustomerFacilityAccountNumber,
} from "../../util/utilityFunctions";
import {
  cancelOrSubmitVacOrder,
  getVACOrderDetails,
} from "../../util/vacOrderService";
import { FacilityBaseUserRole } from "../myPatients/facilityBaseUserRole/facilityBaseUserRole.component";
import { NeedHelp } from "../needHelp/needHelp.component";
import { VacOrderSummaryData } from "../newOrder/mapper/VacOrderSummary/newOrderResponseMapper.interface";
import { SharedOrderModal } from "../newOrder/newOrderFooterGroup/shareOrder/shareOrder.enum";
import { ShareOrderInfo } from "../newOrder/newOrderFooterGroup/shareOrder/shareOrderMainComponent";
import { PickUpRequestPageSection } from "../pickUpAndDischargeRequest/pickUpRequest/pickUpRequestPageSection.enum";
import { SendNoteFailure } from "../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import { SupplyOrderPageSection } from "../supplyOrder/SupplyOrderPageSection.enum";
import { AddPatientButton } from "./addPatientButton/addPatientButton.component";
import { AddPatientContainer } from "./addPatientContainer/addPatientContainer.component";
import { AddPatientContext } from "./addPatientContainer/addPatientContainer.context";
import { PageSection } from "./addPatientContainer/addPatientContainer.enum";
import AdditionalPermissionPopUp from "./additionalPermissionPopUp/additionalPermissionPopUp.component";
import {
  IAlertRequest,
  IAlertsRequest,
  ISuppyOrderAlertData,
} from "./alert.interface";
import MissingRx from "./allAlerts/missingRx/missingRx.component";
import { MyPatientOrderFilter } from "./myPatientOrderFilter/myPatientOrderFilter.component";
import MyPatientTable from "./myPatientTable/myPatientTable.component";
import "./myPatients.css";
import {
  Header,
  HeaderDiv,
  MyPatientsSubDiv,
  NeedHelpDiv,
  PatientProfileMessage,
  QuickLinksDiv,
  RightMenuDiv,
  Search,
  SearchIconWrapper,
  SearchPatientDiv,
  StyledCardContent,
  StyledInputBase,
} from "./myPatients.style";
import { NoPatients } from "./noPatients/noPatients.component";
import { defaultNoPatientData } from "./noPatients/noPatients.model";
import {
  IAlertTypes,
  IMenuAction,
  IPatient,
  IPatientAlert,
  ISeverityTypes,
  IStopSaringOrder,
  IUpdatePopupFlagReqBody,
  UpdatesPopupScreenTypes,
} from "./patient.interface";
import { CancelPatientOrder } from "./patientActions/cancelPatientOrder/cancelPatientOrder.component";
import { HoldTherapy } from "./patientActions/holdOrResumeTherapy/holdTherapy/holdTherapy.component";
import { WoundDetails } from "./patientActions/holdOrResumeTherapy/main/holdOrResumeTherapy.interface";
import { ResumeTherapy } from "./patientActions/holdOrResumeTherapy/resumeTherapy/resumeTherapy.component";
import { SuccessPopUp } from "./patientActions/holdOrResumeTherapy/successPopUp/successPopUp.component";
import { PatientActions } from "./patientActions/patientActions.component";
import StopSharingOrder from "./patientActions/stopSharingOrder/stopSharingOrder.component";
import PatientAlerts from "./patientAlerts/patientAlerts.component";
import {
  NavigatePatientAction,
  getTherapyStartDate,
  getWoundList,
} from "./patientAndTherapyDetails/orderOverview/navigatePatientAction";
import { acceptedOrderOverviewStatus } from "./patientAndTherapyDetails/orderOverview/orderOverviewContainer.enum";
import {
  MyPatientModalSection,
  PatientOrdersDetails,
} from "./patientOrdersDetails/patientOrdersDetails.component";
import { GetPatientLockedData } from "./savedPatientLockDetails";
import { FilterType } from "./myPatientOrderFilter/myPatientOrderFilter.interface";
import {
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  SELECTED_PATIENT_DATA_FROM_MPD_IS_NULL,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
  USER_FACILITY_WO_FACILITY_DONOT_MATCH,
  USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE,
} from "../../util/errorCode";
import { OrdersMenuBar } from "./ordersSideBar/ordersMenuBar.component";
import { ICustomerPlusListingDetails } from "../inventory/customerPlusInventory/customerPlusInventory.interface";
import { getCustomerPlusPatientInfo } from "../../util/actueService";
import { InventoryPopUpContainer } from "../inventory/popUpContainer/inventoryPopUpContainer.component";
import {
  InventoryPopUp,
  InventoryPopUpFlow,
} from "../inventory/popUpContainer/inventoryPopUpContainer.enum";
import { CustomerPlusMenuActionTypes } from "../inventory/inventoryTabContainer.enum";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../context/InpatientOrderContext";
import {
  AcuteOrderPickUpRequestContextType,
  AcuteOrderPickUpRequestContext,
} from "../../context/AcutePickUpRequestContext";
import {
  CreateServiceRequestContext,
  CreateServiceRequestContextType,
} from "../../context/CreateServiceRequestContext";
import { ExpressButton } from "../../core/expressButton/expressButton.component";
import {
  IFacilitysupplyOrdersContextType,
  FaciliitySupplyOrdersContext,
} from "../../context/FacilitySupplyOrdersContext";
import { defaultSalesAssistanceData } from "./salesAssisstanceNeedHelp/salesAssisstanceNeedHelp.model";

//mpd csv header options
const MyPatientsCSVColumns: string[] = [
  "RO Number",
  "Patient Last Name",
  "Patient First Name",
  "Patient DOB",
  "Patient Location",
  "Patient ID",
  "Alert Description(s)",
  "VAC Order Status",
  "Product Name",
  "Serial Number",
  "Care Type",
  "Facility Name",
  "VAC Order Creation Date",
];

type Props = {
  isTesting?: boolean;
};

export const MyPatients = ({ isTesting = false }: Props) => {
  const [noPatientErrorPopUpFlag, setNoPatientErrorPopUpFlag] = useState(false);
  const [noPatientErrorMessage, setNoPatientErrorMessage] = useState("");
  const [noPatientLoader, setNoPatientLoader] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const supplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [holdOrResumeErrorPopUp, setHoldOrResumeErrorPopUp] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorCode, setErrorCode] = useState("");
  const [errorConfirmPlacement, setErrorConfirmPlacement] = useState(false);
  const [alertsRequest, setAlertsRequest] = useState<IAlertsRequest>();
  const [updatedAlertData, setUpdatedAlertData] = useState<Array<IPatient>>([]);
  const [, setSelectedPatientAction] = useState("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const [emptyPopup, setEmptyPopup] = useState<boolean>(false);
  const [searchingPatient, setSearchingPatient] = useState<boolean>(false);
  const [patients, setPatients] = React.useState<Array<IPatient>>();
  const [removePatientOpen, setRemovePatientOpen] = React.useState(false);
  const [hubStarted, setHubStarted] = React.useState(false);
  const [removePatientMsg, setRemovePatientMsg] = useState("");
  const [alertType, setAlertType] = useState<IAlertTypes>();
  const [patientActionAlertPopUp, setPatientActionAlertPopUp] = useState(false);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [isHoldTherapy, setIsHoldTherapy] = useState<boolean>(true);
  const [wounds, setWounds] = useState<[WoundDetails] | undefined>(undefined);
  const [therapyStartDate, setTherapyStartDate] = useState<Date | undefined>(
    undefined
  );
  const [showErrorPopUp, setShowErrorPopUp] = useState<boolean>(false);
  const [showWarningPoppUp, setShowWarningPoppUp] = useState<boolean>(false);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState<boolean>(false);
  const [isPatientCancelOrder, setIsPatientCancelOrder] =
    useState<boolean>(false);
  const [cancelSharedOrder, setCancelSharedOrder] =
    useState<IStopSaringOrder | null>(null);
  const sendNoteObj = useContext<SendNoteContextType | null>(SendNoteContext);
  const [menuTappedPatient, setMenuTappedPatient] =
    React.useState<IPatient | null>();

  const confirmPlacementObj = useContext<ConfirmPlacementContextType | null>(
    ConfirmPlacementContext
  );
  const dischargeRequestObj = useContext<DischargeRequestContextType | null>(
    DischargeRequestContext
  );
  const myPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const pickUpRequestObj = useContext<PickUpRequestContextType | null>(
    PickUpRequestContext
  );
  const acuteOrderPickUpRequestObj =
    useContext<AcuteOrderPickUpRequestContextType | null>(
      AcuteOrderPickUpRequestContext
    );
  const submitProofOfDeliveryObj =
    useContext<SubmitProofOfDeliveryContextType | null>(
      SubmitProofOfDeliveryContext
    );
  const createServiceRequestObj =
    useContext<CreateServiceRequestContextType | null>(
      CreateServiceRequestContext
    );
  const facilitySupplyOrderContextObj =
    useContext<IFacilitysupplyOrdersContextType | null>(
      FaciliitySupplyOrdersContext
    );
  const [noPatientErrorMsg, setNoPatientErrorMsg] = useState<boolean>(false);
  const [isMyPatientFlow, setIsMyPatientFlow] = useState<boolean>(false);
  const [vacOrderID, setVacOrderID] = useState("");
  const [selectedPatientChangePres, setSelectedPatientChangePres] =
    useState<IPatient | null>();
  const [originalData, setOriginalData] = React.useState<Array<IPatient>>();

  const AuthDetails = sessionStorage.getItem("okta-token-storage");
  let loggedInEmail = "";
  if (AuthDetails) {
    const data = JSON.parse(AuthDetails ?? "");
    loggedInEmail = data.idToken?.claims?.email;
  }

  // For need help
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const isLoggedIn = authObj?.isLoggedIn ? authObj?.isLoggedIn : false;
  const userRole = authObj?.userRolePermissionData?.userRole;
  const [deleteOrderPopUpFlag, setDeleteOrderPopUpFlag] = useState(false);
  const [deleteOrderLoadSpinner, setDeleteOrderLoadSpinner] = useState(false);
  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const exchangeVacUnitObj = useContext<ExchangeVacUnitContextType | null>(
    ExchangeVacUnitContext
  );
  const [menuSelectedPatient, setMenuSelectedPatient] = useState<IPatient>();

  // Remove Patient
  const [showLoader, setShowLoader] = useState<boolean>(false);

  // Popper
  let openEL = Boolean(anchorEl);
  const id = openEL ? "simple-popper" : undefined;

  // useMediaQuery
  const isIPadScreen = useMediaQuery("(max-width:1024px)");
  const isBiggerMobileScreen = useMediaQuery("(max-width:768px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isSmallerMobileScreen = useMediaQuery("(max-width:414px)");
  const isStandardMobileScreen = useMediaQuery("(max-width:926px)");

  // Dropdown Filter
  const [statusOptions, setStatusOptions] = useState<
    DropDownMultiCheckboxValue[]
  >([]);
  const [allSelectCount, setAllSelectCount] = useState<number>(0);
  const [dropdownOptions, setDropdownOption] = useState<
    DropDownMultiCheckboxValue[]
  >([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<string[]>(
    []
  );
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [needToApplyFilter, setNeedToApplyFilter] = useState<boolean>(false);
  const filterData = myPatientObj!.orderFilterData;
  const setOrderFilterData = myPatientObj!.setOrderFilterData;

  // Has Alert
  const [hasAlertSelected, setHasAlertSelected] = useState<boolean>(false);

  //CSV options
  const [csvErrorPopup, setCsvErrorPopup] = useState<boolean>(false);
  const [csvErrorCode, setCsvErrorCode] = useState<string>("");

  //cms Feautures Popup states
  const [cmsLoader, setCmsLoader] = useState<boolean>(false);
  const [openFlag, setOpenFlag] = useState<boolean>(false);
  const guidancePopupFlag = authObj?.userProfile?.guidancePopup;

  //order type filter
  const [orderTypesOptions, setOrderTypesOptions] = useState<
    DropDownMultiCheckboxValue[]
  >([]);
  const [orderTypesFilterOptions, setOrderTypesFilterOptions] = useState<
    DropDownMultiCheckboxValue[]
  >([]);
  const [allSelectOrderTypesCount, setAllSelectOrderTypesCount] =
    useState<number>(0);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState<string[]>([]);
  const [selectedFilteredOrderTypes, setSelectedFilteredOrderTypes] = useState<
    string[]
  >([]);
  const [openContainerPopupFlag, setOpenContainerPopupFlag] = useState(false);
  const [popUpFlow, setPopUpFlow] = useState<InventoryPopUpFlow>();
  const [selectedProductDetails, setSelectedProductDetails] =
    useState<ICustomerPlusListingDetails | null>(null);
  const [currentPopUp, setCurrentPopUp] = useState<InventoryPopUp>(
    InventoryPopUp.LOADER
  );
  const [orderSourceCode, setOrderSourceCode] = useState<string[] | null>(null);

  const inpatientOrderObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );
  const roles = permissionObj && permissionObj.mappedRolesPermissionData;
  const isPostAcuteCheck =
    authObj && authObj?.registeredFaciltyAddress?.isPostAcute;
  const isShowSupplyOrderStatusData =
    roles &&
    (roles.IsShowSupplyOrderButton ||
      (isPostAcuteCheck
        ? false
        : roles.IsShowInPatientSupplyOrderButton ||
          roles.IsShowInPatientStockSupplyOrderButton));

  const fetchOrderSourceCapsuleType = async () => {
    const data = await getdropDownContent(CMS_ORDER_SOURCE_CONTENT);
    if (data.items.length > 0) {
      const orderSourceObject = data.items.filter(
        (item: { name: string }) => item.name === CMS_ORDER_SOURCE_CONTENT
      );
      const orderSourceData = orderSourceObject[0].data.sort(
        (a: { order: number }, b: { order: number }) =>
          a.order > b.order ? 1 : -1
      );
      setOrderSourceCode(orderSourceData);
    }
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_DISPLAY_ORDER_STATUS,
        DD_DISPLAY_ORDER_TYPES ?? ""
      );
      const data = await getMultiCheckDropdownContent(ddContent);
      if (data.items.length > 0) {
        const statusTypeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_DISPLAY_ORDER_STATUS
        );
        const statusTypeData = statusTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        const orderTypeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_DISPLAY_ORDER_TYPES
        );
        const orderTypeData = orderTypeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setStatusOptions(statusTypeData);
        setOrderTypesOptions(orderTypeData);
        updateDropdownOptions(
          statusTypeData,
          filterData.selectedFilters,
          orderTypeData,
          filterData.orderTypeFilters,
          true
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const myPatientAnalytics = (eventName: string) => {
    let analyticsData: IAnalyticsData = {
      page_type: "react",
      view_name: "MyPatientComponent",
      event_type: "click",
      event_name: eventName,
      tealium_event: "My_Patient_Dashboard",
      mmmex_userrecordid: authObj?.userProfile?.userID!,
      mmmex_facilityid: authObj?.registeredFaciltyAddress?.siteUseId!,
      mmmex_roleid: permissionObj?.mappedRolesPermissionData?.roleName!,
      mmmex_pagename: "My Patients",
    };
    sendAnalyticsData(analyticsData);
  };

  const handleOpen = () => {
    myPatientAnalytics("Add_Patient");
    setOpen(true);
  };

  const negotiateSignalR = async () => {
    if (myPatientObj?.hubConnection !== undefined) {
      // Stop the existing hub connection if exist
      myPatientObj?.hubConnection.stop();
    }
    let connection = await negotiate();
    if (connection) {
      myPatientObj?.setHubConnection(connection);
      connection
        .start()
        .then(function () {
          setHubStarted(true);
          return connection;
        })
        .catch(function (err: any) {
          console.log(err.toString());
        });
      connection.on("end", () => {
        console.log("connection ended");
      });
      connection?.onclose((err) => {
        console.log("connection closed", err);
      });
      connection.on("NotifyAlerts", (notifyResp) => {
        console.log("Notify Response(all alerts)", notifyResp);
        parseAlertResponse(notifyResp);
      });
    }
  };

  const parseAlertResponse = async (notifyResp: string) => {
    const data = JSON.parse(notifyResp);
    let patientAlerts: Array<IPatientAlert> = [];
    if (data.MissinRxAlert !== null) {
      patientAlerts.push(createAlert(IAlertTypes.MISRX));
    }
    if (data.MissingDocumentAlert !== null) {
      patientAlerts.push(
        getMissingDocsAlert(IAlertTypes.MSDOC, "", data.MissingDocumentAlert)
      );
    }
    if (data.DischargePendingAlert != null) {
      patientAlerts.push(createAlert(IAlertTypes.DISPEN));
    }
    if (data.ConfirmPlacementAlert != null) {
      patientAlerts.push(createAlert(IAlertTypes.CONPL));
    }
    if (
      data.MeasurementDueAlert != null &&
      permissionObj?.mappedRolesPermissionData.IsShowAddWoundAssessmentMenu
    ) {
      await createMeasurementDueAlert(
        data.MeasurementDueAlert.Wounds,
        data.MeasurementDueAlert.alertId,
        patientAlerts
      );
    }
    if (data.ProofOfDeliveryAlert != null) {
      patientAlerts.push(createAlert(IAlertTypes.PODEL));
    }
    if (
      data.SupplyOrderAlert != null &&
      permissionObj?.mappedRolesPermissionData.IsShowSupplyOrderButton
    ) {
      const supplyOrderAlert = data.SupplyOrderAlert
        .SupplyOrderData as Array<ISuppyOrderAlertData>;
      supplyOrderAlert.forEach((x) => {
        if (x.Type === "SHIPPED") {
          patientAlerts.push(
            getSupplyOrderDeliveredAlert(
              IAlertTypes.SUPSH,
              x.ROPN,
              new Date(x.shippedOn),
              x.TrackingNumber!
            )
          );
        } else if (x.Type === "ORDERED") {
          if (
            Array.isArray(x.DisplayText?.split("-")) &&
            x.DisplayText?.split("-").length === 2 &&
            !x.DisplayText?.toLowerCase().includes("excessive supply")
          ) {
            patientAlerts.push(
              getSupplyOrderPendingAlert(
                IAlertTypes.PNDSO,
                x.DisplayText,
                x.ROPN
              )
            );
          } else if (
            x.DisplayText?.split("-")[0] &&
            !x.DisplayText?.toLowerCase().includes("excessive supply")
          ) {
            patientAlerts.push(
              getSupplyOrderPendingAlert(
                IAlertTypes.PNDSO,
                x.DisplayText?.split("-")[0],
                x.ROPN
              )
            );
          } else {
            patientAlerts.push(
              getSupplyOrderPendingAlert(
                IAlertTypes.PNDSO,
                x.DisplayText?.split("Call")[0].trim(),
                x.ROPN
              )
            );
          }
        }
      });
    }
    if (
      data.SharedOrderAlert != null &&
      (loggedInEmail.toLowerCase() ===
        data.SharedOrderAlert.From.toLowerCase() ||
        loggedInEmail.toLowerCase() === data.SharedOrderAlert.To.toLowerCase())
    ) {
      patientAlerts.push(
        createAlert(
          IAlertTypes.SHODR,
          loggedInEmail.toLowerCase() !==
            data.SharedOrderAlert.From.toLowerCase()
            ? `From ${makeCapitalEachWordInString(
                data.SharedOrderAlert.FromRecipientName.toLowerCase()
              )}`
            : `To ${makeCapitalEachWordInString(
                data.SharedOrderAlert.ToRecipientName.toLowerCase()
              )}`,
          null,
          data.SharedOrderAlert
        )
      );
    }

    let patient: IPatient = {
      roNumber: Number(data.RON),
      orderID: data.OrderId,
      alerts: patientAlerts,
      firstName: "",
      lastName: "",
      dob: "",
      facilityName: "",
      isAcuteOrder:
        data.type && data.type.toLowerCase() === "customerplus"
          ? true
          : data.isAcuteOrder,
      orderCreationDate: "",
      patientId: null,
      patientLocation: null,
      status: data.status,
      peelAndPlace: data.PeelAndPlace,
    };
    setUpdatedAlertData((updatedAlertData) => [...updatedAlertData, patient]);
  };

  const initiateGetAlerts = async (reqParams: IAlertsRequest) => {
    try {
      if (
        reqParams &&
        reqParams.orders &&
        reqParams.orders.length > 0 &&
        reqParams.siteUseId !== ""
      ) {
        setUpdatedAlertData([]);
        await getAlerts(reqParams);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const updatePatientAlert = (patientsArr: IPatient[]): IPatient[] => {
    return patientsArr.map((patient) => {
      patient.statusColor = patient.color + "Circle";
      patient.orderCreationDate = patient.orderCreationDate.substring(0, 10);
      patient.isAcuteOrder =
        patient.type?.toLowerCase() === "customerplus"
          ? true
          : patient.isAcuteOrder;
      patient.alerts = [];
      if (patient.isAcuteOrderFailed || patient.isSubmitWoundFailed) {
        const alertIndicatorData: IPatientAlert = {
          alertID: "",
          alertName: patient.isSubmitWoundFailed
            ? "Incomplete Wound Details"
            : "Incomplete Order",
          alertSubLabel: patient.isSubmitWoundFailed
            ? "Resubmit Wound To Update"
            : "Resubmit To Update",
          alertDate: null,
          severity: null,
          trackingNumber: "",
          alertType: IAlertTypes.APIERROR,
        };
        patient.alerts.push(alertIndicatorData);
      }
      if (
        patient.isAcuteOrder &&
        patient.type?.toLowerCase() === "customerplus" &&
        patient.menuActions &&
        patient.menuActions.length > 0
      ) {
        patient.menuActions = patient.menuActions
          .map((x) => {
            let menuItemPermission = getAcuteMenuActionPermission(
              x.text,
              permissionObj!.mappedRolesPermissionData
            );
            if (menuItemPermission) {
              return x;
            } else return null;
          })
          .filter((x) => x !== null) as IMenuAction[];
      }
      return patient;
    });
  };
  const loadPatientData = async (
    searchParam: string,
    status: string[],
    orderTypeStatus: string[]
  ) => {
    if (isTesting) return;
    setSearchedInput(searchParam);
    if (
      myPatientObj &&
      !myPatientObj.reloadMyPatient &&
      originalData &&
      originalData.length > 0
    ) {
      doLocalSearchForPatient(searchParam, status, orderTypeStatus);
    } else {
      if (
        authObj &&
        authObj.userProfile &&
        authObj.userProfile.userName &&
        authObj.userProfile.userName !== "" &&
        authObj.registeredFaciltyAddress &&
        authObj.registeredFaciltyAddress.siteUseId &&
        authObj.registeredFaciltyAddress.siteUseId !== ""
      ) {
        setSearchingPatient(true);
        myPatientObj?.setIsPatientDataLoaded(false);
        const customerMasterNumber = await getCustomerFacilityAccountNumber(
          getSiteUseId(authObj)
        );
        authObj.setCustomerMasterNumber(customerMasterNumber);
        const masterNumber =
          customerMasterNumber === "" ? null : customerMasterNumber;
        const response = await getFaciityPatients(
          getSiteUseId(authObj),
          getCareGiverId(authObj),
          authObj.registeredFaciltyAddress.careGiverSiteUseId,
          authObj.userProfile.userName,
          false,
          masterNumber,
          authObj.registeredFaciltyAddress.accountNumber!,
          authObj.registeredFaciltyAddress.isPostAcute ?? true
        );
        if (!response || !response.succeeded) {
          setNoPatientErrorMsg(true);
          setErrorCode(response?.error?.code || response?.status || "0000");
        } else {
          if (response.data !== null) {
            let patientData = response.data as Array<IPatient>;
            if (patientData.length > 0) {
              if (orderSourceCode === null) {
                await fetchOrderSourceCapsuleType();
              }
              patientData = updatePatientAlert(patientData);
              const alertsRequests: IAlertsRequest = {
                orders: filterPostAcuteAlertsRequest(patientData),
                siteUseId: getSiteUseId(authObj),
                caregiverSiteUseId: getcaregiverSiteUseId(authObj),
              };
              setAlertsRequest(alertsRequests);
              setOriginalData(patientData);
              if (
                searchParam !== "" ||
                status.length > 0 ||
                orderTypeStatus.length > 0
              ) {
                doLocalSearchForPatient(
                  searchParam,
                  status,
                  orderTypeStatus,
                  patientData
                );
              } else {
                setPatients(patientData);
              }
            } else {
              setPatients([]);
              setOriginalData([]);
            }
          } else {
            setPatients([]);
            setOriginalData([]);
          }
        }
      } else {
        if (!authObj || (authObj && !authObj.userProfile)) {
          setErrorCode(USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE);
        } else if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
          setErrorCode(REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE);
        } else if (
          !authObj ||
          (authObj && !authObj.registeredFaciltyAddress?.siteUseId) ||
          (authObj && authObj.registeredFaciltyAddress?.siteUseId === "")
        ) {
          setErrorCode(SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE);
        }
        setNoPatientErrorMsg(true);
      }
      setSearchingPatient(false);
      myPatientObj?.setIsPatientDataLoaded(true);
      myPatientObj?.setReloadMyPatient(false);
    }
  };

  const doLocalSearchForPatient = (
    searchParam: string,
    searchStatus: string[],
    orderTypeSearchStatus: string[],
    data: IPatient[] | undefined = originalData,
    hasAlert: boolean | null = null
  ) => {
    if (isTesting) return;
    const checkForAcute = orderTypeSearchStatus.includes("In-Patient");
    const checkForPostAcute = orderTypeSearchStatus.includes("Home");
    const hasAlerts =
      hasAlert !== null
        ? hasAlert
        : hasAlertSelected &&
          myPatientObj &&
          myPatientObj.orderFilterData.isAlertFilterEnabled &&
          myPatientObj.orderFilterData.isOrderFilterCardShown;
    if (!originalData) {
      setOriginalData(data);
    }
    if (data && data.length > 0) {
      let filteredPatient = data;
      filteredPatient = data.filter(
        (patient: IPatient) =>
          ((searchStatus.length > 0 &&
            patient.status &&
            searchStatus.includes(patient.status!)) ||
            searchStatus.length === 0) &&
          ((searchParam.length > 0 &&
            patient.lastName
              .toLowerCase()
              .includes(searchParam.toLowerCase())) ||
            patient.firstName
              .toLowerCase()
              .includes(searchParam.toLowerCase()) ||
            patient.roNumber.toString().includes(searchParam) ||
            searchParam.length === 0) &&
          ((hasAlerts && patient.alerts.length > 0) || !hasAlerts) &&
          ((checkForAcute && patient.isAcuteOrder) ||
            (checkForPostAcute && !patient.isAcuteOrder) ||
            (!checkForAcute && !checkForPostAcute))
      );
      setPatients(filteredPatient);
    } else if (searchStatus.length === 0) {
      setPatients(originalData);
    }
  };

  const addAlertsToPatients = (
    array: Array<IPatient>,
    alert: IPatient,
    index: any
  ) => {
    if (array && array.length > 0) {
      const index = array.findIndex(
        (patient: IPatient) =>
          patient.roNumber.toString() === alert.roNumber.toString() ||
          (patient.orderID &&
            alert.orderID &&
            patient.orderID === alert.orderID)
      );
      if (index !== -1) {
        const patient = array[index];
        patient.alerts = alert.alerts;
        patient.orderCreationDate = patient.orderCreationDate.substring(0, 10);
        patient.peelAndPlace = alert.peelAndPlace;
      }
    }
  };

  const removePateint = async (value: string) => {
    if (originalData) {
      const index = originalData.findIndex(
        (patient: IPatient) =>
          patient.roNumber.toString() === value ||
          (patient.orderID && patient.orderID === value)
      );
      if (index !== -1) {
        let updatedList: IPatient[] = getDeepClone(originalData);
        updatedList.splice(index, 1);
        await setOriginalData(updatedList);
        setSearchInput("");
        setSearchedInput("");
        if (updatedList.length > 0) {
          doLocalSearchForPatient(
            "",
            filterData.selectedFilters,
            filterData.orderTypeFilters,
            updatedList
          );
        } else {
          setPatients([]);
        }
      }
    }
  };

  const getMenuActions = (patient: any, menuAction: any) => {
    patient!.menuActions =
      patient &&
      patient?.menuActions?.filter((menus: any) => menus.text !== menuAction);
    return patient!.menuActions;
  };

  const handleClick = (event: any) => {
    if (event.currentTarget.id !== "") {
      const patient = patients?.filter(
        (patient: { roNumber: Number }) =>
          patient.roNumber.toString() === event.currentTarget.id
      )[0];
      if (!permissionObj?.mappedRolesPermissionData?.IsShowSupplyOrderButton) {
        patient!.menuActions = getMenuActions(patient, "Order Supplies");
      }
      if (
        !permissionObj?.mappedRolesPermissionData?.IsShowAddWoundAssessmentMenu
      ) {
        patient!.menuActions = getMenuActions(patient, "Add Wound Assessment");
      }
      setMenuTappedPatient(patient);
      setVacOrderID(event.currentTarget.name);
    } else {
      const patient = patients?.filter(
        (patient: IPatient) =>
          patient?.orderID !== null &&
          patient?.orderID!.toString() === event.currentTarget.name
      )[0];
      setVacOrderID(event.currentTarget.name);
      setMenuTappedPatient(patient);
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getOrderDetailsFromMS = async (menuTappedPatient: IPatient) => {
    if (
      menuTappedPatient &&
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      try {
        const reqParams = {
          RentalOrderNumber: menuTappedPatient.roNumber,
          DOB: moment(menuTappedPatient.dob).format("DD-MMM-yyyy"),
          userName: authObj.userProfile.userName,
          siteUseId: authObj.registeredFaciltyAddress.siteUseId,
        };
        const response = await getVACOrderDetails(reqParams);
        if (response.succeeded) {
          const vacOrderSummary: VacOrderSummaryData = response.item;
          return vacOrderSummary;
        }
        return null;
      } catch (error) {
        console.log("error", error);
      }
    } else {
      if (!authObj || (authObj && !authObj.userProfile)) {
        setErrorCode(USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE);
      } else if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        setErrorCode(REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE);
      }
      setShowLoader(false);
      setErrorConfirmPlacement(true);
    }
  };

  const handlePopperClose = async (
    event: React.MouseEvent<HTMLElement>,
    selectedVal: string
  ) => {
    const selectedValue = selectedVal.toLowerCase();
    setSelectedPatientAction(selectedValue);
    myPatientAnalytics(`${selectedVal} PatientAction Button`);
    if (
      (selectedValue === "Order Supplies".toLowerCase() ||
        selectedValue === "Remove Patient".toLowerCase() ||
        selectedValue === "Pickup/Discharge Request".toLowerCase() ||
        selectedValue === "Send a Note".toLowerCase() ||
        selectedValue === "Add Wound Assessment".toLowerCase() ||
        selectedValue === "Upload Documents".toLowerCase() ||
        selectedValue === "Reprint Proof Of Delivery".toLowerCase() ||
        selectedValue === "Exchange V.A.C.® Unit".toLowerCase() ||
        selectedValue === "Create Service Request".toLowerCase()) &&
      menuTappedPatient
    ) {
      if (selectedValue === "Remove Patient".toLowerCase()) {
        setAnchorEl(null);
        setMenuTappedPatient(null);
      }
      if (menuTappedPatient.type?.toLowerCase() === "customerplus") {
        menuTappedPatient.isCustomerPlus = true;
        const customerPlusProduct: ICustomerPlusListingDetails | null =
          await getCustomerPlusPlatientInfo(menuTappedPatient);
        if (customerPlusProduct) {
          menuTappedPatient.customerPlusProduct = customerPlusProduct;
          menuTappedPatient.productName = customerPlusProduct.productName;
          menuTappedPatient.productNameDescription =
            customerPlusProduct.productNameDescription;
        }
      }
      NavigatePatientAction(
        menuTappedPatient,
        selectedValue,
        setRemovePatientMsg,
        setEmptyPopup,
        setRemovePatientOpen,
        supplyOrderObj,
        sendNoteObj,
        orderOverViewObj,
        history,
        location,
        removePateint,
        pickUpRequestObj,
        dischargeRequestObj,
        confirmPlacementObj,
        submitProofOfDeliveryObj,
        exchangeVacUnitObj,
        acuteOrderPickUpRequestObj,
        createServiceRequestObj,
        setShowLoader,
        authObj,
        setAnchorEl,
        setMenuTappedPatient
      );
    } else if (
      selectedValue === "Confirm Placement".toLowerCase() &&
      menuTappedPatient
    ) {
      setAnchorEl(null);
      setShowLoader(true);
      const vacOrderDetails = await getOrderDetailsFromMS(menuTappedPatient);
      if (
        vacOrderDetails?.requestor.siteUseID ===
          authObj?.registeredFaciltyAddress?.siteUseId ||
        vacOrderDetails?.requestor.siteUseID ===
          authObj?.registeredFaciltyAddress?.careGiverSiteUseId
      ) {
        confirmPlacementObj?.resetData();
        confirmPlacementObj?.setPatient(menuTappedPatient);
        history.push({
          pathname: "/home/confirmPlacement",
          state: {
            stateData: menuTappedPatient,
          },
        });
      } else {
        setErrorCode(USER_FACILITY_WO_FACILITY_DONOT_MATCH);
        setShowLoader(false);
        setErrorConfirmPlacement(true);
      }
    } else if (
      selectedValue === "Discharge Request".toLowerCase() &&
      menuTappedPatient
    ) {
      pickUpRequestObj?.resetData();
      dischargeRequestObj?.resetData();
      pickUpRequestObj?.setPickUpRequestPage(
        PickUpRequestPageSection.DISCHARGE_REQUEST_START_FORM
      );
      pickUpRequestObj?.setPatient(menuTappedPatient);
      history.push("/home/dischargeRequest");
      return;
    } else if (
      selectedValue === "Share Order".toLowerCase() &&
      menuTappedPatient
    ) {
      newOrderObj?.setshareOrderAddPopUpSection(SharedOrderModal.SHARE_ORDER);
      setIsMyPatientFlow(true);
      newOrderObj?.setshareOrderPopup(true);
    } else if (
      selectedValue === "Continue Saved Order".toLowerCase() &&
      menuTappedPatient
    ) {
      if (
        permissionObj &&
        permissionObj.mappedRolesPermissionData.IsShowVacOrderButton
      ) {
        newOrderObj?.resetNewOrderForm();
        GetPatientLockedData(
          menuTappedPatient.orderID,
          true,
          myPatientObj,
          history,
          authObj?.registeredFaciltyAddress?.siteUseId
        );
      } else {
        setModalVisible(true);
      }
    } else if (
      selectedValue === "Send Prescription".toLowerCase() &&
      menuTappedPatient
    ) {
      setAlertTypeAndPatientData(IAlertTypes.MISRX, menuTappedPatient);
    } else if (
      (selectedValue === "Hold Therapy".toLowerCase() ||
        selectedValue === "Resume Therapy".toLowerCase()) &&
      menuTappedPatient
    ) {
      setWounds(undefined);
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setIsHoldTherapy(selectedValue === "Hold Therapy".toLowerCase());
      setShowLoader(true);
      const result = await Promise.all([
        getWoundList(
          menuTappedPatient.roNumber,
          menuTappedPatient.dob,
          menuTappedPatient.isAcuteOrder
        ),
        getTherapyStartDate(
          menuTappedPatient,
          authObj?.userProfile?.userName,
          authObj?.registeredFaciltyAddress?.siteUseId
        ),
      ]);
      const woundListResponse = result[0];
      const therapyResponse = result[1];
      if (woundListResponse?.succeeded && therapyResponse.succeeded) {
        setShowLoader(false);
        setWounds(woundListResponse?.updatedWounds);
        setTherapyStartDate(therapyResponse.date);
        setOpenPopUp(true);
      } else if (!woundListResponse?.succeeded) {
        if (woundListResponse.error.errorCode.toString() === "917") {
          setErrorMessage(NO_WOUND_ERROR_MESSAGE_FOR_HOLD_THERAPY);
        } else {
          setErrorMessage(HOLD_OR_RESUME_THERAPY_ERROR_MESSAGE);
        }
        setErrorCode(
          woundListResponse?.error?.errorCode || woundListResponse?.status
        );
        setShowLoader(false);
        setHoldOrResumeErrorPopUp(true);
      } else if (
        !therapyResponse.succeeded &&
        therapyResponse.error.errorCode
      ) {
        setErrorCode(
          therapyResponse?.error?.errorCode || therapyResponse?.status
        );
        setErrorMessage(HOLD_OR_RESUME_THERAPY_ERROR_MESSAGE);
        setShowLoader(false);
        setHoldOrResumeErrorPopUp(true);
      }
    } else if (
      (selectedValue === "Stop Sharing Order".toLowerCase() ||
        selectedValue === "Cancel Sharing".toLowerCase()) &&
      menuTappedPatient
    ) {
      setCancelSharedOrder({
        stopSharingOrder: true,
        orderID: menuTappedPatient.orderID
          ? menuTappedPatient.orderID?.toString()
          : null,
        sharedBy: menuTappedPatient?.sharedStatus
          ? menuTappedPatient?.sharedStatus
          : null,
      });
    } else if (
      selectedValue === "Delete Saved Order".toLowerCase() &&
      menuTappedPatient
    ) {
      if (
        permissionObj &&
        permissionObj.mappedRolesPermissionData.IsShowVacOrderButton
      ) {
        setDeleteOrderPopUpFlag(true);
      } else {
        setModalVisible(true);
      }
    } else if (
      selectedValue === "Cancel Patient Order".toLowerCase() &&
      menuTappedPatient
    ) {
      setMenuSelectedPatient(menuTappedPatient!);
      setIsPatientCancelOrder(true);
    } else if (
      selectedValue === "Create Transition Order".toLowerCase() &&
      menuTappedPatient
    ) {
      newOrderObj?.resetNewOrderForm();
      history.push({
        pathname: "/orders/newOrder",
        state: {
          ...(menuTappedPatient.type &&
          menuTappedPatient.type.toLowerCase() === "customerplus"
            ? { isTransitionFromCustomerPlus: true }
            : { createTransition: true }),
          roNumber: menuTappedPatient?.roNumber,
          siteUseID: authObj?.registeredFaciltyAddress?.siteUseId,
          userName: authObj?.userProfile?.userName,
          serialNumber: menuTappedPatient?.productSerialNumber,
        },
      });
    } else if (
      selectedValue.toLowerCase() ===
        CustomerPlusMenuActionTypes.END_THERAPHY.toLowerCase() &&
      menuTappedPatient
    ) {
      handleOpenPopupForEndTherapy(menuTappedPatient);
    } else {
      setMenuTappedPatient(null);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const handleOpenPopupForEndTherapy = async (patientData: IPatient) => {
    if (patientData) {
      setOpenContainerPopupFlag(true);
      setPopUpFlow(InventoryPopUpFlow.END_THERAPY);
    } else {
      setNoPatientErrorPopUpFlag(true);
      setNoPatientErrorMessage(SVC_FETCH_CUSTOMER_PLUS_INFO);
      setErrorCode(SELECTED_PATIENT_DATA_FROM_MPD_IS_NULL);
    }
  };

  const handlePopperClosewhenClickOutside = (
    event: MouseEvent | TouchEvent
  ) => {
    setMenuTappedPatient(null);
    setAnchorEl(null);
  };

  const handleCloseRemovePatient = () => {
    setRemovePatientOpen(false);
  };

  const redirectToSupplyOrder = () => {
    myPatientAnalytics("Order_Supplies_MyPatient_Button");
    supplyOrderObj?.setSupplyOrderPage(
      SupplyOrderPageSection.SUPPLYORDER_PATIENT_LIST
    );
    supplyOrderObj?.resetSupplyOrder();
    history.push("/orders/supplyOrderList");
  };

  const handlePatientSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9-']+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const closeAllTherapyPopups = () => {
    setOpenPopUp((x) => !x);
    setTimeout(() => {
      setWounds(undefined);
      setShowErrorPopUp(false);
      setShowWarningPoppUp(false);
      setShowSuccessPopUp(false);
    }, 500);
  };

  const closeTherapyPopUp = () => {
    setWounds(undefined);
    setShowSuccessPopUp(true);
  };

  const showWarningMessage = () => {
    setWounds(undefined);
    setShowWarningPoppUp(true);
  };

  const unLoadStopHubConnection = async () => {
    if (myPatientObj?.hubConnection) {
      myPatientObj?.hubConnection.stop();
    }
  };

  const setAlertTypeAndPatientData = (
    alertType: IAlertTypes,
    selectedPatientData: any
  ) => {
    setSelectedPatientChangePres(selectedPatientData);
    setAlertType(alertType);
    setPatientActionAlertPopUp(true);
  };

  const doNotDeleteHandler = () => {
    setDeleteOrderPopUpFlag(false);
  };

  const deleteHandler = async () => {
    try {
      setDeleteOrderLoadSpinner(true);
      const response = await cancelOrSubmitVacOrder(
        vacOrderID,
        0,
        authObj?.registeredFaciltyAddress?.siteUseId
      );
      setDeleteOrderPopUpFlag(false);
      if (response.succeeded) {
        removePateint(vacOrderID);
        setTimeout(() => {
          setDeleteOrderLoadSpinner(false);
        }, 500);
      } else {
        setDeleteOrderLoadSpinner(false);
        setShowErrorPopUp(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleOnPatientClick = async (rec: IPatient) => {
    myPatientAnalytics("Patient_Click");
    var myPatientObjData: IPatient = {
      firstName: rec.firstName,
      lastName: rec.lastName,
      dob: rec.dob,
      roNumber: rec.roNumber,
      orderCreationDate: rec.orderCreationDate,
      status: rec.status,
      statusColor: statusColor(rec.status),
      orderID: rec.orderID,
      menuActions: rec.menuActions,
      alerts: rec.alerts,
      color: rec.color,
      productName: rec.productName,
      placementDate: rec.placementDate,
      productSerialNumber: rec.productSerialNumber,
      sharedStatus: rec.sharedStatus,
      woundOrderID: rec.woundOrderID,
      facilityName: rec.facilityName,
      emailAddress: rec.emailAddress,
      isAcuteOrder: rec.isAcuteOrder,
      isCustomerPlus:
        rec.type && rec.type.toLowerCase() === "customerplus" ? true : false,
      patientId: null,
      patientLocation: rec.patientLocation,
      type: rec.type,
      isSubmitWoundFailed: rec.isSubmitWoundFailed,
      ropNumber: rec.ropNumber,
    };
    var acceptedStatus: Array<string> = [];
    acceptedStatus = Object.values(acceptedOrderOverviewStatus).map(
      (element: any) => element.toUpperCase()
    );

    if (acceptedStatus.includes(rec.status!.toUpperCase())) {
      if (rec.type && rec.type.toLowerCase() === "customerplus") {
        const customerPlusProduct: ICustomerPlusListingDetails | null =
          await getCustomerPlusPlatientInfo(rec);
        if (customerPlusProduct) {
          myPatientObjData.isAcuteOrder = true;
          myPatientObjData.customerPlusProduct = customerPlusProduct;
          myPatientObjData.productName = customerPlusProduct.productName;
          myPatientObjData.productNameDescription =
            customerPlusProduct.productNameDescription;
          handleNavigationToOrderOverview(myPatientObjData);
        }
      } else {
        handleNavigationToOrderOverview(myPatientObjData);
      }
    } else if (rec.status === "Saved") {
      if (
        permissionObj &&
        permissionObj.mappedRolesPermissionData.IsShowVacOrderButton &&
        !myPatientObjData.isAcuteOrder
      ) {
        newOrderObj?.resetNewOrderForm();
        GetPatientLockedData(
          rec.orderID,
          true,
          myPatientObj,
          history,
          authObj?.registeredFaciltyAddress?.siteUseId
        );
      } else if (
        permissionObj &&
        permissionObj.mappedRolesPermissionData.IsShowInPatientOrderButton &&
        myPatientObjData.isAcuteOrder
      ) {
        inpatientOrderObj?.resetContext();
        inpatientOrderObj?.setIsFromStartNewOrder(true);
        history.push({
          pathname: "/orders/inpatientOrder",
          state: {
            orderID: rec.orderID,
            allProducts: [],
            product: null,
          },
        });
      } else {
        setModalVisible(true);
      }
    } else {
      myPatientObj?.setMyPatientClickModalSection(
        MyPatientModalSection.LOAD_PATIENT
      );
      myPatientObj?.setOpenPatientOrderAndDetail(true);
    }
    myPatientObj?.setPatientOrderStatus(rec.status);
    myPatientObj?.setorderId(rec.orderID);
  };

  const getCustomerPlusPlatientInfo = async (
    patientSelected: IPatient
  ): Promise<ICustomerPlusListingDetails | null> => {
    setShowLoader(true);
    const reqBody = {
      roNumber: patientSelected.roNumber,
      siteUseId: getSiteUseId(authObj),
      userName: authObj?.userProfile?.userName,
      serialNumber: patientSelected.productSerialNumber,
    };
    const response = await getCustomerPlusPatientInfo(reqBody);
    if (response && response.succeeded) {
      setShowLoader(false);
      return response.item;
    } else {
      setShowLoader(false);
      setNoPatientErrorPopUpFlag(true);
      setNoPatientErrorMessage(SVC_FETCH_CUSTOMER_PLUS_INFO);
      setErrorCode(
        response?.error?.code || response?.error?.errorCode || response?.status
      );
      return null;
    }
  };

  const handleNavigationToOrderOverview = (myPatientObjData: IPatient) => {
    orderOverViewObj!.resetData();
    orderOverViewObj!.resetSeletedSupplyOrderData();
    orderOverViewObj!.resetWoundData();
    orderOverViewObj?.setSelectedOrderTab("Orders");
    history.push({
      pathname: "/home/orderOverview",
      state: {
        stateData: myPatientObjData,
      },
    });
  };
  const handleOnClickFilterIcon = () => {
    setOrderFilterData((p) => ({
      ...p,
      isOrderFilterCardShown: true,
    }));
  };

  const handleApplyFilter = () => {
    setOrderFilterData((p) => ({
      ...p,
      selectedFilters: selectedFilterOptions,
      orderTypeFilters: selectedFilteredOrderTypes,
    }));
    loadPatientData(
      debouncedText,
      selectedFilterOptions,
      selectedFilteredOrderTypes
    );
  };

  const handleRemoveFilter = () => {
    setHasAlertSelected(false);
    setSelectedValue(["Select Orders"]);
    doLocalSearchForPatient(debouncedText, [], [], originalData, false);
  };

  const updateDropdownOptions = (
    statusData: DropDownMultiCheckboxValue[],
    selectesOptions: string[],
    orderTypeData: DropDownMultiCheckboxValue[],
    orderTypeOptions: string[],
    doFilter: boolean
  ) => {
    const dropdownOptions = statusData.map(
      (option: DropDownMultiCheckboxValue) => {
        let isSelectedCount = 0;
        if (option.options && option.options.length > 0) {
          option.options.forEach((childOption) => {
            childOption.isSelected =
              selectesOptions.length === 0
                ? option.text.toLowerCase() !== "closed"
                : selectesOptions.includes(childOption.text);
            if (childOption.isSelected) {
              isSelectedCount += 1;
            }
            return childOption;
          });
        }
        option.isSelected =
          selectesOptions.length > 0 &&
          option.options &&
          option.options.length === 0
            ? selectesOptions.includes(option.text)
            : isSelectedCount > 0 &&
              option.options &&
              option.options.length > 0 &&
              option.options.length === isSelectedCount;
        if (
          option.isSelected &&
          option.options &&
          option.options.length > 0 &&
          option.options.length === isSelectedCount
        ) {
          option.isPartialSelected = false;
        }
        return option;
      }
    );
    const allOptions: string[] = dropdownOptions.reduce(
      (acc: string[], curr: DropDownMultiCheckboxValue) => {
        if (curr.options && curr.options.length > 0) {
          curr.options.forEach((option) => {
            acc.push(option.text);
          });
        } else {
          acc.push(curr.text);
        }
        return acc;
      },
      []
    );
    const orderTypeDropdownOptions = orderTypeData.map(
      (option: DropDownMultiCheckboxValue) => {
        option.isSelected =
          (orderTypeOptions.length > 0 &&
            orderTypeOptions.includes(option.text)) ||
          orderTypeOptions.length === 0;
        return option;
      }
    );
    const allOrderTypeOptions: string[] = orderTypeDropdownOptions.reduce(
      (acc: string[], curr: DropDownMultiCheckboxValue) => {
        acc.push(curr.text);
        return acc;
      },
      []
    );

    setAllSelectCount(allOptions.length);
    setAllSelectOrderTypesCount(allOrderTypeOptions.length);
    setDropdownOption(dropdownOptions);
    setOrderTypesFilterOptions(orderTypeDropdownOptions);
    getDropdownSelectedValues(
      dropdownOptions,
      allOptions.length,
      orderTypeDropdownOptions,
      allOrderTypeOptions.length,
      doFilter
    );
  };

  const updateFilterOptions = (
    list: string[],
    stringList: string[],
    count: number,
    type: FilterType
  ) => {
    if (type === FilterType.orderType) {
      if (list.length === count) {
        setSelectedOrderTypes(["All Order Types"]);
      } else if (list.length === 0) {
        setSelectedOrderTypes(["Order Types"]);
      } else setSelectedOrderTypes(stringList);
    } else if (type === FilterType.statusType) {
      if (list.length === count) {
        setSelectedValue(["All Orders"]);
      } else if (list.length === 0) {
        setSelectedValue(["Select Orders"]);
      } else setSelectedValue(stringList);
    }
  };

  const getDropdownSelectedValues = (
    options: DropDownMultiCheckboxValue[],
    allCount: number,
    orderTypeOptions: DropDownMultiCheckboxValue[],
    orderTypeCount: number,
    doFilter: boolean
  ) => {
    let stringValues: Array<string> = [];
    let selectedFilterValues: Array<string> = new Array<string>();
    let orderTypeStringValues: Array<string> = [];
    let selectedOrderTypeFilterValues: Array<string> = new Array<string>();

    orderTypeOptions.forEach((item) => {
      if (item.isSelected && item.isSelected === true) {
        orderTypeStringValues.push(item.text);
        selectedOrderTypeFilterValues.push(item.text);
      }
    });
    options.forEach((item) => {
      if (item.options && item.options.length > 0) {
        let selectedChildOptionsCount = 0;
        item.options.forEach((option) => {
          if (option.isSelected && option.isSelected === true) {
            stringValues.push(option.text);
            selectedFilterValues.push(option.text);
            selectedChildOptionsCount += 1;
          }
        });
        if (item.options.length === selectedChildOptionsCount) {
          const totalCount = item.options.length;
          const startIndex = stringValues.length - totalCount;
          const deleteCount = totalCount;
          stringValues.splice(startIndex, deleteCount, item.text);
        }
      } else {
        if (item.isSelected && item.isSelected === true) {
          stringValues.push(item.text);
          selectedFilterValues.push(item.text);
        }
      }
    });
    updateFilterOptions(
      selectedOrderTypeFilterValues,
      orderTypeStringValues,
      orderTypeCount,
      FilterType.orderType
    );
    //2.Status Type Dropdown
    updateFilterOptions(
      selectedFilterValues,
      stringValues,
      allCount,
      FilterType.statusType
    );
    setSelectedFilterOptions(selectedFilterValues);
    setSelectedFilteredOrderTypes(selectedOrderTypeFilterValues);
    setOrderFilterData({
      ...filterData,
      selectedFilters: selectedFilterValues,
      orderTypeFilters: selectedOrderTypeFilterValues,
    });
    // If patient data present then apply fliter
    // Else set needToApplyFilter as true and wait for patient data in useEffect.
    // This logic will apply only for fresh page load.
    if (originalData && originalData.length > 0 && doFilter) {
      doLocalSearchForPatient(
        debouncedText,
        selectedFilterValues,
        selectedOrderTypeFilterValues,
        originalData
      );
    } else {
      if (!searchingPatient) {
        setNeedToApplyFilter(true);
      }
    }
  };

  const checkAndEnableHasAlerts = () => {
    if (
      updatedAlertData.length > 0 &&
      originalData &&
      updatedAlertData.length === getShownPostAcutePatientsCount()
    ) {
      const isOrderFilterCardShown =
        myPatientObj!.orderFilterData.isOrderFilterCardShown;
      setOrderFilterData((p) => ({
        ...p,
        isOrderFilterCardShown: isOrderFilterCardShown,
        isAlertFilterEnabled: true,
      }));
      updatedAlertData.forEach((x, index) => {
        if (originalData && originalData.length > 0) {
          addAlertsToPatients(originalData, x, index);
        }
      });
      loadPatientData(
        debouncedText,
        filterData.selectedFilters,
        filterData.orderTypeFilters
      );
    } else {
      if (myPatientObj && myPatientObj.orderFilterData.isAlertFilterEnabled) {
        const isOrderFilterCardShown =
          myPatientObj!.orderFilterData.isOrderFilterCardShown;
        setOrderFilterData((data: any) => ({
          ...data,
          isOrderFilterCardShown: isOrderFilterCardShown,
          isAlertFilterEnabled: false,
        }));
      }
    }
  };

  const getFacilityAdminDetailsAPICall = async () => {
    if (
      authObj &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.userProfile &&
      authObj.userProfile.userName
    ) {
      setNoPatientLoader(true);
      const request = {
        siteUseId: authObj.registeredFaciltyAddress.siteUseId,
        userName: authObj.userProfile.userName,
      };

      const response = await getFacilityAdminDetails(request);
      if (response.succeeded) {
        if (response.data) {
          myPatientObj?.setFacilityAdminData({
            facilityAdminFirstName: response.data.firstName,
            facilityAdminLastName: response.data.lastName,
            facilityAdminEmail: response.data.emailAddress,
            facilityAdminPhone: response.data.phoneNo,
            siteUseId: authObj.registeredFaciltyAddress.siteUseId,
          });
        } else {
          let facilityAdminData = getDeepClone(defaultNoPatientData);
          facilityAdminData.siteUseId =
            authObj.registeredFaciltyAddress.siteUseId;
          myPatientObj?.setFacilityAdminData(facilityAdminData);
        }
        setNoPatientLoader(false);
      } else {
        setNoPatientLoader(false);
        setNoPatientErrorPopUpFlag(true);
        setNoPatientErrorMessage(ERROR_MSG_FACILITY_ADMIN_DETAILS);
        setErrorCode(response?.error?.code || response?.status);
      }
    }
  };

  const toggleToListView = () => {
    myPatientObj!.setSwitchToListView(true);
  };

  const toggleToGridView = () => {
    myPatientObj!.setSwitchToListView(false);
  };

  const checkForDownloadOption = () => {
    //1.enabling download option when it has alert logic enabled
    //2.enabling when patients length more than 1
    return filterData.isAlertFilterEnabled && patients && patients.length > 0;
  };

  const renderAlertsInStringFormat = (alerts: IPatientAlert[]): string => {
    //takes patients alerts array and return alerts description in single string
    if (alerts.length > 0) {
      const uniqueAlerts: string[] = [];
      alerts.forEach((eachAlert: IPatientAlert) => {
        if (!uniqueAlerts.includes(eachAlert.alertName)) {
          uniqueAlerts.push(eachAlert.alertName);
        }
      });
      return uniqueAlerts.join(",");
    } else return "";
  };

  const handleDownloadMyPatientsCSV = async () => {
    setShowLoader(true);
    //csv patients mapping with required columns in sequence
    //this sequence should be and similar to the columns array sequence
    const csvPatients = patients?.map((eachPatient: IPatient) => {
      return {
        roNumber: eachPatient.roNumber,
        lastName: makeCapitalEachWordInString(eachPatient.lastName),
        firstName: makeCapitalEachWordInString(eachPatient.firstName),
        dob: eachPatient.dob
          ? ` ${moment(eachPatient.dob).format("MM/DD/YYYY")}`
          : "",
        location: eachPatient.patientLocation ?? "",
        id: eachPatient.patientId ?? "",
        alerts: renderAlertsInStringFormat(eachPatient.alerts),
        status: makeCapitalEachWordInString(eachPatient?.status!),
        productName: eachPatient.productName,
        productSerialNumber: eachPatient.productSerialNumber,
        careType: eachPatient.type,
        facilityName: makeCapitalEachWordInString(eachPatient.facilityName),
        orderCreation: eachPatient.orderCreationDate
          ? ` ${moment(eachPatient.orderCreationDate).format("MM/DD/YYYY")}`
          : "",
      };
    });
    const registeredFacilty = authObj?.registeredFaciltyAddress;
    //FileName Format -> Facility Name Facility ID Patients mmddyyyy Export.csv
    const csvFileName: string = `${registeredFacilty?.accountName
      .split(" ")
      .join("_")}_${registeredFacilty?.accountNumber}_Patients_`;
    const reqsBody = {
      Columns: MyPatientsCSVColumns,
      Data: csvPatients,
    };
    const csvDownloadResponse = await getCSVDownload(reqsBody);
    setShowLoader(false);
    if (csvDownloadResponse && csvDownloadResponse.succeeded) {
      const base64String = csvDownloadResponse.item; //item --> base64
      await getCSV(base64String, csvFileName);
    } else {
      setCsvErrorPopup(true);
      setCsvErrorCode(
        csvDownloadResponse.error.errorCode || csvDownloadResponse.status
      );
    }
  };

  const filterPostAcuteAlertsRequest = (
    patients: IPatient[]
  ): Array<IAlertRequest> => {
    let alertsRequests: Array<IAlertRequest> = [];
    patients.forEach((x) => {
      if (
        x.dob !== null &&
        x.dob !== undefined &&
        x.dob !== "" &&
        x.isAcuteOrder !== true
      ) {
        let alertReq: IAlertRequest = {
          rentalOrderNumber: x.roNumber.toString(),
          dob: x.dob,
          orderID: x.orderID!,
        };
        alertsRequests.push(alertReq);
      }
    });
    return alertsRequests;
  };

  const getShownPostAcutePatientsCount = (): number => {
    return originalData
      ? originalData.filter((x) => x.isAcuteOrder !== true).length
      : 0;
  };

  const getKeyForPatient = (patient: IPatient): string => {
    if (patient.roNumber) {
      return patient.roNumber.toString();
    } else if (patient.orderID) {
      return patient.orderID;
    } else {
      return patient.patientId ?? "";
    }
  };

  useEffect(() => {
    if (myPatientObj?.reloadMyPatient) {
      setSearchInput("");
      setSearchedInput("");
      updateDropdownOptions(statusOptions, [], orderTypesOptions, [], false);
      loadPatientData(
        "",
        filterData.selectedFilters,
        filterData.orderTypeFilters
      );
    }
  }, [myPatientObj?.reloadMyPatient]);

  useEffect(() => {
    window.history.replaceState({}, "Used to clear the history state object");
    fetchDropDownContent();
    if (guidancePopupFlag === null) {
      setOpenFlag(true);
      if (!authObj?.featuresCmsContent) getUserGuidence();
    }
  }, []);

  useEffect(() => {
    if (authObj && authObj.userProfile && authObj.registeredFaciltyAddress) {
      setHubStarted(false);
      negotiateSignalR();
      setUpdatedAlertData([]);
      myPatientObj?.setReloadMyPatient(true);
      setSelectedFilterOptions([]);
      setHasAlertSelected(false);
      setOrderFilterData({
        isOrderFilterCardShown: false,
        isAlertFilterEnabled: false,
        selectedFilters: [],
        orderTypeFilters: [],
      });
    }
    // Unmount
    return () => {
      unLoadStopHubConnection();
    };
  }, [JSON.stringify(authObj?.registeredFaciltyAddress)]);

  useEffect(() => {
    const siteUseId = getSiteUseId(authObj);
    if (
      hubStarted &&
      alertsRequest &&
      siteUseId !== "" &&
      alertsRequest.siteUseId === siteUseId
    ) {
      initiateGetAlerts(alertsRequest);
    }
  }, [alertsRequest, hubStarted]);

  useEffect(() => {
    if (updatedAlertData) {
      checkAndEnableHasAlerts();
      if (updatedAlertData.length > 0) {
        updatedAlertData.forEach((x, index) => {
          if (patients && patients.length > 0) {
            addAlertsToPatients(patients, x, index);
            const latestPatients = [...patients];
            setPatients(latestPatients);
            setNeedToApplyFilter(true);
          }
        });
      }
    }
  }, [JSON.stringify(updatedAlertData)]);

  useMemo(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length >= 3
    ) {
      myPatientObj?.setIsPatientDataLoaded(false);
      setSearchedInput(debouncedText);
      if (
        myPatientObj &&
        !myPatientObj.reloadMyPatient &&
        originalData &&
        originalData.length > 0
      ) {
        doLocalSearchForPatient(
          debouncedText,
          filterData.selectedFilters,
          filterData.orderTypeFilters
        );
      }
      myPatientObj?.setIsPatientDataLoaded(true);
    }
  }, [debouncedText, needToApplyFilter]);

  useEffect(() => {
    // needToApplyFilter we are setting in fetch Dropdown content API
    // Once we get pateint data in originalData object we need to apply filter.
    // This condition is only required when dropdown data is ready but patient data is not ready.
    // In this case we need to wait to get both data and then apply filter only for fresh page load.
    setSearchedInput(debouncedText);
    if (
      needToApplyFilter &&
      originalData &&
      originalData.length > 0 &&
      !searchingPatient
    ) {
      doLocalSearchForPatient(
        debouncedText,
        filterData.selectedFilters,
        filterData.orderTypeFilters
      );
      setNeedToApplyFilter(false);
    }
  }, [needToApplyFilter, originalData]);

  useEffect(() => {
    if (
      patients &&
      patients.length === 0 &&
      authObj &&
      myPatientObj &&
      authObj.registeredFaciltyAddress &&
      myPatientObj.facilityAdminData &&
      authObj.registeredFaciltyAddress.siteUseId !==
        myPatientObj.facilityAdminData.siteUseId &&
      !authObj.isInternalUser
    ) {
      getFacilityAdminDetailsAPICall();
    }
  }, [patients]);

  const getUserGuidence = async () => {
    setCmsLoader(true);
    const data = await getCMSContent(CMS_USER_GUIDENCE);
    setCmsLoader(false);
    if (data.item && data && data.succeeded) {
      authObj?.setFeaturesCmsContent(data.item);
    }
  };

  const handleCloseUpdatesPopupFromMPD = async () => {
    if (!cmsLoader) {
      let tempUser = authObj?.userProfile!;
      tempUser = { ...tempUser, guidancePopup: 2 };
      authObj?.setUserProfile(tempUser);
      const reqBody: IUpdatePopupFlagReqBody = {
        username: authObj?.userProfile?.userName!,
        screen: UpdatesPopupScreenTypes.featuresPopupShownInMPD,
      };
      await updateGuidencePopupFlag(reqBody);
      setOpenFlag(false);
    } else {
      setOpenFlag(false);
    }
  };
  const closePopUp = () => {
    setOpenContainerPopupFlag(false);
    setCurrentPopUp(InventoryPopUp.LOADER);
  };
  const handleClosePopUpAction = () => {
    if (currentPopUp === InventoryPopUp.END_THERAPY_SUCCESS) {
      handleSuccessAndCloseAction();
    } else {
      closePopUp();
    }
  };

  const handleSuccessAndCloseAction = () => {
    setSelectedProductDetails(null);
    closePopUp();
    myPatientObj?.setReloadMyPatient(true);
  };

  const LoadSpinner = () => {
    return (
      <div className="myPatientListSpinner">
        <LoadingSpinner />
      </div>
    );
  };

  const handleAlertClick = (selectedPatient: IPatient) => {
    setMenuTappedPatient(selectedPatient);
  };

  const handleNavigation = () => {
    facilitySupplyOrderContextObj!.resetData();
    history.push("/orders/facilitySupplyOrders");
    window.scrollTo(0, 0);
  };
  const handleNavigationToSalesAssistance = () => {
    myPatientObj?.setNeedHelpData(defaultSalesAssistanceData);
    myPatientObj?.setSalesAssistanceUploadDocuments([]);
    history.push("/salesAssistance");
  };
  const LoadTherapy = () => {
    if (wounds && therapyStartDate && !showSuccessPopUp) {
      return isHoldTherapy ? (
        <HoldTherapy
          closePopup={closeTherapyPopUp}
          patient={menuTappedPatient!}
          showWarningPopup={showWarningMessage}
          therapyStartDate={therapyStartDate}
          wounds={wounds}
        />
      ) : (
        <ResumeTherapy
          closePopup={closeTherapyPopUp}
          patient={menuTappedPatient!}
          showWarningPopup={showWarningMessage}
          therapyStartDate={therapyStartDate}
          wounds={wounds}
        />
      );
    } else if (showSuccessPopUp) {
      return isHoldTherapy ? (
        <SuccessPopUp
          title="Success!"
          description1="The hold request has been successfully applied to the patient order."
          description2="If a resumption date was not indicated, you will need to manually resume therapy by selecting “Resume Therapy after Hold” from the patient actions."
          buttonTitle="Done"
          btnAction={() => {
            setOpenPopUp(false);
            setTimeout(() => {
              setShowSuccessPopUp(false);
            }, 500);
          }}
        />
      ) : (
        <SuccessPopUp
          title="Success!"
          description1="The hold has been removed and therapy can resume for the patient indicated."
          description2=""
          buttonTitle="Done"
          btnAction={() => {
            setOpenPopUp(false);
            setTimeout(() => {
              setShowSuccessPopUp(false);
            }, 500);
          }}
        />
      );
    } else if (!wounds && showWarningPoppUp && !showSuccessPopUp) {
      return (
        <SendNoteFailure
          rootClass="warning-pop-up"
          message={
            isHoldTherapy
              ? HOLD_THERAPY_ERROR_MESSAGE
              : RESUME_THERAPY_ERROR_MESSAGE
          }
          backButtonAction={closeAllTherapyPopups}
        />
      );
    }
  };

  const renderPatientActionPopUp = () => {
    switch (alertType) {
      case IAlertTypes.MISRX:
        return (
          <MissingRx
            isSendPrescription={true}
            closePopUpAction={() => setPatientActionAlertPopUp(false)}
            patientData={selectedPatientChangePres!}
          />
        );
      default:
        return <div style={{ width: "200px", height: "56px" }}></div>;
    }
  };

  return (
    <>
      <MyPatientsSubDiv>
        {isMobileScreen ? (
          <div className="order-menu-bar-drop-down">
            <OrdersMenuBar redirectToSupplyOrder={redirectToSupplyOrder} />
          </div>
        ) : (
          ""
        )}
        <StyledCardContent>
          <HeaderDiv>
            <div className="button-div-name-and-addpatient">
              <Header>My Patients</Header>
              <div className="addpatientbutton-div">
                <AddPatientButton
                  isBtnVisible={
                    !permissionObj?.mappedRolesPermissionData.IsBaseRole
                  }
                  onClickHandler={handleOpen}
                />
              </div>
            </div>
          </HeaderDiv>
          {permissionObj &&
          permissionObj?.mappedRolesPermissionData &&
          permissionObj?.mappedRolesPermissionData.IsBaseRole ? (
            <FacilityBaseUserRole />
          ) : (
            <div className="my-patient-data">
              <div className="addpatientBox">
                <SearchPatientDiv>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon style={{ color: "#76767A" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      autoFocus={!isStandardMobileScreen}
                      placeholder="Search Name or RO #"
                      inputProps={{ "aria-label": "search" }}
                      onChange={handlePatientSearch}
                      value={searchInput}
                    />
                  </Search>
                  <div className="mpd-icon-button-filter">
                    <IconButton
                      data-testid="my-patient-filter-icon"
                      disabled={
                        filterData.isOrderFilterCardShown ||
                        searchingPatient ||
                        !originalData ||
                        (originalData && originalData.length === 0)
                      }
                      onClick={handleOnClickFilterIcon}
                    >
                      {filterData.isOrderFilterCardShown ||
                      searchingPatient ||
                      !originalData ||
                      (originalData && originalData.length === 0) ? (
                        <DisabledFilterIcon />
                      ) : (
                        <FilterIcon />
                      )}
                    </IconButton>
                  </div>
                  <div className="mpd-icon-button-download">
                    <IconButton
                      disabled={!checkForDownloadOption()}
                      onClick={handleDownloadMyPatientsCSV}
                    >
                      {checkForDownloadOption() ? (
                        <Download />
                      ) : (
                        <DownloadDisabled />
                      )}
                    </IconButton>
                  </div>
                  {!isMobileScreen ? (
                    <div className="my-patient-grid-list-container">
                      <div
                        className={`listContainer ${
                          myPatientObj?.switchToListView
                            ? `listContainer-enable`
                            : ``
                        }`}
                        onClick={toggleToListView}
                      >
                        {!myPatientObj?.switchToListView ? (
                          <ListView className="icon" />
                        ) : (
                          <ListViewSelected className="icon" />
                        )}
                      </div>
                      <div
                        className={`myPatientGridContainer ${
                          !myPatientObj?.switchToListView
                            ? `myPatientGridContainer-enable`
                            : ``
                        }`}
                        onClick={toggleToGridView}
                      >
                        {!myPatientObj?.switchToListView ? (
                          <GridViewSlected />
                        ) : (
                          <GridView />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="mobileEnabledToggle"
                      onClick={() =>
                        myPatientObj?.setSwitchToListView(
                          (switchToListView) => !switchToListView
                        )
                      }
                    >
                      {myPatientObj?.switchToListView ? (
                        <ListViewSelected />
                      ) : (
                        <GridViewSlected />
                      )}
                    </div>
                  )}
                  <AddPatientContext.Provider
                    value={{
                      closePopup: () => setOpen(false),
                      patientSearchValidator: new Validator(),
                      addPatientToList: () => {},
                    }}
                  >
                    <Popup
                      dialogParentClass="add-patient-popup"
                      openFlag={open}
                      closeHandler={() => setOpen(false)}
                    >
                      <AddPatientContainer
                        defaultPageSection={PageSection.PATIIENT_TYPE}
                      />
                    </Popup>
                  </AddPatientContext.Provider>
                </SearchPatientDiv>
              </div>
              {myPatientObj &&
                myPatientObj.orderFilterData?.isOrderFilterCardShown &&
                !searchingPatient &&
                originalData &&
                originalData.length > 0 && (
                  <MyPatientOrderFilter
                    allSelectCount={allSelectCount}
                    dropdownOptions={dropdownOptions}
                    handleApplyFilter={handleApplyFilter}
                    handleRemoveFilter={handleRemoveFilter}
                    hasAlertSelected={hasAlertSelected}
                    setDropdownOption={setDropdownOption}
                    setHasAlertSelected={setHasAlertSelected}
                    setSelectedFilterOptions={setSelectedFilterOptions}
                    setSelectedValue={setSelectedValue}
                    selectedValue={selectedValue}
                    selectedOrderTypeValue={selectedOrderTypes}
                    selectedFilteredOrderTypes={selectedFilteredOrderTypes}
                    orderTypesOptions={orderTypesOptions}
                    setOrderTypesOptions={setOrderTypesOptions}
                    orderTypesFilterOptions={orderTypesFilterOptions}
                    setSelectedOrderTypes={setSelectedOrderTypes}
                    setSelectedFilteredOrderTypes={
                      setSelectedFilteredOrderTypes
                    }
                    allSelectOrderTypesCount={allSelectOrderTypesCount}
                  />
                )}
              {searchingPatient ? (
                LoadSpinner()
              ) : noPatientErrorMsg ? (
                <p className="no-patient-err-msg">
                  {ERROR_MSG_NO_PATIENTS_MPD}
                  <span>
                    <a
                      className="no-patient-phone-value"
                      data-testid="phone-value"
                      href={`tel:1-800-275-4524 ext. 41858.}`}
                    >
                      1-800-275-4524 ext. 41858.
                    </a>
                  </span>
                  <p className="no-patient-error-code">
                    Error Code: {errorCode}
                  </p>
                </p>
              ) : patients && patients.length === 0 ? (
                authObj && !authObj.isInternalUser ? (
                  <NoPatients
                    setAddPatientPopUp={setOpen}
                    loader={noPatientLoader}
                  />
                ) : (
                  <PatientProfileMessage>
                    {NO_PATIENT_FOUND}
                  </PatientProfileMessage>
                )
              ) : myPatientObj?.switchToListView ? (
                <>
                  {patients && !myPatientObj?.reloadMyPatient ? (
                    <>
                      <div className="patients-list">
                        {patients.map((patient) => {
                          return (
                            <div
                              className="patients-card-container"
                              key={getKeyForPatient(patient)}
                            >
                              <Grid
                                className="patient-details-main-grid-container"
                                container
                              >
                                <Grid
                                  className="patient-details-main-grid-item"
                                  item
                                  xs={12}
                                >
                                  <Grid
                                    className="patient-details-grid-container"
                                    container
                                    onClick={() =>
                                      handleOnPatientClick(patient)
                                    }
                                  >
                                    <Grid
                                      className="patient-details-header-grid-item"
                                      item
                                      xs={isMobileScreen ? 7 : 8.5}
                                    >
                                      <div className="patient-details-name-and-ready-care-div">
                                        <Typography className="patient-name">
                                          {makeCapitalEachWordInString(
                                            `${patient.lastName}, ${patient.firstName} `
                                          )}
                                          <span
                                            className={
                                              isMobileScreen
                                                ? "myPatient-icon-label-span"
                                                : ""
                                            }
                                          >
                                            <Tooltip
                                              arrow
                                              className="order-type-tooltip"
                                              classes={{
                                                tooltip: "order-type-tooltip",
                                                popper:
                                                  "order-type-tooltip-popper",
                                              }}
                                              data-testid="order-type-tooltip"
                                              placement="top"
                                              PopperProps={{
                                                disablePortal: false,
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                              slotProps={{
                                                arrow: {
                                                  style: {
                                                    color: "#fff",
                                                  },
                                                },
                                              }}
                                              title={
                                                <div
                                                  className="order-type-tooltip-content"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  {patient.isAcuteOrder
                                                    ? "In-Patient Order"
                                                    : "Home Order"}
                                                </div>
                                              }
                                            >
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              >
                                                {patient.isAcuteOrder ? (
                                                  <AcuteOrderIcon className="order-type-icon" />
                                                ) : (
                                                  <PostAcuteOrderIcon className="order-type-icon" />
                                                )}
                                              </span>
                                            </Tooltip>
                                            {patient.type &&
                                              patient.type.toLowerCase() ===
                                                "readycare" && (
                                                <span className="patient-is-ready-care">
                                                  Ready Care
                                                </span>
                                              )}
                                            {patient.type &&
                                              patient.type.toLowerCase() ===
                                                "customerplus" && (
                                                <span
                                                  id="id-customer-plus-identification"
                                                  className="is-customer-plus"
                                                >
                                                  Customer Plus
                                                </span>
                                              )}
                                            {patient.peelAndPlace === true && (
                                              <span className="patient-is-vacPeelAndPlace">
                                                VAC Peel and Place
                                              </span>
                                            )}
                                            {patient.type &&
                                              patient.type.toLowerCase() ===
                                                "storedproduct" && (
                                                <span
                                                  id="id-stored-product-identification"
                                                  className="stored-product-capsule"
                                                >
                                                  Stored Product
                                                </span>
                                              )}
                                            {orderSourceCode &&
                                              authObj &&
                                              authObj.isInternalUser &&
                                              patient &&
                                              patient.orderSource && (
                                                <span
                                                  id="id-order-source-capsule-identification"
                                                  className={
                                                    !patient.isAcuteOrder
                                                      ? "order-source-capsule mobile-screen"
                                                      : "order-source-capsule"
                                                  }
                                                >
                                                  {getTextFromCode(
                                                    orderSourceCode,
                                                    patient.orderSource
                                                  )}
                                                </span>
                                              )}
                                          </span>
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid
                                      className="patient-details-header-grid-item"
                                      item
                                      xs={isMobileScreen ? 5 : 3.5}
                                    >
                                      <Circle
                                        className={`statusIconMain ${patient.statusColor}`}
                                      />
                                      <Typography className="patient-status">
                                        {patient.status}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      className="patient-details-grid-item"
                                      item
                                      xs={
                                        isSmallerMobileScreen
                                          ? 6
                                          : isBiggerMobileScreen
                                          ? 5
                                          : isIPadScreen
                                          ? 4
                                          : 3
                                      }
                                    >
                                      <div className="patient-details-div">
                                        <Typography
                                          className={`title ${
                                            isBiggerMobileScreen
                                              ? " for-mobile"
                                              : ""
                                          }`}
                                        >
                                          DOB
                                        </Typography>
                                        <Typography
                                          className={`value ${
                                            isBiggerMobileScreen
                                              ? " for-mobile"
                                              : ""
                                          }`}
                                        >
                                          {patient.dob !== null &&
                                          patient.dob !== ""
                                            ? moment(patient.dob).format("L")
                                            : "--"}
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid
                                      className="patient-details-grid-item"
                                      item
                                      xs={
                                        isSmallerMobileScreen
                                          ? 6
                                          : isBiggerMobileScreen
                                          ? 7
                                          : isIPadScreen
                                          ? 8
                                          : 6
                                      }
                                    >
                                      <div className="patient-details-div">
                                        {!isBiggerMobileScreen && (
                                          <Typography className="title right-side">
                                            Product
                                          </Typography>
                                        )}
                                        <Typography
                                          className={`value right-side${
                                            !isBiggerMobileScreen
                                              ? ""
                                              : " no-title"
                                          }`}
                                        >
                                          {patient.productName &&
                                          patient.productName !== ""
                                            ? `${patient.productName}${
                                                patient.productSerialNumber &&
                                                patient.productSerialNumber !==
                                                  ""
                                                  ? " - " +
                                                    patient.productSerialNumber
                                                  : ""
                                              }`
                                            : "--"}
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid
                                      xs={
                                        isIPadScreen || isBiggerMobileScreen
                                          ? 0
                                          : 3
                                      }
                                    ></Grid>
                                    <Grid
                                      className="patient-details-grid-item"
                                      item
                                      xs={
                                        isSmallerMobileScreen
                                          ? 6
                                          : isBiggerMobileScreen
                                          ? 5
                                          : isIPadScreen
                                          ? 4
                                          : 3
                                      }
                                    >
                                      <div className="patient-details-div">
                                        <Typography
                                          className={`title ${
                                            isBiggerMobileScreen
                                              ? " for-mobile"
                                              : ""
                                          }`}
                                        >
                                          RO#
                                        </Typography>
                                        <Typography
                                          className={`value ${
                                            isBiggerMobileScreen
                                              ? " for-mobile"
                                              : ""
                                          }`}
                                        >
                                          {patient.roNumber > 0
                                            ? patient.roNumber
                                            : "--"}
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid
                                      className="patient-details-grid-item"
                                      item
                                      xs={
                                        isSmallerMobileScreen
                                          ? 6
                                          : isBiggerMobileScreen
                                          ? 7
                                          : isIPadScreen
                                          ? 8
                                          : 6
                                      }
                                    >
                                      <div className="patient-details-div">
                                        {!isBiggerMobileScreen && (
                                          <Typography className="title right-side">
                                            Facility
                                          </Typography>
                                        )}
                                        <Typography
                                          className={`value right-side${
                                            !isBiggerMobileScreen
                                              ? ""
                                              : " no-title"
                                          }`}
                                        >
                                          {patient.facilityName !== ""
                                            ? makeCapitalEachWordInString(
                                                patient.facilityName
                                              )
                                            : "--"}
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid
                                      xs={
                                        isIPadScreen || isBiggerMobileScreen
                                          ? 0
                                          : 3
                                      }
                                    ></Grid>
                                    {patient.isAcuteOrder && (
                                      <>
                                        <Grid
                                          className="patient-details-grid-item"
                                          item
                                          xs={
                                            isSmallerMobileScreen
                                              ? 6
                                              : isBiggerMobileScreen
                                              ? 5
                                              : isIPadScreen
                                              ? 4
                                              : 3
                                          }
                                        >
                                          <div className="patient-details-div">
                                            <Typography className="title">
                                              Location
                                            </Typography>
                                            <Typography className="value">
                                              {patient.patientLocation &&
                                              patient.patientLocation !== ""
                                                ? patient.patientLocation
                                                : "--"}
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid
                                          className="patient-details-grid-item"
                                          item
                                          xs={
                                            isSmallerMobileScreen
                                              ? 6
                                              : isBiggerMobileScreen
                                              ? 7
                                              : isIPadScreen
                                              ? 8
                                              : 6
                                          }
                                        >
                                          <div className="patient-details-div">
                                            {!isBiggerMobileScreen && (
                                              <Typography className="title right-side">
                                                Patient ID
                                              </Typography>
                                            )}
                                            <Typography
                                              className={`value right-side${
                                                !isBiggerMobileScreen
                                                  ? ""
                                                  : " no-title"
                                              }`}
                                            >
                                              {patient.patientId &&
                                              patient.patientId !== ""
                                                ? patient.patientId
                                                : "--"}
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid
                                          xs={
                                            isIPadScreen || isBiggerMobileScreen
                                              ? 0
                                              : 3
                                          }
                                        ></Grid>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>

                                <Grid
                                  className="patient-details-main-grid-item alerts"
                                  item
                                  xs={12}
                                >
                                  {patient.alerts
                                    .filter(
                                      (alert) =>
                                        alert.severity !== undefined &&
                                        alert.severity !== 0
                                    )
                                    .map((alert) => (
                                      <PatientAlerts
                                        alertData={alert}
                                        key={alert.alertID.toString()}
                                        patient={patient}
                                        patientAnalytics={myPatientAnalytics}
                                      />
                                    ))}
                                </Grid>
                              </Grid>
                              {patient.menuActions &&
                                patient.menuActions.length > 0 && (
                                  <div className="patient-menu-actions">
                                    <IconButton
                                      className="patient-menu-actions-icon"
                                      id={patient.roNumber.toString()}
                                      name={
                                        patient.orderID != null
                                          ? patient.orderID!.toString()
                                          : patient.roNumber.toString()
                                      }
                                      onClick={handleClick}
                                    >
                                      <PatientAction />
                                    </IconButton>
                                    <Popper
                                      open={openEL}
                                      anchorEl={anchorEl}
                                      placement={"bottom-end"}
                                      disablePortal={true}
                                      style={
                                        isMobileScreen && patients?.length === 1
                                          ? {
                                              zIndex: 2,
                                              height: 150,
                                              overflow: "scroll",
                                            }
                                          : { zIndex: 2 }
                                      }
                                      modifiers={[
                                        {
                                          name: "flip",
                                          enabled: true,
                                          options: {
                                            altBoundary: true,
                                            rootBoundary: "window",
                                            flipVariations: true,
                                            allowedAutoPlacements: [
                                              "top-end",
                                              "bottom-end",
                                            ],
                                            padding: 0,
                                          },
                                        },
                                        {
                                          name: "preventOverflow",
                                          enabled: true,
                                          options: {
                                            altAxis: true,
                                            altBoundary: true,
                                            rootBoundary: "window",
                                            tether: true,
                                            padding: 0,
                                          },
                                        },
                                      ]}
                                    >
                                      <PatientActions
                                        setSelectedValue={handlePopperClose}
                                        clickedOutside={
                                          handlePopperClosewhenClickOutside
                                        }
                                        menuData={
                                          menuTappedPatient?.menuActions
                                        }
                                      />
                                    </Popper>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <MyPatientTable
                  patients={patients!}
                  handleOnPatientClick={handleOnPatientClick}
                  patientAnalytics={myPatientAnalytics}
                />
              )}
            </div>
          )}
        </StyledCardContent>
      </MyPatientsSubDiv>
      <RightMenuDiv>
        {!isMobileScreen ? (
          <OrdersMenuBar redirectToSupplyOrder={redirectToSupplyOrder} />
        ) : (
          ""
        )}
        <NeedHelpDiv>
          <NeedHelp
            isLoggedIn={isLoggedIn}
            userRole={userRole}
            isFromHelpSupport={false}
          />
        </NeedHelpDiv>
        <div className="quick-link-group">
          {isShowSupplyOrderStatusData &&
            permissionObj?.mappedRolesPermissionData
              .IsShowFacilitySupplyOrders &&
            !authObj?.registeredFaciltyAddress?.isPostAcute && (
              <div className="quick-links-main-div">
                <Typography
                  className="quick-links-title"
                  data-testid="quick-links-title-testId"
                  id="quick-links-title-id"
                >
                  Quick Links
                </Typography>
                <ExpressButton
                  clickHandler={() => handleNavigation()}
                  variant="outlined"
                  parentClass="view-supply-order-status"
                  id="view-supply-order-status-id"
                  testId="view-supply-order-status-testId"
                >
                  View Facility Supply Order Status
                </ExpressButton>
              </div>
            )}
        </div>
        <div className="need-help-sales-assistance-button">
          <ExpressButton
            clickHandler={handleNavigationToSalesAssistance}
            variant="contained"
            parentClass="need-help-sales-assistance"
            id="need-help-sales-assistance-id"
            testId="need-help-sales-assistance-testId"
          >
            Need help?
          </ExpressButton>
        </div>
      </RightMenuDiv>
      <Popup
        closeHandler={() => {
          setEmptyPopup((x) => !x);
        }}
        openFlag={emptyPopup}
      >
        <div className="emptyPopup"></div>
      </Popup>
      <Popup
        closeHandler={() => {
          setDeleteOrderPopUpFlag(false);
        }}
        openFlag={deleteOrderPopUpFlag}
        hideCloseButton={deleteOrderLoadSpinner ? true : false}
      >
        {deleteOrderLoadSpinner ? (
          <div className="deleteorder-spinner">
            <LoadingSpinner />
          </div>
        ) : (
          <DeletePopup
            doNotDeleteHandler={doNotDeleteHandler}
            deleteHandler={deleteHandler}
          />
        )}
      </Popup>
      <Popup
        closeHandler={() => setPatientActionAlertPopUp(false)}
        dialogParentClass="alertPopUp"
        openFlag={patientActionAlertPopUp}
      >
        {renderPatientActionPopUp()}
      </Popup>
      <Popup
        closeHandler={() => {
          setOpenPopUp((x) => !x);
          setTimeout(() => {
            setWounds(undefined);
            setShowErrorPopUp(false);
            setShowWarningPoppUp(false);
            setShowSuccessPopUp(false);
          }, 500);
        }}
        openFlag={openPopUp}
      >
        {LoadTherapy()}
      </Popup>
      <PatientOrdersDetails />
      <SnackBar
        toastStyle="removePatientToast"
        openFlag={removePatientOpen}
        msg={removePatientMsg}
        handleCloseAlert={handleCloseRemovePatient}
        autoClose={removePatientOpen}
      ></SnackBar>
      <ShareOrderInfo
        isMyPatientFlow={isMyPatientFlow}
        vacOrderID={vacOrderID!}
      />
      {cancelSharedOrder?.stopSharingOrder && (
        <StopSharingOrder
          cancelSharedOrderDetail={cancelSharedOrder}
          setCancelSharedOrderDetail={setCancelSharedOrder}
        />
      )}
      <Popup
        closeHandler={() => {
          setIsPatientCancelOrder(false);
        }}
        openFlag={isPatientCancelOrder}
      >
        <CancelPatientOrder
          isComingFromMPD={true}
          isPatientCancelOrder={() => setIsPatientCancelOrder(false)}
          patient={menuSelectedPatient!}
          removePateint={removePateint}
        />
      </Popup>
      <Popup
        hideCloseButton={true}
        openFlag={showLoader}
        closeHandler={() => {}}
      >
        {LoadSpinner()}
      </Popup>
      <ErrorPopup
        popUpStyles="error-popup-design"
        errorMessage={ERROR_CONFIRM_PLACEMENT_SITEID_MATCHED}
        errorPopupFlag={errorConfirmPlacement}
        handleBackButton={() => {
          setErrorConfirmPlacement(false);
        }}
        buttontitle="Okay"
        showTitle={true}
        isShortSentence={true}
        isSupportPhoneRequired={false}
        errorCode={errorCode}
        errorTitle="Confirm Placement"
      />
      <ErrorPopup
        errorPopupFlag={csvErrorPopup}
        handleBackButton={() => setCsvErrorPopup(false)}
        popUpStyles="errorPopup"
        errorMessage={ERROR_MSG_DOWNLOAD_MY_PATIENTS}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={csvErrorCode}
      />
      <Popup
        closeIconClass="closeIcon"
        closeHandler={() => closeModal()}
        openFlag={modalVisible}
        dialogParentClass={"patientDataPopUp"}
      >
        <AdditionalPermissionPopUp closeModal={closeModal} />
      </Popup>
      <ErrorPopup
        errorPopupFlag={noPatientErrorPopUpFlag}
        handleBackButton={() => setNoPatientErrorPopUpFlag(false)}
        popUpStyles="errorPopup"
        errorMessage={noPatientErrorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={false}
        errorCode={errorCode}
      />
      <ErrorPopup
        popUpStyles="error-popup-design"
        errorMessage={errorMessage}
        errorPopupFlag={holdOrResumeErrorPopUp}
        showTitle={false}
        handleBackButton={() => {
          setHoldOrResumeErrorPopUp(false);
        }}
        buttontitle="Done"
        isShortSentence={true}
        isSupportPhoneRequired={errorCode === "917" ? true : false}
        errorCode={errorCode}
      />
      <Popup
        closeHandler={handleCloseUpdatesPopupFromMPD}
        openFlag={openFlag}
        dialogParentClass="add-new-patient-update"
      >
        <FeaturesInfoPopup
          cmsUserGuidence={authObj?.featuresCmsContent!}
          cmsLoader={cmsLoader}
        />
      </Popup>
      <Popup
        openFlag={openContainerPopupFlag}
        dialogParentClass="inventory-pop-up-container"
        closeHandler={handleClosePopUpAction}
        hideCloseButton={currentPopUp === InventoryPopUp.LOADER}
      >
        {popUpFlow && (
          <InventoryPopUpContainer
            closePopUp={handleClosePopUpAction}
            currentPopUp={currentPopUp}
            flow={popUpFlow}
            setCurrentPopUp={setCurrentPopUp}
            successAndClose={handleSuccessAndCloseAction}
            selectedCustomerPlusListingObj={selectedProductDetails!}
            setSelectedProductDetails={setSelectedProductDetails}
            patientData={menuTappedPatient!}
            isMPDFlow={true}
          />
        )}
      </Popup>
    </>
  );
};
