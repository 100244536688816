import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import "./editFacility.css";
import {
  IUserFacilityData,
  IUserPermissionsData,
} from "../userProfile.interface";
import {
  getFacilityPermissionCategoryName,
  getFacilityPermissionName,
  makeCapitalEachWordInString,
} from "../../../../../util/utilityFunctions";
import { IEditFacilityProps } from "./editFacility.interface";
import { categoryOrder } from "../../../../../util/staticText";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CustomCheckBox } from "../../../../../core/checkBox/checkBox.component";
import { ReactComponent as RadioButtonIcon } from "../../../../../assets/radioButton.svg";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import { CustomDropDown } from "../../../../../core/customDropdown/customDropdown.component";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../../assets/selectedRadioButton.svg";

export const EditFacility = ({
  cancelBtnAction,
  facility,
  updateBtnAction,
}: IEditFacilityProps) => {
  const [active, setActive] = useState<boolean | null>(null);
  const [editableFacility, setEditableFacility] =
    useState<IUserFacilityData>(facility);
  const [isPermissionUpdated, setIsPermissionUpdated] = useState<
    boolean | null
  >(null);
  const [isUserUpdated, setIsUserUpdated] = useState<boolean | null>(null);
  const isMobileScreen = useMediaQuery("(max-width:414px)");
  const isSmallMobileScreen = useMediaQuery("(max-width:350px)");
  const previousRole = useMemo(() => {
    return editableFacility.userRole;
  }, []);
  const [prevRole, setPrevRole] = useState<string>(previousRole);

  const initialCheckedState = useMemo(() => {
    return facility.permissions!.reduce(
      (acc: { [key: string]: boolean }, permission: IUserPermissionsData) => {
        const isEnabled =
          (typeof permission.status === "string" &&
            permission.status === "Enabled") ||
          (typeof permission.status === "boolean" && permission.status);
        acc[permission.name] = isEnabled && !permission.isHide;
        return acc;
      },
      {}
    );
  }, []);

  const categories = useMemo(() => {
    const categorizedPermissions = facility.permissions!.reduce(
      (acc: { [key: string]: IUserPermissionsData[] }, item) => {
        if (!item.isHide) {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
        }
        return acc;
      },
      {}
    );
    return categoryOrder.reduce(
      (acc: { [key: string]: IUserPermissionsData[] }, category) => {
        if (categorizedPermissions[category]) {
          acc[category] = categorizedPermissions[category];
        }
        return acc;
      },
      {}
    );
  }, []);

  const [checkedItems, setCheckedItems] = useState<{
    [key: string]: boolean;
  }>(initialCheckedState);

  const [enabledPermissionCount, setEnabledPermissionCount] = useState<number>(
    editableFacility.enabledPermissionsCount ?? 0
  );

  const validateAndSetData = (e: any) => {
    let tempData = editableFacility;
    if (e.target.name === "status") {
      if (e.target.value === "Active") {
        setActive(true);
      } else if (e.target.value === "Inactive") {
        setActive(false);
      } else {
        setActive(null);
      }
      tempData = {
        ...tempData,
        status: e.target.value,
      };
      checkIsUserUpdatedFacility(
        tempData.status.toLowerCase() !== facility.status.toLowerCase(),
        tempData
      );
    } else if (e.target.name === "role") {
      tempData = {
        ...editableFacility,
        userRole: e.target.value,
      };
    }
    setEditableFacility(tempData);
  };

  const updateFacilityPermissionsAndCount = (
    permissions: IUserPermissionsData[],
    count: number
  ) => {
    let tempData = {
      ...editableFacility,
      permissions: permissions,
      enabledPermissionsCount: count,
    };
    setEditableFacility(tempData);
    updateBtnAction(tempData);
  };

  const checkIsUserUpdatedFacility = (
    newValue: boolean,
    tempData: IUserFacilityData
  ) => {
    if (!isUserUpdated) {
      setIsUserUpdated(newValue);
    } else {
      if (
        facility.status.toLowerCase() === tempData.status.toLowerCase() &&
        facility.userRole.toLowerCase() === tempData.userRole.toLowerCase() &&
        !isPermissionUpdated
      ) {
        setIsUserUpdated(false);
      }
    }
  };

  const validateAndUpdateCheckBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setCheckedItems((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    },
    []
  );

  const handleUpdateButtonAction = useCallback(() => {
    setPrevRole(editableFacility.userRole);
    let enabledPermissionCount = 0;
    // Using one loop updating count and returning updated permission status.
    const permissions: IUserPermissionsData[] =
      editableFacility.permissions!.map((permission) => {
        // When checkedItems has permission name, that means checkbox is checked
        // In this case increament enabledPermissionCount by 1
        if (checkedItems[permission.name]) {
          enabledPermissionCount += 1;
        }
        return {
          ...permission,
          status: checkedItems[permission.name],
        };
      });
    updateFacilityPermissionsAndCount(permissions, enabledPermissionCount);
  }, [checkedItems, editableFacility.status, editableFacility.userRole]);

  useEffect(() => {
    if (
      facility.status.toLowerCase() === "active" ||
      facility.status.toLowerCase() === "inactive"
    ) {
      setActive(facility.status.toLowerCase() === "active" ? true : false);
    }
  }, []);

  // Update the isUserUpdated state whenever checkedItems state changes
  useEffect(() => {
    let isUserUpdated: boolean = false;
    let enabledPermissionsCount = 0;
    Object.keys(initialCheckedState).forEach((key) => {
      if (initialCheckedState[key] !== checkedItems[key] && !isUserUpdated) {
        isUserUpdated = true;
      }
      if (checkedItems[key]) {
        enabledPermissionsCount += 1;
      }
    });
    if (
      editableFacility.userRole.toLowerCase() !== prevRole.toLowerCase() &&
      !isUserUpdated
    ) {
      isUserUpdated = true;
    }
    setIsUserUpdated(isUserUpdated);
    setIsPermissionUpdated(isUserUpdated);
    setEnabledPermissionCount(enabledPermissionsCount);
  }, [checkedItems, initialCheckedState, editableFacility.userRole]);

  return (
    <div className="edit-facility-component">
      <p className="edit-facility-header" data-testid="edit-facility-header">
        Facility Site Access
      </p>
      <Grid
        className="facility-details-grid-container"
        container
        data-testid="facility-details"
      >
        <Grid className="facility-details-grid-item" item xs={12}>
          <p className="facility-name" data-testid="facility-name">
            {makeCapitalEachWordInString(facility.accountName)}
          </p>
        </Grid>
        <Grid
          className="facility-details-grid-item"
          item
          xs={isSmallMobileScreen ? 12 : 6}
        >
          <div className="facility-address-div">
            <p
              className="facility-address-value"
              data-testid="facility-address1-value"
            >{`${makeCapitalEachWordInString(editableFacility.address1)}, ${
              editableFacility.address2
                ? makeCapitalEachWordInString(editableFacility.address2)
                : ""
            }`}</p>
            <p
              className="facility-address-value"
              data-testid="facility-address2-value"
            >{`${makeCapitalEachWordInString(editableFacility.city)}, ${
              editableFacility.state
            } ${editableFacility.zip}`}</p>
          </div>
        </Grid>
        <Grid
          className="facility-details-grid-item"
          item
          xs={isSmallMobileScreen ? 12 : 6}
        >
          <div className="facility-siteUseId-div">
            <p
              className="facility-siteUseId-title"
              data-testid="facility-siteUseId-title"
            >
              Facility ID:
            </p>
            <p
              className="facility-siteUseId-value"
              data-testid="facility-siteUseId-value"
            >
              {editableFacility.accountNumber}
            </p>
          </div>
        </Grid>
      </Grid>
      <p
        className="edit-facility-sub-header"
        data-testid="edit-facility-sub-header-status-&-role"
      >
        Status & Role
      </p>
      <Grid
        className="site-access-status-role-grid-container"
        container
        spacing={2}
      >
        <Grid
          className="site-access-status-role-grid-item"
          item
          xs={isSmallMobileScreen ? 12 : 8}
        >
          <InputWithLabel
            label="Site access status"
            isRequired={true}
            testId="site-access-status-title"
          >
            <RadioGroup
              name="status"
              classes={{ root: "radioRoot" }}
              onChange={validateAndSetData}
              value={makeCapitalEachWordInString(editableFacility.status)}
            >
              <FormControlLabel
                classes={{
                  root: active === true ? "optionRoot-active" : "optionRoot",
                }}
                componentsProps={{
                  typography: {
                    classes: {
                      root: active === true ? "optiontxtSelect" : "optiontxt",
                    },
                  },
                }}
                control={
                  <Radio
                    icon={<RadioButtonIcon />}
                    checkedIcon={<SelectedRadioButtonIcon />}
                  />
                }
                data-testid="site-access-status-active"
                label="Active"
                value="Active"
              />
              <FormControlLabel
                classes={{
                  root: active === false ? "optionRoot-active" : "optionRoot",
                }}
                componentsProps={{
                  typography: {
                    classes: {
                      root: active === false ? "optiontxtSelect" : "optiontxt",
                    },
                  },
                }}
                control={
                  <Radio
                    icon={<RadioButtonIcon />}
                    checkedIcon={<SelectedRadioButtonIcon />}
                  />
                }
                data-testid="site-access-status-inactive"
                label="Inactive"
                value="Inactive"
              />
            </RadioGroup>
          </InputWithLabel>
        </Grid>
        <Grid
          className="site-access-status-role-grid-item"
          item
          xs={isSmallMobileScreen ? 12 : 8}
        >
          <InputWithLabel
            label="Role"
            isRequired={true}
            labelClassName="site-access-status-role-title"
            testId="site-access-status-role-title"
            isDropdown={true}
          >
            <CustomDropDown
              disabled={editableFacility.status.toLowerCase() === "inactive"}
              handleChange={validateAndSetData}
              menuItem={["Admin", "Clinician"]}
              name="role"
              selectpropsClassName={`site-access-status-role-select${
                editableFacility.status.toLowerCase() === "inactive"
                  ? " place-holder"
                  : ""
              }`}
              selectClassName={`site-access-status-role-input${
                editableFacility.status.toLowerCase() === "inactive"
                  ? " place-holder"
                  : ""
              }`}
              testId="site-access-status-role"
              value={makeCapitalEachWordInString(editableFacility.userRole)}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
      {editableFacility.permissions && (
        <>
          <p
            className="edit-facility-sub-header"
            data-testid="edit-facility-sub-header"
          >
            Permissions
            <span className="permissions-count">{` (${enabledPermissionCount} of ${editableFacility.availablePermissionsCount})`}</span>
          </p>
          <div className="site-access-permissions">
            <InputWithLabel
              isRequired={true}
              label="Which of the following features should be enabled for this facility?"
              labelClassName="site-access-status-title"
              testId="site-access-permission-title"
            >
              <div className="site-access-permissions-categories">
                {Object.keys(categories).map((category) => (
                  <div
                    className="site-access-permission-category"
                    key={category}
                  >
                    <p className="site-access-permission-category-name">
                      {getFacilityPermissionCategoryName(category)}
                    </p>
                    <Grid
                      className="facility-permissions-grid-container"
                      container
                    >
                      {categories[category].map((permission, index) => (
                        <Grid
                          className="facility-permissions-grid-item"
                          item
                          xs={isMobileScreen ? 12 : 6}
                        >
                          <CustomCheckBox
                            checked={!!checkedItems[permission.name]}
                            handleChange={validateAndUpdateCheckBox}
                            isDisabled={
                              editableFacility.status.toLowerCase() ===
                              "inactive"
                            }
                            key={index}
                            labelClassName={
                              permission.status
                                ? "facility-permissions-checkbox-description-text-active"
                                : "facility-permissions-checkbox-description-text"
                            }
                            labelText={getFacilityPermissionName(
                              permission.name
                            )}
                            name={permission.name}
                            required={false}
                            selectClassName="facility-permissions-checkbox"
                            selectpropsClassName="facility-permissions-checkbox-root"
                            testId={permission.name}
                            value={permission.name}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))}
              </div>
            </InputWithLabel>
          </div>
        </>
      )}
      <Grid
        className="edit-facility-buttons-grid-container"
        container
        data-testid="facility-details"
        spacing={2}
      >
        <Grid className="edit-facility-buttons-grid-item" item xs={6}>
          <Button
            className="cancelBtn"
            data-testid="cancelBtn"
            onClick={() => cancelBtnAction()}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
        <Grid className="edit-facility-buttons-grid-item" item xs={6}>
          <Button
            className={`updateBtn ${isUserUpdated ? "enabled" : "disbaled"}`}
            data-testid="updateBtn"
            disabled={!isUserUpdated}
            onClick={handleUpdateButtonAction}
            variant="contained"
          >
            Update Access
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
