import React, { useContext, useState } from "react";
import "../customerPlusInventory/customerPlusInventory.css";
import { ReactComponent as Download } from "../../../assets/download.svg";
import { ReactComponent as DownloadDisabledIcon } from "../../../assets/downloadDisabled.svg";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ColDef, DomLayoutType } from "ag-grid-enterprise";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import InventoryProductMenu from "../InventoryProductMenu/InventoryProductMenu.component";
import {
  CustomerPlusMenuActionTypes,
  InventoryTabListingSection,
} from "../inventoryTabContainer.enum";
import {
  agGridGetRowStyle,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import {
  CustomerPlusListingTableTypes,
  ICustomerListingData,
  ICustomerPlusListingDetails,
} from "./customerPlusInventory.interface";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { Popup } from "../../../core/popup/popup.component";
import { InventoryPopUpContainer } from "../popUpContainer/inventoryPopUpContainer.component";
import {
  InventoryPopUp,
  InventoryPopUpFlow,
} from "../popUpContainer/inventoryPopUpContainer.enum";
import {
  CreateServiceRequestContext,
  CreateServiceRequestContextType,
} from "../../../context/CreateServiceRequestContext";
import { IPatient } from "../../myPatients/patient.interface";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../context/InpatientOrderContext";
import { ReactComponent as CheckIcon } from "../../../assets/checkMark.svg";
import ErrorPopup, {
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import {
  SERVICE_PENDING_ERROR,
  SVC_SERVICE_ALREADY_REQUESTED,
} from "../../../util/errorMsg";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";

const NowsRowsEmptyText = () => {
  return <></>;
};

interface ICustomerPlusListingProps {
  customerPlusListingData: ICustomerListingData;
  downloadBtnAction: Function;
  originalCustomerPlusData: ICustomerPlusListingDetails[];
  getCustomerPlusListingData: Function;
  handleSetErrorInformation: Function;
}

const customerPlusListingCSVHeaders: string[] = [
  "Status",
  "Unit Tag Id",
  "Product",
  "Serial Number",
  "Location",
  "Patient",
  "Service Pending",
  "Days in Storage",
  "MIA",
];

const CustomerPlusInventory: React.FC<ICustomerPlusListingProps> = ({
  customerPlusListingData,
  downloadBtnAction,
  originalCustomerPlusData,
  getCustomerPlusListingData,
  handleSetErrorInformation,
}) => {
  const isMobileScreen = useMediaQuery("(max-width:1024px)");
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const createServiceRequestObj =
    useContext<CreateServiceRequestContextType | null>(
      CreateServiceRequestContext
    );
  const inpatientOrderObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );
  const permissionsObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const isSmallMobile = useMediaQuery("(max-width:600px)");
  const isIpadScreen = !isSmallMobile && isMobileScreen;
  const history = useHistory();
  const rowHeight = 52;
  const [currentPopUp, setCurrentPopUp] = useState<InventoryPopUp>(
    InventoryPopUp.LOADER
  );
  const [openContainerPopupFlag, setOpenContainerPopupFlag] = useState(false);
  const [popUpFlow, setPopUpFlow] = useState<InventoryPopUpFlow>();
  const [selectedProductDetails, setSelectedProductDetails] =
    useState<ICustomerPlusListingDetails | null>(null);
  const [servicePendingErrorPopup, setservicePendingErrorPopup] = useState({
    code: 0,
    flag: false,
  });
  const closePopUp = () => {
    setOpenContainerPopupFlag(false);
    setCurrentPopUp(InventoryPopUp.LOADER);
  };

  const handleClosePopUpAction = () => {
    if (
      currentPopUp === InventoryPopUp.END_THERAPY_SUCCESS ||
      currentPopUp === InventoryPopUp.MAKE_AVAILABLE_FOR_PLACEMENT_SUCCESS
    ) {
      handleSuccessAndCloseAction();
    } else {
      closePopUp();
    }
  };

  const handleSuccessAndCloseAction = () => {
    // Get latest customer plus listing products
    getCustomerPlusListingData();
    // Clear selected product
    setSelectedProductDetails(null);
    // Close pop up
    closePopUp();
  };

  const handleServiceRequestedPopupOpen = (
    servicePendingData: ICustomerPlusListingDetails
  ) => {
    const errorobj = {
      code: servicePendingData.errorCode!,
      flag: true,
    };
    setservicePendingErrorPopup(errorobj);
  };

  const renderServiceRequestedStatus = (params: any) => {
    return params.data.isServiceRequested === true ? (
      <CheckIcon />
    ) : params.data.isServiceRequested === null ? (
      <span
        className="inventory-customer-plus-servicePending"
        onClick={() => handleServiceRequestedPopupOpen(params.data)}
      >
        Unknown
      </span>
    ) : (
      ""
    );
  };

  const defaultColDef: ColDef = {
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    autoHeight: true,
    resizable: false,
  };

  const commonColumnDefs: ColDef[] = [
    {
      headerName: "Unit Tag ID",
      field: "unitTagId",
      sortable: true,
      suppressMovable: true,
      width: isMobileScreen ? 110 : 180,
      minWidth: isMobileScreen ? 110 : 80,
      pinned: isMobileScreen ? "left" : null,
      lockPinned: isMobileScreen ? true : false,
      wrapText: true,
      cellRenderer: (params: any) => {
        return <>{makeCapitalEachWordInString(params.data.unitTagId)}</>;
      },
    },
    {
      headerName: "Product",
      minWidth: isMobileScreen ? 110 : 70,
      width: isMobileScreen ? 110 : 100,
      field: "productNameDescription",
      sortable: true,
      suppressMovable: true,
      wrapText: true,
    },
    {
      headerName: "Serial Number",
      width: isMobileScreen ? 130 : 130,
      minWidth: isMobileScreen ? 130 : 70,
      field: "serialNumber",
      sortable: true,
      suppressMovable: true,
    },
    {
      headerName: "MIA",
      field: "isMissingAsset",
      sortable: true,
      filter: false,
      suppressMovable: true,
      width: isMobileScreen ? 60 : 80,
      minWidth: isMobileScreen ? 50 : 80,
      cellRenderer: function (params: any) {
        return (
          <div className="inventory-customer-plus-missing-asset">
            {params.data.isMissingAsset ? <CheckIcon /> : ""}
          </div>
        );
      },
    },
  ];

  const availableUnitsColDefs: ColDef[] = [
    ...commonColumnDefs,
    {
      headerName: "Location",
      field: "currentLocation",
      sortable: true,
      wrapText: true,
      suppressMovable: true,
      width: isMobileScreen ? 200 : 200,
      minWidth: isMobileScreen ? 130 : 200,
      cellClass: "inventory-customer-plus-location text-uppercase",
      cellRenderer: function (params: any) {
        return (
          <span
            onClick={() => handleOpenPopupForUpdateStorageLocation(params.data)}
          >
            {params.data.currentLocation}
          </span>
        );
      },
    },
    {
      headerName: "Service Pending",
      field: "isServiceRequested",
      sortable: true,
      suppressMovable: true,
      width: isMobileScreen ? 130 : 130,
      cellClass: "inventory-stored-product-days",
      cellRenderer: function (params: any) {
        return <>{renderServiceRequestedStatus(params)}</>;
      },
    },
    {
      headerName: "Days in Storage",
      field: "storageDays",
      sortable: true,
      suppressMovable: true,
      width: isMobileScreen ? 140 : 130,
      cellRenderer: function (params: any) {
        return (
          <div className="inventory-stored-product-days">
            {params.data.storageDays}
          </div>
        );
      },
    },
    {
      headerName: "",
      field: "",
      pinned: isMobileScreen ? "right" : null,
      suppressMovable: true,
      lockPinned: isMobileScreen ? true : false,
      sortable: false,
      type: "rightAligned",
      cellClass: "menu-column-menu-btn",
      flex: 1,
      minWidth: isMobileScreen ? 40 : 80,
      width: isMobileScreen ? 45 : 65,
      cellRendererSelector: (params: any) => {
        return {
          component: InventoryProductMenu,
          params: {
            productData: params.data,
            handleClickFunctionlity: handleMenuOptionClick,
            tabListingSection: InventoryTabListingSection.CUSTOMER_PLUS_LISTING,
          },
        };
      },
    },
  ];

  const unitsInUseColDefs: ColDef[] = [
    ...commonColumnDefs,
    {
      headerName: "Location",
      field: "currentLocation",
      minWidth: 120,
      width: isMobileScreen ? 130 : 120,
      sortable: true,
      wrapText: true,
      wrapHeaderText: true,
      suppressMovable: true,
    },
    {
      headerName: "Patient",
      field: "patientFirstName",
      sortable: true,
      minWidth: 100,
      width: isMobileScreen ? 190 : 170,
      wrapText: true,
      suppressMovable: true,
      cellRenderer: function (params: any) {
        return (
          <span
            className={"inventory-customer-plus-location"}
            onClick={(e) =>
              handleOnPatientClickNavigationToOrderOverview(params.data)
            }
          >
            {checkForValidPatientName(params.data)}
          </span>
        );
      },
    },
    {
      headerName: "Service Pending",
      field: "isServiceRequested",
      sortable: true,
      suppressMovable: true,
      width: isMobileScreen ? 150 : 150,
      cellClass: "inventory-stored-product-days",
      cellRenderer: function (params: any) {
        return <>{renderServiceRequestedStatus(params)}</>;
      },
    },
    {
      headerName: "",
      field: "",
      minWidth: isMobileScreen ? 40 : 80,
      width: isMobileScreen ? 45 : 65,
      pinned: isMobileScreen ? "right" : null,
      suppressMovable: true,
      lockPinned: isMobileScreen ? true : false,
      sortable: false,
      type: "rightAligned",
      headerClass: "menu-column-empty-text",
      cellClass: "menu-column-menu-btn",
      flex: 1,
      cellRendererSelector: (params: any) => {
        return {
          component: InventoryProductMenu,
          params: {
            productData: params.data,
            handleClickFunctionlity: handleMenuOptionClick,
            tabListingSection: InventoryTabListingSection.CUSTOMER_PLUS_LISTING,
          },
        };
      },
    },
  ];

  const unitsNeedingPreparationColDefs: ColDef[] = [
    ...commonColumnDefs,
    {
      headerName: "Location",
      field: "currentLocation",
      sortable: true,
      wrapText: true,
      suppressMovable: true,
      width: isMobileScreen ? 275 : 275,
      minWidth: isMobileScreen ? 150 : 250,
      cellClass: "inventory-customer-plus-location text-uppercase",
      cellRenderer: function (params: any) {
        return (
          <span
            onClick={() => handleOpenPopupForUpdateStorageLocation(params.data)}
          >
            {params.data.currentLocation}
          </span>
        );
      },
    },
    {
      headerName: "Service Pending",
      field: "isServiceRequested",
      sortable: true,
      suppressMovable: true,
      width: isMobileScreen ? 200 : 180,
      cellClass: "inventory-stored-product-days",
      cellRenderer: function (params: any) {
        return <>{renderServiceRequestedStatus(params)}</>;
      },
    },
    {
      headerName: "",
      field: "",
      minWidth: isMobileScreen ? 40 : 80,
      width: isMobileScreen ? 45 : 65,
      pinned: isMobileScreen ? "right" : null,
      suppressMovable: true,
      lockPinned: isMobileScreen ? true : false,
      sortable: false,
      type: "rightAligned",
      flex: 1,
      headerClass: "menu-column-empty-text",
      cellClass: "menu-column-menu-btn",
      cellRendererSelector: (params: any) => {
        return {
          component: InventoryProductMenu,
          params: {
            productData: params.data,
            handleClickFunctionlity: handleMenuOptionClick,
            tabListingSection: InventoryTabListingSection.CUSTOMER_PLUS_LISTING,
          },
        };
      },
    },
  ];

  const checkForValidPatientName = (
    customerPlusProduct: ICustomerPlusListingDetails
  ): string => {
    let patientName: string = "";
    const firstName =
      customerPlusProduct.patientFirstName === null
        ? ""
        : customerPlusProduct.patientFirstName;
    const lastName =
      customerPlusProduct.patientLastName === null
        ? ""
        : customerPlusProduct.patientLastName;
    patientName = firstName + " " + lastName;
    return customerPlusProduct.status ===
      CustomerPlusListingTableTypes.UNITS_IN_USE
      ? makeCapitalEachWordInString(patientName)
      : "";
  };

  const conditionToShowDaysInStorage = (
    customerPlusProduct: ICustomerPlusListingDetails
  ) => {
    let daysInStorage: string = "";
    if (
      customerPlusProduct.status ===
      CustomerPlusListingTableTypes.AVAILABLE_UNITS
    ) {
      daysInStorage = customerPlusProduct.storageDays;
    } else daysInStorage = "";
    return daysInStorage;
  };

  const handleCustomerPlusListDownload = (): void => {
    const updatedCustomerPlusCSVData = originalCustomerPlusData.map((x) => {
      return {
        status: x.status,
        unitTagId: x.unitTagId,
        productName: x.productNameDescription,
        serialNumber: x.serialNumber,
        currentLocation: x.currentLocation ?? "",
        patient: checkForValidPatientName(x),
        servicePending: x.isServiceRequested ? "Done" : "Open",
        storageDays: conditionToShowDaysInStorage(x),
        MIA: x.isMissingAsset ? "Yes" : "No",
      };
    });
    const csvReqBody = {
      Columns: customerPlusListingCSVHeaders,
      Data: updatedCustomerPlusCSVData,
    };
    const registeredFacilty = authObj?.registeredFaciltyAddress;
    const csvFileName: string = `${registeredFacilty?.accountName
      .split(" ")
      .join("_")}_${registeredFacilty?.accountNumber}_CP_Inventory_`;
    downloadBtnAction(csvReqBody, csvFileName);
  };

  const handleOpenPopupForMakeAvailable = (
    customerPlusObj: ICustomerPlusListingDetails
  ) => {
    setSelectedProductDetails(customerPlusObj);
    setOpenContainerPopupFlag(true);
    setPopUpFlow(InventoryPopUpFlow.MAKE_AVAILABLE_FOR_PLACEMENT);
  };

  const handleOpenPopupForEndTherapy = (
    customerPlusObj: ICustomerPlusListingDetails
  ) => {
    setSelectedProductDetails(customerPlusObj);
    setOpenContainerPopupFlag(true);
    setPopUpFlow(InventoryPopUpFlow.END_THERAPY);
  };

  const handleOpenPopupForUpdateStorageLocation = (
    customerPlus: ICustomerPlusListingDetails
  ) => {
    setPopUpFlow(InventoryPopUpFlow.CUSTOMER_PLUS_UPDATE_STORAGE_LOCATION);
    setSelectedProductDetails(customerPlus);
    setOpenContainerPopupFlag(true);
  };

  const handleRequestServiceClick = (
    selectedProduct: ICustomerPlusListingDetails
  ) => {
    const isServiceRequested = originalCustomerPlusData.find(
      (product) =>
        product.id === selectedProduct.id && product.isServiceRequested
    );
    if (isServiceRequested) {
      const errorObj: IErrorPopUp = {
        errorCode: "",
        errorFlag: true,
        errorMessage: SVC_SERVICE_ALREADY_REQUESTED,
      };
      handleSetErrorInformation(errorObj, false, false);
    } else {
      window.scrollTo(0, 0);
      createServiceRequestObj?.resetContext();
      history.push({
        pathname: "/inventory/createServiceRequest",
        state: {
          product: selectedProduct,
        },
      });
    }
  };

  const handleMenuOptionClick = (
    typeOfEvent: string,
    selectedProduct: ICustomerPlusListingDetails
  ) => {
    if (
      typeOfEvent.toLowerCase() ===
      CustomerPlusMenuActionTypes.REQUEST_SERVICE.toLowerCase()
    ) {
      //Request Service
      handleRequestServiceClick(selectedProduct);
    } else if (
      typeOfEvent.toLowerCase() ===
      CustomerPlusMenuActionTypes.CREATE_TRANSITION_ORDER.toLowerCase()
    ) {
      newOrderObj?.resetNewOrderForm();
      history.push({
        pathname: "/orders/newOrder",
        state: {
          isTransitionFromCustomerPlus: true,
          roNumber: selectedProduct?.roNumber,
          serialNumber: selectedProduct?.serialNumber,
        },
      });
    } else if (
      typeOfEvent.toLowerCase() ===
      CustomerPlusMenuActionTypes.END_THERAPHY.toLowerCase()
    ) {
      handleOpenPopupForEndTherapy(selectedProduct);
    } else if (
      typeOfEvent.toLowerCase() ===
      CustomerPlusMenuActionTypes.MAKE_AVAILABLE_FOR_PLACEMENT.toLowerCase()
    ) {
      //Make Available For placement
      handleOpenPopupForMakeAvailable(selectedProduct);
    } else if (
      typeOfEvent.toLowerCase() ===
      CustomerPlusMenuActionTypes.PLACE_ON_PATIENT.toLowerCase()
    ) {
      //Place on Patient
      window.scrollTo(0, 0);
      inpatientOrderObj?.resetContext();
      history.push({
        pathname: "/orders/inpatientOrder",
        state: {
          allProducts: customerPlusListingData.availableUnits,
          product: selectedProduct,
        },
      });
    } else if (
      typeOfEvent.toLowerCase() ===
      CustomerPlusMenuActionTypes.UPDATE_STORAGE_LOCATION.toLowerCase()
    ) {
      handleOpenPopupForUpdateStorageLocation(selectedProduct);
    }
  };

  const getDynamicHeightForTab = (): string => {
    let className: string = "h-700";
    const keys = Object.keys(customerPlusListingData) as Array<
      keyof ICustomerListingData
    >;
    let count = 0;
    keys.forEach((x: any) => {
      if (customerPlusListingData[x as keyof ICustomerListingData].length > 1) {
        count++;
      }
    });
    if (count === 3) {
      className = "h-900";
    } else if (count === 2) {
      className = "h-800";
    } else if (count === 1) {
      className = "h-700";
    }
    return className;
  };

  const manageStorageLocationsOnClick = () => {
    history.push("/administration/myLists");
  };

  const getDomLayout = (
    tableData: ICustomerPlusListingDetails[]
  ): DomLayoutType => {
    let domLayout: DomLayoutType = "normal";
    if (tableData.length > 1) {
      domLayout = "normal";
    } else domLayout = "autoHeight";
    return domLayout;
  };

  const handleOnPatientClickNavigationToOrderOverview = (
    customerPlusProduct: ICustomerPlusListingDetails
  ) => {
    const patientdata: IPatient = {
      roNumber: Number(customerPlusProduct.roNumber),
      firstName: customerPlusProduct?.patientFirstName!,
      lastName: customerPlusProduct?.patientLastName!,
      dob: "",
      facilityName: authObj?.registeredFaciltyAddress?.accountName!,
      isAcuteOrder: true,
      orderCreationDate: customerPlusProduct.therapyStartDate!,
      patientId: null,
      patientLocation: null,
      alerts: [],
      status: "Therapy",
      type: "customerplus",
      statusColor: "blueCircle",
      productName: customerPlusProduct?.productName,
      productNameDescription: customerPlusProduct?.productNameDescription,
      placementDate: customerPlusProduct.therapyStartDate!,
      productSerialNumber: customerPlusProduct.serialNumber,
      menuActions: [],
      isCustomerPlus: true,
      customerPlusProduct: customerPlusProduct,
    };
    history.push({
      pathname: "/home/orderOverview",
      state: {
        stateData: patientdata,
      },
    });
  };

  return (
    <Grid
      container
      className={`customer-plus-inventory-main-div ${getDynamicHeightForTab()}`}
    >
      <Grid
        item
        xs={12}
        data-testid="customer-plus-available-units-table"
        className="ag-theme-quartz inventory-customer-plus-ag-table-spacing"
        style={{
          width: "100%",
          height: customerPlusListingData.availableUnits.length > 1 ? 200 : 100,
        }}
      >
        <Typography
          className="customer-plus-inventory-table-header"
          data-testid="customer-plus-available-units-header"
        >
          Available Units
        </Typography>
        <AgGridReact
          getRowStyle={agGridGetRowStyle}
          defaultColDef={defaultColDef}
          columnDefs={availableUnitsColDefs}
          rowData={customerPlusListingData.availableUnits}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          suppressContextMenu={true}
          className="customer-plus-ag-table"
          rowHeight={rowHeight}
          domLayout={getDomLayout(customerPlusListingData.availableUnits)}
          noRowsOverlayComponent={NowsRowsEmptyText}
        />
      </Grid>
      <Grid
        item
        xs={12}
        data-testid="customer-plus-units-in-use-table"
        className="ag-theme-quartz inventory-customer-plus-ag-table-spacing"
        style={{
          width: "100%",
          height: customerPlusListingData.unitsInUse.length > 1 ? 200 : 100,
        }}
      >
        <Typography
          className="customer-plus-inventory-table-header"
          data-testid="customer-plus-units-in-use-header"
        >
          Units in Use
        </Typography>
        <AgGridReact
          getRowStyle={agGridGetRowStyle}
          defaultColDef={defaultColDef}
          columnDefs={unitsInUseColDefs}
          rowData={customerPlusListingData.unitsInUse}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          suppressContextMenu={true}
          className="customer-plus-ag-table"
          rowHeight={rowHeight}
          domLayout={getDomLayout(customerPlusListingData.unitsInUse)}
          noRowsOverlayComponent={NowsRowsEmptyText}
        />
      </Grid>
      <Grid
        item
        xs={12}
        data-testid="customer-plus-units-needing-preparation-table"
        className="ag-theme-quartz inventory-customer-plus-ag-table-spacing"
        style={{
          width: "100%",
          height:
            customerPlusListingData.unitsNeedingPreparation.length > 1
              ? 200
              : 100,
        }}
      >
        <Typography
          className="customer-plus-inventory-table-header"
          data-testid="customer-plus-units-needing-preparation-header"
        >
          Units Needing Preparation
        </Typography>
        <AgGridReact
          getRowStyle={agGridGetRowStyle}
          defaultColDef={defaultColDef}
          columnDefs={unitsNeedingPreparationColDefs}
          rowData={customerPlusListingData.unitsNeedingPreparation}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          suppressContextMenu={true}
          className="customer-plus-ag-table"
          rowHeight={rowHeight}
          domLayout={getDomLayout(
            customerPlusListingData.unitsNeedingPreparation
          )}
          noRowsOverlayComponent={NowsRowsEmptyText}
        />
      </Grid>
      <div className="customer-plus-inventory-btn-grp">
        <ExpressButton
          variant={"outlined"}
          clickHandler={handleCustomerPlusListDownload}
          startIcon={
            originalCustomerPlusData.length === 0 ? (
              <DownloadDisabledIcon />
            ) : (
              <Download />
            )
          }
          parentClass="customer-plus-download-btn"
          testId="customer-plus-download-btn"
          disabled={originalCustomerPlusData.length === 0}
        >
          Download
        </ExpressButton>
        {permissionsObj?.mappedRolesPermissionData.IsAdminMyListsButton && (
          <ExpressButton
            variant={"outlined"}
            clickHandler={manageStorageLocationsOnClick}
            parentClass="customer-plus-manage-sl-btn"
            testId="customer-plus-manage-sl-btn"
          >
            Manage Storage Locations
          </ExpressButton>
        )}
      </div>
      <Popup
        openFlag={openContainerPopupFlag}
        dialogParentClass="inventory-pop-up-container"
        closeHandler={handleClosePopUpAction}
        hideCloseButton={currentPopUp === InventoryPopUp.LOADER}
      >
        {selectedProductDetails && popUpFlow && (
          <InventoryPopUpContainer
            closePopUp={handleClosePopUpAction}
            currentPopUp={currentPopUp}
            flow={popUpFlow}
            setCurrentPopUp={setCurrentPopUp}
            successAndClose={handleSuccessAndCloseAction}
            selectedCustomerPlusListingObj={selectedProductDetails}
            setSelectedProductDetails={setSelectedProductDetails}
          />
        )}
      </Popup>
      <ErrorPopup
        popUpStyles="errorPopup"
        handleBackButton={() =>
          setservicePendingErrorPopup((p) => ({ ...p, flag: false }))
        }
        isShortSentence={true}
        buttontitle="Done"
        errorPopupFlag={servicePendingErrorPopup.flag}
        errorMessage={SERVICE_PENDING_ERROR}
        errorCode={servicePendingErrorPopup.code}
        showTitle={false}
      />
    </Grid>
  );
};

export default CustomerPlusInventory;
