import { useContext, useEffect, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { mapUserRolesAndPermissionData } from "../../RolesPermission/RolesPermission.Mapper";
import { IPermissionData } from "../../RolesPermission/RolesPermission.model";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import "./page.css";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../context/NewOrderContext";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../context/OrderDetailsContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../context/RolesPermissionContext";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../context/submitProofOfDeliveryContext";
import PrivateRoute from "../../routeHooks/privateRouterAccess";
import {
  caregiverFacilitySearch,
  checkPostAcuteFacility,
  getSalesRep,
} from "../../util/3meService";
import { getProperty } from "../../util/ObjectFunctions";
import { getCMSContent, getKeyVaultContent } from "../../util/cmsService";
import { getStaticContent } from "../../util/staticContentService";
import {
  CMS_TECHNICALSUPPORT_CONTENT,
  STATIC_DEVICE_SUPPORT_NUMBER,
} from "../../util/staticText";
import { getUserProfile } from "../../util/userService";
import { UnlockVacOrder } from "../../util/vacOrderService";
import { AdminRolesPermissions } from "../administration/adminRolePermissions/adminRolesPermissions.component";
import { MyLists } from "../administration/myLists/myLists.component";
import Administration from "../administration/administration.component";
import { ManageTerritoriesContainer } from "../administration/manageTerritories/manageTerritoriesContainer.component";
import { TerritoryDetails } from "../administration/manageTerritories/viewTerritories/territoryDetails/territoryDetails.component";
import { ManageUserAccounts } from "../administration/manageUserAccounts/manageUserAccounts.component";
import { ManageUsers } from "../administration/manageUsers/manageUsers.component";
import { UserProfile } from "../administration/manageUsers/userProfile/userProfile.component";
import SalesManageUsers from "../administration/salesManageUsers/SalesManageUsers.component";
import SalesUserProfile from "../administration/salesManageUsers/salesUserProfile/salesUserProfile.component";
import SalesManagerHistory from "../administration/salesManagerHistory/salesManagerHistory.component";
import { ConfirmPlacement } from "../confirmPlacement/confirmPlacement.component";
import { Education } from "../education/education.component";
import { VideoLibrary } from "../education/videoLibrary/videoLibrary.component";
import { FacilityBanner } from "../facilityBanner/facilityBanner.component";
import { HelpAndSupport } from "../helpAndSupport/helpAndSupport.component";
import { InventoryRequest } from "../inventory/inventoryRequest/inventoryRequest.component";
import { searchFacility } from "../manageProfile/facilityInformation/addFacility/addFacility.service";
import {
  FacilityMode,
  IFacility,
} from "../manageProfile/facilityInformation/facility.interface";
import { ICheckPostAcuteFacility } from "../manageProfile/facilityInformation/facilityFound/facilityFound.interface";
import { IUser } from "../manageProfile/user.interface";
import { MyPatients } from "../myPatients/myPatients.component";
import { OrderOverview } from "../myPatients/patientAndTherapyDetails/orderOverview/orderOverview.component";
import { AddWoundAssessmentContainer } from "../myPatients/patientAndTherapyDetails/woundProgress/addWoundAssessment/AddWoundAssessmentContainer/AddWoundAssessmentContainer.component";
import { SubmitProofOfDelivery } from "../myPatients/submitProofOfDelivery/submitProofOfDelivery.component";
import {
  IDeviceTroubleshootingSupport,
  ISalesRep,
  ITechnicalSupport,
} from "../needHelp/needHelp.interface";
import { NewOrder } from "../newOrder/newOrder.component";
import { NewOrderOptions } from "../newOrder/newOrderOptions/newOrderOptions.component";
import { OrderSuccess } from "../newOrder/orderSuccess/orderSuccess.component";
import { DischargeRequest } from "../pickUpAndDischargeRequest/dischargeRequest/dischargeRequest.component";
import { ReviewDischargeRequest } from "../pickUpAndDischargeRequest/dischargeRequest/reviewDischargeRequest/reviewDischargeRequest.component";
import { PickUpRequest } from "../pickUpAndDischargeRequest/pickUpRequest/pickUpRequest.component";
import { ProductDetail } from "../productsAndSolutions/productDetail/productDetail.component";
import { ProductsAndSolutions } from "../productsAndSolutions/productsAndSolutions.component";
import { SendNote } from "../send3MNote/sendNote.component";
import { SideNav } from "../sideNav/sideNav.component";
import { ISideNav } from "../sideNav/sideNavMenuOption.interface";
import SupplyOrder from "../supplyOrder/supplyOrder.component";
import SystemRequirements from "../systemRequirements/systemReq.components";
import { PageContext } from "./page.context";
import { MainPage, PageDiv, RouteSection, SubPageDiv } from "./page.style";
import {
  InternalSignOnContextType,
  InternalSignOnContext,
} from "../../context/InternalSignOnContext";
import { ViewReports } from "../../sso/viewReports/viewReports.component";
import { ExchangeVac } from "../exchangeVacUnit/exchangeVacUnit.component";
import { FacilitySettings } from "../administration/facilitySettings/facilitySettings.component";
import { InventoryTabContainer } from "../inventory/inventoryTabContainer.component";
import { ManageSiteStatuses } from "../administration/manageSiteStatuses/manageSiteStatuses.component";
import { AddSiteStatus } from "../administration/manageSiteStatuses/addSiteStatus/addSiteStatus.component";
import {
  SiteStatusesContext,
  SiteStatusesContextType,
} from "../../context/SiteStatusesContext";
import { SiteStatusBanners } from "../../signIn/siteStatusesBanners/siteStatusesBanners.component";
import {
  ISiteStatusesBanner,
  MessageType,
} from "../../signIn/siteStatusesBanners/siteStatusesBanner/siteStatusesBanner.interfaces";
import { getApplicationSiteStatus } from "../../util/siteStatusesService";
import ErrorPopup from "../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_GET_SITE_STATUSES } from "../../util/errorMsg";
import { LicenseManager } from "ag-grid-enterprise";
import { mapFunctionKeyData } from "../../functionKey/functionKey.Mapper";
import { encrypt } from "../../util/encryptDecrypt";
import { FUNCTION_KEY_REQUESTBODY } from "../../util/keyVaultNames";
import { CreateServiceRequestContainer } from "../inventory/requestService/createServiceRequestContainer.component";
import { InpatientOrderContainer } from "../inpatientOrder/container/inpatientOrderContainer.component";
import { showVacOrderMenuOption } from "../../util/utilityFunctions";
import { AcuteOrderPickUpContainer } from "../acutePickUpRequest/container/acuteOrderPickUpRequestContainer.component";
import { InpatientSupplyOrderContainer } from "../inpatientSupplyOrder/container/inpatientSupplyOrderContainer.component";
import { WoundAssessmentReport } from "../administration/woundAssessmentReport/woundAssessmentReport.component";
import { InPatientSupplyOrderDetail } from "../facilitySupplyOrders/inpatientSupplyOrderDetails/inpatientSupplyOrderDetails.component";
import { SalesAssistanceNeedHelp } from "../myPatients/salesAssisstanceNeedHelp/salesAssisstanceNeedHelp.component";
import SalesRoundingToolContainer from "../../sso/salesRoundingTool/salesRoundingToolContainer/salesRoundingToolContainer.component";
import LifeOfAnOrderGuide from "../../sso/lifeOfAnOrderGuide/container/lifeOfAnOrderGuide.container";

export const Page = () => {
  const [salesRepInfo, setSalesRepInfo] = useState<ISalesRep>();
  const [techSupportInfo, setTechSupportInfo] = useState<ITechnicalSupport>();
  const [pssSupportInfo, setPssSupportInfo] = useState<ISalesRep>();
  const [deviceTroubleshootNumber, setDeviceTroubleshootNumber] =
    useState<IDeviceTroubleshootingSupport>();
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const [currentPath, setCurrentPath] = useState<string | null>(null);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const NewOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const siteStatusesObj = useContext<SiteStatusesContextType | null>(
    SiteStatusesContext
  );
  const permissionBtnData = permissionObj?.mappedRolesPermissionData;

  const location = useLocation();
  const history = useHistory();

  // Error Pop up
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);

  const loadTechSupport = async () => {
    const { item } = (await getCMSContent(CMS_TECHNICALSUPPORT_CONTENT)) || {};
    const techSupportData: ITechnicalSupport = {
      emailAddress: item.emailAddress,
      phoneNo: item.phoneNo,
    };
    setTechSupportInfo(techSupportData);
  };

  const loadDeviceSupport = async () => {
    const data = await getStaticContent(STATIC_DEVICE_SUPPORT_NUMBER);
    if (data.succeeded) {
      const deviceData: IDeviceTroubleshootingSupport = {
        phoneNo: data.items[0]?.value,
      };
      setDeviceTroubleshootNumber(deviceData);
    }
  };

  const getPostAcuteValue = async (code: any) => {
    const postAcuteResponse: ICheckPostAcuteFacility =
      await checkPostAcuteFacility({
        FacilityTypeCode: code,
      });
    return postAcuteResponse;
  };

  const searchFacilityAddr = async (data: IUser) => {
    let linkedFacilities: any;
    let manualFacilities: any;
    if (
      (data?.facilities.length > 0 &&
        AuthObj?.registeredFaciltyAddress === undefined) ||
      AuthObj?.registeredFaciltyAddress?.facilityAddressID === undefined
    ) {
      AuthObj?.setuserRolePermissionData(data.userPermissions!);
      linkedFacilities = data.facilities.filter(
        (x) => x.mode === FacilityMode.LINKED
      );
      if (linkedFacilities.length < 1) {
        manualFacilities = data.facilities.filter(
          (x: any) => x.mode === FacilityMode.MANUAL
        );
      }
      if (linkedFacilities.length > 0) {
        var facilitySearchRequest = {
          customerNumber: linkedFacilities[0].number!,
          customerName: "",
          postalCode: "",
        };
        let searchedFacilities: IFacility[] = [];
        await searchFacility(facilitySearchRequest)
          .then((result) => {
            searchedFacilities = result;
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        const facilityRes =
          searchedFacilities &&
          searchedFacilities.filter(
            (x) => x.siteUseId === linkedFacilities[0].siteUseId?.toString()
          )[0];
        let caregiverId = null;
        let careGiverSiteUseId = null;
        if (
          facilityRes &&
          facilityRes.siteUseCode &&
          facilityRes.siteUseCode?.includes("CG")
        ) {
          const reqBody = {
            customerAccountNumber: facilityRes.accountNumber?.toString(),
          };
          const response = await caregiverFacilitySearch(reqBody);
          if (response && response.items && response.items.length > 0) {
            let homeCareGiverDetails = response.items;
            const homeCareGiverDetail = homeCareGiverDetails[0];
            if (facilityRes.siteUseCode?.includes("ST/CG")) {
              caregiverId = homeCareGiverDetail.origSystemReference;
              careGiverSiteUseId = homeCareGiverDetail.siteUseId;
            } else {
              caregiverId = homeCareGiverDetail.origSystemReference;
            }
          }
        }

        let registeredFaciltyAddress = {
          ...facilityRes,
          facilityAddressID: linkedFacilities[0].facilityAddressID,
          careGiverId: caregiverId
            ? caregiverId
            : linkedFacilities[0].careGiverId,
          careGiverSiteUseId: careGiverSiteUseId
            ? careGiverSiteUseId
            : linkedFacilities[0].careGiverSiteUseId,
          isPostAcute: (await getPostAcuteValue(linkedFacilities[0].typeCode))
            .postAcuteFound,
        };
        AuthObj?.setregisteredFaciltyAddress(registeredFaciltyAddress);

        // set Permission
        const rolesPermissionRes: IPermissionData =
          await mapUserRolesAndPermissionData(
            data.userPermissions!,
            registeredFaciltyAddress?.readyCareFlag
          );
        permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
        AuthObj?.setsideNavMenuOptionModelData((dt: ISideNav) => ({
          ...dt,
          orders: {
            ...dt.orders,
            isVisible: showVacOrderMenuOption(rolesPermissionRes),
          },
          inventory: {
            ...dt.inventory,
            isVisible: rolesPermissionRes.IsShowInventoryOption,
          },
          administration: {
            ...dt.administration,
            isVisible: rolesPermissionRes.IsShowAdminstrationOption,
          },
        }));
        AuthObj?.setuserRolesAndPermissionLoaded(true);
        if (
          siteStatusesObj &&
          siteStatusesObj?.fullScreenSiteStatues === null
        ) {
          getSiteStatusesAPI(rolesPermissionRes.IsBaseRole);
        }
      } else {
        const rolesPermissionRes: IPermissionData =
          await mapUserRolesAndPermissionData(data.userPermissions!, "N");
        permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
        AuthObj?.setsideNavMenuOptionModelData((dt: ISideNav) => ({
          ...dt,
          orders: {
            ...dt.orders,
            isVisible: showVacOrderMenuOption(rolesPermissionRes),
          },
          inventory: {
            ...dt.inventory,
            isVisible: rolesPermissionRes.IsShowInventoryOption,
          },
          administration: {
            ...dt.administration,
            isVisible: rolesPermissionRes.IsShowAdminstrationOption,
          },
        }));
        AuthObj?.setuserRolesAndPermissionLoaded(true);
        if (
          siteStatusesObj &&
          siteStatusesObj?.fullScreenSiteStatues === null
        ) {
          getSiteStatusesAPI(rolesPermissionRes.IsBaseRole);
        }
      }
    }
    return null;
  };

  const getUserDetails = async () => {
    if (!AuthObj?.isInternalUser) {
      const user = await getUserProfile();
      if (user !== undefined) {
        if (
          user.departmentName === "Internal" &&
          AuthObj?.registeredFaciltyAddress === undefined
        ) {
          history.push("/ssoRedirect");
        } else {
          AuthObj?.setUserProfile(user);
          await searchFacilityAddr(user);
        }
      }
    } else {
      AuthObj?.setuserRolesAndPermissionLoaded(true);
    }
  };

  const loadSalesRep = async (
    zipCode: number,
    facilityTypeCode: string,
    rolesPermissionRes: IPermissionData
  ) => {
    if (rolesPermissionRes.IsSalesRepDetails && zipCode) {
      if (zipCode) {
        const resdata =
          (await getSalesRep(
            zipCode.toString(),
            facilityTypeCode.toString()
          )) || {};
        const saleRepData: ISalesRep = {
          emailAddress: resdata?.data[0]?.emailAddress,
          phoneNo: resdata?.data[0]?.phoneNo,
          name: resdata?.data[0]?.name,
        };
        setSalesRepInfo(saleRepData);
        const pssData: ISalesRep = {
          emailAddress: resdata?.data[1]?.emailAddress,
          phoneNo: resdata?.data[1]?.phoneNo,
          name: resdata?.data[1]?.name,
        };
        setPssSupportInfo(pssData);
      }
    }
  };

  const Getlicense = async () => {
    const body = {
      SecretNames: FUNCTION_KEY_REQUESTBODY,
    };
    if (sessionStorage.getItem("keys") === null) {
      const licenseResponse = await getKeyVaultContent(body);
      if (licenseResponse?.succeeded) {
        const mapper = await mapFunctionKeyData(licenseResponse);
        const encryptedData = encrypt(JSON.stringify(mapper));
        sessionStorage.setItem("keys", encryptedData);
        LicenseManager.setLicenseKey(licenseResponse?.item[0].value);
      }
    }
  };

  useEffect(() => {
    Getlicense();
    getUserDetails();
    loadTechSupport();
    loadDeviceSupport();
    if (siteStatusesObj && siteStatusesObj?.fullScreenSiteStatues === null) {
      getSiteStatusesAPI();
    }
  }, []);

  const getSiteStatusesAPI = async (isBaseRole?: boolean) => {
    const response = await getApplicationSiteStatus();
    if (response && response.succeeded) {
      let fullScreenStatuses = response.item.fullScreen;
      if (fullScreenStatuses) {
        const order = {
          [MessageType.ALERT]: 1,
          [MessageType.WARNING]: 2,
          [MessageType.INFORMATION]: 3,
        };
        let updatedfullScreenStatuses: any = [];
        if (AuthObj?.isInternalUser === true) {
          updatedfullScreenStatuses = fullScreenStatuses
            .filter((item: ISiteStatusesBanner) => item.showToInternalUsers)
            .sort((a: ISiteStatusesBanner, b: ISiteStatusesBanner) =>
              order[a.messageType] > order[b.messageType] ? 1 : -1
            );
        } else if (isBaseRole === true && AuthObj?.isInternalUser === false) {
          updatedfullScreenStatuses = fullScreenStatuses
            .filter(
              (item: ISiteStatusesBanner) => item.showToUnAuthenticatedUsers
            )
            .sort((a: ISiteStatusesBanner, b: ISiteStatusesBanner) =>
              order[a.messageType] > order[b.messageType] ? 1 : -1
            );
        } else if (AuthObj?.isInternalUser === false) {
          updatedfullScreenStatuses = fullScreenStatuses
            .filter((item: ISiteStatusesBanner) => item.showToExternalUsers)
            .sort((a: ISiteStatusesBanner, b: ISiteStatusesBanner) =>
              order[a.messageType] > order[b.messageType] ? 1 : -1
            );
        }
        siteStatusesObj?.setFullScreenSiteStatues(updatedfullScreenStatuses);
      }
    } else {
      setErrorMessage(ERROR_MSG_GET_SITE_STATUSES);
      setErrorCode(
        response?.error?.code || response?.error?.errorCode || response?.status
      );
      setErrorPopUpFlag(true);
    }
  };

  const handleCloseButton = (index: number) => {
    if (siteStatusesObj && siteStatusesObj?.fullScreenSiteStatues) {
      let fullScreenSiteStatues = [...siteStatusesObj.fullScreenSiteStatues];
      fullScreenSiteStatues.splice(index, 1);
      siteStatusesObj.setFullScreenSiteStatues(fullScreenSiteStatues);
    }
  };

  useEffect(() => {
    if (AuthObj?.registeredFaciltyAddress) {
      loadSalesRep(
        AuthObj?.registeredFaciltyAddress.zip,
        AuthObj?.registeredFaciltyAddress.typeCode,
        permissionObj?.mappedRolesPermissionData!
      );
    }
  }, [AuthObj?.registeredFaciltyAddress]);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setCurrentPath(location.pathname);
    }
    if (currentPath?.includes("/orders/newOrder") && NewOrderObj?.vacOrderID) {
      UnlockVacOrder(NewOrderObj?.vacOrderID);
      NewOrderObj?.resetNewOrderForm();
    }
    if (location.pathname !== "/home/orderOverview") {
      orderOverViewObj?.resetData();
      orderOverViewObj?.resetSeletedSupplyOrderData();
      orderOverViewObj?.resetWoundData();
    }
  }, [location.pathname]);

  const internalHelpSupport = (
    <div className="internalappWithRouter">
      <div className="helpSectionAtSignup">
        <div className="helpComponent">
          <HelpAndSupport />
        </div>
      </div>
    </div>
  );

  const internalSysreq = (
    <div className="internalappWithRouter">
      <div className="helpSectionAtSignup">
        <div className="helpComponent">
          <SystemRequirements data={""} />
        </div>
      </div>
    </div>
  );

  const inventory = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "inventory")
        .isVisible ? (
        <InventoryTabContainer />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const createServiceRequest = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "inventory")
        .isVisible ? (
        <CreateServiceRequestContainer />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const newOrderOptions = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "orders").isVisible ? (
        <NewOrderOptions />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const administration = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "administration")
        .isVisible ||
      getProperty(
        internalObj?.ssoSideNavMenuOptionModelData,
        "internalAdministration"
      ).isVisible ? (
        <Administration />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const internalAdministration =
    process.env.REACT_APP_ENV_HIDE_SSOSIDENAV === "false" ? (
      <Administration />
    ) : (
      <Route>
        <Redirect to="/" />
      </Route>
    );

  const viewReports = AuthObj?.isLoggedIn ? (
    AuthObj?.isInternalUser ? (
      <ViewReports />
    ) : (
      <Route>
        <Redirect to="/home" />
      </Route>
    )
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const lifeOfAnOrderGuide = AuthObj?.isLoggedIn ? (
    AuthObj?.isInternalUser ? (
      <LifeOfAnOrderGuide />
    ) : (
      <Route>
        <Redirect to="/home" />
      </Route>
    )
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const salesRoundingTool = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(
        internalObj?.ssoSideNavMenuOptionModelData,
        "salesRoundingTool"
      ).isVisible ? (
        <SalesRoundingToolContainer />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const manageUsers = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "administration")
        .isVisible ||
      getProperty(
        internalObj?.ssoSideNavMenuOptionModelData,
        "internalAdministration"
      ).isVisible ? (
        <ManageUsers />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const salesManageUsers = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "administration")
        .isVisible ||
      getProperty(
        internalObj?.ssoSideNavMenuOptionModelData,
        "internalAdministration"
      ).isVisible ? (
        <SalesManageUsers />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const manageTerritories = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "administration")
        .isVisible ||
      getProperty(
        internalObj?.ssoSideNavMenuOptionModelData,
        "internalAdministration"
      ).isVisible ? (
        <ManageTerritoriesContainer />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const salesManagerHistory = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "administration")
        .isVisible ||
      getProperty(
        internalObj?.ssoSideNavMenuOptionModelData,
        "internalAdministration"
      ).isVisible ? (
        <SalesManagerHistory />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const territoriesDetail = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "administration")
        .isVisible ||
      getProperty(
        internalObj?.ssoSideNavMenuOptionModelData,
        "internalAdministration"
      ).isVisible ? (
        <TerritoryDetails />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const manageUserAccounts = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "administration")
        .isVisible ||
      getProperty(
        internalObj?.ssoSideNavMenuOptionModelData,
        "internalAdministration"
      ).isVisible ? (
        <ManageUserAccounts />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const manageSiteStatuses = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      (getProperty(AuthObj?.sideNavMenuOptionModelData, "administration")
        .isVisible ||
        getProperty(
          internalObj?.ssoSideNavMenuOptionModelData,
          "internalAdministration"
        ).isVisible) &&
      permissionBtnData?.IsPrdMgrSiteStatus ? (
        <ManageSiteStatuses />
      ) : (
        <Route>
          <Redirect to="/ssoRedirect" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const salesManageUserProfile = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "administration")
        .isVisible ||
      getProperty(
        internalObj?.ssoSideNavMenuOptionModelData,
        "internalAdministration"
      ).isVisible ? (
        <SalesUserProfile />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const userProfile = AuthObj?.isLoggedIn ? (
    <UserProfile />
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const myLists = AuthObj?.isLoggedIn ? (
    <MyLists />
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );
  const facilitySettings = AuthObj?.isLoggedIn ? (
    <FacilitySettings />
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const addSiteStatus = AuthObj?.isLoggedIn ? (
    <AddSiteStatus />
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const adminRolesAndPermissions = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      permissionObj?.mappedRolesPermissionData.IsShowAdminstrationOption ? (
        <AdminRolesPermissions />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const education = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "education")
        .isVisible ? (
        <Education />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/education" />
    </Route>
  );

  const educationVideoLibrary = AuthObj?.isLoggedIn ? (
    <VideoLibrary />
  ) : (
    <Route>
      <Redirect to="/home" />
    </Route>
  );

  const productsAndSolutions = AuthObj?.isLoggedIn ? (
    <ProductsAndSolutions />
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const productDetail = AuthObj?.isLoggedIn ? (
    <ProductDetail />
  ) : (
    <Redirect to="/" />
  );

  const orders = AuthObj?.isLoggedIn ? (
    internalObj?.isVACOrderSSO ? (
      <NewOrder />
    ) : AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "orders").isVisible ? (
        <NewOrder />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : (
      <NewOrder />
    )
  ) : internalObj?.isVACOrderSSO ? (
    <NewOrder />
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const inpatientOrder = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "orders").isVisible ? (
        <InpatientOrderContainer />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : (
      <InpatientOrderContainer />
    )
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const inpatientSupplyOrder = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "orders").isVisible &&
      permissionObj?.mappedRolesPermissionData
        .IsShowInPatientSupplyOrderButton ? (
        <InpatientSupplyOrderContainer />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : (
      <InpatientSupplyOrderContainer />
    )
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const inpatientStockOrder = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "orders").isVisible &&
      permissionObj?.mappedRolesPermissionData
        .IsShowInPatientSupplyOrderButton ? (
        <InpatientSupplyOrderContainer />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : (
      <InpatientSupplyOrderContainer />
    )
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const neededHelp = AuthObj?.isLoggedIn ? (
    AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "orders").isVisible ? (
        <SalesAssistanceNeedHelp />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : (
      <SalesAssistanceNeedHelp />
    )
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const orderOverviewRoute = AuthObj?.isLoggedIn ? (
    <OrderOverview />
  ) : (
    <Route>
      <Redirect to="/home" />
    </Route>
  );

  const supplyOrders = AuthObj?.isLoggedIn ? (
    AuthObj.isUnlinkedFacilityOrder ? (
      <SupplyOrder />
    ) : AuthObj?.userRolesAndPermissionLoaded ? (
      getProperty(AuthObj?.sideNavMenuOptionModelData, "orders").isVisible ? (
        <SupplyOrder />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : (
      <SupplyOrder />
    )
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const supplyOrderDetail = AuthObj?.isLoggedIn ? (
    AuthObj?.deepLinkPath?.includes("/orders/supplyOrderDetail/deepLink/") ? (
      <InPatientSupplyOrderDetail />
    ) : AuthObj?.userRolesAndPermissionLoaded ? (
      permissionObj?.mappedRolesPermissionData.IsShowFacilitySupplyOrders ? (
        <InPatientSupplyOrderDetail />
      ) : (
        <Route>
          <Redirect to="/home" />
        </Route>
      )
    ) : null
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );
  return (location.pathname === "/helpAndSupport" && !AuthObj?.isLoggedIn) ||
    (location.pathname === "/helpAndSupport/sysreq" && !AuthObj?.isLoggedIn) ||
    (location.pathname === "/helpAndSupport/faq" && !AuthObj?.isLoggedIn) ||
    (location.pathname === "/education" && !AuthObj?.isLoggedIn) ||
    (location.pathname === "/helpAndSupport/ContactUs" &&
      !AuthObj?.isLoggedIn) ||
    (location.pathname === "/helpAndSupport/ContactUsSent" &&
      !AuthObj?.isLoggedIn) ||
    (location.pathname === "/education" && !AuthObj?.isLoggedIn) ||
    (location.pathname === "/education/videoLibrary" && !AuthObj?.isLoggedIn) ||
    (location.pathname === "/productsAndSolutions" && !AuthObj?.isLoggedIn) ||
    (location.pathname === "/productsAndSolutions/productDetail" &&
      !AuthObj?.isLoggedIn) ? (
    <> </>
  ) : (
    <PageDiv data-testid="pageComponentTest">
      {localStorage.getItem("isComingFromSSO") !== "true" &&
        permissionObj &&
        !permissionObj.mappedRolesPermissionData.IsBaseRole &&
        permissionObj?.isMobileViewVisible && <FacilityBanner />}
      {location.pathname !== "/salesRounding" && (
        <div className="alertInPageContainerInPage">
          {siteStatusesObj?.fullScreenSiteStatues &&
            siteStatusesObj?.fullScreenSiteStatues.length > 0 && (
              <SiteStatusBanners
                siteStatuses={siteStatusesObj?.fullScreenSiteStatues}
                handleCloseButtonAction={handleCloseButton}
              />
            )}
        </div>
      )}
      <MainPage>
        {AuthObj?.isInternalUserFacilitySelected && <SideNav />}
        <SubPageDiv>
          <RouteSection>
            <PageContext.Provider
              value={{
                salesRepContacts: salesRepInfo!,
                techRepContacts: techSupportInfo!,
                pssRepContacts: pssSupportInfo!,
                deviceSupportNumber: deviceTroubleshootNumber!,
              }}
            >
              <PrivateRoute path="/home" exact component={MyPatients} />
              <Route path="/home/orderOverview">{orderOverviewRoute}</Route>
              <Route path="/orders" exact>
                {newOrderOptions}
              </Route>
              <Route path="/orders/newOrder">{orders}</Route>
              <Route path="/orders/inpatientOrder">{inpatientOrder}</Route>
              <Route path="/orders/inpatientSupplyOrder">
                {inpatientSupplyOrder}
              </Route>
              <Route path="/orders/inpatientStockSupplyOrder">
                {inpatientStockOrder}
              </Route>
              <Route path="/salesAssistance">{neededHelp}</Route>
              <Route path="/inventory" exact>
                {inventory}
              </Route>
              <PrivateRoute
                path="/inventory/inventoryAdjustment"
                exact
                component={InventoryRequest}
              />
              <Route path="/inventory/createServiceRequest" exact>
                {createServiceRequest}
              </Route>
              <Route path="/home/createServiceRequest" exact>
                {createServiceRequest}
              </Route>
              <Route path="/administration" exact>
                {administration}
              </Route>
              <Route path="/internalAdministration" exact>
                {internalAdministration}
              </Route>
              <Route path="/viewReports" exact>
                {viewReports}
              </Route>
              <Route path="/lifeOfAnOrderGuide" exact>
                {lifeOfAnOrderGuide}
              </Route>
              <Route path="/salesRounding" exact>
                {salesRoundingTool}
              </Route>
              <Route path="/administration/manageUsers" exact>
                {manageUsers}
              </Route>
              <Route path="/administration/salesManageUsers" exact>
                {salesManageUsers}
              </Route>
              <Route
                path="/administration/salesManageUsers/saleUserProfile"
                exact
              >
                {salesManageUserProfile}
              </Route>
              <Route path="/administration/manageTerritories" exact>
                {manageTerritories}
              </Route>
              <Route
                path="/administration/manageTerritories/territoryDetail"
                exact
              >
                {territoriesDetail}
              </Route>
              <Route path="/administration/history" exact>
                {salesManagerHistory}
              </Route>
              <Route path="/administration/manageUserAccounts" exact>
                {manageUserAccounts}
              </Route>
              <Route path="/administration/manageSiteStatuses" exact>
                {manageSiteStatuses}
              </Route>
              <Route
                path="/administration/manageSiteStatuses/siteStatusDetail"
                exact
              >
                {addSiteStatus}
              </Route>
              <Route path="/administration/manageUsers/deepLink">
                {manageUsers}
              </Route>
              <Route path="/administration/manageUsers/userProfile" exact>
                {userProfile}
              </Route>
              <Route path="/administration/manageUsers/userProfile/deepLink">
                {userProfile}
              </Route>
              <Route path="/administration/rolesPermissions" exact>
                {adminRolesAndPermissions}
              </Route>
              <Route path="/administration/myLists" exact>
                {myLists}
              </Route>
              <Route path="/administration/facilitySettings" exact>
                {facilitySettings}
              </Route>
              <Route path="/education" exact>
                {education}
              </Route>
              <Route path="/education/videoLibrary" exact>
                {educationVideoLibrary}
              </Route>
              <Route path="/productsAndSolutions" exact>
                {productsAndSolutions}
              </Route>
              <Route path="/productsAndSolutions/productDetail" exact>
                {productDetail}
              </Route>
              <Route path="/orders/supplyOrderList">{supplyOrders}</Route>
              <Route path="/orders/supplyOrderDetail">
                {supplyOrderDetail}
              </Route>
              <PrivateRoute
                path="/home/pickUpRequest"
                component={PickUpRequest}
              />
              <PrivateRoute
                path="/home/dischargeRequest"
                component={DischargeRequest}
              />
              <PrivateRoute
                path="/reviewDischargeRequest"
                component={ReviewDischargeRequest}
              />
              <PrivateRoute
                path="/addWoundAssessment"
                component={AddWoundAssessmentContainer}
              />
              <PrivateRoute
                path="/home/proofOfDelivery"
                component={SubmitProofOfDelivery}
              />
              <PrivateRoute path="/home/sendNote" component={SendNote} />
              <PrivateRoute
                path="/home/confirmPlacement"
                component={ConfirmPlacement}
              />
              <PrivateRoute
                path="/home/exchangeVacUnit"
                component={ExchangeVac}
              />
              <PrivateRoute
                path="/home/acuteOrderPickUpRequest"
                component={AcuteOrderPickUpContainer}
              />
              <PrivateRoute
                path="/orders/orderSuccess"
                component={OrderSuccess}
              />
              {AuthObj?.isLoggedIn &&
                !AuthObj?.isInternalUserFacilitySelected && (
                  <>
                    <Route path="/helpAndSupport">{internalHelpSupport} </Route>
                    <Route path="/sysreq"> {internalSysreq} </Route>
                  </>
                )}
              {AuthObj?.isInternalUserFacilitySelected &&
                AuthObj?.isLoggedIn && (
                  <>
                    <Route path="/helpAndSupport" component={HelpAndSupport} />
                    <Route path="/sysreq" component={SystemRequirements} />
                  </>
                )}
            </PageContext.Provider>
          </RouteSection>
        </SubPageDiv>
      </MainPage>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={() => {
          setErrorPopUpFlag(false);
        }}
        popUpStyles="errorPopupSiteStatus"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
    </PageDiv>
  );
};
