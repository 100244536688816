import React, { useContext, useEffect } from "react";
import { IProductInformationInfo } from "./productInformation.interface";
import "./productInformation.css";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Box,
  Typography,
} from "@mui/material";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { format } from "react-string-format";
import { DD_PRODUCT_INFORMATION_CONTENT } from "../../../util/staticText";
import { getdropDownContent } from "../../../util/dropDownService";
import { ReactComponent as RadioButtonIcon } from "../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../assets/selectedRadioButton.svg";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import {
  SpecialProduct,
  checkSelectedProdcutForType,
  getCodeFromText,
  getCustomerMasterNumber,
  getSiteUseId,
  getSiteUseIdFromNewOrderContext,
  getTextFromCode,
} from "../../../util/utilityFunctions";
import { ProductInformationReviewOrder } from "./reviewOrder/productInformationReviewOrder.component";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";

import { IDressingKit, IProductInfo } from "../newOrder.interface";
import { getInventoryInfoList } from "../../../util/inventoryMgrService";
import { MobileDisplayContext } from "../../../context/MobileDisplayContext";
import { Popup } from "../../../core/popup/popup.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../context/InternalSignOnContext";

export const ProductInformation = ({
  productInfo,
  setProductInfo,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
  data,
  setData,
  vacAllProducts,
}: IProductInformationInfo) => {
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const [prodInfoTypes, setProdInfoTypes] = React.useState([]);
  const [prodInfoTypesText, setProdInfoTypesText] = React.useState([]);
  const [active, setActive] = React.useState<boolean>(
    productInfo.productInformation.value === "yes" ? true : false
  );
  const [productConfirmationPopup, setProductConfirmationPopup] =
    React.useState<boolean>(false);

  const checkForReadyCareFlag = () => {
    return (
      (newOrderObj?.newOrderData &&
        newOrderObj?.newOrderData.requestingFacility?.value &&
        newOrderObj?.newOrderData.requestingFacility?.value?.readyCareFlag &&
        newOrderObj?.newOrderData.requestingFacility?.value?.readyCareFlag ===
          "Y") ||
      (authObj &&
        authObj.registeredFaciltyAddress &&
        authObj.registeredFaciltyAddress.readyCareFlag &&
        authObj.registeredFaciltyAddress.readyCareFlag === "Y")
    );
  };

  const getInventoryList = async () => {
    newOrderObj?.setInventoryProductCount(0);
    const customerNumber =
      internalObj && internalObj.isVACOrderSSO
        ? data?.requestingFacility?.value?.accountNumber
        : authObj?.registeredFaciltyAddress?.accountNumber;
    const siteUseId =
      internalObj && internalObj.isVACOrderSSO
        ? getSiteUseIdFromNewOrderContext(newOrderObj)
        : getSiteUseId(authObj);
    let reqParams = {
      siteUseId: siteUseId,
      customerNumber: customerNumber,
      customerMasterNumber: getCustomerMasterNumber(authObj),
    };
    try {
      const response = await getInventoryInfoList(reqParams);
      if (response && !response.error) {
        newOrderObj?.setInventoryProductCount(response.items.length);
        if (
          newOrderObj &&
          newOrderObj.vacOrderID === "" &&
          !newOrderObj.isComingFromPrev &&
          !isReviewOrder
        ) {
          if (response.items.length > 0) {
            if (productInfo.productInformation.value !== "yes") {
              setProductInfo((productInfo: IProductInfo) => ({
                ...productInfo,
                productInformation: {
                  value: "yes",
                  valid: ValidationStatus.VALID,
                },
              }));
            }
          } else {
            if (productInfo.productInformation.value !== "no") {
              setProductInfo((productInfo: IProductInfo) => ({
                ...productInfo,
                productInformation: {
                  value: "no",
                  valid: ValidationStatus.VALID,
                },
              }));
            }
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchproductType = async () => {
    try {
      const ddContent = format("{0}", DD_PRODUCT_INFORMATION_CONTENT);
      const dropDowndata = await getdropDownContent(ddContent);
      if (dropDowndata.items.length > 0) {
        const accidentTypeArray = dropDowndata.items[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setProdInfoTypes(accidentTypeArray);
        setProdInfoTypesText(
          accidentTypeArray.map((x: { text: string }) => x.text)
        );
        if (!newOrderObj?.isComingFromPrev && !isReviewOrder) {
          setProductInfo((productInfo: IProductInfo) => ({
            ...productInfo,
            productValue: {
              value: getTextFromCode(prodInfoTypes, "1"),
              valid: ValidationStatus.VALID,
              isDefaultValid: true,
            },
          }));
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateAndSetData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    let productInformation: string = productInfo.productInformation.value;
    let productValue: string = productInfo.productValue.value;
    if (e.target.name === "productInformation") {
      if (e.target.value === "yes") {
        setActive(true);
      } else {
        setProductConfirmationPopup(true);
      }
      productInformation = e.target.value;
      productValue = "1";
    } else if (e.target.name === "productValue") {
      productValue = getCodeFromText(prodInfoTypes, e.target.value);
    }
    let tempData = {
      productInformation: {
        value: productInformation,
        valid: ValidationStatus.VALID,
      },
      productValue: {
        value: productValue ?? "",
        valid: ValidationStatus.VALID,
      },
    };
    if (e.target.value === "yes") {
      setProductInfo(tempData);
      updateDressingKit(tempData);
      updateCanister(tempData);
    } else {
      setProductInfo((p: IProductInfo) => ({
        ...p,
        productValue: {
          value: productValue ?? "",
          valid: ValidationStatus.VALID,
        },
      }));
    }
  };

  const updateDressingKit = (productInfoData: IProductInfo) => {
    const data = newOrderObj!.dressingKit;
    const setData = newOrderObj!.setDressingKit;
    let tempData = data;
    const isPeelAndPlace = checkSelectedProdcutForType(
      vacAllProducts?.items ?? [],
      SpecialProduct.PEELANDPLACE,
      tempData.productName.value
    );
    if (productInfoData.productInformation.value === "yes") {
      newOrderObj?.setShowAddDressingBtn(
        newOrderObj && newOrderObj.showPrimaryDressingKit ? false : true
      );
      newOrderObj?.setShowSecondaryDressingKit(false);
      tempData = handleResetSecondaryDressingKit(tempData);
      if (newOrderObj && newOrderObj.showPrimaryDressingKit) {
        if (data.productName.value === "") {
          newOrderObj?.setShowSize(false);
          newOrderObj?.setshowQunatity(false);
        }
        const defaultPrimaryQuantity = isPeelAndPlace
          ? "1"
          : checkForReadyCareFlag() &&
            productInfo.productInformation.value &&
            productInfoData.productInformation.value === "yes"
          ? "2"
          : "3";
        tempData = {
          ...tempData,
          productQuantity: {
            value: defaultPrimaryQuantity,
            valid: ValidationStatus.VALID,
          },
        };
      }
    } else if (productInfoData.productInformation.value === "no") {
      if (!newOrderObj?.showSecondaryDressingKit) {
        newOrderObj?.setShowAddDressingBtn(true);
      }
      if (data.productName.value === "") {
        newOrderObj?.setShowSize(false);
        newOrderObj?.setshowQunatity(false);
      }
      tempData = {
        ...tempData,
        productQuantity: {
          value: isPeelAndPlace ? "1" : "3",
          valid: ValidationStatus.VALID,
        },
      };
      if (newOrderObj?.showSecondaryDressingKit) {
        tempData = {
          ...tempData,
          secProductQuantity: {
            value: isPeelAndPlace ? tempData.secProductQuantity.value : "1",
            valid: ValidationStatus.VALID,
          },
        };
      }
    }
    setData(tempData);
  };

  const handleResetSecondaryDressingKit = (
    data: IDressingKit
  ): IDressingKit => {
    let tempData = {
      ...data,
      secProductName: {
        ...data.secProductName,
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductSizeName: {
        ...data.secProductSizeName,
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductId: { value: "", valid: ValidationStatus.VALID },
      secProductCode: { value: "", valid: ValidationStatus.VALID },
      secProductSizID: {
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductSizeCode: {
        valid: ValidationStatus.VALID,
        value: "",
      },
      secProductQuantity: {
        value: "0",
        valid: ValidationStatus.VALID,
      },
    };
    newOrderObj?.setShowSecSize(false);
    newOrderObj?.setshowSecQunatity(false);
    return tempData;
  };

  const updateCanister = (productInfoData: IProductInfo) => {
    const data = newOrderObj!.canister;
    const setData = newOrderObj!.setCanister;
    if (newOrderObj && !newOrderObj.isComingFromPrev) {
      const tempData = {
        ...data,
        canisterProductQuantity: {
          value: getCanisterQuantity(productInfoData),
          valid: ValidationStatus.VALID,
          isDefaultValid: true,
        },
      };
      setData(tempData);
    }
  };

  const getCanisterQuantity = (productInfoData: IProductInfo): string => {
    let canisterQuantity: string =
      checkForReadyCareFlag() &&
      productInfo.productInformation.value &&
      productInfoData.productInformation.value === "yes"
        ? "1"
        : "2";
    return canisterQuantity;
  };

  useEffect(() => {
    fetchproductType();
  }, []);

  useEffect(() => {
    if (newOrderObj && !newOrderObj.isComingFromPrev && !isReviewOrder) {
      if (checkForReadyCareFlag()) {
        getInventoryList();
      } else if (productInfo.productInformation.value !== "no") {
        productInfo.productInformation.value = "no";
      }
    }
  }, [
    authObj?.registeredFaciltyAddress,
    JSON.stringify(data?.requestingFacility?.value),
  ]);

  useEffect(() => {
    if (productInfo.productInformation.value === "yes") {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [productInfo.productInformation.value]);

  const mapProductInfo = (): any => {
    return (
      <p className="product-info">
        Will this order be using one of your{" "}
        {newOrderObj && newOrderObj.inventoryProductCount >= 0 ? (
          <b>{newOrderObj.inventoryProductCount} </b>
        ) : (
          ` `
        )}
        3M™ V.A.C.<sup>®</sup> Ready Care Program kits?
        <span className="star-red">*</span>
      </p>
    );
  };

  const mapProductTitle = (): any => {
    return (
      <p className="productName">
        The following products will be given to the patient with the 3M™ V.A.C.
        <sup>®</sup> Ready Care Program kit
      </p>
    );
  };

  const handleProductConfirmationClick = (value: string) => {
    newOrderObj?.setshowCannisterType(false);
    let tempData = {
      ...productInfo,
      productInformation: {
        value: value,
        valid: ValidationStatus.VALID,
      },
    };
    setProductInfo(tempData);
    setProductConfirmationPopup(false);
    setActive(true);
    updateCanister(tempData);
    updateDressingKit(tempData);
    if (!newOrderObj?.showPrimaryDressingKit) {
      let dressingKit = newOrderObj!.dressingKit;
      newOrderObj!.setDressingKit({
        ...dressingKit,
        productName: {
          ...dressingKit.productName,
          isOptional: false,
          required: true,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        productSizeName: {
          ...dressingKit.productSizeName,
          isOptional: false,
          valid: ValidationStatus.UNTOUCHED,
          value: "",
        },
        productId: { value: "", valid: ValidationStatus.VALID },
        productCode: { value: "", valid: ValidationStatus.VALID },
        productSizeID: { value: "", valid: ValidationStatus.VALID },
        productSizeCode: { value: "", valid: ValidationStatus.VALID },
        productQuantity: {
          value: "3",
          valid: ValidationStatus.UNTOUCHED,
        },
      });
      newOrderObj?.setShowPrimaryDressingKit(true);
    }
  };

  const handleClose = () => {
    setProductConfirmationPopup(false);
  };

  return (
    <div className="productInformation-component">
      {!isReviewOrder && (
        <div className="productInformation">
          <h2 data-testid="prodTitleTest" className="productHeader">
            Product Information
          </h2>
          {(authObj?.registeredFaciltyAddress?.readyCareFlag === "Y" ||
            data?.requestingFacility?.value?.readyCareFlag === "Y") && (
            <InputWithLabel
              testId="prodInfoTitleTest"
              label={mapProductInfo()}
              error={
                productInfo.productInformation.valid ===
                ValidationStatus.INVALID
              }
              labelClassName="prodDescLabel"
            >
              <Box className="prodBoxContianer" sx={{ flexGrow: 1 }}>
                <Grid className="prodInfoGridContainer" container spacing={2}>
                  <Grid
                    className="prodInfoGridItem"
                    item
                    xs={isMobileScreen ? 12 : 6}
                  >
                    <RadioGroup
                      name="productInformation"
                      classes={{ root: "prodInfoRadioRoot" }}
                      onChange={validateAndSetData}
                      value={productInfo.productInformation.value}
                    >
                      <FormControlLabel
                        classes={{
                          root:
                            active === true
                              ? "prodInfoOptionRootActive"
                              : "prodInfoOptionRoot",
                        }}
                        componentsProps={{
                          typography: {
                            classes: { root: "prodInfoOptiontxt" },
                          },
                        }}
                        control={
                          <Radio
                            icon={<RadioButtonIcon />}
                            checkedIcon={<SelectedRadioButtonIcon />}
                          />
                        }
                        data-testid="prod-information-yes"
                        label="Yes"
                        value="yes"
                      />
                      <FormControlLabel
                        classes={{
                          root:
                            active === false
                              ? "prodInfoOptionRootActive"
                              : "prodInfoOptionRoot",
                        }}
                        componentsProps={{
                          typography: {
                            classes: { root: "prodInfoOptiontxt" },
                          },
                        }}
                        control={
                          <Radio
                            icon={<RadioButtonIcon />}
                            checkedIcon={<SelectedRadioButtonIcon />}
                          />
                        }
                        data-testid="prod-information-no"
                        label="No"
                        value="no"
                        className="rootClass"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Box>
            </InputWithLabel>
          )}
          <div className="prodSelectContainer">
            {productInfo.productInformation.value === "yes" &&
            (authObj?.registeredFaciltyAddress?.readyCareFlag === "Y" ||
              data?.requestingFacility?.value?.readyCareFlag === "Y") ? (
              <InputWithLabel isRequired={false} label={mapProductTitle()}>
                <div className="products">
                  <div className="productInfo">
                    {productInfo.productInformation.value === "yes" && (
                      <>
                        <span className="dot-with-space">&bull; </span>
                        <div>
                          <p
                            className="productContent"
                            data-testid="prod-title"
                          >
                            {productInfo.productValue.value
                              ? getTextFromCode(
                                  prodInfoTypes,
                                  productInfo.productValue.value
                                )
                              : ""}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="productInfo">
                    {productInfo.productInformation.value === "yes" && (
                      <>
                        <span className="dot-with-space">&bull; </span>
                        <div>
                          <p
                            className="productContent"
                            data-testid="dressing-kit"
                          >
                            1 Case of 5 - 3M™ V.A.C.® Granufoam™ Dressing Kit,
                            Medium (M8275052/5)
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="productInfo">
                    {productInfo.productInformation.value === "yes" && (
                      <>
                        <span className="dot-with-space">&bull; </span>
                        <div>
                          <p className="productContent" data-testid="canister">
                            1 Case of 5 - ACTIV.A.C™ 300mL Canister w/Gel,
                            (M8275058/5)
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </InputWithLabel>
            ) : (
              <InputWithLabel
                testId="prod-title"
                isRequired={true}
                label="Select Product"
                error={
                  productInfo.productValue?.valid === ValidationStatus.INVALID
                }
                isDropdown={true}
              >
                <CustomDropDown
                  name="productValue"
                  value={
                    productInfo.productValue.value
                      ? getTextFromCode(
                          prodInfoTypes,
                          productInfo.productValue.value
                        )
                      : null
                  }
                  handleChange={validateAndSetData}
                  selectpropsClassName="prod-info-select"
                  selectClassName="prod-info-input prod-info"
                  testId="prod-info-type"
                  menuItem={prodInfoTypesText}
                />
              </InputWithLabel>
            )}
          </div>
        </div>
      )}
      {isReviewOrder && (
        <ProductInformationReviewOrder
          productInfo={productInfo}
          editButtonClicked={editButtonClicked}
          productValues={prodInfoTypes}
          isOrderSummary={isOrderSummary}
          isReadyCare={authObj?.registeredFaciltyAddress?.readyCareFlag === "Y"}
        />
      )}
      <Popup
        openFlag={productConfirmationPopup}
        dialogParentClass="product-confirmation"
        closeHandler={handleClose}
      >
        <div data-testid="confirmation-popup">
          <Typography
            className="product-confirmation-header"
            data-testid="product-confirmation-header"
          >
            Are you sure you do not want to use a 3M™ V.A.C.<sup>®</sup> Ready
            Care Program kit?
          </Typography>
          <Typography className="product-confirmation-description">
            Using a device that is not part of a V.A.C.<sup>®</sup> Ready Care
            Program kit will require that device to be delivered from a 3M
            Service center. Please account for additional delivery time if the
            ship my device option is selected.
          </Typography>
          <div className="btn-grp">
            <ExpressButton
              clickHandler={() => handleProductConfirmationClick("yes")}
              variant={"outlined"}
              parentClass="product-confirmation-btn"
              testId="No-Button"
            >
              No, use a V.A.C.<sup className="sup-tag">® </sup>
              Ready Care Program kit
            </ExpressButton>
            <ExpressButton
              clickHandler={() => handleProductConfirmationClick("no")}
              variant={"contained"}
              parentClass="product-confirmation-btn"
              testId="Yes-Button"
            >
              Yes, ship my device instead
            </ExpressButton>
          </div>
        </div>
      </Popup>
    </div>
  );
};
