import { IUserRolesPermission } from "../components/myPatients/userRolesPermission.interface";
import { getDeepClone } from "../util/ObjectFunctions";
import { defaultPermissionDataModel } from "./RolesPermission.model";

export const mapUserRolesAndPermissionData = async (
  userRolePermissionData: IUserRolesPermission,
  readyCareFlag: any
) => {
  let permissionData: any;
  permissionData = getDeepClone(defaultPermissionDataModel);
  const permission: any = {
    INVENTORY: "IsShowInventoryOption",
    INVENTORY_READY_CARE: "IsShowInventoryReadyCareTab",
    INVENTORY_CUSTOMER_PLUS: "IsShowInventoryCustomerPlusTab",
    INVENTORY_STORED_PRODUCT: "IsShowInventoryStoredProductTab",
    RENTAL_ORDERS: "IsShowVacOrderButton",
    SALES_ORDERS: "IsShowSupplyOrderButton",
    FACILITY_SETTINGS: "IsAdminFacilitySettingsButton",
    WOUND_ASSESSMENT_REPORT: "IsAdminWoundAssessmentReportButton",
    FACILITY_USERS: "IsAdminFacilityUsersButton",
    MY_LIST: "IsAdminMyListsButton",
    ROLE_PERMISSIONS: "IsAdminRolesPermissionButton",
    USER_ACCOUNTS: "IsAdminUserAccounts",
    SITE_STATUS: "IsPrdMgrSiteStatus",
    HISTORY: "IsSalesMgrHistoryBtn",
    SALES_PEOPLE: "IsSalesMgrPeopleBtn",
    TERRITORIES: "IsSalesTerritoriesBtn",
    WOUND_MEASUREMENTS: "IsShowAddWoundAssessmentMenu",
    "3M_REP_ACCESS": "IsSalesRepDetails",
    SEARCH_HOMECARE_ORDER: "IsSearchForHomeCareOrder",
    VIEW_REPORT_SALES: "IsViewReport",
    IN_PATIENT_ORDERS: "IsShowInPatientOrderButton",
    IN_PATIENT_SUPPLY_ORDERS: "IsShowInPatientSupplyOrderButton",
    IN_PATIENT_STOCK_SUPPLY_ORDERS: "IsShowInPatientStockSupplyOrderButton",
    VIEW_FACILITY_SUPPLY_ORDER: "IsShowFacilitySupplyOrders",
    SALES_ROUNDING_TOOL: "IsSalesRoundingTool",
    LIFE_OF_ORDER_GUIDE: "IsLifeOfAnOrderGuide",
  };
  const role: any = {
    SUPPORT: "IsSupportRole",
    SALES: "IsSalesRole",
    "SALES MANAGER": "IsSalesManagerRole",
    CLINICIAN: "IsClinicianRole",
    "FACILITY ADMIN": "IsFacilityAdminRole",
    "3M ADMIN": "Is3MAdminRole",
    BASEUSER: "IsBaseRole",
    "PRODUCT MANAGER": "IsProdManagerRole",
    NATIONAL: "IsNationalRole",
  };
  const pagePermission: any = {
    ADMINISTRATIVE_SETTINGS: "IsShowAdminstrationOption",
    MANAGE_ACCOUNT: "IsShowManageAccountMenu",
    NEW_ORDER: "IsShowStartNewOrder",
    MANAGE_USERS: "IsManageUsersBtn",
  };

  const hasInventoryChildPermission = (permissions: string[]): boolean => {
    for (let key of permissions) {
      if (
        (key === "INVENTORY_READY_CARE" && readyCareFlag === "Y") ||
        key === "INVENTORY_CUSTOMER_PLUS" ||
        key === "INVENTORY_STORED_PRODUCT"
      ) {
        return true;
      }
    }
    return false;
  };

  if (userRolePermissionData) {
    if (role.hasOwnProperty(userRolePermissionData.userRole)) {
      let roleVal = role[userRolePermissionData.userRole];
      permissionData.roleName = userRolePermissionData.userRole;
      permissionData[roleVal] = true;
    }
    if (userRolePermissionData.permissions.length > 0) {
      let inventoryPermissionGranted = hasInventoryChildPermission(
        userRolePermissionData.permissions
      );

      userRolePermissionData.permissions.forEach((key: string) => {
        if (permission.hasOwnProperty(key)) {
          let keyValue = permission[key];
          if (key === "INVENTORY") {
            permissionData[keyValue] = inventoryPermissionGranted && true;
          } else if (key === "INVENTORY_READY_CARE") {
            if (readyCareFlag === "Y") {
              permissionData[keyValue] = true;
            }
          } else {
            permissionData[keyValue] = true;
          }
        }
      });
    }
  }
  if (
    userRolePermissionData &&
    userRolePermissionData.pagePermissions.length > 0
  ) {
    // to set pagePermission
    userRolePermissionData.pagePermissions.forEach((x: string) => {
      if (pagePermission.hasOwnProperty(x)) {
        let value = pagePermission[x];
        if (x === "NEW_ORDER") {
          if (
            permissionData.IsShowVacOrderButton ||
            permissionData.IsShowSupplyOrderButton
          ) {
            permissionData[value] = true;
          } else {
            permissionData[value] = false;
          }
        } else {
          permissionData[value] = true;
        }
      }
    });
  }
  return permissionData;
};
