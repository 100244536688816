import { IInventoryMenuActionItem } from "../inventoryTabContainer.enum";

export enum CustomerPlusListingTableTypes {
  AVAILABLE_UNITS = "Available Units",
  UNITS_IN_USE = "Units in Use",
  UNITS_NEEDING_PREPARATION = "Units Needing Preparation",
}

export interface ICustomerPlusListingDetails {
  id: number;
  unitTagId: string;
  productName: string;
  productNameDescription?: string;
  serialNumber: string;
  currentLocation: string | null;
  storageDays: string;
  status: string;
  patientFirstName: string | null;
  patientLastName: string | null;
  therapyStartDate: string | null;
  therapyEndDate: string | null;
  availableDate: string;
  menuItems: IInventoryMenuActionItem[];
  storageLocation?: string;
  roNumber: string;
  ropn?: string | null;
  isServiceRequested: boolean;
  errorCode?: number;
  isMissingAsset?: boolean;
}

export interface ICustomerListingData {
  availableUnits: ICustomerPlusListingDetails[];
  unitsInUse: ICustomerPlusListingDetails[];
  unitsNeedingPreparation: ICustomerPlusListingDetails[];
}
