import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Navigator } from "../../components/helpAndSupport/Navigator/navigator.component";
import {
  ConfirmPlacementContext,
  ConfirmPlacementContextType,
} from "../../context/ConfirmPlacementContext";
import { LoadingSpinner } from "../../core/loader/LoadingSpinner";
import { PatientDetails } from "../pickUpAndDischargeRequest/pickUpRequest/patientDetails/patientDetails.component";
import "./confirmPlacement.css";
import { ConfirmPlacementFooter } from "./confirmPlacementFooter/confirmPlacementFooterButtonGroup.component";
import { DataEntryAndDressingsSections } from "./dataEntryVerifyAndDressingsSection/dataEntryVerifyAndDressings.component";
import {
  CONFIRM_PLACEMENT_RESTRICTION_ERRORMESSAGE,
  CONFIRM_PLACEMENT_ERRORTEXT,
  CONFIRM_PLACEMENT_SERAIL_NUMBER_FETCH_ERRORTEXT,
  CONFIRM_PLACEMENT_SERAIL_NUMBER_NOT_PRESENT,
  Contact_US,
  DD_TIME_OF_PLACEMENT_CONTENT,
  CONFIRM_PLACEMENT_DB_UPDATE_ERRORTEXT,
} from "../../util/staticText";
import { getdropDownContent } from "../../util/dropDownService";
import { format } from "react-string-format";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { getInventoryInfoList } from "../../util/inventoryMgrService";
import moment from "moment";
import { getVACOrderDetails } from "../../util/vacOrderService";
import { VacOrderSummaryData } from "../newOrder/mapper/VacOrderSummary/newOrderResponseMapper.interface";
import { getVacDressingKitProducts } from "../../util/3meService";
import { getSelectedVacProduct } from "../newOrder/mapper/VacOrderSummary/vacProductsMapper";
import { Product } from "../newOrder/mapper/newOrderRequestMapper.interface";
import { ProductInformation } from "../newOrder/dressingSupplies/vacDressingKit/vacDressingKit.interface";
import { ValidationStatus } from "../../core/interfaces/input.interface";
import { ICanister, IDressingKit } from "../newOrder/newOrder.interface";
import { Popup } from "../../core/popup/popup.component";
import { SendNoteFailure } from "../send3MNote/popUps/failurePopUp/sendNoteFailure.component";
import { IPatient } from "../myPatients/patient.interface";
import { IInventoryProduct } from "./confirmPlacement.interface";
import { SuccessPopupForConfirmPlacement } from "./successConfirmPlacementPopup/successConfirmPlacementPopup.component";
import { updateConfirmPlacementDetails } from "../../util/confirmPlacement.service";
import ErrorPopup from "../../core/errorPopup/errorPopup.component";
import {
  ERROR_CONFIRM_PLACEMENT_SITEID_MATCHED,
  ERROR_MSG_CONFIRM_PLACEMENT,
} from "../../util/errorMsg";
import { ExpressButton } from "../../core/expressButton/expressButton.component";
import { log } from "console";
import {
  getCustomerMasterNumber,
  getSiteUseId,
} from "../../util/utilityFunctions";
import {
  CONFIRM_PLACEMENT_ALREADY_COMPLETED,
  CONFIRM_PLACEMENT_SERIAL_NUMBER_EMPTY,
  PATIENT_OBJ_FROM_CONFIRM_PLACEMENT_CONTEXT_FAILURE,
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  ROPN_VALUE_EMPTY_STRING,
  USER_FACILITY_WO_FACILITY_DONOT_MATCH,
  USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  WO_NUMBER_EMPTY_STRING,
} from "../../util/errorCode";

export const ConfirmPlacement = () => {
  const history = useHistory();
  const location: any = useLocation();
  const [patientData] = useState<IPatient | null>(location.state?.stateData);

  const [error, setError] = useState(false);
  const [ropn, setRopn] = useState<string>("");
  const [releaseDate, setReleaseDate] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);
  const [placementTimes, setPlacementTimes] = useState([]);
  const [openLoaderPopUp, setOpenLoaderPopUp] = useState(false);
  const [placementTimeText, setPlacementTimeText] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const [serialNumbersText, setSerialNumbersText] = useState<string[]>([]);
  const [isConfimPlacement, setIsConfimPlacement] = useState(false);
  const [workOrderNumber, setWorkOrderNumber] = useState<string>("");
  const [openFailurePopUp, setOpenFailurePopUp] = useState<boolean>(false);
  const [openSuccessPopUp, setOpenSuccessPopUp] = useState<boolean>(false);
  // For Api validation else part show error pop up with code
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [title, setTitle] = useState(false);
  const [phoneNo, setPhoneNo] = useState<boolean>(true);
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const [deliveryPhoneNumber, setDeliveryPhoneNumber] = useState<string>("");
  const [inventoryProducts, setInventoryProducts] = useState<
    IInventoryProduct[]
  >([]);
  const [selectedInventoryProduct, setSelectedInventoryProduct] =
    useState<IInventoryProduct | null>(null);
  const [confirmButtonDisabled, setconfirmButtonDisabled] =
    useState<boolean>(false);
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const confirmPlacementObj = useContext<ConfirmPlacementContextType | null>(
    ConfirmPlacementContext
  );
  const dressingKit = confirmPlacementObj?.dressingKit!;
  const setDressingKit = confirmPlacementObj?.setDressingKit!;
  const canister = confirmPlacementObj?.canister!;
  const setCanister = confirmPlacementObj?.setCanister!;
  const patient = confirmPlacementObj!.patient;

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0},{1}", DD_TIME_OF_PLACEMENT_CONTENT);
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const timeOfPlacementObject = data.items.filter(
          (item: { name: string }) => item.name === DD_TIME_OF_PLACEMENT_CONTENT
        );
        const timeOfPlacementData = timeOfPlacementObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setPlacementTimes(timeOfPlacementData);
        setPlacementTimeText(
          timeOfPlacementData.map((x: { text: string }) => x.text)
        );
        return true;
      }
      return false;
    } catch (error) {
      console.log("error", error);
    }
  };

  const getInventoryList = async () => {
    const customerNumber =
      authObj?.registeredFaciltyAddress?.accountNumber ?? "";
    if (customerNumber !== "") {
      let reqParams = {
        siteUseId: getSiteUseId(authObj),
        customerNumber: customerNumber,
        customerMasterNumber: getCustomerMasterNumber(authObj),
      };
      try {
        const response = await getInventoryInfoList(reqParams);
        if (!response || response.error) {
          return false;
        } else {
          const inventoryList: IInventoryProduct[] = response.items;
          setInventoryProducts(inventoryList);
          const serialNumbers = inventoryList.map((item) => item.serialNumber);
          const serialNumbersText = inventoryList.map(
            (item) =>
              `${item.serialNumber} - ${item.storageDays} days in storage`
          );
          if (serialNumbers.length > 0) {
            setSerialNumbers(serialNumbers);
            setSerialNumbersText(serialNumbersText);
            return true;
          } else {
            const err = CONFIRM_PLACEMENT_SERAIL_NUMBER_NOT_PRESENT;
            const errMsg = !isConfimPlacement ? err + " " + Contact_US : err;
            setIsLoading(false);
            setErrorMessage(errMsg);
            setErrorCode(CONFIRM_PLACEMENT_SERIAL_NUMBER_EMPTY);
            setErrorPopUpFlag(true);
            setPhoneNo(false);
          }
        }
      } catch (error) {
        console.log("error", error);
        return false;
      }
    }
  };

  const getOrderDetailsFromMS = async () => {
    if (
      patient &&
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      try {
        const reqParams = {
          RentalOrderNumber: patient.roNumber,
          DOB: moment(patient.dob).format("DD-MMM-yyyy"),
          userName: authObj.userProfile.userName,
          siteUseId: authObj.registeredFaciltyAddress.siteUseId,
        };
        const response = await getVACOrderDetails(reqParams);
        if (response.succeeded) {
          const vacOrderSummary: VacOrderSummaryData = response.item;
          return vacOrderSummary;
        } else {
          setErrorCode(
            response.error.errorCode || response.error.code || response.status
          );
        }
        return null;
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const fetchVacProducts = async () => {
    try {
      const response = await getVacDressingKitProducts();
      if (response.items.length > 0) {
        return response.items;
      }
      return null;
    } catch (error) {
      console.log("error", error);
    }
  };

  const confirmPlacement = async () => {
    if (
      authObj &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      patient &&
      ropn !== "" &&
      workOrderNumber !== ""
    ) {
      const serialNumberData =
        confirmPlacementObj!.confirmPlacementData.serialNumber.value.split(
          " - "
        );
      const serialNumber =
        serialNumberData.length > 0 ? serialNumberData[0] : "";
      const placementDate =
        confirmPlacementObj!.confirmPlacementData.placementDate.value;
      const placementTime =
        confirmPlacementObj!.confirmPlacementData.placementTime.value;
      const placementDateTimeString = `${placementDate} ${placementTime}`;
      const placementDateAndTime = moment(
        moment(placementDateTimeString, "MM/DD/YYYY hh:mm a")
          .utcOffset(0, true)
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      ).toISOString();
      try {
        let reqParams = {
          rentalOrderNumber: patient.roNumber,
          dob: moment(patient.dob).format("YYYY-MM-DD"),
          ropn: ropn,
          won: workOrderNumber,
          seqNumber: serialNumber,
          placementDateTime: placementDateAndTime,
          userEmail: authObj.userProfile.emailAddress,
          userFirstName: authObj.userProfile.firstName,
          userLastName: authObj.userProfile.lastName,
          reqFacilityZipCode: authObj.registeredFaciltyAddress.zip.toString(),
          reqFacilityAccountNumber:
            authObj.registeredFaciltyAddress.accountNumber,
          reqFacilityName: authObj.registeredFaciltyAddress.accountName,
          siteUseId: authObj.registeredFaciltyAddress.siteUseId ?? "",
          callerName:
            authObj.userProfile.firstName && authObj.userProfile.lastName
              ? `${authObj.userProfile.firstName} ${authObj.userProfile.lastName}`
              : "",
          callerPhone: authObj.userProfile.phoneNumber
            ? authObj.userProfile.phoneNumber
            : deliveryPhoneNumber
            ? deliveryPhoneNumber
            : "",
        };
        setOpenLoaderPopUp(true);
        const response = await updateConfirmPlacementDetails(reqParams);
        setOpenLoaderPopUp(false);
        if (response && response.succeeded && response.item.code === 0) {
          setOpenSuccessPopUp(true);
        } else {
          let errorCode =
            response?.error?.code ||
            response?.error?.errorCode ||
            response?.status;
          setErrorPopUpFlag(true);
          if (response && errorCode.toString() === "5159") {
            setErrorMessage(CONFIRM_PLACEMENT_DB_UPDATE_ERRORTEXT);
          } else {
            setErrorMessage(CONFIRM_PLACEMENT_ERRORTEXT);
          }
          setErrorCode(
            response.error.errorCode || response.error.code || response.status
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      if (!authObj || (authObj && !authObj.userProfile)) {
        setErrorCode(USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE);
      } else if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        setErrorCode(REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE);
      } else if (!patient) {
        setErrorCode(PATIENT_OBJ_FROM_CONFIRM_PLACEMENT_CONTEXT_FAILURE);
      } else if (ropn === "") {
        setErrorCode(ROPN_VALUE_EMPTY_STRING);
      } else if (workOrderNumber === "") {
        setErrorCode(WO_NUMBER_EMPTY_STRING);
      }
      setErrorMessage(ERROR_MSG_CONFIRM_PLACEMENT);
      setErrorPopUpFlag(true);
    }
  };
  const handleConfirmplacementPopupAndButton = (siteUseID: string) => {
    const registeredFacilty = authObj?.registeredFaciltyAddress;
    if (
      siteUseID === registeredFacilty?.siteUseId ||
      siteUseID === registeredFacilty?.careGiverSiteUseId
    ) {
      setErrorPopUpFlag(false);
      setconfirmButtonDisabled(false);
    } else {
      setErrorCode(USER_FACILITY_WO_FACILITY_DONOT_MATCH);
      setTitle(true);
      setErrorMessage(ERROR_CONFIRM_PLACEMENT_SITEID_MATCHED);
      setErrorPopUpFlag(true);
      setconfirmButtonDisabled(true);
    }
  };

  const fetchAllBasicDetails = async () => {
    setIsLoading(true);
    setIsClosed(false);
    let apiCollection: any[] = [
      getInventoryList(),
      fetchVacProducts(),
      getOrderDetailsFromMS(),
      fetchDropDownContent(),
    ];
    const result = await Promise.all(apiCollection);
    if (result[0]) {
      if (result[1] && result[2]) {
        const vacAllProducts: ProductInformation[] = result[1];
        const ropnValue = result[2].ropn ?? "";
        if (ropnValue !== "") {
          setRopn(ropnValue);
        }
        if (result[2].releaseDate) {
          setReleaseDate(result[2].releaseDate);
        }
        handleConfirmplacementPopupAndButton(result[2].requestor.siteUseID);
        const won = result[2].workOrderNumber;
        const wos = result[2].workOrderStatus;
        const isSerialNumberPresent = result[2].serialNumber !== "";

        if (isSerialNumberPresent && wos.toLowerCase() === "wo closed") {
          setErrorPopUpFlag(true);
          setErrorMessage(CONFIRM_PLACEMENT_RESTRICTION_ERRORMESSAGE);
          setErrorCode(CONFIRM_PLACEMENT_ALREADY_COMPLETED);
          setIsClosed(true);
          setPhoneNo(false);
        }
        if (won !== "") {
          setWorkOrderNumber(won);
        }
        const phoneNumber = result[2].deliveryPhoneNumber;
        if (phoneNumber !== "") {
          setDeliveryPhoneNumber(phoneNumber);
        }
        const mainDressing: Product | null = result[2].mainDressing;
        const additionalDressing: Product | null = result[2].additionalDressing;
        let tempDressKit: IDressingKit = dressingKit;
        if (mainDressing) {
          const value: IDressingKit | undefined = mapMainDressingKit(
            tempDressKit,
            mainDressing,
            vacAllProducts
          );
          if (value) {
            tempDressKit = value;
          }
        }
        if (additionalDressing) {
          const value: IDressingKit | undefined = mapSecondaryDressingKit(
            tempDressKit,
            additionalDressing,
            vacAllProducts
          );
          if (value) {
            tempDressKit = value;
          }
        }
        setDressingKit(tempDressKit);
        const canister: Product | null = result[2].canister;
        if (canister) {
          mapCanister(canister, vacAllProducts);
        }
      } else {
        setErrorMessage(ERROR_MSG_CONFIRM_PLACEMENT);
        setErrorPopUpFlag(true);
        setTitle(false);
      }
    }
    setIsLoading(false);
  };

  const closePopup = () => {
    setErrorPopUpFlag(false);
    if (isClosed) history.goBack();
  };

  const mapMainDressingKit = (
    dressingKitVal: IDressingKit,
    resultedDressingProduct: Product,
    vacAllProducts: ProductInformation[]
  ) => {
    const dressing = getSelectedVacProduct(
      resultedDressingProduct.sku,
      vacAllProducts
    )! as ProductInformation;
    if (dressing) {
      const filteredResults = dressing.sizes?.filter(
        (x: any) => x.sku === resultedDressingProduct.sku
      );
      const selectedDressingSize = filteredResults[0];
      const dressingSizeName = selectedDressingSize.name;
      return {
        ...dressingKitVal,
        productName: {
          value: dressing.productName,
          valid: ValidationStatus.VALID,
        },
        productSizeCode: {
          value: selectedDressingSize.sku,
          valid: ValidationStatus.VALID,
        },
        productSizeName: {
          value: dressingSizeName,
          valid: ValidationStatus.VALID,
        },
        productId: { value: "", valid: ValidationStatus.VALID },
        productCode: {
          value: dressing.sku,
          valid: ValidationStatus.VALID,
        },
        productQuantity: {
          value: resultedDressingProduct.quantity?.toString() ?? "0",
          valid: ValidationStatus.VALID,
        },
      };
    }
  };

  const mapSecondaryDressingKit = (
    dressingKitVal: IDressingKit,
    resultedDressingProduct: Product,
    vacAllProducts: ProductInformation[]
  ) => {
    const dressing = getSelectedVacProduct(
      resultedDressingProduct.sku,
      vacAllProducts
    )! as ProductInformation;
    if (dressing) {
      const filteredResults = dressing.sizes?.filter(
        (x: any) => x.sku === resultedDressingProduct.sku
      );
      const selectedDressingSize = filteredResults[0];
      const dressingSizeName = selectedDressingSize.name;
      return {
        ...dressingKitVal,
        secProductName: {
          value: dressing.productName,
          valid: ValidationStatus.VALID,
        },
        secProductSizeCode: {
          value: selectedDressingSize.sku,
          valid: ValidationStatus.VALID,
        },
        secProductSizeName: {
          value: dressingSizeName,
          valid: ValidationStatus.VALID,
        },
        secProductId: { value: "", valid: ValidationStatus.VALID },
        secProductCode: {
          value: dressing.sku,
          valid: ValidationStatus.VALID,
        },
        secProductQuantity: {
          value: resultedDressingProduct.quantity?.toString() ?? "0",
          valid: ValidationStatus.VALID,
        },
      };
    }
  };

  const mapCanister = (
    canisterData: Product,
    vacAllProducts: ProductInformation[]
  ) => {
    const canister = getSelectedVacProduct(
      canisterData.sku,
      vacAllProducts
    )! as ProductInformation;
    if (canister) {
      const tempCanister: ICanister = {
        canisterProductName: {
          valid: ValidationStatus.VALID,
          value: canister.productName,
        },
        canisterProductCode: {
          valid: ValidationStatus.VALID,
          value: canister.sku,
        },
        canisterProductQuantity: {
          valid: ValidationStatus.VALID,
          value: canisterData.quantity ? canisterData.quantity.toString() : "0",
          isOptional: true,
        },
        canisterProductID: {
          valid: ValidationStatus.VALID,
          value: canister.sku,
        },
      };
      setCanister(tempCanister);
    }
  };

  const setInventoryProduct = () => {
    const serialNumberData =
      confirmPlacementObj!.confirmPlacementData.serialNumber.value.split(" - ");
    const serialNumber = serialNumberData.length > 0 ? serialNumberData[0] : "";
    if (serialNumber !== "") {
      const filteredInventoryProduct = inventoryProducts.filter(
        (item: IInventoryProduct) => item.serialNumber === serialNumber
      );
      const inventoryProduct =
        filteredInventoryProduct.length > 0
          ? filteredInventoryProduct[0]
          : null;
      setSelectedInventoryProduct(inventoryProduct);
    }
  };

  const getPlacementDateAndTime = (): string => {
    if (confirmPlacementObj) {
      const placementDate = moment(
        confirmPlacementObj.confirmPlacementData.placementDate.value
      ).format("MM/DD/YYYY");
      const placementTime =
        confirmPlacementObj.confirmPlacementData.placementTime.value;
      return `${placementDate}, ${placementTime}`;
    }
    return "--";
  };

  const closePopUpAction = () => {
    setOpenFailurePopUp(false);
    if (isConfimPlacement) {
      setIsConfimPlacement(false);
    } else {
      history.goBack();
    }
  };

  const cancelConfirmPlacement = () => {
    confirmPlacementObj?.resetData();
    history.goBack();
  };

  const confirmPlacementAction = () => {
    setIsConfimPlacement(true);
    confirmPlacement();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (patientData) {
      confirmPlacementObj?.setPatient(patientData);
    }
    if (authObj && !authObj.userProfile) {
      history.replace("/home");
    }
  }, []);

  useEffect(() => {
    fetchAllBasicDetails();
  }, [authObj?.registeredFaciltyAddress?.accountNumber]);

  useEffect(() => {
    const isSerialNumberValid =
      confirmPlacementObj?.confirmPlacementData.serialNumber.valid ??
      ValidationStatus.UNTOUCHED;
    if (isSerialNumberValid === ValidationStatus.VALID) {
      setInventoryProduct();
    }
  }, [confirmPlacementObj?.confirmPlacementData.serialNumber]);

  return (
    <div
      className="confirm-main-component"
      data-testid="confirm-main-component"
    >
      <div
        className="confirm-request-component"
        data-testid="confirm-request-component"
      >
        {isLoading && (
          <div className="confirm-request-loader">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && error && (
          <>
            <Navigator
              array={[
                {
                  route: "/home",
                  pageName: "My Patients",
                },
              ]}
              className="confirm-request-route-section"
              title="Confirm Placement and Proof of Delivery"
            />
            <ConfirmPlacementFooter
              cancelButtonAction={cancelConfirmPlacement}
              confirmButtonAction={confirmPlacementAction}
            />
          </>
        )}
        {!isLoading && !error && patient && (
          <>
            <Navigator
              array={[
                {
                  route: "/home",
                  pageName: "My Patients",
                },
              ]}
              className="confirm-request-route-section"
              title="Confirm Placement and Proof of Delivery"
            />
            <div className="confirm-short-form">
              <h2
                className="confirm-request-component-header"
                data-testid="confirm-request-component-header"
              >
                Confirm Placement and Proof of Delivery
              </h2>
              <ul className="confirm-request-component-detail">
                <li>
                  Complete the Confirm Placement process to assign a 3M™ V.A.C.®
                  Ready Care Program Unit to the patient.
                </li>
                <li>
                  Once complete you will be able to print the Proof of Delivery
                  and Assignment of Benefits to complete the placement process.
                </li>
                <li>
                  During placement, please have the patient sign the Proof of
                  Delivery and Assignment of Benefits and submit it to 3M
                  Medical Solutions as soon as possible and provide the patient
                  with a copy.
                </li>
              </ul>
              <div className="patient-component-detail">
                <PatientDetails
                  isAcuteOrderFlow={patient!.isAcuteOrder ?? false}
                  patient={patient}
                  isConfirmPlacementFlow={true}
                />
              </div>
              <DataEntryAndDressingsSections
                canister={canister}
                dressingKit={dressingKit}
                placementTimes={placementTimes}
                placementTimeText={placementTimeText}
                serialNumbers={serialNumbers}
                serialNumbersText={serialNumbersText}
                releaseDate={releaseDate}
              />
            </div>
            <ConfirmPlacementFooter
              cancelButtonAction={cancelConfirmPlacement}
              confirmButtonAction={confirmPlacementAction}
              confirmButtonDisabled={confirmButtonDisabled}
            />
          </>
        )}
      </div>
      <Popup
        openFlag={openLoaderPopUp}
        closeHandler={() => setOpenLoaderPopUp(false)}
        dialogParentClass={"confirm-placement-loader-pop-up"}
        data-testid="confirm-placement-pop-up"
        hideCloseButton={true}
      >
        <div className="confirm-placement-loader">
          <LoadingSpinner />
        </div>
      </Popup>
      <Popup
        closeHandler={() => {}}
        dialogParentClass={"confirm-placement-success-pop-up"}
        hideCloseButton={true}
        openFlag={openSuccessPopUp}
      >
        <SuccessPopupForConfirmPlacement
          inventoryProduct={selectedInventoryProduct!}
          patient={patient!}
          placementDateAndTime={getPlacementDateAndTime()}
        />
      </Popup>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={closePopup}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={title}
        isShortSentence={true}
        isSupportPhoneRequired={phoneNo}
        errorCode={errorCode}
        errorTitle="Confirm Placement"
      />
    </div>
  );
};
