export enum LifeOfAnOrderGuidePageSection {
  contentSelection = "CONTENT_SECTION",
  preReleaseContent = "PRE_RELEASE_CONTENT_SECTION",
  postReleaseContent = "POST_RELEASE_CONTENT_SECTION",
}

export interface IContentOptions {
  title: string;
  contentSection: LifeOfAnOrderGuidePageSection;
}

export const contentCardOptions: IContentOptions[] = [
  {
    title: "Pre-release Content",
    contentSection: LifeOfAnOrderGuidePageSection.preReleaseContent,
  },
  {
    title: "Post-release Content",
    contentSection: LifeOfAnOrderGuidePageSection.postReleaseContent,
  },
];
