import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IBillingInformation } from "./billingInformation.interface";

export const defaultBillingInformationData: IBillingInformation = {
  rentalPONumber: {
    componentId: "in-patient-order-Billing-information-rental-po-number",
    id: "in-patient-order-Billing-information-rental-po-number-input",
    order: 13,
    title: "Rental PO #",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  salesPONumber: {
    componentId: "in-patient-order-Billing-information-sales-po-number",
    id: "in-patient-order-Billing-information-sales-po-number-input",
    order: 14,
    title: "Sales Purchase Order #",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    required: false,
  },
  isPORequired: null,
};
