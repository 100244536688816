import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../../../util/ObjectFunctions";
import {
  convertStringToDate,
  formatDate,
  getDevicePlacementType,
} from "../../../../../util/utilityFunctions";
import {
  IInventoryProductDetails,
  ISolventumProduct,
} from "../../../../inventory/inventoryTabContainer.enum";
import { defaultPrescriberInformationdata } from "../../../__test__/inpatientOrder.test.data";
import { IRequiredData } from "../../../container/inpatientOrderContainer.component";
import {
  IInpatientOrder,
  IInpatientOrderSecondPage,
} from "../../../inpatientOrder.interface";
import {
  defaultInpatientOrderData,
  defaultInpatientOrderSecondPageData,
} from "../../../inpatientOrder.model";
import { InpatientOrderValidator } from "../../../inpatientOrder.validator";
import { IBillingInformation } from "../../billingInformation/billingInformation.interface";
import { defaultBillingInformationData } from "../../billingInformation/billingInformation.model";
import { defaultDeliveryPreferenceData } from "../../deliveryPreference/deliveryPreference.model";
import { IDeliveryPreference } from "../../deliveryPreference/delivveryPreference.interface";
import { IDevicePlacement } from "../../devicePlacement/devicePlacement.interface";
import { defaultDevicePlacementInformation } from "../../devicePlacement/devicePlacement.model";
import {
  DevicePlacementTabs,
  PlacementTypeSelection,
} from "../../devicePlacement/devicePlacemet.enums";
import { IPatientInformation } from "../../patientInformation/patientInformation.interface";
import { defaultPatientInformationData } from "../../patientInformation/patientInformation.model";
import { IPrescriberInformation } from "../../prescriberInformation/prescriberInformation.interface";
import {
  defaultReplacementInformationData,
  IReplacementInformationInterface,
} from "../../replacementInformation/replacementInformation.interface";
import { IWoundInformation } from "../../woundInformation/woundInformation.interface";
import {
  defaultInPatientPrimaryWoundInfo,
  defaultInPatientSecondaryWoundInfo,
  defaultWoundInformation,
} from "../../woundInformation/woundInformation.model";
import { InPatientOrderSummaryData } from "./inPatientOrder.Response.interface";

export const mapInPatientOrderResponse = async (
  allSolventumProducts: ISolventumProduct[],
  inPatientOrdersummaryInfo: InPatientOrderSummaryData,
  Validator = new InpatientOrderValidator(),
  storedProductData: IInventoryProductDetails[]
) => {
  const placementType = getDevicePlacementType(
    inPatientOrdersummaryInfo.orderType
  );
  let defaultOrderData: IInpatientOrder = getDeepClone(
    defaultInpatientOrderData
  );
  let inpatientFirstPage: IInpatientOrder = {
    ...defaultOrderData,
    patientInformation: {
      ...defaultOrderData.patientInformation,
      value: getPatientInformationData(inPatientOrdersummaryInfo, Validator),
    },
    prescriberInformation: {
      ...defaultOrderData.prescriberInformation,
      value: getPrescriberData(inPatientOrdersummaryInfo),
    },
    devicePlacement: {
      ...defaultOrderData.devicePlacement,
      value: getDevicePalcementData(
        inPatientOrdersummaryInfo,
        storedProductData
      ),
    },
    therapyStartDate: {
      ...defaultOrderData.therapyStartDate,
      valid: Validate(
        Validator,
        "therapyStartDate",
        inPatientOrdersummaryInfo.therapyDate
      )!,
      value: inPatientOrdersummaryInfo.therapyDate,
    },
    orderWithSolventum: getSolventumProductData(
      allSolventumProducts,
      defaultOrderData.orderWithSolventum,
      inPatientOrdersummaryInfo
    ),
    billingAddress: getBillingAddressData(
      defaultOrderData.billingAddress,
      inPatientOrdersummaryInfo
    ),
    patientWoundInformation: {
      ...defaultOrderData.patientWoundInformation,
      value: getWoundInformationData(inPatientOrdersummaryInfo, Validator),
    },
    selectionSupplies: {
      ...defaultOrderData.selectionSupplies,
      value: inPatientOrdersummaryInfo.isSuppliesOrdered ? "yes" : "no",
      valid: ValidationStatus.VALID,
      required:
        placementType === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM,
    },
  };
  return inpatientFirstPage;
};

export const mapInPatientSecondPageResponse = async (
  inPatientOrdersummaryInfo: InPatientOrderSummaryData,
  Validator = new InpatientOrderValidator(),
  requiredData: IRequiredData
) => {
  let defaultOrderData: IInpatientOrderSecondPage = getDeepClone(
    defaultInpatientOrderSecondPageData
  );
  const placementType = getDevicePlacementType(
    inPatientOrdersummaryInfo.orderType.toString()
  );
  let inpatientSecondPage: IInpatientOrderSecondPage = {
    ...defaultOrderData,
    deliveryPreference: {
      ...defaultOrderData.deliveryPreference,
      required:
        placementType === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM,
      value: getDeliveryPreferenceData(inPatientOrdersummaryInfo, Validator),
    },
    placementDate: {
      ...defaultOrderData.placementDate,
      required:
        placementType !== PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM,
      valid: Validate(
        Validator,
        "placementDate",
        convertStringToDate(inPatientOrdersummaryInfo.placementDate)
      )!,
      value: convertStringToDate(inPatientOrdersummaryInfo.placementDate),
    },
    replacementInformation: {
      ...defaultOrderData.replacementInformation,
      required:
        placementType !== PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM,
      value: getReplacementInformationData(
        inPatientOrdersummaryInfo,
        Validator
      ),
    },
    billingInformation: {
      ...defaultOrderData.replacementInformation,
      value: getBillingInformationData(
        inPatientOrdersummaryInfo,
        Validator,
        requiredData
      ),
    },
  };
  return inpatientSecondPage;
};

const getPatientInformationData = (
  inPatientOrdersummaryInfo: InPatientOrderSummaryData,
  Validator = new InpatientOrderValidator()
) => {
  let defaultPatientData: IPatientInformation = getDeepClone(
    defaultPatientInformationData
  );
  let PatientData: IPatientInformation = {
    ...defaultPatientData,
    firstName: {
      ...defaultPatientData.firstName,
      value: inPatientOrdersummaryInfo.patientFirstName,
      valid: Validate(
        Validator,
        "firstName",
        inPatientOrdersummaryInfo.patientFirstName
      )!,
    },
    lastName: {
      ...defaultPatientData.lastName,
      value: inPatientOrdersummaryInfo.patientLastName,
      valid: Validate(
        Validator,
        "lastName",
        inPatientOrdersummaryInfo.patientLastName
      )!,
    },
    dateOfBirth: {
      ...defaultPatientData.dateOfBirth,
      value: formatDate(inPatientOrdersummaryInfo.patientDOB),
      valid: Validate(
        Validator,
        "dateOfBirth",
        formatDate(inPatientOrdersummaryInfo.patientDOB)
      )!,
    },
    room: {
      ...defaultPatientData.room,
      value: inPatientOrdersummaryInfo.patientLocation,
      valid: Validate(
        Validator,
        "room",
        inPatientOrdersummaryInfo.patientLocation
      )!,
    },
    patientId: {
      ...defaultPatientData.patientId,
      value: inPatientOrdersummaryInfo.patientId,
      valid: Validate(
        Validator,
        "patientId",
        inPatientOrdersummaryInfo.patientId
      )!,
    },
  };
  return PatientData;
};

const getPrescriberData = (
  inPatientOrdersummaryInfo: InPatientOrderSummaryData
) => {
  let defaultPrescriberData: IPrescriberInformation = getDeepClone(
    defaultPrescriberInformationdata
  );
  let PrescriberData: IPrescriberInformation = {
    ...defaultPrescriberData,
    firstName: inPatientOrdersummaryInfo.prescriber.firstName,
    lastName: inPatientOrdersummaryInfo.prescriber.lastName,
    npi: inPatientOrdersummaryInfo.prescriber.npi,
    city: inPatientOrdersummaryInfo.prescriber.city,
    state: inPatientOrdersummaryInfo.prescriber.state,
    telephoneNumber: inPatientOrdersummaryInfo.prescriber.telephoneNumber,
    zipCode: inPatientOrdersummaryInfo.prescriber.zipCode,
    address1: inPatientOrdersummaryInfo.prescriber.address1,
    address2: inPatientOrdersummaryInfo.prescriber.address2,
    emails: [],
  };
  return PrescriberData;
};

const getProductType = (orderType: string): DevicePlacementTabs => {
  let type: DevicePlacementTabs = DevicePlacementTabs.STORED_PRODUCT_TAB;
  switch (orderType) {
    case "3":
      type = DevicePlacementTabs.STORED_PRODUCT_TAB;
      break;
    case "4":
      type = DevicePlacementTabs.CUSTOMER_PLUS_TAB;
      break;
  }
  return type;
};

const getSelectedProductData = (
  storedProductData: IInventoryProductDetails[],
  inPatientOrdersummaryInfo: InPatientOrderSummaryData,
  productType: DevicePlacementTabs,
  placementType: PlacementTypeSelection
): IInventoryProductDetails | null => {
  if (placementType === PlacementTypeSelection.PLACE_FROM_STORAGE) {
    if (productType === DevicePlacementTabs.STORED_PRODUCT_TAB) {
      const storedProducts: IInventoryProductDetails[] =
        storedProductData.filter(
          (x: IInventoryProductDetails) =>
            x.productName === inPatientOrdersummaryInfo.rentalProduct &&
            x.serialNumber === inPatientOrdersummaryInfo.serialNumber
        );
      if (storedProducts.length > 0) {
        const storedProduct = storedProducts[0];
        return storedProduct;
      }
    }
  }
  return null;
};

const getDevicePalcementData = (
  inPatientOrdersummaryInfo: InPatientOrderSummaryData,
  storedProductData: IInventoryProductDetails[]
) => {
  let defaultDevicePalcementData: IDevicePlacement = getDeepClone(
    defaultDevicePlacementInformation
  );
  const productType = getProductType(inPatientOrdersummaryInfo.orderType);
  const placementType = getDevicePlacementType(
    inPatientOrdersummaryInfo.orderType.toString()
  );
  const storedProduct = getSelectedProductData(
    storedProductData,
    inPatientOrdersummaryInfo,
    productType,
    placementType
  );
  const devicePalcementData: IDevicePlacement = {
    ...defaultDevicePalcementData,
    productType: {
      ...defaultDevicePalcementData.productType,
      value: productType,
    },
    placementType: {
      ...defaultDevicePalcementData.placementType,
      value: placementType,
    },
    selectedProduct: {
      ...defaultDevicePalcementData.selectedProduct,
      required:
        placementType !== PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM,
      valid:
        inPatientOrdersummaryInfo.rentalProduct !== "" && storedProduct
          ? ValidationStatus.VALID
          : ValidationStatus.UNTOUCHED,
      value:
        placementType !== PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
          ? storedProduct
          : null,
    },
  };
  return devicePalcementData;
};

const getSolventumProductData = (
  allSolventumProducts: ISolventumProduct[],
  defaultOrderWithSolventum: IInputField,
  inPatientOrdersummaryInfo: InPatientOrderSummaryData
) => {
  const solventumType = getDevicePlacementType(
    inPatientOrdersummaryInfo.orderType.toString()
  );
  const orderWithSolventum: IInputField = {
    ...defaultOrderWithSolventum,
    valid:
      inPatientOrdersummaryInfo.rentalProduct !== ""
        ? ValidationStatus.VALID
        : ValidationStatus.UNTOUCHED,
    value:
      solventumType === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
        ? getSelectedSolventumProduct(
            allSolventumProducts,
            inPatientOrdersummaryInfo
          )
        : null,
  };
  return orderWithSolventum;
};

const getSelectedSolventumProduct = (
  allSolventumProduct: ISolventumProduct[],
  inPatientOrdersummaryInfo: InPatientOrderSummaryData
) => {
  const solventumProducts = allSolventumProduct.filter(
    (solventumProducts: ISolventumProduct) =>
      solventumProducts.sku === inPatientOrdersummaryInfo.rentalProduct
  );
  console.log("solventumProducts - ", solventumProducts);
  if (solventumProducts.length > 0) {
    return solventumProducts[0];
  }
  return null;
};

const getBillingAddressData = (
  defaultBiillingAddress: IInputField,
  inPatientOrdersummaryInfo: InPatientOrderSummaryData
) => {
  const productType = getProductType(inPatientOrdersummaryInfo.orderType);
  const billingAddressValue = inPatientOrdersummaryInfo.billingAddress;
  const biillingAddress: IInputField = {
    ...defaultBiillingAddress,
    required: productType !== DevicePlacementTabs.CUSTOMER_PLUS_TAB,
    valid:
      billingAddressValue && billingAddressValue.siteUseId !== ""
        ? ValidationStatus.VALID
        : ValidationStatus.UNTOUCHED,
    value: billingAddressValue ? billingAddressValue.siteUseId : null,
  };
  return biillingAddress;
};

const Validate = (
  Validator: InpatientOrderValidator,
  Name: string,
  Value: string
) => {
  let notNullValue = Value === null ? "" : Value;
  const Status = Validator?.validate(notNullValue, Name)?.status;
  return Status === ValidationStatus.INVALID
    ? ValidationStatus.UNTOUCHED
    : Status;
};

const getWoundInfoCount = (
  inPatientOrdersummaryInfo: InPatientOrderSummaryData
) => {
  if (
    inPatientOrdersummaryInfo.primaryWound.type !== "" &&
    inPatientOrdersummaryInfo.primaryWound.type !== null &&
    inPatientOrdersummaryInfo.secondaryWound.type !== "" &&
    inPatientOrdersummaryInfo.secondaryWound.type !== null
  ) {
    return "2";
  } else if (
    inPatientOrdersummaryInfo.primaryWound.type !== "" &&
    inPatientOrdersummaryInfo.primaryWound.type !== null
  ) {
    return "1";
  } else return "0";
};

const getWoundInformationData = (
  inPatientOrdersummaryInfo: InPatientOrderSummaryData,
  Validator = new InpatientOrderValidator()
) => {
  let defaultWoundInformationData: IWoundInformation = getDeepClone(
    defaultWoundInformation
  );
  let woundInfoData: IWoundInformation = {
    ...defaultWoundInformationData,
    woundInfoCount: {
      ...defaultWoundInformation.woundInfoCount,
      value: getWoundInfoCount(inPatientOrdersummaryInfo),
    },
    primaryWoundInformation: {
      ...defaultInPatientPrimaryWoundInfo,
      woundType: {
        ...defaultInPatientPrimaryWoundInfo.woundType,
        valid: Validate(
          Validator,
          "woundType",
          getBlankForNullValue(inPatientOrdersummaryInfo.primaryWound.type)
        )!,
        value: inPatientOrdersummaryInfo.primaryWound.type,
      },
      woundLocation: {
        ...defaultInPatientPrimaryWoundInfo.woundLocation,
        valid: Validate(
          Validator,
          "woundLocation",
          getBlankForNullValue(inPatientOrdersummaryInfo.primaryWound.location)
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.primaryWound.location
        ),
      },
      woundDirection: {
        ...defaultInPatientPrimaryWoundInfo.woundDirection,
        valid: Validate(
          Validator,
          "woundDirection",
          getBlankForNullValue(inPatientOrdersummaryInfo.primaryWound.direction)
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.primaryWound.direction
        ),
      },
      woundOrientation: {
        ...defaultInPatientPrimaryWoundInfo.woundOrientation,
        valid: Validate(
          Validator,
          "woundOrientation",
          getBlankForNullValue(
            inPatientOrdersummaryInfo.primaryWound.orientation
          )
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.primaryWound.orientation
        ),
      },
      woundMeasurementDate: {
        ...defaultInPatientPrimaryWoundInfo.woundMeasurementDate,
        valid: Validate(
          Validator,
          "woundMeasurementDate",
          getBlankForNullValue(
            inPatientOrdersummaryInfo.primaryWound.measurementDate
          )
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.primaryWound.measurementDate
        ),
      },
      woundLength: {
        ...defaultInPatientPrimaryWoundInfo.woundLength,
        valid: Validate(
          Validator,
          "woundLength",
          getBlankForNullValue(inPatientOrdersummaryInfo.primaryWound.length)
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.primaryWound.length
        ),
      },
      woundWidth: {
        ...defaultInPatientPrimaryWoundInfo.woundWidth,
        valid: Validate(
          Validator,
          "woundWidth",
          getBlankForNullValue(inPatientOrdersummaryInfo.primaryWound.width)
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.primaryWound.width
        ),
      },
      woundDepth: {
        ...defaultInPatientPrimaryWoundInfo.woundDepth,
        valid: Validate(
          Validator,
          "woundDepth",
          getBlankForNullValue(inPatientOrdersummaryInfo.primaryWound.depth)
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.primaryWound.depth
        ),
      },
    },
    secondaryWoundInformation: {
      ...defaultInPatientSecondaryWoundInfo,
      woundType: {
        ...defaultInPatientSecondaryWoundInfo.woundType,
        valid: Validate(
          Validator,
          "woundType",
          inPatientOrdersummaryInfo.secondaryWound.type
        )!,
        value: inPatientOrdersummaryInfo.secondaryWound.type,
      },
      woundLocation: {
        ...defaultInPatientSecondaryWoundInfo.woundLocation,
        valid: Validate(
          Validator,
          "woundLocation",
          getBlankForNullValue(
            inPatientOrdersummaryInfo.secondaryWound.location
          )
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.secondaryWound.location
        ),
      },
      woundDirection: {
        ...defaultInPatientSecondaryWoundInfo.woundDirection,
        valid: Validate(
          Validator,
          "woundDirection",
          getBlankForNullValue(
            inPatientOrdersummaryInfo.secondaryWound.direction
          )
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.secondaryWound.direction
        ),
      },
      woundOrientation: {
        ...defaultInPatientSecondaryWoundInfo.woundOrientation,
        valid: Validate(
          Validator,
          "woundOrientation",
          getBlankForNullValue(
            inPatientOrdersummaryInfo.secondaryWound.orientation
          )
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.secondaryWound.orientation
        ),
      },
      woundMeasurementDate: {
        ...defaultInPatientSecondaryWoundInfo.woundMeasurementDate,
        valid: Validate(
          Validator,
          "woundMeasurementDate",
          getBlankForNullValue(
            inPatientOrdersummaryInfo.secondaryWound.measurementDate
          )
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.secondaryWound.measurementDate
        ),
      },
      woundLength: {
        ...defaultInPatientSecondaryWoundInfo.woundLength,
        valid: Validate(
          Validator,
          "woundLength",
          getBlankForNullValue(inPatientOrdersummaryInfo.secondaryWound.length)
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.secondaryWound.length
        ),
      },
      woundWidth: {
        ...defaultInPatientSecondaryWoundInfo.woundWidth,
        valid: Validate(
          Validator,
          "woundWidth",
          getBlankForNullValue(inPatientOrdersummaryInfo.secondaryWound.width)
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.secondaryWound.width
        ),
      },
      woundDepth: {
        ...defaultInPatientSecondaryWoundInfo.woundDepth,
        valid: Validate(
          Validator,
          "woundDepth",
          getBlankForNullValue(inPatientOrdersummaryInfo.secondaryWound.depth)
        )!,
        value: getBlankForNullValue(
          inPatientOrdersummaryInfo.secondaryWound.depth
        ),
      },
    },
  };
  return woundInfoData;
};
const getBlankForNullValue = (fieldValue: any | null | undefined) => {
  if (fieldValue === undefined) return "";
  else if (fieldValue === null) return "";
  else return fieldValue.toString();
};
const getDeliveryPreferenceData = (
  inPatientOrdersummaryInfo: InPatientOrderSummaryData,
  Validator = new InpatientOrderValidator()
) => {
  let deliveryPrefernceData: IDeliveryPreference = getDeepClone(
    defaultDeliveryPreferenceData
  );
  let deliveryData: IDeliveryPreference = {
    ...deliveryPrefernceData,
    needByDate: {
      ...deliveryPrefernceData.needByDate,
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    needByTime: {
      ...deliveryPrefernceData.needByTime,
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
    isCallOnEstimatedArrivalTime: {
      ...deliveryPrefernceData.isCallOnEstimatedArrivalTime,
      valid: Validate(
        Validator,
        "isCallOnEstimatedArrivalTime",
        inPatientOrdersummaryInfo.isCallOnEstimatedArrivalTime ? "yes" : "no"
      )!,
      value: inPatientOrdersummaryInfo.isCallOnEstimatedArrivalTime
        ? "yes"
        : "no",
    },
    firstName: {
      ...deliveryPrefernceData.firstName,
      valid: Validate(
        Validator,
        "firstName",
        inPatientOrdersummaryInfo.deliveryFirstName
      )!,
      value: inPatientOrdersummaryInfo.deliveryFirstName,
    },
    lastName: {
      ...deliveryPrefernceData.lastName,
      valid: Validate(
        Validator,
        "lastName",
        inPatientOrdersummaryInfo.deliveryLastName
      )!,
      value: inPatientOrdersummaryInfo.deliveryLastName,
    },
    phoneNumber: {
      ...deliveryPrefernceData.phoneNumber,
      valid: Validate(
        Validator,
        "phoneNumber",
        inPatientOrdersummaryInfo.deliveryPhoneNo
      )!,
      value: inPatientOrdersummaryInfo.deliveryPhoneNo,
    },
    deliveryInstructions: {
      ...deliveryPrefernceData.deliveryInstructions,
      valid: Validate(
        Validator,
        "deliveryInstructions",
        inPatientOrdersummaryInfo.deliveryInstructions
      )!,
      value: inPatientOrdersummaryInfo.deliveryInstructions,
    },
  };
  return deliveryData;
};

const getReplacementInformationData = (
  inPatientOrdersummaryInfo: InPatientOrderSummaryData,
  Validator = new InpatientOrderValidator()
) => {
  let replacementInformationData: IReplacementInformationInterface =
    getDeepClone(defaultReplacementInformationData);
  let replacementInformation: IReplacementInformationInterface = {
    ...replacementInformationData,
    deliveryLocation: {
      ...replacementInformationData.deliveryLocation,
      valid: Validate(
        Validator,
        "deliveryLocation",
        inPatientOrdersummaryInfo.deliveryLocation
      )!,
      value: inPatientOrdersummaryInfo.deliveryLocation,
    },
    isCallOnEstimatedArrivalTime: {
      ...replacementInformationData.isCallOnEstimatedArrivalTime,
      valid: Validate(
        Validator,
        "isCallOnEstimatedArrivalTime",
        inPatientOrdersummaryInfo.isCallOnEstimatedArrivalTime ? "yes" : "no"
      )!,
      value: inPatientOrdersummaryInfo.isCallOnEstimatedArrivalTime
        ? "yes"
        : "no",
    },
    department: {
      ...replacementInformationData.department,
      valid: Validate(
        Validator,
        "department",
        inPatientOrdersummaryInfo?.requestorDepartment
      )!,
      value: inPatientOrdersummaryInfo?.requestorDepartment,
    },
    firstName: {
      ...replacementInformationData.firstName,
      valid: Validate(
        Validator,
        "firstName",
        inPatientOrdersummaryInfo.deliveryFirstName
      )!,
      value: inPatientOrdersummaryInfo.deliveryFirstName,
    },
    lastName: {
      ...replacementInformationData.lastName,
      valid: Validate(
        Validator,
        "lastName",
        inPatientOrdersummaryInfo.deliveryLastName
      )!,
      value: inPatientOrdersummaryInfo.deliveryLastName,
    },
    phoneNumber: {
      ...replacementInformationData.phoneNumber,
      valid: Validate(
        Validator,
        "phoneNumber",
        inPatientOrdersummaryInfo.deliveryPhoneNo
      )!,
      value: inPatientOrdersummaryInfo.deliveryPhoneNo,
    },
    deliveryInstructions: {
      ...replacementInformationData.deliveryInstructions,
      valid: Validate(
        Validator,
        "deliveryInstructions",
        inPatientOrdersummaryInfo.deliveryInstructions
      )!,
      value: inPatientOrdersummaryInfo.deliveryInstructions,
    },
  };
  return replacementInformation;
};

const getBillingInformationData = (
  inPatientOrdersummaryInfo: InPatientOrderSummaryData,
  Validator = new InpatientOrderValidator(),
  requiredData: IRequiredData
) => {
  let billingInformationData: IBillingInformation = getDeepClone(
    defaultBillingInformationData
  );
  let billingInformation: IBillingInformation = {
    ...billingInformationData,
    rentalPONumber: {
      ...billingInformationData.rentalPONumber,
      valid: Validate(
        Validator,
        "rentalPONumber",
        inPatientOrdersummaryInfo.billingRentalPO
      )!,
      required: requiredData.isRentalPORequired,
      value: inPatientOrdersummaryInfo.billingRentalPO,
    },
    salesPONumber: {
      ...billingInformationData.salesPONumber,
      valid: Validate(
        Validator,
        "salesPONumber",
        inPatientOrdersummaryInfo.salesPurchaseOrderNo
      )!,
      required: requiredData.isSalesPORequired,
      value: inPatientOrdersummaryInfo?.salesPurchaseOrderNo ?? "",
    },
    isPORequired:
      inPatientOrdersummaryInfo.billingRentalPO === "NOT REQUIRED"
        ? false
        : true,
  };
  return billingInformation;
};
