import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "react-string-format";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import {
  ExchangeVacUnitContext,
  ExchangeVacUnitContextType,
} from "../../context/ExchangeVacUnitContext";
import ErrorPopup from "../../core/errorPopup/errorPopup.component";
import { LoadingSpinner } from "../../core/loader/LoadingSpinner";
import { Popup } from "../../core/popup/popup.component";
import { getdropDownContent } from "../../util/dropDownService";
import { EXCHANGE_VAC_SERIAL_NUMBER_EMPTY_CODE } from "../../util/errorCode";
import {
  ERROR_MSG_EXCHANGE_VAC,
  EXCHANGE_VAC_GET_SERIAL_NUMBER_API_ERROR,
  EXCHANGE_VAC_SERIAL_NUMBER_EMPTY,
  EXCHANGE_VAC_SERIAL_NUMBER_EMPTY_ACUTE_ORDER,
} from "../../util/errorMsg";
import { getInventoryInfoList } from "../../util/inventoryMgrService";
import { retrievePatientDetails } from "../../util/pickUpOrDischargeService";
import { DD_TIME_OF_PLACEMENT_CONTENT } from "../../util/staticText";
import {
  getCustomerMasterNumber,
  getSiteUseId,
} from "../../util/utilityFunctions";
import { submitExchangeVac } from "../../util/vacOrderService";
import { IInventoryProduct } from "../confirmPlacement/confirmPlacement.interface";
import { Navigator } from "../helpAndSupport/Navigator/navigator.component";
import { PatientDetails } from "../pickUpAndDischargeRequest/pickUpRequest/patientDetails/patientDetails.component";
import { FooterButtonGroup } from "../send3MNote/footerButtonGroup/footerButtonGroup.component";
import { DeviceInformationExchangeVac } from "./deviceInformationExchangeVac/deviceInformationExchangeVac.component";
import { ExchangeVacValidator } from "./exchangeVac.validator";
import { ExchangeVacPageSection } from "./exchangeVacPageSection.enum";
import "./exchangeVacUnit.css";
import { SubmitProofOfService } from "./submitProofOfService/submitProofOfService.component";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../context/RolesPermissionContext";
import { IFacility } from "../manageProfile/facilityInformation/facility.interface";

export const ExchangeVac = ({
  Validator = new ExchangeVacValidator(),
}: any) => {
  const history = useHistory();
  const location: any = useLocation();
  const [openFailurePopUp, setOpenFailurePopUp] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState("");
  const [pickupWO, setPickupWO] = useState<string>();
  const exchangeVacUnitObj = useContext<ExchangeVacUnitContextType | null>(
    ExchangeVacUnitContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const patient = exchangeVacUnitObj?.patient;
  const data = exchangeVacUnitObj!.data;
  const setData = exchangeVacUnitObj!.setData;
  const [serviceTimesText, setServiceTimesText] = useState([]);
  const [serviceTimes, setServiceTimes] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const [serialNumbersText, setSerialNumbersText] = useState<string[]>([]);
  const [inventoryProducts, setInventoryProducts] = useState<
    IInventoryProduct[]
  >([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorCodeVisible, setIsErrorCodeVisible] = useState<boolean>(true);
  const [submitExchangeVacAPILoader, setSubmitExchangeVacAPILoader] =
    useState(false);
  const [validator] = useState<ExchangeVacValidator>(Validator!);
  let patientDeepLinkData = location.state?.stateData;
  const authObj = useContext<AuthContextType | null>(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (exchangeVacUnitObj?.patient) {
      loadPatientProductInfo();
      fetchDropDownContent();
    } else if (patientDeepLinkData && patientDeepLinkData.isDeepLink) {
      exchangeVacUnitObj?.setPatient(patientDeepLinkData);
      loadPatientProductInfo();
    } else history.replace("/home");
  }, []);

  useEffect(() => {
    if (patientDeepLinkData && patientDeepLinkData.isDeepLink) {
      if (
        authObj?.allFacilities !== undefined &&
        authObj?.allFacilities.length > 0 &&
        patient?.siteUseId
      ) {
        let facilityFound: IFacility[] = authObj?.allFacilities.filter(
          (item: IFacility) => item.siteUseId === patient?.siteUseId
        );
        if (facilityFound.length === 0) {
          history.replace("/home");
        } else {
          if (
            authObj?.registeredFaciltyAddress?.siteUseId !== patient?.siteUseId
          ) {
            authObj?.setregisteredFaciltyAddress(facilityFound[0]);
            authObj?.setUpdateFacilityBannerFacility(true);
          }
        }
      }
    }
  }, [authObj?.allFacilities]);

  const loadPatientProductInfo = async () => {
    setLoading(true);
    const roNumber = patientDeepLinkData.isDeepLink
      ? patientDeepLinkData.roNumber
      : patient?.roNumber.toString();
    const dob = patientDeepLinkData.isDeepLink
      ? patientDeepLinkData.dob
      : patient?.dob;
    let reqParams = {
      RentalOrderNumber: roNumber,
      DOB: dob ? moment(dob).format("yyyy-MM-DD") : null,
      isAcuteOrder: patientDeepLinkData.isDeepLink
        ? patientDeepLinkData.isAcuteOrder
        : patient?.isAcuteOrder,
    };
    try {
      const response = await retrievePatientDetails(reqParams);
      if (response && response.error) {
        setOpenFailurePopUp(true);
        setErrorCode(
          response.error.code || response.error.errorCode || response.status
        );
        setErrorMessage(ERROR_MSG_EXCHANGE_VAC);
        setLoading(false);
      } else {
        const productName = response?.item?.productName || "";
        const productType = response?.item?.orderType || "";
        exchangeVacUnitObj?.setPatient((dt: any) => ({
          ...dt,
          productName: response.item.productName,
          productNameDescription: response.item.productDescription,
          placementDate: response.item.placemetDate,
          productSerialNumber: response.item.productSerialNumber,
          rentalOrderProductNumber: response.item.rentalOrderProductNumber,
          isExchangeVacSubmitted: response.item.isExchangeVacSubmitted,
          oldSerialNumber: response.item.oldSerialNumber,
          serviceDate: response.item.needByDate,
          exchangeWONumber: response.item.exchangeWorkOrderNumber,
        }));
        if (
          (response.item && response.item.isExchangeVacSubmitted) ||
          patientDeepLinkData.isDeepLink
        ) {
          exchangeVacUnitObj?.setExchangeVacPage(
            ExchangeVacPageSection.EXCHANGE_VAC_POS_FORM
          );
          setLoading(false);
        } else {
          getInventoryList(productName, productType);
        }
      }
    } catch (error) {
      console.log("error", error);
      setErrorMessage(ERROR_MSG_EXCHANGE_VAC);
      setOpenFailurePopUp(true);
      return false;
    }
  };

  const getInventoryList = async (productName: string, productType: string) => {
    const customerNumber =
      authObj?.registeredFaciltyAddress?.accountNumber ?? "";
    const readyCareFlag =
      authObj?.registeredFaciltyAddress?.readyCareFlag?.toUpperCase() === "Y"
        ? true
        : false;
    if (customerNumber !== "") {
      let reqParams = {
        siteUseId: getSiteUseId(authObj),
        customerNumber: customerNumber,
        programType: patient!.isAcuteOrder ? 2 : 1,
        ...(patient!.isAcuteOrder && { isReadyCare: readyCareFlag }),
        customerMasterNumber: getCustomerMasterNumber(authObj),
      };
      try {
        const response = await getInventoryInfoList(reqParams);
        if (response && (response?.error || response?.status)) {
          setOpenFailurePopUp(true);
          setErrorCode(response?.error?.errorCode || response?.status);
          setErrorMessage(EXCHANGE_VAC_GET_SERIAL_NUMBER_API_ERROR);
          setLoading(false);
        } else {
          let inventoryList: IInventoryProduct[] = response.items
            ? response.items
            : [];
          if (inventoryList.length > 0) {
            inventoryList = inventoryList.filter(
              (item: IInventoryProduct) =>
                item.status.toLowerCase() === "available"
            );
            setInventoryProducts(inventoryList);
            const isStoredProduct =
              productType.toLowerCase() === "storedproduct";
            const filteredProductSerialNumbers: string[] = [];
            const filteredProductSerialNumbersText: string[] = [];
            inventoryList.forEach((item: IInventoryProduct) => {
              if (!isStoredProduct || item.productName === productName) {
                filteredProductSerialNumbers.push(item.serialNumber);
                filteredProductSerialNumbersText.push(
                  `${item.serialNumber} - ${item.storageDays} days in storage`
                );
              }
            });
            if (filteredProductSerialNumbers.length > 0) {
              setSerialNumbers(filteredProductSerialNumbers);
              setSerialNumbersText(filteredProductSerialNumbersText);
              exchangeVacUnitObj?.setExchangeVacPage(
                ExchangeVacPageSection.EXCHANGE_VAC_SUBMIT_FORM
              );
            } else {
              triggerErrorPopup();
            }
          } else {
            triggerErrorPopup();
          }
          setLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        return false;
      }
    }
  };

  const triggerErrorPopup = () => {
    setErrorCode(EXCHANGE_VAC_SERIAL_NUMBER_EMPTY_CODE);
    setErrorMessage(
      patient!.isAcuteOrder
        ? EXCHANGE_VAC_SERIAL_NUMBER_EMPTY_ACUTE_ORDER
        : EXCHANGE_VAC_SERIAL_NUMBER_EMPTY
    );
    setIsErrorCodeVisible(false);
    setOpenFailurePopUp(true);
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0},{1}", DD_TIME_OF_PLACEMENT_CONTENT);
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const timeOfPlacementObject = data.items.filter(
          (item: { name: string }) => item.name === DD_TIME_OF_PLACEMENT_CONTENT
        );
        const timeOfPlacementData = timeOfPlacementObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setServiceTimes(timeOfPlacementData);
        setServiceTimesText(
          timeOfPlacementData.map((x: { text: string }) => x.text)
        );
        return true;
      }
      return false;
    } catch (error) {
      console.log("error", error);
    }
  };

  const checkIsConfirmBtnShouldDisable = (): boolean => {
    if (exchangeVacUnitObj) {
      return !validator.validateAll(data);
    }
    return true;
  };

  const submitVacExchange = async () => {
    if (
      authObj &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      patient &&
      exchangeVacUnitObj?.patient?.rentalOrderProductNumber !== ""
    ) {
      const servicetDate = data.serviceDate.value;
      const serviceTime = data.timeOfService.value;
      const serviceDateTimeString = `${servicetDate} ${serviceTime}`;
      const serviceDateAndTime = moment(
        moment(serviceDateTimeString, "MM/DD/YYYY hh:mm a")
          .utcOffset(0, true)
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      ).toISOString();
      try {
        let reqParams = {
          RentalOrderNumber: patient.roNumber,
          DOB: patient.dob ? moment(patient.dob).format("YYYY-MM-DD") : null,
          isAcuteOrder: patient.isAcuteOrder,
          orderType: patient.type,
          SiteUseId: getSiteUseId(authObj),
          ROPNumber: exchangeVacUnitObj?.patient?.rentalOrderProductNumber,
          CallerFirstName: authObj.userProfile.firstName,
          CallerLastName: authObj.userProfile.lastName,
          CallerCompany: authObj.registeredFaciltyAddress.accountName,
          CallerEmail: authObj.userProfile.emailAddress,
          CallerPhone: authObj.userProfile.phoneNumber
            ? authObj.userProfile.phoneNumber
            : authObj.userProfile.mobilePhoneNumber,
          CallerDepartment: authObj.userProfile.departmentName,
          NeededTime: serviceDateAndTime,
          Instructions:
            data.descriptionOfInjuryAndProblemWithTheDevice.value +
            "" +
            data.issueDesc.value,
          Version: process.env.REACT_APP_VERSION,
          OldSerialNumber: exchangeVacUnitObj.patient?.productSerialNumber,
          NewSerialNumber: data.serialNumber.value.split("-")[0].trim(),
          ProductProblem: data.injuryCauseBy3MDevice.value,
          MedicalEvent: data.problemWith3MDevice.value,
        };
        setSubmitExchangeVacAPILoader(true);
        const response = await submitExchangeVac(reqParams);
        if (response && response?.succeeded) {
          exchangeVacUnitObj?.setExchangeVacPage(
            ExchangeVacPageSection.EXCHANGE_VAC_POS_FORM
          );
          setPickupWO(response.item.outputReturnPSAWorkOrder);
          setSubmitExchangeVacAPILoader(false);
        } else {
          setSubmitExchangeVacAPILoader(false);
          setOpenFailurePopUp(true);
          setErrorMessage(EXCHANGE_VAC_GET_SERIAL_NUMBER_API_ERROR);
          setErrorCode(response?.error?.errorCode || response?.status);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const spinner = () => {
    return (
      <div>
        <div className="submitapi-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };
  return (
    <div className="exchange-vac-unit" data-testid="exchangevaccomponet-test">
      {loading && (
        <div className="exchange-vac-loader">
          <LoadingSpinner />
        </div>
      )}
      {!loading &&
        exchangeVacUnitObj?.exchangeVacPage ===
          ExchangeVacPageSection.EXCHANGE_VAC_SUBMIT_FORM && (
          <Navigator
            array={[
              {
                route: "/home",
                pageName: "My Patients",
              },
            ]}
            className="exchange-vac-route-section"
            title="Exchange V.A.C."
          />
        )}

      <ErrorPopup
        popUpStyles="exchange-vac-failure-pop-up"
        errorMessage={errorMessage}
        errorPopupFlag={openFailurePopUp}
        handleBackButton={() => {
          setOpenFailurePopUp(false);
          history.goBack();
        }}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={
          errorMessage === EXCHANGE_VAC_SERIAL_NUMBER_EMPTY_ACUTE_ORDER
            ? true
            : false
        }
        errorCode={errorCode}
        isSupportPhoneRequired={
          errorMessage === EXCHANGE_VAC_SERIAL_NUMBER_EMPTY ? false : true
        }
        isSupportEmailRequired={
          errorMessage === EXCHANGE_VAC_SERIAL_NUMBER_EMPTY_ACUTE_ORDER
            ? true
            : false
        }
        isErrorCodeVisible={isErrorCodeVisible}
      />
      {submitExchangeVacAPILoader ? (
        <Popup
          hideCloseButton={true}
          openFlag={submitExchangeVacAPILoader}
          closeHandler={() => {}}
        >
          {spinner()}
        </Popup>
      ) : (
        ""
      )}
      {!openFailurePopUp &&
      !loading &&
      exchangeVacUnitObj?.exchangeVacPage ===
        ExchangeVacPageSection.EXCHANGE_VAC_SUBMIT_FORM ? (
        <>
          <div className="exchange-short-form">
            <div className="exchange-vacheadernote">
              <h4 className="title" data-testid="title">
                Exchange V.A.C.® Therapy Unit
              </h4>
              {patient && (
                <>
                  <PatientDetails
                    isAcuteOrderFlow={patient!.isAcuteOrder ?? false}
                    patient={patient!}
                    excangeVacFlow={true}
                  />
                  <div className="sn-sec-div">
                    <span className="confirm-the-placement-sn-sec">
                      Confirm the placement of the new unit to complete the
                      exchange
                    </span>
                    <div className="current-sn-div">
                      <span className="current-sn-title">
                        Current Serial Number{" "}
                      </span>
                      <span className="current-sn-value">
                        {patient.productSerialNumber}
                      </span>
                    </div>
                  </div>
                  <DeviceInformationExchangeVac
                    data={data}
                    setData={setData}
                    serviceTimes={serviceTimesText}
                    serviceTimeText={serviceTimesText}
                    serialNumbers={serialNumbers}
                    serialNumbersText={serialNumbersText}
                  />
                  <div>
                    <FooterButtonGroup
                      firstButtonTitle="Cancel"
                      firstButtonAction={() => {
                        history.goBack();
                      }}
                      secondButtonTitle="Submit Exchange"
                      secondButtonDisabled={
                        checkIsConfirmBtnShouldDisable() ||
                        permissionObj?.mappedRolesPermissionData.IsSupportRole
                      }
                      secondButtonAction={submitVacExchange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {!openFailurePopUp && !loading && (
            <SubmitProofOfService pickUpWorkOrder={pickupWO} />
          )}
        </>
      )}
    </div>
  );
};
