import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { OAuthResponseType, OktaAuth, TokenParams } from "@okta/okta-auth-js";
import config from "../config";
import { LoadingSpinner } from "../core/loader/LoadingSpinner";
import "./ssoRedirect.css";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import {
  addOrUpdateInternalUser,
  getUser,
  selectFacility,
} from "../util/userService";
import { SearchFacilityDataBase } from "./searchFacilities/searchFacilities.component";
import {
  CMS_USER_GUIDENCE,
  SVC_GET_ADDORUPDATEINTERNALUSER,
  SVC_GET_USER,
} from "../util/staticText";
import { TerritorySalesAndNonSales } from "./sales/territoryDropDown.component";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../context/RolesPermissionContext";
import {
  getUnlinkedFacilitesCount,
  updateGuidencePopupFlag,
} from "../util/3meService";
import { SSoSideNav } from "./ssoSideNav/ssosideNav.component";
import { mapUserRolesAndPermissionData } from "../RolesPermission/RolesPermission.Mapper";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../context/InternalSignOnContext";
import { ISSOSideNav } from "./ssoSideNav/ssoSideNavMenuOption.interface";
import { Popup } from "../core/popup/popup.component";
import { RadioGroup } from "@mui/material";
import { CustomRadioButton } from "../core/radioButton/customRadioButton.component";
import { ExpressButton } from "../core/expressButton/expressButton.component";
import {
  INTERNAL_SALES_MGR_ROLE_TEXT,
  INTERNAL_SALES_ROLE_TEXT,
  INTERNAL_3MADMIN_ROLE_TEXT,
  INTERNAL_NATIONAL_ROLE_TEXT,
  INTERNAL_PRODMGR_ROLE_TEXT,
  INTERNAL_SUPPORT_ROLE_TEXT,
} from "../util/internalGroupName";
import FeaturesInfoPopup from "../core/featuresInfoPopup/featuresInfoPopup.component";
import { getCMSContent } from "../util/cmsService";
import {
  IUpdatePopupFlagReqBody,
  UpdatesPopupScreenTypes,
} from "../components/myPatients/patient.interface";
import {
  MyPatientContextType,
  MyPatientContext,
} from "../context/MyPatientContext";
import { getApplicationSiteStatus } from "../util/siteStatusesService";
import {
  ISiteStatusesBanner,
  MessageType,
} from "../signIn/siteStatusesBanners/siteStatusesBanner/siteStatusesBanner.interfaces";
import {
  SiteStatusesContext,
  SiteStatusesContextType,
} from "../context/SiteStatusesContext";
import { SiteStatusBanners } from "../signIn/siteStatusesBanners/siteStatusesBanners.component";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../core/errorPopup/errorPopup.component";
import {
  ERROR_MSG_GET_SITE_STATUSES,
  ERROR_MSG_SSO_ADD_INTERNAL_USER,
  ERROR_MSG_SSO_FETCH_INTERNAL_USER,
} from "../util/errorMsg";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../context/OrderDetailsContext";
import { deeplinkHandlerForSSO } from "../signIn/deeplinkHandlerForSSO";
import { showVacOrderMenuOption } from "../util/utilityFunctions";

export const SSORedirect = () => {
  const AuthObj = useContext<AuthContextType | null>(AuthContext);
  const oktaAuth = new OktaAuth(config.oidc2);
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [salesRole, setSalesRole] = useState(false);
  const [arrayOfGroups, setarrayOfGroups] = useState([]);
  const [openFlag, setOpenFlag] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [user, setUser] = useState();
  const [userName, setUserName] = useState("");
  const [deepLinkSpinner, setDeepLinkSpinner] = useState<boolean>(false);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const internalObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const myPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const siteStatusesObj = useContext<SiteStatusesContextType | null>(
    SiteStatusesContext
  );
  const orderOverviewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const history = useHistory();
  const [cmsLoader, setCmsLoader] = useState<boolean>(false);
  const [popupFlag, setPopupFlag] = useState<boolean>(false);
  const [allOperationsLoaded, setAllOperationsLoaded] =
    useState<boolean>(false);
  const guidancePopupFlag = AuthObj?.userProfile?.guidancePopup;
  // Error Pop up
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUpFlag, setErrorPopUpFlag] = useState(false);
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  useEffect(() => {
    AuthObj?.setIsUnlinkedFacilityOrder(false);
    internalObj?.setIsVACOrderSSO(false);
    AuthObj?.setregisteredFaciltyAddress(undefined);
    AuthObj?.setIsInternalUserFacilitySelected(false);
    myPatientObj?.resetSearchFilterData();
    if (
      location.pathname.includes("/ssoRedirect") &&
      (AuthObj?.updateInternalUser === undefined || !AuthObj.updateInternalUser)
    ) {
      AuthObj?.setIsInternalUser(true);
      AuthObj?.setIsInternalUserFacilitySelected(false);

      oktaAuth.session.get().then(function (session: any) {
        const AuthDetails = sessionStorage.getItem("okta-token-storage");
        const data = JSON.parse(AuthDetails ?? "");
        const accessToken = data.accessToken?.accessToken;
        if (!accessToken) {
          oktaAuth.token
            .getWithRedirect({
              responseType: ["token", "id_token", "refresh_token"],
              state: "12345", // will be URI encoded
            })
            .catch(function (err) {
              // handle AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
            });
        } else {
          getSignedInUserDetails(data.idToken.claims.preferred_username);
        }
        //Commented code for cookies issue in sso. keeping it for future referned (pream)
        // if (session.status === "ACTIVE") {
        //   const responseType: OAuthResponseType[] = [
        //     "id_token",
        //     "token",
        //     "refresh_token",
        //   ];
        //   const tokenParams: TokenParams = {
        //     responseType: responseType, // or array of types
        //     sessionToken: session.id, // optional if the user has an existing Okta session
        //   };
        //   oktaAuth?.token
        //     ?.getWithoutPrompt(tokenParams)
        //     .then(function (res: any) {
        //       var tokens = res.tokens;
        //       AuthObj?.setUserName(tokens.idToken?.claims.preferred_username); // Do something with tokens, such as
        //       oktaAuth.tokenManager.add("idToken", tokens.idToken!);
        //       oktaAuth.tokenManager.add("accessToken", tokens.accessToken!);
        //       oktaAuth.tokenManager.add("refreshToken", tokens.refreshToken!);
        //       AuthObj?.setIsLoggedIn(true);
        //       getSignedInUserDetails(tokens.idToken?.claims.preferred_username);
        //     })
        //     .catch(function (err) {
        //       console.log(err);
        //       window.location.href = `${process.env.REACT_APP_INTERNAL_SSO_URL}`;
        //       // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
        //     });
        // } else {
        //
        // }
      });
    } else {
      if (!AuthObj?.isLoggedIn) {
        AuthObj?.setIsInternalUser(false);
      }
      if (
        permissionObj?.mappedRolesPermissionData.IsSalesRole ||
        permissionObj?.mappedRolesPermissionData.IsSalesManagerRole
      ) {
        setSalesRole(true);
      }
      setLoader(false);
    }
    siteStatusesObj?.resetSiteStatuses();
    getSiteStatusesAPI();
  }, [location.pathname]);

  const getSiteStatusesAPI = async () => {
    const response = await getApplicationSiteStatus();
    if (response && response.succeeded) {
      let fullScreenStatuses = response.item.fullScreen;
      if (fullScreenStatuses) {
        const order = {
          [MessageType.ALERT]: 1,
          [MessageType.WARNING]: 2,
          [MessageType.INFORMATION]: 3,
        };
        let updatedfullScreenStatuses: any = [];
        updatedfullScreenStatuses = fullScreenStatuses
          .filter((item: ISiteStatusesBanner) => item.showToInternalUsers)
          .sort((a: ISiteStatusesBanner, b: ISiteStatusesBanner) =>
            order[a.messageType] > order[b.messageType] ? 1 : -1
          );
        siteStatusesObj?.setFullScreenSiteStatues(updatedfullScreenStatuses);
      }
    } else {
      setErrorMessage(ERROR_MSG_GET_SITE_STATUSES);
      setErrorCode(
        response?.error?.code || response?.error?.errorCode || response?.status
      );
      setErrorPopUpFlag(true);
    }
  };

  const getGroupLabelName = (groupName: string) => {
    let label = "";
    switch (groupName.toLowerCase()) {
      case INTERNAL_SALES_ROLE_TEXT:
        label = "Sales";
        break;
      case INTERNAL_SALES_MGR_ROLE_TEXT:
        label = "Sales Manager";
        break;
      case INTERNAL_PRODMGR_ROLE_TEXT:
        label = "Product Manager";
        break;
      case INTERNAL_SUPPORT_ROLE_TEXT:
        label = "Support";
        break;
      case INTERNAL_3MADMIN_ROLE_TEXT:
        label = "3M Administrator";
        break;
      case INTERNAL_NATIONAL_ROLE_TEXT:
        label = "National";
        break;
    }
    return label;
  };
  const mappedGroupNames = (groups: string[]) => {
    let arr: any = [];
    //  for checking multiple groups
    // groups = [
    //   INTERNAL_SALES_ROLE_TEXT,
    //   INTERNAL_SALES_MGR_ROLE_TEXT,
    //   INTERNAL_PRODMGR_ROLE_TEXT,
    //   INTERNAL_SUPPORT_ROLE_TEXT,
    //   INTERNAL_3MADMIN_ROLE_TEXT,
    //   INTERNAL_NATIONAL_ROLE_TEXT,
    // ];
    groups.map((x) => {
      let sa = {
        label: getGroupLabelName(x),
        value: x,
      };
      arr.push(sa);
    });
    return arr;
  };

  const getSignedInUserDetails = async (userName: string) => {
    let group = "";
    oktaAuth.getUser().then(async (user: any) => {
      if (user.groups) {
        if (
          user.groups.length > 1 &&
          process.env.REACT_APP_ENV_HIDE_MULTIGROUP === "false"
        ) {
          const arrGroups = mappedGroupNames(user.groups);
          setLoader(false);
          setUser(user);
          setUserName(userName);
          setarrayOfGroups(arrGroups);
          setOpenFlag(true);
        } else {
          group = user.groups[0];
          // group = "usac-ms-express-sales";
          await callAddOrUpdateUser(userName, user, group);
        }
      }
    });
  };

  useEffect(() => {
    if (allOperationsLoaded) {
      if (guidancePopupFlag === null && !openFlag) {
        getGuidancePopupContent();
        setPopupFlag(true);
      }
    }
  }, [openFlag, allOperationsLoaded]);

  const callAddOrUpdateUser = async (
    userName: any,
    user: any,
    group: string
  ) => {
    let unlinkedFacilityCount: any = null;
    if (group && group.includes("usac-ms-express-sales")) {
      setSalesRole(true);
      permissionObj?.setMappedRolesPermissionData({
        ...permissionObj.mappedRolesPermissionData,
        IsSalesManagerRole:
          group.toLowerCase() === INTERNAL_SALES_MGR_ROLE_TEXT ? true : false,
        IsSalesRole:
          group.toLowerCase() === INTERNAL_SALES_ROLE_TEXT ? true : false,
      });
    }
    if (
      (group && group.includes("usac-ms-express-admin")) ||
      (group && group.includes("usac-ms-express-prdmgr")) ||
      (group && group.includes("usac-ms-express-support"))
    ) {
      const res = await getUnlinkedFacilitesCount();
      if (res?.succeeded) {
        unlinkedFacilityCount = res.data;
        AuthObj?.setUnLinkedFacilityCount(res.data);
      }
    }
    const onlyDigits = user?.phone_number?.replace(/\D/g, "");
    const last10Digits = onlyDigits?.slice(-10);
    let reqBody = {
      userName: userName,
      firstName: user.given_name,
      lastName: user.family_name,
      EmailAddress: user.email,
      PhoneNo: last10Digits,
      EmployeeID:
        userName === "aitqexprsthrpy@3mhealth.com"
          ? "999999"
          : user.employeeNumber,
      Group: group?.trimEnd(),
    };
    setLoader(true);
    addOrUpdateInternlaUserService(reqBody, unlinkedFacilityCount);
    AuthObj?.setUpdateInternalUser(true);
    localStorage.setItem("isInternalUserFromLocal", "true");
    let deepLinkUrl = sessionStorage.getItem("DeepLinkPath");
    if (!AuthObj?.deepLinkPath) {
      AuthObj?.setDeepLinkPath(deepLinkUrl);
    }
    let deeplinkHandlerPath = AuthObj?.deepLinkPath ?? deepLinkUrl;
    sessionStorage.removeItem("DeepLinkPath");
    if (deeplinkHandlerPath) {
      setDeepLinkSpinner(true);
      const locationDescriptor = await deeplinkHandlerForSSO({
        group,
        deeplinkHandlerPath,
        authObj: AuthObj!,
        orderOverviewObj: orderOverviewObj!,
        permissionsObj: permissionObj!,
      });
      history.replace(locationDescriptor!);
      setDeepLinkSpinner(false);
    }
  };
  const addOrUpdateInternlaUserService = async (
    reqBody: any,
    unlinkedFacilityCount: any
  ) => {
    const userDetails = await addOrUpdateInternalUser(
      reqBody,
      SVC_GET_ADDORUPDATEINTERNALUSER
    );
    if (userDetails && userDetails.succeeded) {
      const res = await getUser(reqBody.userName, SVC_GET_USER);
      const reqBody1 = {
        UserName: reqBody.userName,
      };
      if (res.succeeded) {
        const userPermissions = await selectFacility(reqBody1);
        const rolesPermissionRes = await mapUserRolesAndPermissionData(
          userPermissions,
          "N"
        );
        permissionObj?.setMappedRolesPermissionData(rolesPermissionRes);
        let adminText: any = "Administration";
        if (unlinkedFacilityCount) {
          adminText = (
            <div className="sideNavmadminBtnMain">
              Administration
              <span className="sideNavmadminBtnStyle">
                {unlinkedFacilityCount}{" "}
              </span>{" "}
            </div>
          );
        }
        if (internalObj) {
          let tempSideNavMenuOption: ISSOSideNav =
            internalObj.ssoSideNavMenuOptionModelData;
          tempSideNavMenuOption = {
            ...tempSideNavMenuOption,
            searchforhomecare: {
              ...tempSideNavMenuOption.searchforhomecare,
              isVisible: rolesPermissionRes.IsSearchForHomeCareOrder,
            },
            internalAdministration: {
              ...tempSideNavMenuOption.internalAdministration,
              labelText: adminText,
              isVisible:
                rolesPermissionRes.IsShowAdminstrationOption &&
                !rolesPermissionRes.IsSalesRole &&
                !rolesPermissionRes.IsNationalRole,
            },
            orderVACTherapy: {
              ...tempSideNavMenuOption.orderVACTherapy,
              isVisible: showVacOrderMenuOption(rolesPermissionRes),
            },
            viewReports: {
              ...tempSideNavMenuOption.viewReports,
              isVisible: rolesPermissionRes.IsViewReport,
            },
            salesRoundingTool: {
              ...tempSideNavMenuOption.salesRoundingTool,
              isVisible: rolesPermissionRes.IsSalesRoundingTool,
            },
            lifeOfAnOrderGuide: {
              ...tempSideNavMenuOption.lifeOfAnOrderGuide,
              isVisible: rolesPermissionRes.IsLifeOfAnOrderGuide,
            },
          };
          internalObj.setSsoNavMenuOptionModelData(tempSideNavMenuOption);
          AuthObj?.setuserRolesAndPermissionLoaded(true);
        }
        AuthObj?.setuserRolesAndPermissionLoaded(true);
        AuthObj?.setUserProfile(res.data);
        setLoader(false);
        setAllOperationsLoaded(true);
      } else {
        const errorFailure = {
          errorCode: res?.error?.errorCode || res?.error?.code || res?.status,
          errorFlag: true,
          errorMessage: ERROR_MSG_SSO_FETCH_INTERNAL_USER,
        };
        setError(errorFailure);
        console.log("Error in fetching user");
        setLoader(false);
      }
    } else {
      const errorFailure = {
        errorCode:
          userDetails?.error?.errorCode ||
          userDetails?.error?.code ||
          userDetails?.status,
        errorFlag: true,
        errorMessage: ERROR_MSG_SSO_ADD_INTERNAL_USER,
      };
      setError(errorFailure);
      console.log("Error in fetching user");
      setLoader(false);
    }
  };

  const handleGroupAddBtnClick = async () => {
    setLoader(true);
    setOpenFlag(false);
    await callAddOrUpdateUser(userName, user, selectedValue);
  };

  const freshNewOrderSpinner = () => {
    return (
      <div className="ssoNew-spinner">
        <LoadingSpinner />
      </div>
    );
  };

  const getGuidancePopupContent = async () => {
    setCmsLoader(true);
    const data = await getCMSContent(CMS_USER_GUIDENCE);
    setCmsLoader(false);
    if (data.item && data && data.succeeded) {
      AuthObj?.setFeaturesCmsContent(data.item);
    }
  };

  const handleCloseUpdatesPopupFromSSO = async () => {
    if (!cmsLoader) {
      let tempUser = AuthObj?.userProfile!;
      tempUser = { ...tempUser, guidancePopup: 1 };
      AuthObj?.setUserProfile(tempUser);
      const reqBody: IUpdatePopupFlagReqBody = {
        username: AuthObj?.userProfile?.userName!,
        screen: UpdatesPopupScreenTypes.featuresPopupShownInSSO,
      };
      await updateGuidencePopupFlag(reqBody);
      setPopupFlag(false);
    } else {
      setPopupFlag(false);
    }
  };

  const handleCloseButton = (index: number) => {
    if (siteStatusesObj && siteStatusesObj?.fullScreenSiteStatues) {
      let fullScreenSiteStatues = [...siteStatusesObj?.fullScreenSiteStatues];
      fullScreenSiteStatues.splice(index, 1);
      siteStatusesObj.setFullScreenSiteStatues(fullScreenSiteStatues);
    }
  };

  return (
    <div className="selectfacility-main">
      {loader ? (
        freshNewOrderSpinner()
      ) : (
        <>
          <div className="alertInPageContainerInSSORedirect">
            {siteStatusesObj?.fullScreenSiteStatues &&
              siteStatusesObj?.fullScreenSiteStatues.length > 0 && (
                <SiteStatusBanners
                  siteStatuses={siteStatusesObj?.fullScreenSiteStatues}
                  handleCloseButtonAction={handleCloseButton}
                />
              )}
          </div>
          <div className="selectFacility-container">
            <div className="selectFacility-subContainer">
              <div className="selectFacility-subMain">
                <h2 className="selectfacility-title">Select a Facility</h2>
                {salesRole ? (
                  <TerritorySalesAndNonSales salesRole={salesRole} />
                ) : (
                  <SearchFacilityDataBase salesRole={salesRole} />
                )}
              </div>
            </div>
            {process.env.REACT_APP_ENV_HIDE_SSOSIDENAV === "false" && (
              <div className="sso-side-nav">
                <SSoSideNav />
              </div>
            )}
            <Popup
              openFlag={openFlag}
              closeHandler={() => setOpenFlag(false)}
              hideCloseButton={true}
              dialogParentClass="multigroupsClass"
            >
              <div className="multigroupsMainClass">
                <h3>Please select any one of the groups:</h3>
                <RadioGroup
                  onChange={(e) => setSelectedValue(e.target.value)}
                  value={selectedValue}
                >
                  {arrayOfGroups.map((x: any) => {
                    return (
                      <CustomRadioButton
                        label={x.label}
                        textValue={x.value}
                        selectedValue={x.value}
                        error={false}
                      />
                    );
                  })}
                </RadioGroup>{" "}
                <ExpressButton
                  parentClass="submitGroupBtn"
                  variant="contained"
                  clickHandler={() => handleGroupAddBtnClick()}
                  disabled={selectedValue === ""}
                >
                  Submit
                </ExpressButton>
              </div>
            </Popup>
          </div>
        </>
      )}
      <Popup
        closeHandler={handleCloseUpdatesPopupFromSSO}
        openFlag={popupFlag}
        dialogParentClass="add-new-patient-update"
      >
        <FeaturesInfoPopup
          cmsUserGuidence={AuthObj?.featuresCmsContent!}
          cmsLoader={cmsLoader}
        />
      </Popup>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={() => {
          setErrorPopUpFlag(false);
        }}
        popUpStyles="errorPopupSSOSiteStatus"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => setError(defaultErrorPopUp)}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
      <Popup
        openFlag={deepLinkSpinner}
        closeHandler={() => {}}
        dialogParentClass="ssoNew-spinner-popup"
      >
        {freshNewOrderSpinner()}
      </Popup>
    </div>
  );
};
