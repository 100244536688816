import { Typography, useMediaQuery } from "@mui/material";
import "./salesRoundingPatientDetails.css";
import {
  ISalesRoundingPatientDetails,
  ISrtDropDownValues,
  ITerritoryFacility,
} from "../salesRoundingTool.interface";
import { PatientInformation } from "../components/patientInformation/patientInformation.component";
import { AdditionalNotes } from "../components/additinalNotes/additionalNotes.component";
import OrderInformation from "../components/orderInformation/orderInformation.component";
import { SalesRoundingToolPageSection } from "../salesRoundingTool.enum";
import { ReactComponent as BackIcon } from "../../../assets/LPBackButton.svg";
import { SrtPatientStatus } from "../components/srtPatientStatus/srtPatientStatus.component";
import { AddPrescriberDetails } from "../components/addPrescriberDetails/addPrescriberDetails.component";
import { IPrescriberModal } from "../../../components/newOrder/prescriberInformation/prescriberSearch/prescriberSearch.model";
import { SrtTherapyInformation } from "../components/srtTherapyInformation/srtTherapyInformation.component";
import { ServiceCoveringInformation } from "../components/serviceCoveringInformation/serviceCoveringInformation.component";
import { SrtWoundInformation } from "../components/woundInformation/srtWoundInformation.component";

export interface ISalesRoundingPatientDetailsProps {
  setPageSection: Function;
  data: ISalesRoundingPatientDetails;
  setData: Function;
  selectedFacility: ITerritoryFacility;
  srtDropDownValues: ISrtDropDownValues;
}
export const SalesRoundingPatientDetails = ({
  setPageSection,
  data,
  setData,
  selectedFacility,
  srtDropDownValues,
}: ISalesRoundingPatientDetailsProps) => {
  const handleSuccessAndClose = (
    prescriber: IPrescriberModal,
    type: "Attending" | "Prescribing"
  ) => {
    let tempData = data;
    if (type === "Attending") {
      tempData = {
        ...tempData,
        attendingPhysician: prescriber,
      };
    } else if (type === "Prescribing") {
      tempData = {
        ...tempData,
        prescribingPhysician: prescriber,
      };
    }
    setData(tempData);
  };
  const isMobileScreen = useMediaQuery("(max-width:768px)");

  return (
    <div
      className="sales-rounding-patient-details-main-div"
      data-testid="sales-rounding-patient-details-main-div"
      id="sales-rounding-patient-details-main-div"
    >
      {isMobileScreen && (
        <div
          className="sales-rounding-patient-details-back-button"
          data-testid="sales-rounding-patient-details-back-button"
          id="sales-rounding-patient-details-back-button"
        >
          {isMobileScreen && (
            <BackIcon
              className="backIcon-SelectaFacility"
              onClick={() =>
                setPageSection(
                  SalesRoundingToolPageSection.SRT_PATIENTS_DASHBOARD
                )
              }
            />
          )}
          <Typography
            className="sales-rounding-patient-details-back-button-name"
            data-testid="sales-rounding-patient-details-back-button-name"
          >
            Return to Patient List
          </Typography>
        </div>
      )}
      {!isMobileScreen && (
        <div
          className="sales-rounding-patient-details-name"
          data-testid="sales-rounding-patient-details-name"
          id="sales-rounding-patient-details-name"
        >
          <Typography
            className="sales-rounding-patient-details-header-name"
            data-testid="sales-rounding-patient-details-header-name"
          >
            Patient Details
          </Typography>
        </div>
      )}
      <div className="sales-rounding-patient-information-sub-component">
        <PatientInformation
          data={data}
          setData={setData}
          patientInformation={data.patientInformation}
        />
        <AdditionalNotes
          data={data}
          setData={setData}
          additionalNotes={data.additionalNotes}
        />
        <OrderInformation orderInformation={data.orderInformation} />
        <SrtPatientStatus
          data={data}
          setData={setData}
          patientStatus={data.patientStatus}
        />
        <SrtTherapyInformation
          data={data}
          setData={setData}
          srtDropDownValues={srtDropDownValues}
        />
        <AddPrescriberDetails
          prescriberInformation={data.attendingPhysician}
          selectedFacility={selectedFacility}
          isAttendingPhysician={true}
          handleSuccessAndClose={(prescriber: any) =>
            handleSuccessAndClose(prescriber, "Attending")
          }
        />
        <AddPrescriberDetails
          prescriberInformation={data.prescribingPhysician}
          selectedFacility={selectedFacility}
          isAttendingPhysician={false}
          handleSuccessAndClose={(prescriber: any) =>
            handleSuccessAndClose(prescriber, "Prescribing")
          }
        />
        <ServiceCoveringInformation
          data={data}
          setData={setData}
          srtDropDownValues={srtDropDownValues}
        />
        <SrtWoundInformation
          data={data}
          setData={setData}
          woundInfoData={data.patientWoundInformation.value}
        />
      </div>
    </div>
  );
};
