import { MouseEventHandler, useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./vacReadyCareInventory.css";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Popup } from "../../../core/popup/popup.component";
import { agGridGetRowStyle } from "../../../util/utilityFunctions";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import {
  InventoryContext,
  InventoryContextType,
} from "../../../context/InventoryContext";
import { AgGridReact } from "ag-grid-react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { useMediaQuery } from "@mui/material";
import { ReactComponent as Download } from "../../../assets/download.svg";
import { ReactComponent as DownloadDisabledIcon } from "../../../assets/downloadDisabled.svg";
import {
  InventoryPopUp,
  InventoryPopUpFlow,
} from "../popUpContainer/inventoryPopUpContainer.enum";
import { InventoryPopUpContainer } from "../popUpContainer/inventoryPopUpContainer.component";
import {
  IInventoryProductDetails,
  InventoryTabListingSection,
  VacReadyCareMenuActionTypes,
} from "../inventoryTabContainer.enum";
import InventoryProductMenu from "../InventoryProductMenu/InventoryProductMenu.component";
import { ReactComponent as CheckIcon } from "../../../assets/checkMark.svg";
import { filter } from "underscore";

interface Props {
  redirectToNewOrderPage?: MouseEventHandler<HTMLButtonElement>;
  redirectToInventoryAdjPage?: MouseEventHandler<HTMLButtonElement>;
  readyCareProducts: IInventoryProductDetails[];
  downloadBtnAction: Function;
  setStoredProductData: Function;
  manageStorageLocationsButtonAction: Function;
  refreshVacReadyCareData: Function;
}

const VacReadyCareInventory = ({
  redirectToNewOrderPage,
  redirectToInventoryAdjPage,
  readyCareProducts,
  downloadBtnAction,
  setStoredProductData,
  manageStorageLocationsButtonAction,
  refreshVacReadyCareData,
}: Props) => {
  const gridRef: any = useRef(null);
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(false);
  const isMobileScreen = useMediaQuery("(max-width:1024px)");
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const inventoryObj = useContext<InventoryContextType | null>(
    InventoryContext
  );
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const disableDownloadBtn =
    readyCareProducts && readyCareProducts.length === 0;

  const [currentPopUp, setCurrentPopUp] = useState<InventoryPopUp>(
    InventoryPopUp.LOADER
  );
  const [openContainerPopupFlag, setOpenContainerPopupFlag] = useState(false);
  const [popUpFlow, setPopUpFlow] = useState<InventoryPopUpFlow>();
  const [selectedProduct, setSelectedProduct] =
    useState<IInventoryProductDetails | null>(null);

  const csvColumns: string[] = [
    "Product",
    "Serial Number",
    "Location",
    "Days in Storage",
    "MIA",
  ];

  const defaultColDef = {
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    autoHeight: true,
    resizable: false,
    filter: false,
    flex: 1,
  };

  const columnDefs: any = [
    {
      headerName: "Product",
      field: "product",
      sortable: true,
      suppressMovable: true,
      pinned: isMobileScreen ? "left" : "none",
      lockPinned: isMobileScreen ? true : false,
      width: isMobileScreen ? 100 : 250,
      wrapText: true,
    },
    {
      headerName: "Serial Number",
      field: "serialNumber",
      sortable: true,
      filter: false,
      suppressMovable: true,
      minWidth: 150,
    },
    {
      headerName: "Location",
      field: "storageLocation",
      sortable: true,
      width: isMobileScreen ? 150 : 300,
      filter: false,
      wrapText: true,
      suppressMovable: true,
      flex: isMobileScreen ? 0 : 2,
      cellRenderer: function (params: any) {
        return (
          <>
            <span
              className={"inventory-ready-care-location"}
              onClick={() => handlePopupOpen(params.data)}
            >
              {params.data.storageLocation}
            </span>
          </>
        );
      },
    },
    {
      headerName: "Days in Storage",
      field: "storageDays",
      sortable: true,
      filter: false,
      suppressMovable: true,
      minWidth: 150,
      cellRenderer: function (params: any) {
        return (
          <div className="inventory-ready-care-days">
            {params.data.storageDays}
          </div>
        );
      },
    },
    {
      headerName: "MIA",
      field: "isMissingAsset",
      sortable: true,
      filter: false,
      suppressMovable: true,
      minWidth: 70,
      cellRenderer: function (params: any) {
        return (
          <div className="inventory-ready-care-missing-asset">
            {params.data.isMissingAsset ? <CheckIcon /> : ""}
          </div>
        );
      },
    },

    {
      headerName: "",
      field: "",
      width: isMobileScreen ? 45 : 100,
      pinned: isMobileScreen ? "right" : null,
      suppressMovable: true,
      lockPinned: isMobileScreen ? true : false,
      sortable: false,
      type: "rightAligned",
      headerClass: "menu-column-empty-text",
      cellClass: "menu-column-menu-btn",
      cellRendererSelector: (params: any) => {
        return {
          component: InventoryProductMenu,
          params: {
            productData: params.data,
            handleClickFunctionlity: handleLocationMenuOptionClick,
            tabListingSection:
              InventoryTabListingSection.VAC_READY_CARE_PROGRAM_LISTING_SECTION,
          },
        };
      },
    },
  ];

  const closePopUp = () => {
    setOpenContainerPopupFlag(false);
    setCurrentPopUp(InventoryPopUp.LOADER);
  };

  const redirectToVacOrder = () => {
    newOrderObj?.resetNewOrderForm();
    newOrderObj?.setInventoryProductCount(readyCareProducts.length);
    history.push("/orders/newOrder");
  };

  const redirectToRequestAdjpage = (
    isAddingInventory: boolean,
    product: IInventoryProductDetails | null = null
  ) => {
    inventoryObj?.resetInventoryData();
    history.push({
      pathname: "/inventory/inventoryAdjustment",
      state: {
        stateData: readyCareProducts,
        selectedProduct: product,
        requestType: isAddingInventory,
      },
    });
  };

  const handleDownloadBtnCSV = async () => {
    let rowData: any[] = [];
    gridRef?.current?.api?.forEachNode((node: any) => rowData.push(node.data));
    const csvData = rowData?.map((x: any) => {
      return {
        product: x.product,
        serialNumber: x.serialNumber,
        storageLocation: x.storageLocation,
        storageDays: x.storageDays,
        MIA: x.isMissingAsset ? "Yes" : "No",
      };
    });
    const registeredFacilty = authObj?.registeredFaciltyAddress;
    const csvFileName: string = `${registeredFacilty?.accountName
      .split(" ")
      .join("_")}_${registeredFacilty?.accountNumber}_RC_Inventory_`;
    const reqsBody = {
      Columns: csvColumns,
      Data: csvData,
    };
    await downloadBtnAction(reqsBody, csvFileName);
  };

  const handleClosePopUpAction = () => {
    if (
      currentPopUp === InventoryPopUp.MOVE_TO_READY_CARE_PRODUCT_SUCCESS ||
      currentPopUp === InventoryPopUp.MOVE_TO_STORE_PRODUCT_SUCCESS
    ) {
      handleSuccessAndClose();
    } else {
      closePopUp();
    }
  };

  const handleSuccessAndClose = (
    isUpdateOnlyCurrentProduct: boolean = false
  ) => {
    // Get updated vac ready care products
    refreshVacReadyCareData();
    if (!isUpdateOnlyCurrentProduct) {
      // Clear from stored Product
      setStoredProductData(null);
    }
    // clear selected product
    setSelectedProduct(null);
    // Close pop up
    closePopUp();
  };

  const handlePopupOpen = (product: IInventoryProductDetails) => {
    const updatedProduct: IInventoryProductDetails = {
      ...product,
      storageLocation: product.storageLocation.toUpperCase(),
    };
    setSelectedProduct(updatedProduct);
    setCurrentPopUp(InventoryPopUp.LOADER);
    setPopUpFlow(InventoryPopUpFlow.UPDATE_LOCATION);
    setOpenContainerPopupFlag(true);
  };

  const handleLocationMenuOptionClick = (
    typeOfEvent: string,
    product: IInventoryProductDetails
  ) => {
    switch (typeOfEvent.toLowerCase()) {
      case VacReadyCareMenuActionTypes.UPDATE_STORAGE_LOCATION.toLowerCase():
        handleUpdateLocationPopupOpen(product);
        break;
      case VacReadyCareMenuActionTypes.MOVE_UNIT_TO_STORED_PRODUCT.toLowerCase():
        handleMovedToStoredProductPopupOpen(product);
        break;
      case VacReadyCareMenuActionTypes.REMOVE_FROM_INVENTORY.toLowerCase():
        redirectToRequestAdjpage(false, product);
        break;
      default:
        break;
    }
  };

  const handleUpdateLocationPopupOpen = (product: IInventoryProductDetails) => {
    const updatedProduct: IInventoryProductDetails = {
      ...product,
      storageLocation: product.storageLocation
        ? product.storageLocation.toUpperCase()
        : "",
    };
    setSelectedProduct(updatedProduct);
    setCurrentPopUp(InventoryPopUp.LOADER);
    setPopUpFlow(InventoryPopUpFlow.UPDATE_LOCATION);
    setOpenContainerPopupFlag(true);
  };

  const handleMovedToStoredProductPopupOpen = (
    product: IInventoryProductDetails
  ) => {
    const updatedProduct: IInventoryProductDetails = {
      ...product,
      storageLocation: product.storageLocation.toUpperCase(),
    };
    setSelectedProduct(updatedProduct);
    setCurrentPopUp(InventoryPopUp.LOADER);
    setPopUpFlow(InventoryPopUpFlow.MOVE_TO_STORED_PRODUCT);
    setOpenContainerPopupFlag(true);
  };

  return (
    <div className="inventory-main-component">
      <div className="inventory-sub-subcomponent">
        <div className="inventory-subcomponent">
          <Popup
            closeHandler={() => setLoader(false)}
            openFlag={loader}
            hideCloseButton={true}
          >
            <div className="inventory-spinner">
              <LoadingSpinner />
            </div>
          </Popup>

          <div className="inventory-button-container">
            <ExpressButton
              variant="outlined"
              testId="requestAdjBtnTest"
              clickHandler={
                redirectToInventoryAdjPage === undefined
                  ? () => redirectToRequestAdjpage(true)
                  : redirectToInventoryAdjPage
              }
              parentClass="inventory-button"
              disabled={permissionObj?.mappedRolesPermissionData.IsSupportRole}
            >
              Request Inventory Adjustment
            </ExpressButton>
            {permissionObj?.mappedRolesPermissionData.IsShowVacOrderButton && (
              <ExpressButton
                variant="contained"
                parentClass="start-ready-care-order"
                testId="startNewOrderBtn"
                clickHandler={
                  redirectToNewOrderPage === undefined
                    ? redirectToVacOrder
                    : redirectToNewOrderPage
                }
                disabled={
                  permissionObj?.mappedRolesPermissionData.IsSupportRole
                }
              >
                Start 3M™ V.A.C.® Ready Care Program Order
              </ExpressButton>
            )}
          </div>
          <div
            data-testid="inventory-vac-ready-care-ag-table"
            className="ag-theme-quartz"
            style={{ height: 400, width: "100%" }}
          >
            <AgGridReact
              ref={gridRef}
              getRowStyle={agGridGetRowStyle}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={readyCareProducts}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              suppressContextMenu={true}
            />
          </div>
          <div className="inventory-btn-div">
            <ExpressButton
              clickHandler={handleDownloadBtnCSV}
              variant="outlined"
              parentClass="ready-care-download-btn"
              testId="inventory-download-button-test"
              startIcon={
                disableDownloadBtn ? <DownloadDisabledIcon /> : <Download />
              }
              disabled={disableDownloadBtn}
            >
              Download
            </ExpressButton>
            {permissionObj &&
              permissionObj.mappedRolesPermissionData.IsAdminMyListsButton && (
                <ExpressButton
                  clickHandler={() => manageStorageLocationsButtonAction()}
                  variant="outlined"
                  parentClass="manage-storage-locations-btn"
                  testId="manage-storage-locations-button-test"
                >
                  Manage Storage Locations
                </ExpressButton>
              )}
          </div>
        </div>
      </div>
      <Popup
        openFlag={openContainerPopupFlag}
        dialogParentClass="inventory-pop-up-container"
        closeHandler={handleClosePopUpAction}
        hideCloseButton={currentPopUp === InventoryPopUp.LOADER}
      >
        {selectedProduct && popUpFlow && (
          <InventoryPopUpContainer
            closePopUp={handleClosePopUpAction}
            currentPopUp={currentPopUp}
            flow={popUpFlow}
            setCurrentPopUp={setCurrentPopUp}
            selectedProduct={selectedProduct}
            successAndClose={handleSuccessAndClose}
          />
        )}
      </Popup>
    </div>
  );
};

export default VacReadyCareInventory;
