import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ISalesRoundingPatientDetails } from "../../salesRoundingTool.interface";
import { ISRTAdditionalNotes } from "./additionalNotes.interface";

export interface ISRTAdditionalNotesProps {
  data: ISalesRoundingPatientDetails;
  setData: Function;
  additionalNotes: ISRTAdditionalNotes;
}

export const defaultAdditionalNotes: ISRTAdditionalNotes = {
  notes: {
    componentId: "srt-additional-notes",
    id: "srt-additional-notes-text",
    order: 5,
    required: false,
    title: "Notes",
    valid: ValidationStatus.VALID,
    value: "",
  },
};
