export interface ISSOSideNav {
  searchforhomecare: ISSOSideNavInput;
  internalAdministration: ISSOSideNavInput;
  orderVACTherapy: ISSOSideNavInput;
  viewReports: ISSOSideNavInput;
  salesRoundingTool: ISSOSideNavInput;
  lifeOfAnOrderGuide: ISSOSideNavInput;
}

export interface ISSOSideNavInput {
  labelText: string;
  isVisible: boolean;
  pageType: SSOSideNavPageType;
  path: string;
}

export enum SSOSideNavPageType {
  POPUP = "popup",
  PATH = "path",
}
