import moment from "moment";
import { format } from "react-string-format";
import { IPatient } from "../../patient.interface";
import { OrderOverViewTabsTitle } from "./orderOverviewContainer.enum";
import { getOrderStatusDetails } from "../../../../util/vacOrderService";
import { fetchWoundList, removePatient } from "../../../../util/3meService";
import { SupplyOrderPageSection } from "../../../supplyOrder/SupplyOrderPageSection.enum";
import { WoundDetails } from "../../patientActions/holdOrResumeTherapy/main/holdOrResumeTherapy.interface";
import { PickUpRequestPageSection } from "../../../pickUpAndDischargeRequest/pickUpRequest/pickUpRequestPageSection.enum";
import { WOUND_LIST_EMPTY_ERROR_CODE } from "../../../../util/errorCode";
import { AcuteOrderPickUpRequestPageSection } from "../../../acutePickUpRequest/acuteOrderPickUpRequest.enum";
import { AcuteOrderPickUpRequestContextType } from "../../../../context/AcutePickUpRequestContext";
import { ICustomerPlusListingDetails } from "../../../inventory/customerPlusInventory/customerPlusInventory.interface";
import { CreateServiceRequestContextType } from "../../../../context/CreateServiceRequestContext";

export const NavigatePatientAction = async (
  selectedPatient: IPatient | null | undefined,
  selectedValue: any,
  setRemovePatientMsg: Function,
  setEmptyPopup: Function,
  setRemovePatientOpen: Function,
  supplyOrderObj: any,
  sendNoteObj: any,
  orderOvervewObj: any,
  history: any,
  location: any,
  removePateint: Function,
  pickUpRequestObj: any,
  dischargeRequestObj: any,
  confirmPlacementObj: any,
  submitProofOfDeliveryObj: any,
  exchangeVacUnitObj: any,
  acuteOrderPickUpRequestObj: AcuteOrderPickUpRequestContextType | null,
  createServiceRequestObj: CreateServiceRequestContextType | null,
  showLoader: Function,
  authObj?: any,
  setAnchorEl?: any,
  setMenuTappedPatient?: any
) => {
  try {
    if (selectedValue === "Order Supplies".toLowerCase() && selectedPatient) {
      supplyOrderObj?.resetSupplyOrder();
      supplyOrderObj.setSelectedPatient(selectedPatient);
      supplyOrderObj.setSupplyOrderPage(
        SupplyOrderPageSection.SUPPLYORDER_INFO
      );
      orderOvervewObj?.resetData();
      orderOvervewObj?.resetSeletedSupplyOrderData();
      orderOvervewObj?.resetWoundData();
      history.push({
        pathname: "/orders/supplyOrderList",
        state: {
          isOrderOverviewFlow: true,
        },
      });
    } else if (
      selectedValue === "Remove Patient".toLowerCase() &&
      selectedPatient
    ) {
      showLoader(true);
      setRemovePatientMsg(
        `Patient ${format(
          "{0}, {1}",
          selectedPatient?.lastName!,
          selectedPatient?.firstName!
        )} has been removed`
      );
      const reqParams = {
        FirstName: selectedPatient.firstName,
        LastName: selectedPatient.lastName,
        RentalOrderNumber: selectedPatient.roNumber,
        DOB: selectedPatient.dob ?? null,
        UserName: authObj.userProfile.userName,
        EmailAddress: authObj.userProfile.emailAddress,
        SiteUseId: authObj?.registeredFaciltyAddress?.siteUseId,
        IsAcuteOrder: selectedPatient.isAcuteOrder,
        OrderId:selectedPatient.orderID
      };
      const removePatientResponse = await removePatient(reqParams);
      setAnchorEl(null);
      setMenuTappedPatient(null);
      showLoader(false);
      if (removePatientResponse.succeeded) {
        removePateint(selectedPatient.roNumber.toString().length >0 ? selectedPatient.roNumber.toString() : selectedPatient.orderID?.toString());
        setRemovePatientOpen(true);
        history.replace(location.pathname, {});
        window.history.replaceState(
          {},
          "Used to clear the history state object"
        );
        orderOvervewObj?.resetData();
        orderOvervewObj?.setOrderTabTitle("Orders...");
        orderOvervewObj?.resetWoundData();
      } else {
        console.log("Error" + removePatientResponse.error.message);
      }
    } else if (
      selectedValue === "Send a Note".toLowerCase() &&
      selectedPatient
    ) {
      orderOvervewObj?.resetData();
      orderOvervewObj?.resetSeletedSupplyOrderData();
      orderOvervewObj?.resetWoundData();
      sendNoteObj.resetSendNoteData();
      history.push({
        pathname: "/home/sendNote",
        state: {
          selectedPatient: selectedPatient,
          isOrderOverviewFlow: true,
        },
      });
    } else if (
      selectedValue === "Create Service Request".toLowerCase() &&
      selectedPatient
    ) {
      const selectedProduct: ICustomerPlusListingDetails = {
        id: Number(selectedPatient!.patientId),
        unitTagId: "",
        productName: selectedPatient!.productName ?? "",
        productNameDescription: selectedPatient!.productName ?? "",
        serialNumber: selectedPatient!.productSerialNumber ?? "",
        currentLocation: selectedPatient!.patientLocation,
        storageDays: "",
        status: "",
        patientFirstName: selectedPatient.firstName,
        patientLastName: selectedPatient.lastName,
        therapyStartDate: null,
        therapyEndDate: null,
        availableDate: "",
        menuItems: [],
        roNumber: selectedPatient!.roNumber.toString(),
        isServiceRequested: false,
      };
      createServiceRequestObj?.resetContext();
      window.scrollTo(0, 0);
      history.push({
        pathname: "/home/createServiceRequest",
        state: {
          product: selectedProduct,
          isSolventumProduct: true,
          patientData: {
            ...selectedPatient,
            placementDate: selectedPatient.orderCreationDate,
          },
        },
      });
    } else if (
      selectedValue === "Pickup/Discharge Request".toLowerCase() &&
      selectedPatient
    ) {
      if (selectedPatient.isAcuteOrder) {
        acuteOrderPickUpRequestObj?.resetData();
        acuteOrderPickUpRequestObj?.setPage(
          AcuteOrderPickUpRequestPageSection.ACUTE_PICKUP_FIRST_PAGE
        );
        acuteOrderPickUpRequestObj?.setPatient(selectedPatient);
        history.push("/home/acuteOrderPickUpRequest");
      } else {
        pickUpRequestObj?.resetData();
        dischargeRequestObj?.resetData();
        pickUpRequestObj.setData((dt: any) => ({
          ...dt,
          isComingFromAlert: false,
        }));
        pickUpRequestObj?.setPickUpRequestPage(
          PickUpRequestPageSection.PICK_UP_REQUEST_START_FORM
        );
        pickUpRequestObj?.setPatient(selectedPatient);
        history.push("/home/pickUpRequest");
      }
    } else if (
      selectedValue === "Add Wound Assessment".toLowerCase() &&
      selectedPatient
    ) {
      orderOvervewObj?.resetData();
      orderOvervewObj?.resetSeletedSupplyOrderData();
      orderOvervewObj?.resetWoundData();
      orderOvervewObj?.setSelectedOrderTab(
        OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
      );
      history.push({
        pathname: "/home/orderOverview",
        state: {
          stateData: selectedPatient,
        },
      });
    } else if (selectedValue === "Upload Documents".toLowerCase()) {
      orderOvervewObj?.setSelectedOrderTab(
        OrderOverViewTabsTitle.DOCUMENTS_TAB_TITLE
      );
      history.push({
        pathname: "/home/orderOverview",
        state: {
          stateData: selectedPatient,
        },
      });
    } else if (
      selectedValue === "Reprint Proof Of Delivery".toLowerCase() &&
      selectedPatient
    ) {
      submitProofOfDeliveryObj?.resetData();
      history.push({
        pathname: "/home/proofOfDelivery",
        state: {
          stateData: selectedPatient,
        },
      });
    } else if (
      selectedValue === "Confirm Placement".toLowerCase() &&
      selectedPatient
    ) {
      confirmPlacementObj?.resetData();
      confirmPlacementObj?.setPatient(selectedPatient);
      history.push({
        pathname: "/home/confirmPlacement",
        state: {
          stateData: selectedPatient,
        },
      });
    } else if (
      selectedValue === "Discharge Request".toLowerCase() &&
      selectedPatient
    ) {
      pickUpRequestObj?.resetData();
      dischargeRequestObj?.resetData();
      pickUpRequestObj?.setPickUpRequestPage(
        PickUpRequestPageSection.DISCHARGE_REQUEST_START_FORM
      );
      pickUpRequestObj?.setPatient(selectedPatient);
      history.push({
        pathname: "/home/dischargeRequest",
        state: {
          stateData: selectedPatient,
        },
      });
    } else if (
      selectedValue === "Exchange V.A.C.® Unit".toLowerCase() &&
      selectedPatient
    ) {
      exchangeVacUnitObj?.resetData();
      exchangeVacUnitObj?.setPatient(selectedPatient);
      history.push({
        pathname: "/home/exchangeVacUnit",
        state: {
          stateData: selectedPatient,
        },
      });
    } else {
      setEmptyPopup(true);
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getWoundList = async (
  roNumber: number,
  dob: string,
  isAcuteOrder: boolean
) => {
  const params = {
    RentalOrderNumber: roNumber.toString(),
    DOB: moment(dob).format("MM/DD/yyyy"),
    isAcuteOrder: isAcuteOrder,
  };
  try {
    const response = await fetchWoundList(params);
    if (response.succeeded) {
      const wounds = response.item.wounds;
      if (wounds && wounds.length > 0) {
        const updatedWounds = wounds
          .map((wound: WoundDetails, index: number) => {
            if (index > 1) {
              wound.id = "";
            }
            wound.isChecked = false;
            return wound;
          })
          .filter((wound: WoundDetails) => wound.id !== "");
        return { succeeded: true, updatedWounds };
      } else {
        return {
          succeeded: false,
          error: { errorCode: WOUND_LIST_EMPTY_ERROR_CODE },
        };
      }
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getTherapyStartDate = async (
  selectedPatient: IPatient,
  userName: string | undefined,
  siteUseId: string | undefined
) => {
  if (userName && userName !== "" && siteUseId && siteUseId !== "") {
    const reqParam = {
      RentalOrderNumber: selectedPatient.roNumber.toString(),
      DOB: selectedPatient.dob,
      userName: userName,
      siteUseId: siteUseId,
    };
    try {
      const result = await getOrderStatusDetails(reqParam);
      if (result.succeeded) {
        const dateStr = result.item.therapyDate;
        const date = moment(dateStr, "YYYY-MM-DDTHH:mm:ss").toDate();
        return { succeeded: true, date };
      } else {
        return result;
      }
    } catch (error) {
      console.log("error", error);
    }
  }
};
