export enum SRTDischargeStatus {
  DISCHARGE_TO_SNF = "Discharge to SNF",
  DISCHARGE_TO_LTF = "Discharge to LTAC",
  OTHER_REASON = "Other reason",
  DISCHARGE_TO_HOME = "Discharge to Home",
  TRANSITIONED_ON_ACTI_VAC = "Transitioned on ActiVAC",
  UNKNOWN = "Unknown",
  PATIENT_DECLINED_THERAPY = "Patient declined therapy",
  ALTERNATE_THERAPY = "Alternate therapy",
  COMPETITIVE_THREAT = "Competitive threat",
  PAYOR_ISSUE = "Payer issue",
}
