import "./salesRoundingDashboard.css";
import {
  ISrtDropDownValues,
  ITerritoryFacility,
} from "../salesRoundingTool.interface";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import {
  getCSV,
  getSiteUseId,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { ReactComponent as DownloadDisabledIcon } from "../../../assets/downloadDisabled.svg";
import { ReactComponent as Download } from "../../../assets/download.svg";
import { ReactComponent as RoundingComplete } from "../../../assets/rounding status.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  InputBase,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ReactComponent as BackIcon } from "../../../assets/LPBackButton.svg";
import { SalesRoundingToolPageSection } from "../salesRoundingTool.enum";
import { useContext, useEffect, useState } from "react";
import {
  getCSVDownload,
  getSalesRoundAcutePatients,
} from "../../../util/3meService";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import {
  ISalesRoundingPatient,
  salesRoundingCSVHeaders,
} from "./salesRoundingDashboard.interface";
import { ReactComponent as AcuteOrderIcon } from "../../../assets/acuteOrder.svg";
import { ReactComponent as DownarrowImage } from "../../../assets/DownarrowImage.svg";
import { Circle, Search } from "@mui/icons-material";
import moment from "moment";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import {
  ERROR_MSG_DOWNLOAD_MY_PATIENTS,
  ERROR_MSG_GET_PRESCRIBER_DETAILS,
  ERROR_MSG_SRT_FACILITES_PATIENT,
  SVC_GET_CUSTOMER_INFORMATION_ERROR,
} from "../../../util/errorMsg";
import {
  ISalesRoundingContextType,
  SaleRoundingToolContext,
} from "../../../context/SalesRoundingToolContext";
import { Popup } from "../../../core/popup/popup.component";
import SuccessSnackbar from "../../../core/snackBar/successSnackBar.component";
import SearchIcon from "@mui/icons-material/Search";
import { SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE } from "../../../util/errorCode";
import { getCustomerInformation } from "../../../util/inPatientOrderService";
import { mapSRTResponseData } from "../mappers/salesRounding.response.mapper";
import { SalesRoundingPatientSummary } from "./salesRoundingPatientSummary/salesRoundingPatientsummary.component";
import { useDebounce } from "use-debounce";
import {
  defaultAttendingPhysician,
  defaultPrescribingPhysician,
} from "../salesRoundingTool.model";
import { getNPIPrescriber } from "../../../util/npiService";
import { log } from "node:console";
import { format } from "react-string-format";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import {
  DD_SRT_LEAD_GENERATION,
  DD_SRT_SERVICE_COVERING,
} from "../../../util/staticText";
import { getdropDownContent } from "../../../util/dropDownService";

export interface ISalesRoundingDashboard {
  setPageSection: Function;
  setData: Function;
  setSelectedPatientData?: Function;
  srtDropDownValues: ISrtDropDownValues;
  setSrtDropDownValues: Function;
}
const SalesRoundingDashboard = ({
  setPageSection,
  setData,
  setSelectedPatientData,
  srtDropDownValues,
  setSrtDropDownValues,
}: ISalesRoundingDashboard) => {
  const isMobileScreen = useMediaQuery("(max-width:728px)");
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [loader, setLoader] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchedInput, setSearchedInput] = useState("");
  const [debouncedText] = useDebounce(searchInput, 500);
  const [srtPatientList, setSrtPatientList] = useState<ISalesRoundingPatient[]>(
    []
  );
  const [originalPatientData, setOriginalPatientData] = useState<
    ISalesRoundingPatient[]
  >([]);
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [showPopUpLoader, setShowPopUpLoader] = useState(false);
  const [downloadPopUp, setDownLoadPopUp] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [rentalPONumber, setRentalPONumber] = useState("");

  const salesRoundingObj = useContext<ISalesRoundingContextType | null>(
    SaleRoundingToolContext
  );
  const facilityName = salesRoundingObj!.selectedFacility!.accountName;
  const getAcutePatientsByFacility = async () => {
    setLoader(true);
    if (salesRoundingObj?.selectedFacility?.siteUseId !== "") {
      const params = {
        siteUseId: salesRoundingObj?.selectedFacility?.siteUseId,
        fetchBillTo: false,
      };
      const customerResponse = await getCustomerInformation(params);
      if (customerResponse && customerResponse.succeeded) {
        setRentalPONumber(customerResponse?.item?.rentalPOValue);
        let reqBody = {
          userName: authObj?.userProfile?.userName,
          siteUseId: salesRoundingObj?.selectedFacility?.siteUseId,
          FacilityAccountNumber:
            salesRoundingObj?.selectedFacility?.accountNumber,
          customerMasterNumber: customerResponse?.item?.customerNumber,
        };
        setLoader(true);
        const response = await getSalesRoundAcutePatients(reqBody);
        if (response && response?.succeeded) {
          const mappedData = response?.data?.map((x: any) => {
            return {
              ...x,
              isExpanded: false,
            };
          });
          setSrtPatientList(mappedData as ISalesRoundingPatient[]);
          setOriginalPatientData(mappedData as ISalesRoundingPatient[]);
          setLoader(false);
        } else {
          const apiFailure = {
            errorCode:
              response?.error?.errorCode ||
              response?.error?.code ||
              response?.status,
            errorFlag: true,
            errorMessage: ERROR_MSG_SRT_FACILITES_PATIENT,
            buttonTitle: "Done",
            isPhoneNumberRequired: true,
          };
          setLoader(false);
          setError(apiFailure);
        }
      } else {
        const apiFailure = {
          errorCode:
            customerResponse?.error?.errorCode ||
            customerResponse?.error?.code ||
            customerResponse?.status,
          errorFlag: true,
          errorMessage: SVC_GET_CUSTOMER_INFORMATION_ERROR,
          buttonTitle: "Done",
          isPhoneNumberRequired: true,
        };
        setLoader(false);
        setError(apiFailure);
      }
    } else {
      const errorFailure = {
        errorCode: SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
        errorFlag: true,
        errorMessage: ERROR_MSG_SRT_FACILITES_PATIENT,
      };
      setLoader(false);
      setError(errorFailure);
    }
  };
  useEffect(() => {
    getAcutePatientsByFacility();
  }, []);

  const filteredList = (searchInputParam: string) => {
    setSearchedInput(searchInputParam);
    let filteredData: ISalesRoundingPatient[] = srtPatientList;
    const isValidDate =
      moment(searchInputParam, "YYYY-MM-DD", true).isValid() ||
      moment(searchInputParam, "MM/DD/YYYY", true).isValid();
    const searchInputLower = Array.isArray(searchInputParam)
      ? ""
      : searchInputParam?.trim().toLowerCase();

    if (searchInputLower.length > 2) {
      filteredData =
        filteredData &&
        filteredData.filter((patientData: ISalesRoundingPatient) => {
          if (!isValidDate) {
            return (
              patientData.firstName
                .toLowerCase()
                .includes(searchInputLower.toLowerCase()) ||
              patientData.lastName
                .toLowerCase()
                .includes(searchInputLower.toLowerCase()) ||
              patientData.roNumber
                .toString()
                .includes(searchInputLower.toLowerCase()) ||
              (patientData.productSerialNumber &&
                patientData.productSerialNumber
                  .toString()
                  ?.trim()
                  .toLowerCase()
                  .includes(searchInputLower))
            );
          } else {
            return moment(patientData.dob).isSame(moment(searchInput));
          }
        });
    }
    setOriginalPatientData(filteredData);
  };

  const closeErrorPopUpButtonAction = () => {
    // history.goBack();
    setLoader(false);
    setPageSection(SalesRoundingToolPageSection.FACILITY_SELECTION);
  };

  const getKeyForPatient = (patient: ISalesRoundingPatient): string => {
    if (patient.roNumber) {
      return patient.roNumber.toString();
    } else {
      return patient.patientId ?? "";
    }
  };

  const handlePatientSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const re = /^[a-zA-Z0-9-'/]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  const checkForDownloadOption = () => {
    //1.enabling download option when it has alert logic enabled
    //2.enabling when patients length more than 1
    return originalPatientData && originalPatientData.length > 0;
  };

  const handleDownloadClose = () => {
    setDownLoadPopUp(false);
  };

  const handleCloseAlert = () => {
    setShowSnackbar(false);
  };

  const handleDownloadBtn = () => {
    setDownLoadPopUp(true);
  };

  const handleDownloadBtnCSV = async () => {
    setShowPopUpLoader(true);
    setDownLoadPopUp(false);
    //csv patients mapping with required columns in sequence
    //this sequence should be and similar to the columns array sequence
    const csvPatients = originalPatientData?.map(
      (eachPatient: ISalesRoundingPatient) => {
        return {
          roNumber: eachPatient.roNumber,
          firstName: makeCapitalEachWordInString(eachPatient.firstName),
          lastName: makeCapitalEachWordInString(eachPatient.lastName),
          dob: eachPatient.dob
            ? ` ${moment(eachPatient.dob).format("MM/DD/YYYY")}`
            : "",
          location: eachPatient.patientLocation ?? "",
          id: eachPatient.patientId ?? "",
          status: makeCapitalEachWordInString(eachPatient?.status!),
          productName: eachPatient.productName,
          productSerialNumber: eachPatient.productSerialNumber,
          type: eachPatient.placementType,
          placementType: eachPatient.placementType,
          dischargeStatus: eachPatient.dischargeStatus,
          reasonNotTransitioningHome: eachPatient.reasonNotTransitioningHome,
          otherReasonPatientNotTransitioningHome:
            eachPatient.otherReasonPatientNotTransitioningHome,
          transitionTherapyStartDate: eachPatient.transitionTherapyStartDate
            ? moment(eachPatient.transitionTherapyStartDate).format("L")
            : "",
          lastUpdatedDate: eachPatient.lastUpdatedDate,
          leadGeneration: eachPatient.leadGeneration,
          attendingPhysicianFirstName: eachPatient.attendingPhysicianFirstName,
          attendingPhysicianLastName: eachPatient.attendingPhysicianLastName,
          attendingPhysicianNPI: eachPatient.attendingPhysicianNPI,
          prescribingPhysicianFirstName:
            eachPatient.prescribingPhysicianFirstName,
          prescribingPhysicianLastName:
            eachPatient.prescribingPhysicianLastName,
          prescribingPhysicianNPI: eachPatient.prescribingPhysicianNPI,
          serviceCovering: eachPatient.serviceCovering,
          numberOfWounds: eachPatient.numberOfWounds,
          isSaleRoundingCompleted: eachPatient.isSaleRoundingCompleted,
          primaryWoundType: eachPatient.primaryWoundType,
          primaryWoundLocation: eachPatient.primaryWoundLocation,
          primaryWoundDirection: eachPatient.primaryWoundDirection,
          primaryWoundOrientation: eachPatient.primaryWoundOrientation,
          secondaryWoundType: eachPatient.secondaryWoundType,
          secondaryWoundLocation: eachPatient.secondaryWoundLocation,
          secondaryWoundDirection: eachPatient.secondaryWoundDirection,
          secondaryWoundOrientation: eachPatient.secondaryWoundOrientation,
        };
      }
    );
    const registeredFacilty = salesRoundingObj?.selectedFacility;
    //FileName Format -> Facility Name Facility ID Patients mmddyyyy Export.csv
    const csvFileName: string = `${registeredFacilty?.accountName
      .split(" ")
      .join("_")}_${registeredFacilty?.accountNumber}_Patients_`;
    const reqsBody = {
      Columns: salesRoundingCSVHeaders,
      Data: csvPatients,
    };
    const csvDownloadResponse = await getCSVDownload(reqsBody);
    setShowPopUpLoader(false);
    setShowSnackbar(true);
    if (csvDownloadResponse && csvDownloadResponse.succeeded) {
      const base64String = csvDownloadResponse.item; //item --> base64
      await getCSV(base64String, csvFileName);
    } else {
      const csvApiFailure = {
        errorCode:
          csvDownloadResponse.error.errorCode || csvDownloadResponse.status,
        errorFlag: true,
        errorMessage: ERROR_MSG_DOWNLOAD_MY_PATIENTS,
        buttonTitle: "Done",
        isPhoneNumberRequired: true,
      };
      setLoader(false);
      setError(csvApiFailure);
    }
  };

  const mapNPIResponse = (response: any, isAllSucceeded: boolean) => {
    if (response && response.succeeded) {
      if (response?.items?.length > 0) {
        return response.items[0];
      }
    } else {
      isAllSucceeded = false;
      const failureDetails = {
        errorCode: response?.error?.errorCode || response?.status,
        errorFlag: true,
        errorMessage: ERROR_MSG_GET_PRESCRIBER_DETAILS,
        buttonTitle: "Done",
        isPhoneNumberRequired: true,
      };
      setLoader(false);
      setError(failureDetails);
    }
  };

  const handlePatientSelection = async (patientData: ISalesRoundingPatient) => {
    let isAllSucceeded: boolean = true;
    setLoader(true);
    let selectedPatientData = {
      orderId: patientData.orderId!,
      roNumber: patientData?.roNumber,
      type: patientData?.type,
      ropNumber: patientData?.ropNumber,
      purchaseOrderValue: rentalPONumber,
      facilityName: facilityName,
    };
    setSelectedPatientData!(selectedPatientData);
    const dropDownResponse = await fetchDropdownContents();
    let apiCollection: any = [];
    let attendingPhysicianData = defaultAttendingPhysician;
    let prescribingPhysicianData = defaultPrescribingPhysician;
    if (patientData.attendingPhysicianNPI) {
      apiCollection.push(
        getNPIPrescriber({
          NPI: patientData.attendingPhysicianNPI,
        })
      );
    }
    if (patientData.prescribingPhysicianNPI) {
      apiCollection.push(
        getNPIPrescriber({
          NPI: patientData.prescribingPhysicianNPI,
        })
      );
    } else {
      setPageSection(SalesRoundingToolPageSection.SRT_PATIENT_DETAILS);
      setLoader(false);
    }
    const result = await Promise.all(apiCollection);
    if (result.length >= 1) {
      if (result.length === 1) {
        if (patientData.attendingPhysicianNPI) {
          attendingPhysicianData = mapNPIResponse(result[0], isAllSucceeded);
        }
        if (patientData.prescribingPhysicianNPI) {
          prescribingPhysicianData = mapNPIResponse(result[0], isAllSucceeded);
        }
      } else {
        attendingPhysicianData = mapNPIResponse(result[0], isAllSucceeded);
        prescribingPhysicianData = mapNPIResponse(result[1], isAllSucceeded);
      }
    }

    const mappedSRTData = await mapSRTResponseData(
      patientData,
      attendingPhysicianData,
      prescribingPhysicianData
    );
    let tempData = { ...mappedSRTData };
    if (
      tempData.therapyInformation.leadGeneration.value === "" ||
      tempData.therapyInformation.leadGeneration.value === null
    ) {
      tempData.therapyInformation = {
        ...tempData.therapyInformation,
        leadGeneration: {
          ...tempData.therapyInformation.leadGeneration,
          value: dropDownResponse![0],
        },
      };
    }
    setData(tempData);
    if (isAllSucceeded) {
      setPageSection(SalesRoundingToolPageSection.SRT_PATIENT_DETAILS);
      setLoader(false);
    }
  };
  const fetchDropdownContents = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_SRT_LEAD_GENERATION,
        DD_SRT_SERVICE_COVERING
      );
      const dropDowndata = await getdropDownContent(ddContent);
      if (dropDowndata.items.length > 0) {
        const leadGenerationObject = dropDowndata.items.filter(
          (item: { name: string }) => item.name === DD_SRT_LEAD_GENERATION
        );
        const leadGenerationData = leadGenerationObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        const serviceCoveringObject = dropDowndata.items.filter(
          (item: { name: string }) => item.name === DD_SRT_SERVICE_COVERING
        );
        const serviceCoveringData = serviceCoveringObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setSrtDropDownValues({
          isLeadGenerationCodeDropdown: leadGenerationData,
          isLeadGenerationTextDropdown: leadGenerationData.map(
            (x: { text: string }) => x.text
          ),
          isServiceCoveringCodeDropdown: serviceCoveringData,
          isServiceCoveringTextDropdown: serviceCoveringData.map(
            (x: { text: string }) => x.text
          ),
        });
        let leadGenerationDefaultValue =
          leadGenerationData
            .map((x: { text: string }) => x.text)
            .find((text: string) => text === "NA") ||
          leadGenerationData[0]?.text;
        let serviceCoveringDefaultValue = serviceCoveringData.map(
          (x: { text: string }) => x.text
        )[0];
        return [leadGenerationDefaultValue, serviceCoveringDefaultValue];
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (
      (debouncedText.length === 0 && searchedInput !== debouncedText) ||
      debouncedText.length > 0
    ) {
      filteredList(searchInput);
    }
  }, [debouncedText]);

  const handleExpandPatientData = (patientData: ISalesRoundingPatient) => {
    let sa = originalPatientData.map((x: ISalesRoundingPatient) => {
      if (x.roNumber === patientData.roNumber) {
        x.isExpanded = !x.isExpanded;
      }
      return x;
    });
    setOriginalPatientData(sa);
  };

  return (
    <div
      className="sales-rounding-dashboard-main-div"
      data-testid="sales-rounding-dashboard-main-div"
      id="sales-rounding-dashboard-main-div"
    >
      {showSnackbar === true && (
        <SuccessSnackbar
          alertMessage="List successfully downloaded"
          showSnackbar={showSnackbar}
          handleCloseAlert={handleCloseAlert}
        />
      )}
      {loader === true ? (
        <div className="srt-sales-rounding-full-screen-loader">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div
            className="sales-rounding-dashboard-facility-name"
            data-testid="sales-rounding-dashboard-facility-name"
            id="sales-rounding-dashboard-facility-name"
          >
            {isMobileScreen && (
              <BackIcon
                className="backIcon-SelectaFacility"
                onClick={() =>
                  setPageSection(
                    SalesRoundingToolPageSection.FACILITY_SELECTION
                  )
                }
              />
            )}
            <Typography
              className="sales-rounding-account-name"
              data-testid="sales-rounding-account-name"
            >
              {makeCapitalEachWordInString(facilityName)}
            </Typography>
          </div>
          <div className="sales-rounding-dashboard-search-download-div">
            {isMobileScreen ? (
              <div
                className="sales-rounding-dashboard-download-div mobile-view"
                data-testid="sales-rounding-dashboard-download-div mobile-view"
              >
                <div
                  className="sales-rounding-dashboard-search-input"
                  data-testid="sales-rounding-dashboard-search-input"
                  id="sales-rounding-dashboard-search-input"
                >
                  <div
                    className="dashboard-search-icon-div"
                    id="sales-rounding-dashboard-search-icon-div"
                    data-testid="sales-rounding-dashboard-search-icon-div"
                  >
                    <SearchIcon className="dashboard-search-icon" />
                  </div>
                  <InputBase
                    id="dashboard-user-search-input"
                    className="dashboard-user-search-input"
                    data-testid="dashboard-user-search-input"
                    onChange={handlePatientSearch}
                    placeholder="Patient Name, RO Number, Serial Number, Date of Birth"
                    value={searchInput}
                    autoFocus={true}
                  />
                </div>
                <IconButton
                  disabled={!checkForDownloadOption()}
                  onClick={handleDownloadBtn}
                >
                  {checkForDownloadOption() ? (
                    <Download />
                  ) : (
                    <DownloadDisabledIcon />
                  )}
                </IconButton>
              </div>
            ) : (
              <div className="sales-rounding-dashboard-download-div">
                <div
                  className="sales-rounding-dashboard-search-input"
                  data-testid="sales-rounding-dashboard-search-input"
                  id="sales-rounding-dashboard-search-input"
                >
                  <div
                    className="dashboard-search-icon-div"
                    id="sales-rounding-dashboard-search-icon-div"
                    data-testid="sales-rounding-dashboard-search-icon-div"
                  >
                    <SearchIcon className="dashboard-search-icon" />
                  </div>
                  <InputBase
                    id="sales-rounding-dashboard-search-input"
                    className="dashboard-user-search-input"
                    data-testid="dashboard-user-search-input"
                    onChange={handlePatientSearch}
                    placeholder="Patient Name, RO Number, Serial Number, Date of Birth"
                    value={searchInput}
                    autoFocus={true}
                  />
                </div>
                <ExpressButton
                  variant="outlined"
                  clickHandler={handleDownloadBtn}
                  startIcon={
                    checkForDownloadOption() ? (
                      <Download />
                    ) : (
                      <DownloadDisabledIcon />
                    )
                  }
                  parentClass="sales-rounding-dashboard-download-btn"
                  testId="sales-rounding-dashboard-download-btn"
                  disabled={!checkForDownloadOption()}
                >
                  Download
                </ExpressButton>
              </div>
            )}
          </div>
          {Array.isArray(originalPatientData) &&
          originalPatientData.length > 0 ? (
            originalPatientData?.map(
              (patient: ISalesRoundingPatient, index: number) => (
                <>
                  <Accordion
                    className="srt-accordionMain"
                    expanded={patient?.isExpanded!}
                  >
                    <AccordionSummary
                      expandIcon={
                        <DownarrowImage
                          className="expandSrtIcon"
                          onClick={() => handleExpandPatientData(patient)}
                        />
                      }
                      id="panel1a-header"
                      className="srt-accordion-main-div"
                      classes={{ content: "srt-accordion-main-content" }}
                    >
                      <div
                        className="srt-patients-card-container"
                        key={getKeyForPatient(patient)}
                        id="srt-patients-card-container-id"
                        data-testid="srt-patients-card-container-test"
                        onClick={() => handlePatientSelection(patient)}
                      >
                        <Grid
                          className="srt-patient-details-main-grid-container"
                          id="srt-patient-details-main-grid-container"
                          container
                        >
                          <Grid
                            className="srt-patient-details-main-grid-item"
                            item
                            xs={12}
                          >
                            <Grid
                              className="srt-patient-details-grid-container"
                              container
                            >
                              <Grid
                                className="srt-patient-details-header-grid-item"
                                item
                                xs={isMobileScreen ? 6 : 8}
                              >
                                <div
                                  className="srt-patient-details-name-and-ready-care-div"
                                  id="srt-patient-details-name-and-ready-care-div"
                                >
                                  <Typography
                                    className="srt-patient-name"
                                    data-testid="srt-patients-card-name-test"
                                    id="srt-patient-name"
                                  >
                                    {makeCapitalEachWordInString(
                                      `${patient.lastName}, ${patient.firstName} `
                                    )}
                                    <span
                                      className={
                                        isMobileScreen
                                          ? "srt-myPatient-icon-label-span"
                                          : ""
                                      }
                                    >
                                      <Tooltip
                                        arrow
                                        className="srt-order-type-tooltip"
                                        classes={{
                                          tooltip: "srt-order-type-tooltip",
                                          popper:
                                            "srt-order-type-tooltip-popper",
                                        }}
                                        data-testid="order-type-tooltip"
                                        placement="top"
                                        PopperProps={{
                                          disablePortal: false,
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        slotProps={{
                                          arrow: {
                                            style: {
                                              color: "#fff",
                                            },
                                          },
                                        }}
                                        title={
                                          <div
                                            className="srt-order-type-tooltip-content"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            {"In-Patient Order"}
                                          </div>
                                        }
                                      >
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                        >
                                          <AcuteOrderIcon className="srt-order-type-icon" />
                                        </span>
                                      </Tooltip>
                                      {patient.type &&
                                        patient.type.toLowerCase() ===
                                          "readycare" && (
                                          <span
                                            className="srt-patient-is-ready-care"
                                            data-testid="srt-patients-card-readyCare-test"
                                            id="srt-patients-card-readyCare"
                                          >
                                            Ready Care
                                          </span>
                                        )}
                                      {patient.type &&
                                        patient.type.toLowerCase() ===
                                          "customerplus" && (
                                          <span
                                            id="id-customer-plus-identification"
                                            className="srt-is-customer-plus"
                                          >
                                            Customer Plus
                                          </span>
                                        )}

                                      {patient.type &&
                                        patient.type.toLowerCase() ===
                                          "storedproduct" && (
                                          <span
                                            id="id-stored-product-identification"
                                            className="srt-stored-product-capsule"
                                          >
                                            Stored Product
                                          </span>
                                        )}
                                    </span>
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                className="srt-patient-details-header-grid-item srt-patient-details-status-header"
                                item
                                xs={isMobileScreen ? 4 : 3}
                              >
                                <Circle
                                  className={`srt-statusIconMain ${patient.color}`}
                                />
                                <Typography
                                  className="srt-patient-status"
                                  data-testid="srt-patients-card-status-test"
                                  id="srt-patient-status"
                                >
                                  {patient.status}
                                </Typography>
                              </Grid>
                              {patient.isSaleRoundingCompleted && (
                                <>
                                  <Grid
                                    className="srt-patient-details-header-grid-item srt-patient-details-status-header"
                                    item
                                    xs={isMobileScreen ? 2 : 1}
                                  >
                                    <span
                                      className={
                                        isMobileScreen
                                          ? "srt-myPatient-icon-label-span"
                                          : ""
                                      }
                                      id="srt-tooltip"
                                    >
                                      <Tooltip
                                        arrow
                                        className="srt-order-type-tooltip"
                                        classes={{
                                          tooltip: "srt-order-type-tooltip",
                                          popper:
                                            "srt-order-type-tooltip-popper",
                                        }}
                                        data-testid="order-type-tooltip"
                                        placement="top"
                                        PopperProps={{
                                          disablePortal: false,
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        slotProps={{
                                          arrow: {
                                            style: {
                                              color: "#fff",
                                            },
                                          },
                                        }}
                                        title={
                                          <div
                                            className="srt-order-type-tooltip-content"
                                            id="srt-order-type-tooltip-content"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            {"Rounding Complete"}
                                          </div>
                                        }
                                      >
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                        >
                                          <RoundingComplete className="srt-order-type-icon" />
                                        </span>
                                      </Tooltip>
                                    </span>
                                  </Grid>
                                </>
                              )}

                              <Grid
                                className="srt-patient-details-header-grid-item"
                                item
                                xs={isMobileScreen ? 7 : 3}
                              >
                                <div
                                  className="srt-patient-details-div"
                                  data-testid="srt-patients-card-dob-test"
                                  id="srt-patient-details-div"
                                >
                                  <Typography className={`title`}>
                                    DOB
                                  </Typography>
                                  <Typography className={`value `}>
                                    {patient.dob !== null && patient.dob !== ""
                                      ? moment(patient.dob).format("L")
                                      : "--"}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                className="srt-patient-details-header-grid-item"
                                item
                                xs={isMobileScreen ? 5 : 3}
                              >
                                <div
                                  className="srt-patient-details-div"
                                  data-testid="srt-patients-card-location-test"
                                  id="srt-patient-details-div"
                                >
                                  <Typography className={`title `}>
                                    Location
                                  </Typography>
                                  <Typography className={`value `}>
                                    {patient.patientLocation}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                className="srt-patient-details-header-grid-item"
                                id="srt-patient-details-header-grid-item"
                                item
                                xs={isMobileScreen ? 12 : 6}
                              >
                                <div
                                  className="srt-patient-details-div srt-patient-details-product-div"
                                  data-testid="srt-patients-card-product-test"
                                  id="srt-patient-details-div"
                                >
                                  <Typography className="title">
                                    Product
                                  </Typography>
                                  <Typography className={"value"}>
                                    {patient.productName &&
                                    patient.productName !== ""
                                      ? `${patient.productName}${
                                          patient.productSerialNumber &&
                                          patient.productSerialNumber !== ""
                                            ? " - " +
                                              patient.productSerialNumber
                                            : ""
                                        }`
                                      : "--"}
                                  </Typography>
                                </div>
                              </Grid>
                              {!patient.isExpanded && (
                                <Grid
                                  className="srt-patient-details-header-grid-item"
                                  item
                                  xs={12}
                                >
                                  <div
                                    className="srt-patient-details-div"
                                    data-testid="srt-patients-card-updated-test"
                                    id="srt-patient-details-div"
                                  >
                                    <Typography className={`updatedtitle`}>
                                      Updated
                                    </Typography>
                                    <Typography className={`value updateValue`}>
                                      {patient.lastUpdatedDate !== null &&
                                      patient.lastUpdatedDate !== ""
                                        ? moment
                                            .utc(patient.lastUpdatedDate)
                                            .local()
                                            .format("L") +
                                          " " +
                                          moment
                                            .utc(patient.lastUpdatedDate)
                                            .local()
                                            .format("LT") +
                                          " " +
                                          patient.lastUpdatedBy
                                        : "--"}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      onClick={() => handlePatientSelection(patient)}
                      key={getKeyForPatient(patient)}
                      className="srt-accrodin-details"
                    >
                      <Typography>
                        <SalesRoundingPatientSummary patientData={patient} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              )
            )
          ) : (
            <div
              className="srt-sales-rounding-no-patients"
              data-testid="srt-sales-rounding-no-patients-test"
            >
              No patients to display
            </div>
          )}
        </>
      )}
      <Popup
        closeHandler={() => setShowPopUpLoader(false)}
        dialogParentClass={"srt-dashboard-loader-pop-up"}
        data-testid="srt-dashboard-pop-up"
        hideCloseButton={true}
        openFlag={showPopUpLoader}
      >
        <div
          className="srt-dashboard-loader"
          data-testid="srt-dashboard-loader"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <Popup
        openFlag={downloadPopUp}
        closeHandler={() => setDownLoadPopUp(false)}
        dialogParentClass={"sales-rounding-download-success-pop-up"}
        data-testid="sales-rounding-download-success-pop-up"
      >
        <div className="download-success">
          <p className="download-title" id="download-title">
            Download patient list?
          </p>
          <Grid className="download-success-buttons-grid-item">
            <Typography
              className="download-pop-up-content"
              data-testid="download-pop-up-content"
            >
              {makeCapitalEachWordInString(facilityName)} patient list will be
              saved to your device.
            </Typography>
            <div className="download-success-buttons-div">
              <Button
                className="download-success-cancel-btn"
                data-testid="download-success-cancel-btn"
                onClick={handleDownloadClose}
              >
                Cancel
              </Button>
              <Button
                className="download-success-download-btn"
                data-testid="download-success-download-btn"
                onClick={handleDownloadBtnCSV}
                variant="outlined"
              >
                Yes, Download
              </Button>
            </div>
          </Grid>
        </div>
      </Popup>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => {
          closeErrorPopUpButtonAction();
        }}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
    </div>
  );
};

export default SalesRoundingDashboard;
