import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CMS_VAC_THERAPY_INFORMATION_CONTENT,
  DD_INPATIENT_STOCK_SUPPLY_TYPE,
  IN_PATIENT_SUPPLY_ORDER_DRESSING_GUIDE_LINK,
  CMS_DRESSING_SIZE_PDF,
} from "../../../util/staticText";
import { format } from "react-string-format";
import {
  IDropDownContent,
  IInpatientSupplyOrder,
  IInPatientSupplyOrderProduct,
  IInPatientSupplyOrderRequest,
} from "../inpatientSupplyOrder.interface";
import { useDebounce } from "use-debounce";
import "./inPatientSupplyOrderFirstPage.css";
import SearchIcon from "@mui/icons-material/Search";
import { Cart } from "../component/cart/cart.component";
import { Popup } from "../../../core/popup/popup.component";
import { useCart } from "../../../core/customHooks/useCart";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ERROR_MSG_CHECK_PRODUCT_PRICE_AND_AVAILABILITY,
  ERROR_MSG_GET_INPATIENT_SUPPLY_PRODUCTS,
  ERROR_MSG_POPUP_BLOCKER,
  ERROR_MSG_PRODUCT_OUT_OF_STOCK,
} from "../../../util/errorMsg";
import {
  OPEN_PDF_POP_UP_BLOCKED,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
} from "../../../util/errorCode";
import {
  checkPopUpBlocker,
  getCodeFromText,
  getSiteUseId,
  getTextFromCode,
  parsedTextValue,
  updateSuperScriptContent,
} from "../../../util/utilityFunctions";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import ProductName from "../component/productName/productName.component";
import { CustomDropDown } from "../../../core/customDropdown/customDropdown.component";
import { MemoedAgGridTable } from "../component/memoedAgGridTable/memoedAgGridTable.component";
import SupplyOrderAddButton from "../component/supplyOrderAddButton/supplyOrderAddButton.component";
import { PatientAndProductDetail } from "../../inpatientOrder/components/patientAndProductDetail/patientAndProductDetail.component";
import {
  InpatientSupplyOrderContext,
  InpatientSupplyOrderContextType,
} from "../../../context/InpatientSupplyOrderContext";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import {
  getCustomerInformation,
  getProductPriceAndAvailability,
} from "../../../util/inPatientOrderService";
import { AuthContextType, AuthContext } from "../../../context/AuthContext";
import { ProductSizeInformation } from "../../newOrder/dressingSupplies/vacDressingKit/vacDressingKit.interface";
import {
  defaultProductUnavailableErrorPopUp,
  IProductNotAvailableError,
  ProductNotAvailablePopUp,
} from "../component/popup/productNotAvailablePopUp/productNotAvailablePopUp.component";
import { ReactComponent as ShipToIcon } from "../../../assets/shipToIcon.svg";
import { ReactComponent as FilterIcon } from "../../../assets/filterIcon.svg";
import KeyboardControlKeyOutlinedIcon from "@mui/icons-material/KeyboardControlKeyOutlined";
import { getdropDownContent } from "../../../util/dropDownService";
import { getOrderSuppliesByFacility } from "../../../util/actueService";
import { getCMSContent } from "../../../util/cmsService";
import { useHistory } from "react-router-dom";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import {
  IPatientDetail,
  IProductDetail,
} from "../../inpatientOrder/components/patientAndProductDetail/patientAndProductDetail.interface";
import { IFilterValues } from "../container/inpatientSupplyOrderContainer.component";
import { getStaticContent } from "../../../util/staticContentService";

interface IInpatientSupplyOrderFirstPageProps {
  isFromStockSupplies: boolean;
  openCart: boolean;
  setOpenCart: Function;
  setShowLoader: Function;
  showLoader: boolean;
  patient: IPatientDetail;
  product: IProductDetail | null;
  filterValues: IFilterValues;
  setFilterValues: Function;
  setIsSuppliesDataAvailable?: Function;
  isFromInPatientOrderPage?: boolean;
}

export const InPatientSupplyOrderFirstPage = ({
  openCart,
  isFromStockSupplies = false,
  setOpenCart,
  patient,
  product,
  filterValues,
  setFilterValues,
  setIsSuppliesDataAvailable,
  isFromInPatientOrderPage = false,
}: IInpatientSupplyOrderFirstPageProps) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const inpatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );

  const data = inpatientSupplyOrderObj!.data;
  const setData = inpatientSupplyOrderObj!.setData;

  const [debouncedText] = useDebounce(filterValues.searchInput, 500);

  const history = useHistory();

  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [showPopUpLoader, setShowPopUpLoader] = useState<boolean>(false);
  const [productUnavailableError, setProductUnavailableError] =
    useState<IProductNotAvailableError>(defaultProductUnavailableErrorPopUp);

  const [isPopUpBlocked, setIsPopUpBlocked] = useState<boolean>(false);
  const [dressingSizeDialog, setDressingSizeDialog] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const supplyDropDownList = inpatientSupplyOrderObj!.supplyDropDownList;
  const setSupplyDropDownList = inpatientSupplyOrderObj!.setSupplyDropDownList;
  const productFamilyTypes = inpatientSupplyOrderObj!.productFamilyTypes;
  const setProductFamilyTypes = inpatientSupplyOrderObj!.setProductFamilyTypes;
  const supplies = inpatientSupplyOrderObj!.inPatientSuppliesData ?? [];
  const setSupplies = inpatientSupplyOrderObj!.setInPatientSuppliesData;
  const productFamilyArray = inpatientSupplyOrderObj!.productFamilyArray;
  const setProductFamilyArray = inpatientSupplyOrderObj!.setProductFamilyArray;
  const [showErrorCode, setShowErrorCode] = useState<boolean>(true);
  const inPatientSuppliesProductDescription =
    (data?.patientAndProduct?.product?.name ?? "").replace(
      /<sup>.*?<\/sup>/g,
      "®"
    ) ?? "";
  const productDescription = isFromInPatientOrderPage
    ? product?.name ?? ""
    : inPatientSuppliesProductDescription ?? "";

  // Cart Functionality
  const { cartState, dispatch } = useCart();
  const cartItemsRef = useRef(cartState.cartItems);
  const [isAddedProduct, setIsAddedProduct] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null); // Reference to the button (anchor element)
  const arrowRef = useRef<HTMLDivElement | null>(null); // Reference to the arrow element

  const isSmallMobile = useMediaQuery("(max-width: 600px)");
  const isMobileScreen = useMediaQuery("(max-width: 926px)");
  const isIpadScreen = useMediaQuery("(max-width: 1100px)");

  // Service call methods
  const fetchSupplyDropDownContent = async () => {
    const ddContent = format("{0}", DD_INPATIENT_STOCK_SUPPLY_TYPE);
    const response = await getdropDownContent(ddContent);
    if (response.items.length > 0) {
      const supplyObject = response.items.filter(
        (item: { name: string }) => item.name === DD_INPATIENT_STOCK_SUPPLY_TYPE
      );
      const supplyData: IDropDownContent[] = supplyObject[0].data.sort(
        (a: { order: number }, b: { order: number }) =>
          a.order > b.order ? 1 : -1
      );
      return supplyData;
    }
    return null;
  };
  const updateSuppliesListPopup = (
    isFromInPatientOrderPage: boolean,
    orderSupplies: IInPatientSupplyOrderProduct[]
  ) => {
    const isPresent = isFromInPatientOrderPage
      ? orderSupplies && orderSupplies.length > 0
      : false;
    setIsSuppliesDataAvailable!(isPresent);
  };

  const getCustomerInformationAPI = async (siteUseId: string) => {
    const params = {
      siteUseId: siteUseId,
    };
    const response = await getCustomerInformation(params);
    if (response && response.succeeded) {
      return response.item;
    }
    return null;
  };

  const getOrderSuppliesAPI = async (
    products: string[] = []
  ): Promise<
    | [
        string,
        IDropDownContent[],
        string,
        number,
        IInPatientSupplyOrderProduct[]
      ]
    | null
  > => {
    const request: IInPatientSupplyOrderRequest = {
      description: productDescription!,
      products: products,
      siteUseId: getSiteUseId(authObj),
    };
    const response = await getOrderSuppliesByFacility(request);
    if (response && response.succeeded && response.item) {
      const dropdownValues = response.item.dropDownList;
      const billToSiteId = response.item.billToSiteID;
      const shipToSiteId = response.item.shipToSiteID;
      const customerNumber = response.item.mstCustomerNumber;
      const productFamilies: IDropDownContent[] = dropdownValues.sort(
        (a: { order: number }, b: { order: number }) =>
          a.order > b.order ? 1 : -1
      );

      let supplies: IInPatientSupplyOrderProduct[] = [];
      if (
        response.item.orderSupplies &&
        response.item.orderSupplies.length > 0
      ) {
        supplies = response.item.orderSupplies;
      }
      if (isFromInPatientOrderPage) {
        updateSuppliesListPopup(
          isFromInPatientOrderPage,
          response.item.orderSupplies
        );
      }
      return [
        billToSiteId,
        productFamilies,
        shipToSiteId,
        customerNumber,
        supplies,
      ];
    } else {
      const errorObj = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorFlag: true,
        errorMessage: ERROR_MSG_GET_INPATIENT_SUPPLY_PRODUCTS,
      };
      setShowErrorCode(response?.error?.errorCode ? true : false);
      setError(errorObj);
      return null;
    }
  };

  const fetchSizeToGuideImage = async () => {
    const response = await getCMSContent(CMS_VAC_THERAPY_INFORMATION_CONTENT);
    if (response && response.succeeded && response.item) {
      return response.item;
    }
    return null;
  };

  // Local Methods
  const getSuppliesProductWithDropdown = async () => {
    const siteUseId = getSiteUseId(authObj);
    if (authObj && authObj.registeredFaciltyAddress && siteUseId !== "") {
      const products = isFromStockSupplies ? [] : [product?.productCode ?? ""];
      setShowPopUpLoader(true);
      const result = await Promise.all([
        fetchSupplyDropDownContent(),
        getOrderSuppliesAPI(products),
        fetchSizeToGuideImage(),
        getCustomerInformationAPI(siteUseId),
      ]);
      setShowPopUpLoader(false);
      if (result.length === 4) {
        const dropdownData = result[0];
        const orderSuppliesResult = result[1];
        const sizeToGuide = result[2];
        const customerInfo = result[3];
        let tempData: IInpatientSupplyOrder = data;
        if (dropdownData) {
          setSupplyDropDownList(dropdownData);
        }
        if (orderSuppliesResult) {
          tempData = updateSuppliesDetails(orderSuppliesResult, tempData, true);
        }
        if (sizeToGuide) {
          tempData = {
            ...tempData,
            sizeToGuide: sizeToGuide.sizeGuideImage?.FileLink ?? "",
          };
        }
        if (isFromStockSupplies) {
          tempData = {
            ...tempData,
            patientAndProduct: {},
            salesPurchaseOrderInformation: {
              ...tempData.salesPurchaseOrderInformation,
              isPoRequired: false,
              salesPONumber: {
                ...tempData.salesPurchaseOrderInformation.salesPONumber,
                isDefaultValid: true,
                valid: ValidationStatus.VALID,
                value: "",
              },
            },
          };
        }
        if (customerInfo) {
          inpatientSupplyOrderObj!.setCustomerInformation({
            billToSiteUseId: customerInfo.billToSiteId,
            customerNumber: customerInfo.customerNumber,
            creditStatus: customerInfo.creditstatus,
          });
          tempData = {
            ...tempData,
            salesPurchaseOrderInformation: {
              ...tempData.salesPurchaseOrderInformation,
              salesPONumber: {
                ...tempData.salesPurchaseOrderInformation?.salesPONumber,
                required: customerInfo.isSalesPORequired,
                valid:
                  customerInfo.isSalesPORequired &&
                  (customerInfo.salPOValue === null ||
                    customerInfo.salPOValue === "")
                    ? ValidationStatus.UNTOUCHED
                    : ValidationStatus.VALID,
                value:
                  customerInfo.salPOValue === null
                    ? ""
                    : customerInfo.salPOValue,
              },
              isPoRequired:
                customerInfo.rentalPOValue === "NOT REQUIRED" ? false : true,
            },
          };
        } else {
          tempData = {
            ...tempData,
            salesPurchaseOrderInformation: {
              ...tempData.salesPurchaseOrderInformation,
              salesPONumber: {
                ...tempData.salesPurchaseOrderInformation?.salesPONumber,
                required: true,
                valid: ValidationStatus.VALID,
                value: "",
              },
              isPoRequired: null,
            },
          };
        }
        setData(tempData);
      }
    } else {
      const errorObj = {
        errorCode: SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
        errorFlag: true,
        errorMessage: ERROR_MSG_GET_INPATIENT_SUPPLY_PRODUCTS,
      };
      setError(errorObj);
      // TODO:
      // Navigate back to previous screen
      history.goBack();
    }
  };

  const updateSuppliesDetails = (
    orderSuppliesResult: [
      string,
      IDropDownContent[],
      string,
      number,
      IInPatientSupplyOrderProduct[]
    ],
    tempData: IInpatientSupplyOrder = data,
    isInitalLoad: boolean = false
  ) => {
    const [
      billToSiteId,
      productFamilies,
      shipToSiteId,
      customerNumber,
      products,
    ] = orderSuppliesResult;
    if (productFamilies && isInitalLoad) {
      setProductFamilyTypes(productFamilies);
      let tempArr = productFamilies
        ? productFamilies.map((productDropDownItem: IDropDownContent) =>
            productDropDownItem && productDropDownItem.text
              ? updateSuperScriptContent(productDropDownItem.text, true)
              : productDropDownItem.code
          )
        : [];
      setProductFamilyArray(tempArr);
      setFilterValues({
        ...filterValues,
        searchProductType: productFamilies[0].code,
      });
    }
    setSupplies(products ?? []);
    tempData = {
      ...tempData,
      billToSiteId: billToSiteId,
      shipToSiteId: shipToSiteId,
      customerNumber: customerNumber,
    };
    return tempData;
  };

  // AgGrid Table Column Defination
  const columnDefs: any[] = [
    {
      headerName: "",
      field: "",
      minWidth: isMobileScreen ? 40 : 50,
      width: isMobileScreen ? 60 : 85,
      sortable: false,
      suppressMovable: true,
      wrapText: true,
      cellRendererSelector: (params: any) => {
        return {
          component: SupplyOrderAddButton,
          params: {
            productData: params.data,
            handleAddProduct: addProduct,
          },
        };
      },
    },
    {
      headerName: "",
      field: "",
      minWidth: 100,
      width: 120,
      sortable: false,
      suppressMovable: true,
      hide: isSmallMobile,
      cellRenderer: (params: any) => (
        <img
          alt={params.data.productName.split(" ")[0]}
          className="inpatient-supply-order-product-image"
          src={params.data.image}
        />
      ),
    },
    {
      headerName: "Product",
      field: "productName",
      minWidth: isMobileScreen ? (isSmallMobile ? 90 : 170) : 150,
      width: isMobileScreen ? (isSmallMobile ? 170 : 230) : 350,
      sortable: true,
      suppressMovable: true,
      wrapText: true,
      flex: isSmallMobile ? 1 : 0,
      cellClass: "inpatient-supply-order-product-column",
      cellRendererSelector: (params: any) => {
        return {
          component: ProductName,
          params: {
            productData: params.data,
            isSmallMobile: isSmallMobile,
          },
        };
      },
    },
    {
      headerName: "Part #",
      field: "sku",
      minWidth: isMobileScreen ? 70 : 120,
      width: isMobileScreen ? 110 : 130,
      sortable: true,
      suppressMovable: true,
      hide: isSmallMobile,
    },
    {
      headerName: "Units per case",
      field: "packageType",
      minWidth: isMobileScreen ? 70 : 120,
      width: isMobileScreen ? 110 : 130,
      sortable: true,
      suppressMovable: true,
      hide: isSmallMobile,
      cellRenderer: (params: any) => <>{params.data.packageType ?? "--"}</>,
    },
    {
      headerName: "Descriptor",
      field: "size",
      minWidth: isMobileScreen ? 70 : 120,
      width: isMobileScreen ? 110 : 130,
      flex: 1,
      sortable: true,
      suppressMovable: true,
      hide: isSmallMobile,
      cellRenderer: (params: any) => (
        <>
          {params.data.size && params.data.size !== ""
            ? params.data.size
            : "--"}
        </>
      ),
    },
  ];

  // Cart Functioanlity
  const updateItemQuantity = (sku: string, newQuantity: number) => {
    // Ensure the new quantity is a valid number and greater than or equal to 0
    if (newQuantity >= 0) {
      dispatch({ type: "UPDATE_QUANTITY", sku, quantity: newQuantity });
    }
  };

  const deleteCartItem = (sku: string) => {
    dispatch({ type: "DELETE", sku });
  };

  // Button Actions
  const handleDressingGuide = async () => {
    setShowPopUpLoader(true);
    const dressingSizePDFUrl = await fetchDressingSizeGuidePDFUrl();
    setShowPopUpLoader(false);
    const windowObject = window.open(dressingSizePDFUrl, "_blank");
    const result = checkPopUpBlocker(windowObject);
    setIsPopUpBlocked(result);
  };

  const handleDressingSize = () => {
    setDressingSizeDialog(true);
  };

  const handleClick = () => {
    setOpenCart((prevOpen: any) => !prevOpen);
  };

  const handleClose = () => {
    if (!isAddedProduct) setOpenCart(false);
    setIsAddedProduct(false);
  };

  const handleOnClickFilterIcon = () => {
    setShowFilters((prev) => !prev);
  };

  const handleCloseErrorPopUp = () => {
    let updatedError: IErrorPopUp = {
      ...error,
      errorFlag: false,
    };
    setShowErrorCode(true);
    setError(updatedError);
  };

  const validateAndSetSearchData = async (e: any) => {
    const { name, value } = e.target;
    let tempFilterData = { ...filterValues };
    if (name === "searchInput") {
      const re = /^[a-zA-Z0-9-/ ]+$/;
      if (value === "" || re.test(value)) {
        tempFilterData.searchInput = value;
      }
    } else if (name === "searchProductType") {
      if (isFromStockSupplies) {
        const code = getCodeFromText(productFamilyTypes, value);
        tempFilterData.searchProductType = code;
        const codes = code.split(",");
        const products = codes.length > 1 ? codes : code !== "" ? [code] : [];
        setShowPopUpLoader(true);
        const orderSuppliesResult = await getOrderSuppliesAPI(products);
        setShowPopUpLoader(false);
        if (orderSuppliesResult) {
          const tempData = updateSuppliesDetails(orderSuppliesResult);
          setData(tempData);
        }
      }
    } else {
      tempFilterData.searchSupplyType = getCodeFromText(
        supplyDropDownList,
        value
      );
    }
    setFilterValues(tempFilterData);
  };

  const addProduct = useCallback(
    async (product: IInPatientSupplyOrderProduct) => {
      const productExists = cartItemsRef.current.some(
        (item) => item.sku === product.sku
      );
      if (productExists) return;
      setIsAddedProduct(true);
      const reqParams = {
        billToSiteId: data.billToSiteId,
        inventoryId: product.inventoryID,
        shipToSiteID: data.shipToSiteId,
        supplyPartNo: product.sku,
        shipToState: authObj!.registeredFaciltyAddress?.state,
        shipToZipCode: authObj!.registeredFaciltyAddress?.zip,
      };
      setShowPopUpLoader(true);
      const response = await getProductPriceAndAvailability(reqParams);
      setShowPopUpLoader(false);
      if (response && response.succeeded) {
        if (response.item.isProductAvailable) {
          dispatch({
            type: "ADD_PRODUCT",
            product: {
              ...product,
              maxAvailableQuantity: response.item.earliestATPDateQty,
              totalPrice:
                Number(product.size) * Number(response.item.discountPrice),
              price: Number(response.item.discountPrice),
              quantity: 1,
              wareHouseID: response.item.wareHouseId,
              estimatedArrivalDate: "",
              requestATPDateQty: response.item.requestATPDateQty,
              primaryPriceListId: response.item.primaryPriceListId,
              secondaryPriceListId: response.item.secondaryPriceListId,
            },
          });
          if (!openCart) {
            setOpenCart(true);
          }
        } else {
          let productNotAvailableError: IProductNotAvailableError = {
            errorFlag: true,
            errorMessage: ERROR_MSG_PRODUCT_OUT_OF_STOCK,
            productName: product.productName,
          };
          setProductUnavailableError(productNotAvailableError);
        }
      } else {
        let error: IErrorPopUp = {
          errorCode:
            response?.error?.code ||
            response?.error?.errorCode ||
            response?.status,
          errorMessage: ERROR_MSG_CHECK_PRODUCT_PRICE_AND_AVAILABILITY,
          errorFlag: true,
        };
        setError(error);
      }
    },
    [cartItemsRef, data.billToSiteId, data.shipToSiteId, openCart]
  );
  const memoedData = useMemo(() => {
    const filterProductsList = supplies.filter((product) => {
      const matchesSearchInput =
        product.productName
          .toLowerCase()
          .includes(debouncedText.toLowerCase()) ||
        product.sku.toLowerCase().includes(debouncedText.toLowerCase());

      const matchesSupplyType =
        filterValues.searchSupplyType === "" ||
        product.supplyType === filterValues.searchSupplyType;
      return matchesSearchInput && matchesSupplyType;
    });
    const sortedProductsList = filterProductsList.sort((a, b) =>
      a.sku.localeCompare(b.sku)
    );
    return {
      filterProductsList: sortedProductsList,
      columnDefs,
    };
  }, [
    debouncedText,
    filterValues.searchProductType,
    filterValues.searchSupplyType,
    supplies.length > 0,
  ]);

  const fetchDressingSizeGuidePDFUrl = async () => {
    const staticPDFUrl = format("{0}", CMS_DRESSING_SIZE_PDF);
    const data = await getStaticContent(staticPDFUrl);
    if (data && data.succeeded) {
      const pdfUrls = data.items.filter(
        (item: { key: string }) => item.key === CMS_DRESSING_SIZE_PDF
      );
      if (pdfUrls.length > 0) {
        const dressingPdfUrl = pdfUrls[0];
        return dressingPdfUrl.value;
      }
    }
  };

  useEffect(() => {
    if (inpatientSupplyOrderObj!.inPatientSuppliesData === null) {
      getSuppliesProductWithDropdown();
    }
  }, []);

  useEffect(() => {
    cartItemsRef.current = cartState.cartItems;
  }, [cartState.cartItems, authObj]);

  return (
    <div
      className="in-patient-supply-order-first-page-component"
      id="id_in-patient-supply-order-first-page"
      data-testid="in-patient-supply-order-first-page"
    >
      <div className="short-form">
        {!isFromStockSupplies && (
          <div
            className="in-patient-supply-order-first-page-product-info-section"
            data-testid="in-patient-supply-order-first-page-product-info-section"
            id="in-patient-supply-order-first-page-product-info-section"
          >
            <PatientAndProductDetail patient={patient} product={product} />
          </div>
        )}
        <div
          className="in-patient-supply-order-first-page-product-name-desc"
          data-testid="in-patient-supply-order-first-page-product-name-desc"
          id="in-patient-supply-order-first-page-product-name-desc"
        >
          <Typography className="in-patient-supply-order-title">
            Select Supplies
            {isFromStockSupplies ? "" : " for "}
            {productDescription}
          </Typography>
        </div>
        <div className="in-patient-supply-order-link-main">
          <div>
            <span
              className="in-patient-supply-order-link-button"
              onClick={handleDressingGuide}
              data-testid="in-patient-supply-order-dressing-Guide-link"
            >
              Dressing Guide
            </span>
            <span
              className="in-patient-supply-order-link-button"
              onClick={handleDressingSize}
              data-testid="in-patient-supply-order-dressing-size-link"
            >
              Size Guide
            </span>
          </div>
          <Button
            classes={{
              root: "in-patient-supply-order-button",
            }}
            data-testid="in-patient-supply-order-button"
            onClick={handleClick}
            ref={anchorRef}
            startIcon={<ShipToIcon />}
            variant="outlined"
          >
            {`Supply Order (${cartState.totalQuantity})`}
          </Button>
          {isSmallMobile ? (
            <Popup
              closeHandler={() => setOpenCart(false)}
              dialogParentClass={"in-patient-supply-order-container-popper"}
              hideCloseButton={true}
              openFlag={openCart}
            >
              <div className="in-patient-supply-order-container-popper-div">
                <Cart
                  cartState={cartState}
                  deleteCartItem={deleteCartItem}
                  handleClose={() => setOpenCart(false)}
                  updateItemQuantity={updateItemQuantity}
                />
              </div>
            </Popup>
          ) : (
            <Popper
              open={openCart}
              anchorEl={anchorRef.current}
              placement="bottom-end"
              disablePortal={false}
              modifiers={[
                {
                  name: "flip",
                  enabled: true,
                  options: {
                    altBoundary: true,
                    rootBoundary: "document",
                    padding: 8,
                  },
                },
                {
                  name: "preventOverflow",
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: "document",
                    padding: 8,
                  },
                },
              ]}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box className="popper-arrow-container">
                  <Box className="popper-arrow-background-box" />
                  <Box className="popper-arrow-box" ref={arrowRef} />
                  <Box className="popper-custom-component">
                    <Cart
                      cartState={cartState}
                      deleteCartItem={deleteCartItem}
                      handleClose={() => setOpenCart(false)}
                      updateItemQuantity={updateItemQuantity}
                    />
                  </Box>
                </Box>
              </ClickAwayListener>
            </Popper>
          )}
        </div>
        <Grid container className="in-patient-supply-first-searchbar">
          <Grid item xs={isSmallMobile ? 9.7 : isIpadScreen ? 8.0 : 5.9}>
            <TextField
              autoComplete="off"
              autoFocus={false}
              data-testid="filter-patients-supply-order"
              className="filter-so-list"
              inputProps={{ style: { fontSize: 14 } }}
              placeholder="Filter by item name or part number"
              InputProps={{
                startAdornment: <SearchIcon style={{ marginRight: "5px" }} />,
              }}
              value={filterValues.searchInput}
              onChange={validateAndSetSearchData}
              variant="outlined"
              name="searchInput"
            />
          </Grid>
          {isMobileScreen && (
            <Grid item xs={1.9}>
              <IconButton
                data-testid="my-patient-filter-icon"
                onClick={() => handleOnClickFilterIcon()}
              >
                {showFilters ? (
                  <KeyboardControlKeyOutlinedIcon
                    sx={{ height: "32px", width: "32px" }}
                  />
                ) : (
                  <FilterIcon />
                )}
              </IconButton>
            </Grid>
          )}
          {(!isMobileScreen || (isMobileScreen && showFilters)) && (
            <>
              <Grid
                item
                xs={isSmallMobile ? 5.5 : isIpadScreen ? 3.9 : 2.9}
                className="inpatient-supply-order-dropdown"
              >
                <CustomDropDown
                  handleChange={validateAndSetSearchData}
                  menuItem={productFamilyArray}
                  name="searchProductType"
                  placeHolder="All products"
                  selectpropsClassName={
                    filterValues.searchProductType !== ""
                      ? "status-select"
                      : "placeHolder"
                  }
                  selectClassName={
                    filterValues.searchProductType !== ""
                      ? "status-input"
                      : "placeHolder"
                  }
                  testId="in-patient-supply-order-status-types"
                  value={parsedTextValue(
                    getTextFromCode(
                      productFamilyTypes,
                      filterValues.searchProductType
                    )
                  )}
                />
              </Grid>
              <Grid
                item
                xs={isSmallMobile ? 5.7 : isIpadScreen ? 3.9 : 2.9}
                className="inpatient-supply-order-dropdown"
              >
                <CustomDropDown
                  handleChange={validateAndSetSearchData}
                  menuItem={supplyDropDownList.map(
                    (item: IDropDownContent) => item.text
                  )}
                  name="searchSupplyType"
                  placeHolder="All supply types"
                  selectpropsClassName={
                    filterValues.searchSupplyType !== ""
                      ? "status-select"
                      : "placeHolder"
                  }
                  selectClassName={
                    filterValues.searchSupplyType !== ""
                      ? "status-input"
                      : "placeHolder"
                  }
                  testId="in-patient-supply-order-status-types"
                  value={getTextFromCode(
                    supplyDropDownList,
                    filterValues.searchSupplyType
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
        <div
          className="ag-theme-quartz"
          data-testid="inpatient-supply-order-products-ag-grid"
          style={{
            width: "100%",
            height: memoedData.filterProductsList.length === 0 ? 400 : "auto",
          }}
        >
          <MemoedAgGridTable
            filterProductsList={memoedData.filterProductsList}
            columnDefs={memoedData.columnDefs}
          />
        </div>
      </div>
      <Popup
        closeHandler={() => setShowPopUpLoader(false)}
        dialogParentClass="in-patient-supply-order-first-page-popup"
        hideCloseButton={true}
        openFlag={showPopUpLoader}
      >
        <div
          className="in-patient-supply-order-first-page-popup-div"
          id="in-patient-supply-first-page-spinner"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <Popup
        closeHandler={() =>
          setProductUnavailableError(defaultProductUnavailableErrorPopUp)
        }
        dialogParentClass="in-patient-supply-order-product-not-available-popup"
        hideCloseButton={false}
        openFlag={productUnavailableError.errorFlag}
      >
        <div className="in-patient-supply-order-product-not-available-popup-div">
          <ProductNotAvailablePopUp
            description={productUnavailableError.errorMessage}
            productName={productUnavailableError.productName}
            title="Product is out of stock"
          />
        </div>
      </Popup>
      <Popup
        dialogParentClass="dressingsize-dialog"
        closeHandler={() => setDressingSizeDialog(false)}
        openFlag={dressingSizeDialog}
      >
        <div className="size-maincontainer" data-testid="sizeTest">
          <div className="dressingsizebody-dailog">
            <img
              alt={data.sizeToGuide ?? ""}
              className="dressingImg"
              src={data.sizeToGuide ?? ""}
            />
          </div>
        </div>
      </Popup>
      <ErrorPopup
        buttontitle="Done"
        errorCode={error.errorCode}
        errorMessage={error.errorMessage}
        errorPopupFlag={error.errorFlag}
        errorTitle="Error"
        isErrorCodeVisible={showErrorCode}
        isShortSentence={true}
        handleBackButton={handleCloseErrorPopUp}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
      <ErrorPopup
        errorPopupFlag={isPopUpBlocked}
        popUpStyles="errorPopup"
        showTitle={false}
        handleBackButton={() => setIsPopUpBlocked(false)}
        isSupportPhoneRequired={false}
        errorMessage={ERROR_MSG_POPUP_BLOCKER}
        errorCode={OPEN_PDF_POP_UP_BLOCKED}
      />
    </div>
  );
};
