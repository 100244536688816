import SalesAssistanceRequestor from "./components/salesAssistanceRequestor/salesAssistanceRequestor.component";
import HelpNeeded from "./components/helpNeeded/helpNeeded.component";
import { useContext, useEffect, useState } from "react";
import "./salesAssistanceNeedHelp.css";
import {
  MyPatientContext,
  MyPatientContextType,
} from "../../../context/MyPatientContext";
import { format } from "react-string-format";
import {
  CMS_VAC_THERAPY_INFORMATION_CONTENT,
  DD_AFFECTED_FEATURES_TYPES,
  DD_UPLOAD_DOCUMENT_TYPE,
} from "../../../util/staticText";
import { getdropDownContent } from "../../../util/dropDownService";
import { SalesAssistanceNeedHelpValidator } from "./salesAssistanceNeedhelp.validator";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { FooterButtonGroup } from "../../send3MNote/footerButtonGroup/footerButtonGroup.component";
import SalesAssistanceUploadDocuments from "./components/salesAssistanceUploadDocuments/salesAssistanceUploadDocuments.component";
import { Popup } from "../../../core/popup/popup.component";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import SendMessageSuccessPopup from "./components/sendMessageSuccessPopup/sendMessageSuccessPopup.component";
import { mapSalesAssistanceRequest } from "./components/mapper/salesAssistanceRequestor.mapper";
import { getCMSContent } from "../../../util/cmsService";
import { IVacTherapyInformation } from "../../newOrder/woundBed/vacTherapyInformation.interface";
import { salesAssistanceSendMessage } from "../../../util/3meService";
import { ERROR_MSG_TO_SUBMIT_SEND_MESSAGE } from "../../../util/errorMsg";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { SalesAssistanceAdditionalInformation } from "./components/additionalInformation/salesAssistanceAdditionalInfo.component";
import { IDropZoneDocumentSelect } from "../../../core/customDropZone/dropZoneDocumentSelect.interface";

export const SalesAssistanceNeedHelp = ({}) => {
  const history = useHistory();
  const [affectedFeaturesText, setAffectedFeaturesText] = useState([]);
  const myPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const data = myPatientObj!.needHelpData;
  const setData = myPatientObj!.setNeedHelpData;
  const uploadDocumentData = myPatientObj!.salesAssistanceUploadDocuments;
  const setUploadDocumentData = myPatientObj!.setSalesAssistanceUploadDocuments;
  const validator = new SalesAssistanceNeedHelpValidator();
  const [documentTypeText, setDocumentTypeText] = useState<string[]>([]);
  const [errorInfo, setErrorInfo] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [messageSubmittedSuccessFlag, setMessageSubmittedSuccessFlag] =
    useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_AFFECTED_FEATURES_TYPES,
        DD_UPLOAD_DOCUMENT_TYPE ?? ""
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const affectedFeaturesObj = data.items.filter(
          (item: { name: string }) => item.name === DD_AFFECTED_FEATURES_TYPES
        );
        const affectedFeaturesData = affectedFeaturesObj[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        const uploadDocumentmentObject = data.items.filter(
          (item: { name: string }) => item.name === DD_UPLOAD_DOCUMENT_TYPE
        );
        const uploadDocumentData = uploadDocumentmentObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setDocumentTypeText(
          uploadDocumentData.map((x: { text: string }) => x.text)
        );
        setAffectedFeaturesText(
          affectedFeaturesData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const checkErroredFieldAndFocusOnInputField = async (
    errorField: IInputField
  ) => {
    if (errorField.componentId && errorField.componentId !== "") {
      const scrollableComponent = document.getElementById(
        errorField.componentId
      );
      setTimeout(() => {
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
          if (errorField.id && errorField.id !== "") {
            const autoCursorComponentId = document.getElementById(
              errorField.id
            );
            setTimeout(() => {
              if (autoCursorComponentId) {
                autoCursorComponentId.focus();
              }
            }, 500);
          }
        }
      }, 500);
    }
  };

  const handleSendMessageSubmit = async () => {
    let [isValid, smallestOrderWithInvalidStatus] = validator.validateAll(
      data,
      setData,
      uploadDocumentData,
      setUploadDocumentData
    );
    if (isValid === ValidationStatus.VALID) {
      if (authObj && authObj.userProfile && authObj.registeredFaciltyAddress) {
        const requestParams = await mapSalesAssistanceRequest(
          authObj,
          data,
          uploadDocumentData
        );
        setShowLoader(true);
        const response = await salesAssistanceSendMessage(requestParams);
        setShowLoader(false);
        if (response && response.succeeded) {
          setMessageSubmittedSuccessFlag(true);
        } else {
          const errorCodes = [1017, 1083, 1018, 1043, 1055, 1076];
          let responseDocuments = response.data;
          let updatedDocs: IDropZoneDocumentSelect[] = uploadDocumentData.map(
            (a, index) => ({
              ...a,
              errorMessage: responseDocuments[index]?.message || "",
              succeeded: !errorCodes.includes(responseDocuments[index]?.code),
            })
          );
          setUploadDocumentData(updatedDocs);
          let errorObj: IErrorPopUp = {
            errorCode:
              response?.error?.errorCode ??
              response?.error.code ??
              response?.status,
            errorFlag: true,
            errorMessage: ERROR_MSG_TO_SUBMIT_SEND_MESSAGE,
          };
          setErrorInfo(errorObj);
        }
      }
    } else {
      if (smallestOrderWithInvalidStatus) {
        checkErroredFieldAndFocusOnInputField(smallestOrderWithInvalidStatus);
      }
    }
  };

  useEffect(() => {
    if (!authObj?.registeredFaciltyAddress && !authObj?.userProfile) {
      history.push("/home");
    }
  }, [authObj?.registeredFaciltyAddress, authObj?.userProfile]);

  const spinner = () => {
    return (
      <div className="spinner-popup">
        <LoadingSpinner />
      </div>
    );
  };

  useEffect(() => {
    fetchDropDownContent();
  }, []);

  return (
    <div
      className="need-help-sales-assistance-main-div"
      id="need-help-sales-assistance-component"
      data-testid="need-help-sales-assistance-component"
    >
      <div
        className="need-help-sales-assistance-container"
        id="need-help-sales-assistance-container"
      >
        <Navigator
          array={[
            {
              route: "/home",
              pageName: "My Patients",
            },
          ]}
          className="sales-assistance-component-route-section"
          title="Assistance"
        />
        <div className="need-help-sales-assistance-form">
          <SalesAssistanceRequestor />
          <HelpNeeded
            data={data!}
            setData={setData!}
            affectedFeaturesText={affectedFeaturesText}
          />
          <SalesAssistanceAdditionalInformation data={data} setData={setData} />
          <SalesAssistanceUploadDocuments
            data={data}
            setData={setData}
            uploadDocuments={uploadDocumentData}
            setUploadDocuments={setUploadDocumentData}
            documentTypeText={documentTypeText}
          />
        </div>
        <FooterButtonGroup
          firstButtonTitle={"Cancel"}
          firstButtonAction={() => history.goBack()}
          secondButtonTitle={"Send Message"}
          secondButtonAction={handleSendMessageSubmit}
        />
      </div>
      <Popup
        openFlag={messageSubmittedSuccessFlag}
        closeHandler={() => setMessageSubmittedSuccessFlag(false)}
        dialogParentClass="salesAssistanceSuccessPopup"
        hideCloseButton={true}
      >
        <SendMessageSuccessPopup />
      </Popup>
      <ErrorPopup
        popUpStyles="error-popup-design"
        errorMessage={errorInfo.errorMessage}
        errorPopupFlag={errorInfo.errorFlag}
        errorCode={errorInfo.errorCode}
        showTitle={false}
        handleBackButton={() =>
          setErrorInfo({ ...errorInfo, errorFlag: false })
        }
        buttontitle="Done"
        isShortSentence={true}
      />
      <Popup
        openFlag={showLoader}
        hideCloseButton={true}
        closeHandler={() => {}}
      >
        {spinner()}
      </Popup>
    </div>
  );
};
