import { useContext } from "react";
import { ReactComponent as IconOrderTab } from "../../../../assets/Icons_order_tab.svg";
import { ReactComponent as Navigate } from "../../../../assets/Vector_navigate.svg";
import {
  OrderDetailContext,
  OrderDetailContextType,
} from "../../../../context/OrderDetailsContext";
import OrderDetailAlertBanner from "../orderDetailsAlertBanner/orderDetailsAlertBanner.component";
import { IOrderCurrentTherapy } from "../orderOverview/orderOverview.interface";
import {
  OrderOverViewTabsTitle,
  alertTypes,
} from "../orderOverview/orderOverviewContainer.enum";
import { useHistory } from "react-router-dom";
import "./orderCurrentTherapy.css";
import { ExpressButton } from "../../../../core/expressButton/expressButton.component";
import OrderSupplyDetail from "../orderSupplyDetail/orderSupplyDetails.component";
import { NavigatePatientAction } from "../orderOverview/navigatePatientAction";
import {
  SupplyOrderContext,
  SupplyOrderContextType,
} from "../../../../context/SupplyOrderContext";
import { ISupplyOrdersInfo } from "../orderSupplyDetail/orderSupplyDetails.interface";
import {
  PickUpRequestContext,
  PickUpRequestContextType,
} from "../../../../context/PickUpRequestContext";
import {
  DischargeRequestContext,
  DischargeRequestContextType,
} from "../../../../context/DischargeRequestContext";
import "./orderCurrentTherapy.css";
import {
  ConfirmPlacementContext,
  ConfirmPlacementContextType,
} from "../../../../context/ConfirmPlacementContext";
import {
  SubmitProofOfDeliveryContext,
  SubmitProofOfDeliveryContextType,
} from "../../../../context/submitProofOfDeliveryContext";
import {
  getSiteUseId,
  makeCapitalEachWordInString,
  maskProductInUse,
} from "../../../../util/utilityFunctions";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../../context/RolesPermissionContext";
import { Grid, useMediaQuery } from "@mui/material";
import {
  InternalSignOnContext,
  InternalSignOnContextType,
} from "../../../../context/InternalSignOnContext";
import {
  ExchangeVacUnitContext,
  ExchangeVacUnitContextType,
} from "../../../../context/ExchangeVacUnitContext";
import {
  AcuteOrderPickUpRequestContextType,
  AcuteOrderPickUpRequestContext,
} from "../../../../context/AcutePickUpRequestContext";
import {
  CreateServiceRequestContext,
  CreateServiceRequestContextType,
} from "../../../../context/CreateServiceRequestContext";
import {
  InpatientSupplyOrderContextType,
  InpatientSupplyOrderContext,
} from "../../../../context/InpatientSupplyOrderContext";
import { InpatientSupplyOrderPageSection } from "../../../inpatientSupplyOrder/inpatientSupplyOrder.enum";
import { getBlankForNullValue } from "../orderOverview/orderOverviewResponseMapper";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE } from "../../../../util/errorCode";
import { SVC_GET_CUSTOMER_INFORMATION_ERROR } from "../../../../util/errorMsg";
import { getCustomerInformation } from "../../../../util/inPatientOrderService";
import { IBillingAddress } from "../../../inpatientOrder/components/billingAddress/billingAddress.interface";
import { IInpatientOrder } from "../../../inpatientOrder/inpatientOrder.interface";
import { IInpatientSupplyOrder } from "../../../inpatientSupplyOrder/inpatientSupplyOrder.interface";
import { AuthContextType, AuthContext } from "../../../../context/AuthContext";
import { IOrderDetailResponse } from "../orderDetailsTracking/orderDetailsTracking.interface";
import parse from "html-react-parser";

export const OrderCurrentTherapy = ({
  patientData,
  orderDetailsTrackingData,
  supplyOrderSuppliesDetail,
  alertsForRO,
  setShowLoader,
}: IOrderCurrentTherapy) => {
  const orderOverViewObj = useContext<OrderDetailContextType | null>(
    OrderDetailContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  let acceptedAlerts: Array<string> = [];
  acceptedAlerts = Object.values(alertTypes).map((element: any) => element);
  const error = orderOverViewObj?.error;
  const history: any = useHistory();
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isSmallerMobileScreen = useMediaQuery("(max-width:320px)");
  const isAllMobileScreen = useMediaQuery("(max-width:920px)");

  const handleNavigateToOrderDetail = () => {
    orderOverViewObj?.setIsOrderFlow(true);
    orderOverViewObj?.setOrderTabTitle(
      OrderOverViewTabsTitle.ORDER_DETAIL_TAB_TITLE
    );
    if (isAllMobileScreen) {
      orderOverViewObj?.setIsTabHeaderVisible(false);
      permissionObj?.setIsMobileViewVisible!(false);
    }
  };
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const internalSignOnObj = useContext<InternalSignOnContextType | null>(
    InternalSignOnContext
  );
  const SupplyOrderObj = useContext<SupplyOrderContextType | null>(
    SupplyOrderContext
  );
  const pickUpRequestObj = useContext<PickUpRequestContextType | null>(
    PickUpRequestContext
  );
  const dischargeRequestObj = useContext<DischargeRequestContextType | null>(
    DischargeRequestContext
  );
  const confirmPlacementObj = useContext<ConfirmPlacementContextType | null>(
    ConfirmPlacementContext
  );
  const exchangeVacUnitObj = useContext<ExchangeVacUnitContextType | null>(
    ExchangeVacUnitContext
  );
  const submitProofOfDeliveryObj =
    useContext<SubmitProofOfDeliveryContextType | null>(
      SubmitProofOfDeliveryContext
    );
  const acuteOrderPickUpRequestObj =
    useContext<AcuteOrderPickUpRequestContextType | null>(
      AcuteOrderPickUpRequestContext
    );
  const createServiceRequestObj =
    useContext<CreateServiceRequestContextType | null>(
      CreateServiceRequestContext
    );
  const inpatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );

  const navigateToSupplyOrder = () => {
    if (!patientData?.isAcuteOrder) {
      SupplyOrderObj?.setCondensedView(true);
      NavigatePatientAction(
        patientData!,
        "Order Supplies".toLowerCase(),
        () => {},
        () => {},
        () => {},
        SupplyOrderObj,
        null,
        null,
        history,
        null,
        () => {},
        pickUpRequestObj,
        dischargeRequestObj,
        confirmPlacementObj,
        submitProofOfDeliveryObj,
        exchangeVacUnitObj,
        acuteOrderPickUpRequestObj,
        createServiceRequestObj,
        () => {}
      );
    } else {
      navigateToInpatientSupplyOrderView();
    }
  };

  const navigateToInpatientSupplyOrderView = async () => {
    let tempData = {
      ...inpatientSupplyOrderObj!.data,
      patientAndProduct: {
        patient: {
          firstName:
            orderOverViewObj?.orderDetailsTrackingData?.patientFirstName!,
          lastName:
            orderOverViewObj?.orderDetailsTrackingData?.patientLastName!,
          dateOfBirth: patientData?.dob,
          roNumber: patientData?.roNumber,
          patientLocation: patientData?.patientLocation,
        },
        product: {
          imageLink: getBlankForNullValue(
            orderOverViewObj?.orderDetailsTrackingData?.imageLink
          ),
          name: getBlankForNullValue(
            orderOverViewObj?.orderDetailsTrackingData?.brandName
          ),
          productCode:
            orderOverViewObj?.orderDetailsTrackingData?.rentalProduct,
          serialNumber:
            orderOverViewObj?.orderDetailsTrackingData?.serialNumber ?? "",
        },
      },
    };
    let poTemp = await getCustomerInformationAPI(tempData);
    inpatientSupplyOrderObj?.setData(poTemp!);
    window.scrollTo(0, 0);
    inpatientSupplyOrderObj?.setOrderProgress(40);
    inpatientSupplyOrderObj?.setPage(
      InpatientSupplyOrderPageSection.FIRST_PAGE
    );
    history.push("/orders/inpatientSupplyOrder");
  };

  const getCustomerInformationAPI = async (tempData: IInpatientSupplyOrder) => {
    const siteUseId = getSiteUseId(authObj);
    if (siteUseId !== "") {
      const params = {
        siteUseId: siteUseId,
      };
      setShowLoader!(true);
      const response = await getCustomerInformation(params);
      if (response && response.succeeded) {
        let poTempData: IInpatientSupplyOrder = {
          ...tempData,
          salesPurchaseOrderInformation: {
            ...tempData.salesPurchaseOrderInformation,
            salesPONumber: {
              ...tempData.salesPurchaseOrderInformation?.salesPONumber,
              required: response.item.isSalesPORequired,
              valid: ValidationStatus.VALID,
              value: response.item.rentalPOValue,
            },
            isPoRequired:
              response.item.rentalPOValue === "NOT REQUIRED" ? false : true,
          },
        };
        setShowLoader!(false);
        return poTempData;
      } else {
        let poTempData: IInpatientSupplyOrder = {
          ...tempData,
          salesPurchaseOrderInformation: {
            ...tempData.salesPurchaseOrderInformation,
            salesPONumber: {
              ...tempData.salesPurchaseOrderInformation?.salesPONumber,
              required: true,
              valid: ValidationStatus.VALID,
              value: "",
            },
            isPoRequired: null,
          },
        };
        setShowLoader!(false);
        return poTempData;
      }
    }
  };

  const getProductName = (
    orderDetailsTrackingData: IOrderDetailResponse
  ): string => {
    let productName: string = "";
    if (patientData.isAcuteOrder) {
      productName = orderDetailsTrackingData.brandName ?? "";
    } else {
      productName = orderDetailsTrackingData.rentalProduct;
    }
    return productName;
  };

  return (
    <>
      {(orderDetailsTrackingData || error) && (
        <div className="current-therapy-form">
          <div className="current-therapy-info">
            <div className="current-therapy-info-header">
              <h2
                className="current-therapy-info-title"
                data-testId="sec-header"
              >
                Current Therapy
              </h2>
            </div>
          </div>
          <div className="order-current-therapy-detail-component">
            <div className="order-current-therapy-detail-container">
              <div className="current-therapy-icon-div">
                <IconOrderTab className="icon" />
              </div>

              <div className="current-therapy-details">
                <h4 className="order-current-therapy-label">
                  {orderDetailsTrackingData &&
                  (orderDetailsTrackingData.brandName ||
                    orderDetailsTrackingData.rentalProduct)
                    ? parse(getProductName(orderDetailsTrackingData))
                    : "Product Not Available"}
                </h4>
                <div className="order-current-therapy-status-section">
                  <span
                    className="order-current-therapy-status-label"
                    data-testId="status-testid"
                  >
                    Status :
                  </span>
                  <div
                    className="oval-current-therapy"
                    style={{
                      backgroundColor:
                        patientData?.statusColor === "blueCircle"
                          ? "blue"
                          : patientData?.statusColor === "orangeCircle"
                          ? "orange"
                          : patientData?.statusColor === "yellowCircle"
                          ? "yellow"
                          : patientData?.statusColor === "redCircle"
                          ? "red"
                          : patientData?.statusColor === "greenCircle"
                          ? "green"
                          : patientData?.statusColor === "greyCircle"
                          ? "grey"
                          : patientData?.statusColor === "purpleCircle"
                          ? "purple"
                          : "",
                    }}
                  ></div>
                  <p className="order-current-therapy-status-label-value">
                    {patientData?.status}
                  </p>
                </div>
                <p
                  className="order-current-therapy-detail-label"
                  data-testId="current-therapy-content"
                >
                  RO# : {patientData?.roNumber}
                </p>
                <p
                  className="order-current-therapy-detail-label"
                  data-testId="current-therapy-loaction"
                >
                  Location :{" "}
                  {orderDetailsTrackingData?.deliverySiteType
                    ? makeCapitalEachWordInString(
                        orderDetailsTrackingData.deliverySiteType
                      )
                    : "--"}
                </p>
                <p className="order-current-therapy-detail-label">
                  Therapy Start Date :{" "}
                  {orderDetailsTrackingData?.therapyDate
                    ? orderDetailsTrackingData.therapyDate &&
                      new Date(
                        orderDetailsTrackingData.therapyDate
                      ).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                    : "--"}
                </p>
              </div>
              <div className="order-current-therapy-navigate-icon-div">
                <Navigate
                  className="navigate-icon"
                  onClick={() => {
                    handleNavigateToOrderDetail();
                  }}
                />
              </div>
            </div>

            {alertsForRO?.alerts
              .filter((item) => acceptedAlerts.includes(item.alertName))
              .map((alert) => (
                <div className="alert-banner">
                  <OrderDetailAlertBanner
                    alertData={alert}
                    key={alert.alertID.toString()}
                    patientData={patientData}
                    alertsForRO={alertsForRO}
                  />
                </div>
              ))}
          </div>
          <div className="space-bottom"></div>
          <div className="order-supplies-info">
            <div className="order-supplies-info-header">
              <h2
                className="order-supplies-info-title"
                data-testId="order-supplies-info-title"
              >
                Supply Orders
              </h2>
            </div>
            {!internalSignOnObj?.isManualFacility &&
              patientData?.status !== "Closed" && (
                <div className="order-supplies-info-button">
                  <ExpressButton
                    clickHandler={navigateToSupplyOrder}
                    parentClass="order-supplies-button"
                    variant="outlined"
                    testId="order-supplies-button"
                  >
                    Order Supplies
                  </ExpressButton>
                </div>
              )}
          </div>
          <div className="SupplyOrderdivMain">
            {supplyOrderSuppliesDetail &&
              supplyOrderSuppliesDetail?.supplyOrders &&
              supplyOrderSuppliesDetail?.supplyOrders.map(
                (rec: ISupplyOrdersInfo, index: number) => {
                  return (
                    <div className="order-supplies-detail-container">
                      <OrderSupplyDetail
                        supplyOrderData={rec}
                        therapyStartDate={
                          supplyOrderSuppliesDetail.therapyStartDate!
                        }
                        alertsForRO={alertsForRO}
                        patientData={patientData}
                      />
                    </div>
                  );
                }
              )}
            {supplyOrderSuppliesDetail?.supplyOrders.length === 0 &&
              supplyOrderSuppliesDetail && (
                <div className="order-supplies-not-present">
                  <h4 className="order-supplies-not-present-text">
                    Dressing and Supply orders detail not available
                  </h4>
                </div>
              )}
          </div>
          {/* If required will add this */}
          <div className="space-bottom"></div>
        </div>
      )}
    </>
  );
};
