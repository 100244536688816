import { Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import { ScrollableTabHeader } from "../../../../core/scrollableTabs/scrollableTabHeader.component";
import "./preReleaseDataContent.css";

const tabClasses = {
  textColorPrimary: "post-release-scroll-tabs-textlabel",
  selected: "post-release-scroll-tabs-textlabel-select",
  root: "scroll-tabs-height",
};

const PreReleaseContent: React.FC = () => {
  const preReleaseTabs = [
    { label: "Order Entry", testId: "post-release-order-entry-tab" },
    {
      label: "Communication Process (internal vs external?)",
      testId: "post-release-communication-process-tab",
    },
    {
      label: "Release Policy/exceptions?",
      testId: "post-release-release-and-exception-tab",
    },
  ];
  const [tabValue, setTabValue] = useState<number>(0);
  return (
    <div className="post-release-content-container">
      <Typography
        className="post-release-content-title"
        data-testid="post-release-content-title"
      >
        Pre-release Content
      </Typography>
      <div className="post-release-scroll-table-header">
        <ScrollableTabHeader
          scrollTabValue={tabValue}
          handleChange={(e: any, value: number) => setTabValue(value)}
          tabsIndicator="scroll-tabs-indicator"
          tabsRoot="scroll-tabs"
          variant="scrollable"
        >
          {preReleaseTabs.map(({ label, testId }) => (
            <Tab
              wrapped
              key={testId}
              classes={tabClasses}
              disableRipple
              iconPosition="end"
              label={label}
              data-testid={testId}
            />
          ))}
        </ScrollableTabHeader>
      </div>
    </div>
  );
};

export default PreReleaseContent;
